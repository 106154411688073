<button
    class="pwa-select-button"
    [class.without-box]="!boxDisplay"
    [class.without-border]="noBorder"
    [class.open]="open"
    type="button"
    (click)="onToggle()"
    data-testid="select"
    #button
>
    <div>{{ getButtonLabel() }}</div>
</button>
<div class="pwa-select-list" [class.open]="open" [class.search]="showSearch" #list>
    <div *ngIf="showSearch" class="pwa-select-search">
        <input type="text" [placeholder]="'general' | translate : 'search'" [(ngModel)]="searchText" />
    </div>
    <pwa-option
        *ngFor="let option of options; let index = index"
        (select)="onSelect(option)"
        [value]="option"
        [selected]="isSelected(option)"
        [searchText]="searchText"
        [attr.data-testid]="'pwa-option-' + index"
    >
        <ng-container *ngTemplateOutlet="selectOptionTemplate; context: getContext(option)"></ng-container>
    </pwa-option>
</div>

<ng-template let-option #noTemplate>
    {{ option }}
</ng-template>
