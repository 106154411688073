import { Directive, OnDestroy, OnInit, Optional, TemplateRef, ViewContainerRef } from '@angular/core';
import { PwaRecordsComponent } from './pwa-records.component';

@Directive({
    selector: '[isOnPWA]',
})
export class PwaIsOnPwaDirective implements OnInit, OnDestroy {
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        @Optional() private host: PwaRecordsComponent
    ) {}

    ngOnInit() {
        if (this.host) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }

    ngOnDestroy() {
        this.viewContainer.clear();
    }
}

@Directive({
    selector: '[isNotOnPWA]',
})
export class PwaIsNotOnPwaDirective implements OnInit, OnDestroy {
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        @Optional() private host: PwaRecordsComponent
    ) {}

    ngOnInit() {
        if (!this.host) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }

    ngOnDestroy() {
        this.viewContainer.clear();
    }
}
