import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    ElementRef,
    EmbeddedViewRef,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    Type,
    ViewContainerRef,
} from '@angular/core';
import { BaseRecordComponent, RecordMetadata } from '../base-record.component';
import { EmailRecordComponent } from '../complex-records/email-record/email-record.component';
import { TextRecordComponent } from '../complex-records/text-record/text-record.component';
import { BankRecordComponent } from '../complex-records/bank-record/bank-record.component';
import { CompanyRecordComponent } from '../complex-records/company-record/company-record.component';
import { SubcategoryRecordComponent } from '../complex-records/subcategory-record/subcategory-record.component';
import { DateRecordComponent } from '../complex-records/date-record/date-record.component';
import { IntegerRecordComponent } from '../complex-records/integer-record/integer-record.component';
import { FloatRecordComponent } from '../complex-records/float-record/float-record.component';
import { MoneyAmountRecordComponent } from '../complex-records/money-amount-record/money-amount-record.component';
import { GrantedAmountRecordComponent } from '../complex-records/granted-amount-record/granted-amount-record.component';
import { PostcodeRecordComponent } from '../complex-records/postcode-record/postcode-record.component';
import { MobileRecordComponent } from '../complex-records/mobile-record/mobile-record.component';
import { BigTextRecordComponent } from '../complex-records/big-text-record/big-text-record.component';
import { UrlRecordComponent } from '../complex-records/url-record/url-record.component';
import { BoardMeetingRecordComponent } from '../complex-records/board-meeting-record/board-meeting-record.component';
import { SchemaRecordComponent } from '../complex-records/schema-record/schema-record.component';
import { ResponsibleCaseworkerRecordComponent } from '../complex-records/responsible-caseworker-record/responsible-caseworker-record.component';
import { AssigneeUserRecordComponent } from '../complex-records/assignee-user-record/assignee-user-record.component';
import { BooleanDefaultFalseRecordComponent } from '../complex-records/boolean-default-false-record/boolean-default-false-record.component';
import { AcceptanceRecordComponent } from '../complex-records/acceptance-record/acceptance-record.component';
import { UploadRecordComponent } from '../complex-records/upload-record/upload-record-component';
import { CaseworkerUploadRecordComponent } from '../caseworker-records/caseworker-upload-record/caseworker-upload-record.component';
import { CorrespondenceTemplatePdfGeneratorComponent } from '../complex-records/correspondence-template-pdf-generator/correspondence-template-pdf-generator.component';
import { EconomyRecordComponent } from '../complex-records/economy-record/economy-record.component';
import { CountryRecordComponent } from '../complex-records/country-record/country-record.component';
import { PaymentPlanRecordComponent } from '../complex-records/payment-plan-record/payment-plan-record.component';
import { PaymentRecordComponent } from '../complex-records/payment-record/payment-record.component';
import { PostGrantStatusRecordComponent } from '../complex-records/post-grant-status-record/post-grant-status-record.component';
import { CustomListRecordComponent } from '../complex-records/custom-list-record/custom-list-record.component';
import { MultiLineRecordComponent } from '../complex-records/multi-line-record/multi-line-record.component';
import { Subscription } from 'rxjs';
import cloneDeep from 'lodash/cloneDeep';
import { PaymentRequesterRecordComponent } from '../complex-records/payment-requester-record/payment-requester-record.component';
import { BackendUserRecordComponent } from '../complex-records/backend-user-record/backend-user-record.component';

export const SEARCH_RECORD_TYPES: { [key: string]: Type<BaseRecordComponent> } = {};

export const RECORD_TYPES: { [key: string]: Type<BaseRecordComponent> } = {
    EmailRecord: EmailRecordComponent,
    TextRecord: TextRecordComponent,
    FirstnameRecord: TextRecordComponent,
    LastnameRecord: TextRecordComponent,
    TitleRecord: TextRecordComponent,
    CVRRecord: TextRecordComponent,
    CPRRecord: TextRecordComponent,
    CaseNumberRecord: TextRecordComponent,
    BankRecord: BankRecordComponent,
    CompanyRecord: CompanyRecordComponent,
    SubCategoryRecord: SubcategoryRecordComponent,
    DateRecord: DateRecordComponent,
    IntegerRecord: IntegerRecordComponent,
    FloatRecord: FloatRecordComponent,
    DueBalanceRecord: MoneyAmountRecordComponent,
    SuggestedAmountRecord: MoneyAmountRecordComponent,
    GrantedAmountRecord: GrantedAmountRecordComponent,
    PostcodeRecord: PostcodeRecordComponent,
    MobileRecord: MobileRecordComponent,
    BigTextRecord: BigTextRecordComponent,
    URLRecord: UrlRecordComponent,
    BoardMeetingRecord: BoardMeetingRecordComponent,
    SchemaRecord: SchemaRecordComponent,
    ResponsibleCaseworkerRecord: ResponsibleCaseworkerRecordComponent,
    AssignedUserRecord: AssigneeUserRecordComponent,
    BooleanDefaultFalseRecord: BooleanDefaultFalseRecordComponent,
    AcceptanceRecord: AcceptanceRecordComponent,
    UploadRecord: UploadRecordComponent,
    CaseworkerUploadRecord: CaseworkerUploadRecordComponent,
    CorrespondenceTemplatePDFGeneratorRecord: CorrespondenceTemplatePdfGeneratorComponent,
    EconomyRecord: EconomyRecordComponent,
    CountryRecord: CountryRecordComponent,
    PaymentPlanRecord: PaymentPlanRecordComponent,
    PaymentRecord: PaymentRecordComponent,
    PostGrantStatusRecord: PostGrantStatusRecordComponent,
    CustomListRecord: CustomListRecordComponent,
    MultiLineRecord: MultiLineRecordComponent,
    PaymentRequesterRecord: PaymentRequesterRecordComponent,
    BackendUserRecord: BackendUserRecordComponent,
};

@Component({
    selector: 'record-resolver',
    templateUrl: 'record-resolver.component.html',
})
export class RecordResolverComponent implements OnChanges, AfterViewInit, OnDestroy {
    @Input() uuid: string;
    @Input() value: object;
    @Input() isRequired: boolean;
    @Input() type: string;
    @Input() size: number;
    @Input() title: string;
    @Input() public readonly = false;
    @Input() onSearch = false;
    @Input() configuration: object;
    @Input() hasDynamicPrefilling: boolean;
    @Input() errorMessage: string;
    // tslint:disable-next-line:no-output-on-prefix
    @Output() onChange = new EventEmitter<object>();
    @Output() initHeight = new EventEmitter<number>();
    @Output() afterInit = new EventEmitter();

    private mountedComponentRef?: ComponentRef<BaseRecordComponent>;
    private onChangeSub: Subscription = Subscription.EMPTY;

    constructor(
        private el: ElementRef,
        private viewContainerRef: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver
    ) {}

    @HostBinding('attr.data-record-uuid')
    get recordUuid(): string {
        return this.uuid;
    }

    get child(): BaseRecordComponent | null {
        return this.mountedComponentRef ? this.mountedComponentRef.instance : null;
    }

    static getComponentForType(type: string, onSearch: boolean): Type<BaseRecordComponent> | null {
        if (onSearch) {
            if (Object.keys(SEARCH_RECORD_TYPES).includes(type)) {
                return SEARCH_RECORD_TYPES[type];
            }
        }
        if (!Object.keys(RECORD_TYPES).includes(type)) {
            // throw error in a timeout to not break the site render on missing record
            setTimeout(() => {
                throw new Error(
                    `Unknown record type '${type}'. Did you forget to define this record type in RecordResolverComponent?`
                );
            });
            return null;
        }
        return RECORD_TYPES[type];
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.type && changes.type.currentValue !== changes.type.previousValue) {
            this.mountComponent();
            return;
        }

        if (this.child) {
            if (changes.errorMessage) {
                this.child.setErrorMessage(this.errorMessage);
            }

            if (changes.onSearch) {
                this.child.setOnSearch(this.onSearch);
            }

            if (changes.readonly) {
                this.child.setReadonly(this.readonly);
            }

            if (changes.value) {
                this.child._setValue(this.value);
            }
        }
    }

    public ngAfterViewInit(): void {
        setTimeout(() => {
            this.afterInit.emit();
        });
    }

    public ngOnDestroy(): void {
        if (!this.onChangeSub.closed) this.onChangeSub.unsubscribe();
    }

    public isValid(): boolean {
        if (!this.child) return true;
        return this.child.isValid();
    }

    public isChanged(): boolean {
        if (!this.child) return false;
        return this.child.isChanged();
    }

    public isEmpty(): boolean {
        if (!this.child) return false;
        return this.child.isEmpty();
    }

    public getValue(): object {
        if (!this.child) return this.value;
        return this.child.getValue();
    }

    private mountComponent() {
        this.unmountComponent();

        const factory = RecordResolverComponent.getComponentForType(this.type, this.onSearch);
        if (!factory) return;

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory<BaseRecordComponent>(factory);
        this.mountedComponentRef = this.viewContainerRef.createComponent(componentFactory);

        this.onChangeSub = this.mountedComponentRef.instance.onChange.subscribe(event => {
            this.onChange.emit(event);
        });
        const metadata = new RecordMetadata(
            this.uuid,
            Object.freeze(cloneDeep(this.value)),
            this.isRequired,
            this.size,
            this.title,
            this.configuration,
            this.hasDynamicPrefilling
        );
        this.child._init(metadata, this.readonly, this.onSearch, this.errorMessage);

        // set data-record and data-record-uuid on a record host
        (this.mountedComponentRef.hostView as EmbeddedViewRef<any>).rootNodes[0].dataset.record = '';
        (this.mountedComponentRef.hostView as EmbeddedViewRef<any>).rootNodes[0].dataset.recordUuid = this.uuid;

        // set data-pdf-part on record
        (this.mountedComponentRef.hostView as EmbeddedViewRef<any>).rootNodes[0].dataset.pdfPart = '';
    }

    private unmountComponent() {
        if (!this.onChangeSub.closed) this.onChangeSub.unsubscribe();
        if (this.mountedComponentRef) {
            this.viewContainerRef.remove(this.viewContainerRef.indexOf(this.mountedComponentRef.hostView));
        }
        delete this.mountedComponentRef;
    }
}
