import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    Output,
    QueryList,
    TemplateRef,
} from '@angular/core';
import { SlideTabComponent } from './slide-tab.component';
import { animate, query, sequence, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'slide-tabs',
    templateUrl: './slide-tabs.component.html',
    styleUrls: ['./slide-tabs.component.scss'],
    animations: [
        trigger('tabsTransition', [
            transition(':increment', [
                query(':enter', [style({ opacity: 0, position: 'absolute', top: 0, left: 0 })], { optional: true }),
                query(':leave', [style({ opacity: 1 })], { optional: true }),
                sequence([
                    query(
                        ':leave',
                        [
                            sequence([
                                animate('300ms ease-in', style({ opacity: 0, transform: 'translateX(-30px)' })),
                                style({ position: 'absolute' }),
                            ]),
                        ],
                        { optional: true }
                    ),
                    query(
                        ':enter',
                        [
                            style({ transform: 'translateX(30px)', position: 'static' }),
                            animate('300ms ease-out', style({ opacity: 1, transform: 'translateX(0px)' })),
                        ],
                        { optional: true }
                    ),
                ]),
            ]),
            transition(':decrement', [
                query(':enter', [style({ opacity: 0, position: 'absolute', top: 0, left: 0 })], { optional: true }),
                query(':leave', [style({ opacity: 1 })], { optional: true }),
                sequence([
                    query(
                        ':leave',
                        [
                            sequence([
                                animate('300ms ease-in', style({ opacity: 0, transform: 'translateX(30px)' })),
                                style({ position: 'absolute' }),
                            ]),
                        ],
                        { optional: true }
                    ),
                    query(
                        ':enter',
                        [
                            style({ transform: 'translateX(-30px)', position: 'static' }),
                            animate('300ms ease-out', style({ opacity: 1, transform: 'translateX(0px)' })),
                        ],
                        { optional: true }
                    ),
                ]),
            ]),
        ]),
    ],
})
export class SlideTabsComponent {
    @ContentChildren(SlideTabComponent) tabs: QueryList<SlideTabComponent>;

    public transition = 0;
    private _currentTabIndex = 0;

    @Output() currentTabIndexChange = new EventEmitter<number>();

    constructor(private _cdRef: ChangeDetectorRef) {}

    @Input()
    get currentTabIndex(): number {
        return this._currentTabIndex;
    }

    set currentTabIndex(value: number) {
        this.transition = value - this._currentTabIndex;
        this._currentTabIndex = value;
        this._cdRef.markForCheck();
    }

    get selectedTabTemplate(): TemplateRef<any> | null {
        if (this.tabs.length === 0) return null;
        const template = this.tabs.toArray()[this.currentTabIndex].template;
        return template || null;
    }

    changeCurrentTabIndex(value: number) {
        this.currentTabIndex = value;
        this.currentTabIndexChange.emit(value);
    }
}
