import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PwaBoxComponent } from './pwa-box.component';

const COMPONENTS = [PwaBoxComponent];

@NgModule({
    declarations: [COMPONENTS],
    imports: [CommonModule],
    exports: [COMPONENTS],
})
export class PwaBoxModule {}
