import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PwaAlertComponent } from './pwa-alert.component';

const COMPONENTS = [PwaAlertComponent];

@NgModule({
    declarations: [COMPONENTS],
    imports: [CommonModule],
    exports: [COMPONENTS],
})
export class PwaAlertModule {}
