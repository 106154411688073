import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    forwardRef,
    Input,
    ChangeDetectorRef,
    OnDestroy,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { CustomListDto, CustomListValueDto } from '../../../../../../api/dto/custom-list';
import { CustomListDataService } from '../../../../../../services/custom-list-data.service';

export interface CustomListSubrecordValue {
    uuid: string | null;
}

export interface CustomListSubrecordConfiguration {
    custom_list_uuid: string;
    display_mode: 'radio_buttons' | 'dropdown';
}

@Component({
    selector: 'fonda-custom-list-subrecord',
    templateUrl: './custom-list-subrecord.component.html',
    styleUrls: ['./custom-list-subrecord.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CustomListSubrecordComponent), multi: true },
    ],
})
export class CustomListSubrecordComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @Input() set configuration(configuration: CustomListSubrecordConfiguration) {
        this.customListUuid.next(configuration.custom_list_uuid);
        this._configuration = configuration;
    }
    get configuration(): CustomListSubrecordConfiguration {
        return this._configuration;
    }
    private _configuration: CustomListSubrecordConfiguration;

    private destroy = new Subject();
    private customListUuid = new ReplaySubject<string>(1);
    customListValues: CustomListValueDto[] = [];

    isDisabled: boolean = false;
    value: CustomListSubrecordValue = { uuid: null };
    onChange: (value: CustomListSubrecordValue) => void = () => {};
    onTouched: () => void = () => {};

    constructor(
        private readonly customListDataService: CustomListDataService,
        private readonly cdRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.customListUuid
            .pipe(
                switchMap(uuid => (uuid ? this.customListDataService.getCustomListByUuid(uuid) : of(null))),
                map((list: CustomListDto | null) => (list ? list.values : [])),
                takeUntil(this.destroy)
            )
            .subscribe(values => {
                this.customListValues = values;
                this.cdRef.markForCheck();
            });
    }

    ngOnDestroy() {
        this.destroy.next();
        this.destroy.complete();
    }

    writeValue(obj: any): void {
        if (obj && obj.uuid && typeof obj.uuid === 'string') {
            this.value = { uuid: obj.uuid };
        } else {
            this.value = { uuid: null };
        }
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    viewToModelUpdate(uuid: string | null): void {
        this.value = { uuid };
        this.onChange(this.value);
    }
}
