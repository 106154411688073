import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'pwa-list',
    templateUrl: './pwa-list.component.html',
    styleUrls: ['./pwa-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'pwa-list',
    },
})
export class PwaListComponent {}
