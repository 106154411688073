<ng-container *isNotOnPWA>
    <ng-container *ngIf="!isReadonly">
        <div
            *ngIf="displayMode === 'dropdown'"
            class="form-item"
            [class.half]="metadata.size === 1"
            [class.full]="metadata.size === 2"
        >
            <fonda-select
                [formControl]="control"
                [label]="metadata.uuid | translate : 'title'"
                [placeholder]="metadata.uuid | translate : 'title'"
                [options]="customListValues | filterCustomListValues : metadata.initialValue.uuid | pluck : 'uuid'"
                [boxDisplay]="false"
                [canUnselect]="false"
            >
                <ng-container *fondaSelectOption="let option">
                    <span class="custom-list-value">
                        {{ 'custom_list_value' | translate : option }}
                        <span *ngIf="isDisabled(option)" class="disabled-text">
                            ({{ 'general' | translate : 'disabled' }})
                        </span>
                    </span>
                </ng-container>
            </fonda-select>
        </div>

        <div
            *ngIf="displayMode === 'radio_buttons'"
            class="form-item"
            [class.half]="metadata.size === 1"
            [class.full]="metadata.size === 2"
        >
            <p class="radio-title">
                {{ metadata.uuid | translate : 'title' }}
                <span *ngIf="metadata.isRequired" class="mandatory">*</span>
            </p>
            <div class="radio-list" role="radiogroup">
                <label
                    *ngFor="
                        let option of customListValues
                            | filterCustomListValues : metadata.initialValue.uuid
                            | pluck : 'uuid'
                    "
                    class="radio custom-list-value"
                >
                    <input type="radio" [formControl]="control" [value]="option" />
                    {{ 'custom_list_value' | translate : option }}
                    <span *ngIf="isDisabled(option)" class="disabled-text">
                        ({{ 'general' | translate : 'disabled' }})
                    </span>
                </label>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="isReadonly">
        <a class="form-item" [class.full]="metadata.size === 2" [class.half]="metadata.size === 1">
            <p>{{ metadata.uuid | translate : 'title' }}</p>
            <span *ngIf="metadata.initialValue.uuid; else noValue" class="custom-list-value">
                {{ 'custom_list_value' | translate : metadata.initialValue.uuid }}
            </span>
            <ng-template #noValue>
                <span>-</span>
            </ng-template>
        </a>
    </ng-container>
</ng-container>

<ng-container *isOnPWA>
    <ng-container *ngIf="!isReadonly">
        <pwa-list-element
            *ngIf="displayMode === 'dropdown'"
            [class.half]="metadata.size === 1"
            [class.full]="metadata.size === 2"
        >
            <pwa-labelled-text [mandatory]="metadata.isRequired" [errorMessage]="errorMessage">
                <ng-container pwaLabelledTextLabel>
                    {{ metadata.uuid | translate : 'title' }}
                </ng-container>
                <ng-container pwaLabelledTextContent>
                    <pwa-select
                        class="custom-list-pwa-select"
                        [formControl]="control"
                        [placeholder]="metadata.uuid | translate : 'title'"
                        [options]="
                            customListValues | filterCustomListValues : metadata.initialValue.uuid | pluck : 'uuid'
                        "
                        [canUnselect]="false"
                        [boxDisplay]="false"
                    >
                        <ng-container *pwaSelectOption="let option">
                            {{ 'custom_list_value' | translate : option }}
                            <span *ngIf="isDisabled(option)" class="disabled-text">
                                ({{ 'general' | translate : 'disabled' }})
                            </span>
                        </ng-container>
                    </pwa-select>
                </ng-container>
            </pwa-labelled-text>
        </pwa-list-element>
        <pwa-list-element
            *ngIf="displayMode === 'radio_buttons'"
            [class.half]="metadata.size === 1"
            [class.full]="metadata.size === 2"
        >
            <pwa-labelled-text [mandatory]="metadata.isRequired" [errorMessage]="errorMessage">
                <ng-container pwaLabelledTextLabel>
                    {{ metadata.uuid | translate : 'title' }}
                </ng-container>
                <ng-container pwaLabelledTextContent>
                    <div role="radiogroup">
                        <pwa-radio
                            class="custom-list-pwa-radio"
                            *ngFor="
                                let option of customListValues
                                    | filterCustomListValues : metadata.initialValue.uuid
                                    | pluck : 'uuid'
                            "
                            label="{{ 'custom_list_value' | translate : option }}"
                        >
                            <input pwaRadio type="radio" [formControl]="control" [value]="option" />
                        </pwa-radio>
                    </div>
                </ng-container>
            </pwa-labelled-text>
        </pwa-list-element>
    </ng-container>
    <ng-container *ngIf="isReadonly">
        <pwa-list-element [class.full]="metadata.size === 2" [class.half]="metadata.size === 1">
            <pwa-labelled-text>
                <ng-container pwaLabelledTextLabel>
                    {{ metadata.uuid | translate : 'title' }}
                </ng-container>
                <ng-container pwaLabelledTextContent>
                    {{
                        metadata.initialValue.uuid
                            ? ('custom_list_value' | translate : metadata.initialValue.uuid)
                            : '-'
                    }}
                </ng-container>
            </pwa-labelled-text>
        </pwa-list-element>
    </ng-container>
</ng-container>
