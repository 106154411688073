import { Component } from '@angular/core';
import { BaseRecordComponent, RecordMetadata } from '../../base-record.component';
import { FondaApiService } from '../../../../api/fonda-api.service';
import { ApplicationTemplateDto } from '../../../../api/dto/search-dto/application-template-dto';
import { environment } from '../../../../../environments/environment';
import { UuidValue } from '../../values.types';

@Component({
    selector: 'post-grant-status-record',
    templateUrl: 'post-grant-status-record.component.html',
})
export class PostGrantStatusRecordComponent extends BaseRecordComponent<UuidValue, null> {
    postGrantStatuses: Array<string> = [];
    selectedStatus: string | null = null;

    constructor(private apiService: FondaApiService) {
        super();
    }

    initRecord(metadata: RecordMetadata<UuidValue, null>) {
        this.fetchPostGrantedStatuses();
    }

    isValid(): boolean {
        return this.metadata.isRequired && !this.isEmpty();
    }

    isEmpty(): boolean {
        return this.selectedStatus !== null;
    }

    getValue(): UuidValue {
        if (this.selectedStatus === null) return [];
        return { uuid: this.selectedStatus };
    }

    setValue(value: UuidValue) {
        this.selectedStatus = (!Array.isArray(value) && value.uuid) || null;
    }

    emitValue() {
        this.onChange.emit(this.getValue());
    }

    reset() {
        this.selectedStatus = null;
        this.emitValue();
    }

    private fetchPostGrantedStatuses() {
        this.apiService
            .getApplicationTemplate(environment.APPLICATION_TEMPLATE_UUID)
            .then(res => this.getStatuses(res))
            .then(res => (this.postGrantStatuses = res));
    }

    private async getStatuses(templateDto: ApplicationTemplateDto): Promise<Array<string>> {
        const statuses = new Set<string>();

        for (const schemaUuid of templateDto.schemas) {
            const schema = await this.apiService.getApplicationSchema(schemaUuid);
            schema.postGrantStatusSettings.map(s => s.name).forEach(s => statuses.add(s));
        }

        return Array.from(statuses);
    }
}
