import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { PopupManager } from '../../../../popup/popup-manager';
import { IPopup } from '../../../../popup/popup.interface';
import { PaymentProfileDto } from '../../../../../api/dto/payment-profile-dto/payment-profile-dto';
import { IPaymentRequesterRecordConfigurationData } from '../../../../../shared/record-configuration/configurations/payment-requester-record-configuration/payment-requester-record-configuration.component';
import { PaymentRequesterDto, PaymentTypeDto } from '../payment-types';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

const TYPES = Object.values(PaymentTypeDto);

export interface PaymentRecordPopupDto {
    payment: PaymentRequesterDto;
    blockBalance: number;
    isEdit: boolean;
    uuid: string;
    config: IPaymentRequesterRecordConfigurationData;
    paymentProfiles: Array<PaymentProfileDto>;
}

@Component({
    selector: 'payment-record-popup',
    templateUrl: './payment-record-popup.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentRecordPopupComponent
    implements OnInit, OnDestroy, IPopup<PaymentRequesterDto | null, PaymentRecordPopupDto>
{
    uuid: string;
    blockBalance: number;
    isEdit: boolean;
    config: IPaymentRequesterRecordConfigurationData;
    paymentProfiles: Array<PaymentProfileDto> = [];

    paymentForm: FormGroup = new FormGroup({
        payment_identifier: new FormControl(null),
        name: new FormControl(null, [Validators.required]),
        date: new FormControl(null, [Validators.required]),
        payment_profile: new FormControl(null, [Validators.required]),
        comment: new FormControl(null),
        attachments: new FormControl([]),
        type: new FormControl(null, [Validators.required]),
        payment_status: new FormControl(null),
        amount: new FormControl(null, [Validators.required]),
        grant_identifier: new FormControl(null),
        is_originally_from_applicant: new FormControl(null),
        unique_id: new FormControl(null),
    });

    types = TYPES;
    statuses = [];
    paymentProfileOptions = [];

    typeSub$ = Subscription.EMPTY;

    constructor(private popupManager: PopupManager<PaymentRequesterDto | null, PaymentRecordPopupDto>) {}

    ngOnInit() {
        if (this.popupManager.data) {
            this.paymentForm.patchValue(this.popupManager.data.payment);
            this.uuid = this.popupManager.data.uuid;
            this.blockBalance = this.popupManager.data.blockBalance;
            this.isEdit = this.popupManager.data.isEdit;
            this.config = this.popupManager.data.config;
            this.paymentProfiles = this.popupManager.data.paymentProfiles;
            this.paymentProfileOptions = this.paymentProfiles.map(p => p['uuid']);
            if (this.config.payment_statuses && this.config.payment_statuses.length) {
                this.statuses = this.config.payment_statuses
                    .map(status => status.name)
                    .filter(status => !status.includes('november'));
                this.paymentForm.get('payment_status').setValidators([Validators.required]);
                this.paymentForm.get('payment_status').updateValueAndValidity();
            }
            if (this.config.has_attachments) {
                this.paymentForm.get('attachments').setValidators([Validators.required]);
                this.paymentForm.get('attachments').updateValueAndValidity();
            }
            if (this.config.has_comments) {
                this.paymentForm.get('comment').setValidators([Validators.required]);
                this.paymentForm.get('comment').updateValueAndValidity();
            }

            this.typeSub$ = this.paymentForm.controls['type'].valueChanges.subscribe(type => {
                if (type === 'canceled') {
                    this.paymentForm.patchValue({ payment_status: 'approved' });
                }
            });
        } else {
            this.cancel();
        }
    }

    close(data) {
        return this.popupManager.close(data);
    }

    cancel() {
        this.close(null);
    }

    save() {
        this.close(this.paymentForm.value);
    }

    addFile(file) {
        this.paymentForm.patchValue({
            attachments: this.paymentForm.value.attachments.concat({
                uuid: file.uuid,
                file_name: file.fileName,
                file_size: file.size,
            }),
        });
    }

    deleteFile(uuid) {
        this.paymentForm.patchValue({
            attachments: this.paymentForm.value.attachments.filter(file => file.uuid !== uuid),
        });
    }

    getPaymentProfileByUuid(uuid) {
        return this.paymentProfiles.find(p => p.uuid === uuid);
    }

    getPaymentProfileName(uuid) {
        const profile = this.getPaymentProfileByUuid(uuid);
        return profile ? profile.name : '';
    }

    ngOnDestroy() {
        if (this.typeSub$) this.typeSub$.unsubscribe();
    }
}
