export class ApplicationSchema {
    constructor(public readonly uuid: string, public readonly canBeCreatedByApplicant: boolean) {}

    public static fromApiResponse({
        uuid,
        can_be_created_by_applicant,
    }: {
        uuid: string;
        can_be_created_by_applicant: boolean;
    }): ApplicationSchema {
        return new ApplicationSchema(uuid, can_be_created_by_applicant);
    }
}
