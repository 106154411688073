import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PwaAccordionListComponent } from './pwa-accordion-list/pwa-accordion-list.component';
import { PwaListComponent } from './pwa-list/pwa-list.component';
import { PwaListElementComponent } from './pwa-list-element/pwa-list-element.component';

const COMPONENTS = [PwaAccordionListComponent, PwaListComponent, PwaListElementComponent];

@NgModule({
    declarations: [COMPONENTS],
    imports: [CommonModule],
    exports: [COMPONENTS],
})
export class PwaListModule {}
