<div class="pwa-attachments-uploader">
    <pwa-input [reset]="hasReset && attachments.length" (onReset)="onReset()">
        <pwa-attachments-display
            [readonly]="false"
            (delete)="onDelete($event)"
            [attachments]="attachments"
            [mandatory]="mandatory"
        ></pwa-attachments-display>
        <file-upload-component *ngIf="!readonly" (fileAdded)="onAdd($event)">
            <div class="upload-icon"></div>
        </file-upload-component>
    </pwa-input>
</div>
