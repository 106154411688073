import { ChangeDetectorRef, Component, EventEmitter } from '@angular/core';
import { FondaApiService } from '../../api/fonda-api.service';
import { FileService } from '../../services/file.service';
import { UploadedFondaFile } from '../../models/uploaded-fonda-file';

class ConvertedConvertableFileModel extends UploadedFondaFile {
    picked = false;
    loading?: boolean;
    convertedFileModel?: ConvertedConvertableFileModel;
}

@Component({
    selector: 'files-converter-popup',
    templateUrl: 'files-converter-popup.component.html',
    styleUrls: ['files-converter-popup.component.scss'],
})
export class FilesConverterPopupComponent {
    constructor(private apiService: FondaApiService, private fileService: FileService) {}

    public filesToConvert: Array<ConvertedConvertableFileModel> = [];
    public pdfFiles: Array<ConvertedConvertableFileModel> = [];

    public filesConvertedEvent = new EventEmitter<Array<UploadedFondaFile>>();
    close = new EventEmitter();

    public async initPopup(
        files: Array<{
            uuid: string;
            name: string;
            size: number;
        }>,
        mimeTypesAllowed: Array<string>
    ) {
        this.filesToConvert = [];
        this.pdfFiles = [];
        for (const file of files) {
            const uploadedFileMetaData = await this.fileService.downloadMetaFile(file.uuid);
            if (!this.isNotConvertable(mimeTypesAllowed, uploadedFileMetaData)) {
                this.filesToConvert.push({ ...uploadedFileMetaData, loading: false, picked: true });
            } else {
                this.pdfFiles.push({ ...uploadedFileMetaData, loading: false, picked: true });
            }
        }
        if (this.filesToConvert.length < 1) {
            this.notConvert();
        }
    }

    public closePopup() {
        this.close.emit();
    }

    public notConvert() {
        this.filesConvertedEvent.emit(this.pdfFiles.concat(this.filesToConvert));
    }

    public async clickConvertSingle(file: ConvertedConvertableFileModel) {
        if (!file.loading) {
            file.loading = true;
            file = await this.convertSingleFile(file);
            file.loading = false;
            this.filesToConvert[this.filesToConvert.indexOf(this.filesToConvert.find(f => f.uuid === file.uuid))] =
                file;
        }
    }

    public save() {
        this.filesConvertedEvent.emit(
            this.pdfFiles
                .concat(this.filesToConvert)
                .concat(this.filesToConvert.map(_f => _f.convertedFileModel).filter(__f => __f && __f.picked))
        );
    }

    private async convertSingleFile(file: ConvertedConvertableFileModel): Promise<ConvertedConvertableFileModel> {
        return this.apiService
            .postFileConvertToPdf(file.uuid)
            .then(async res => {
                return {
                    ...file,
                    convertedFileModel: { ...(await this.fileService.downloadMetaFile(res)), picked: true },
                };
            })
            .catch(err => {
                return { ...file, convertedFileModel: { ...file, fileName: 'mocked-' + file.fileName } };
            });
    }

    private async convertFiles(): Promise<Array<ConvertedConvertableFileModel>> {
        const newFiles: Array<ConvertedConvertableFileModel> = [];
        for (const file of this.filesToConvert) {
            newFiles.push(await this.convertSingleFile(file));
        }
        return newFiles;
    }

    private isNotConvertable(mimeTypesAllowed: Array<string>, file: UploadedFondaFile): boolean {
        return !!mimeTypesAllowed.find(f => f == file.mimeType);
    }
}
