import { DOCUMENT } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostListener,
    Inject,
    Input,
    NgZone,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { createPopper, Instance as PopperInstance, Placement } from '@popperjs/core';

@Component({
    selector: 'pwa-error-message',
    templateUrl: 'pwa-error-message.component.html',
    styleUrls: ['pwa-error-message.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class PwaErrorMessageComponent {
    @ViewChild('icon', { static: true }) icon: ElementRef<HTMLDivElement>;

    @Input() errorMessage: string;
    @Input() placement: Placement = 'left';

    popperInstance: PopperInstance | null = null;
    div: HTMLDivElement;

    constructor(private ngZone: NgZone, @Inject(DOCUMENT) private document: Document) {}

    @HostListener('mouseenter') public onMouseOver() {
        this.div = document.createElement('div');
        this.div.classList.add('pwa-error-message-text');
        this.div.textContent = this.errorMessage;
        this.document.body.appendChild(this.div);
        this.ngZone.runOutsideAngular(() => {
            this.destroy();
            this.popperInstance = createPopper(this.icon.nativeElement, this.div, {
                placement: this.placement,
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 5],
                        },
                    },
                ],
            });
        });
    }

    @HostListener('mouseleave') public onMouseLeave() {
        if (this.div && this.div.parentNode) this.div.parentNode.removeChild(this.div);
        this.destroy();
    }

    destroy() {
        if (this.popperInstance) this.popperInstance.destroy();
        this.popperInstance = null;
    }

    ngOnDestroy() {
        this.onMouseLeave();
    }
}
