import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BaseRecordComponent, RecordMetadata } from '../../base-record.component';
import { TextValue } from '../../values.types';
import { setConditionalValidators } from '../../conditional-validators';

export interface BigTextRecordConfiguration {
    min_length: number;
    max_length: number | null;
}

@Component({
    selector: 'big-text-record',
    templateUrl: 'big-text-record.component.html',
})
export class BigTextRecordComponent extends BaseRecordComponent<TextValue, BigTextRecordConfiguration> {
    text = new FormControl('');

    get readonlyValue(): string {
        return this.text.value.replace(/\n/g, '<br />');
    }

    emitValue() {
        this.onChange.emit(this.getValue());
    }

    initRecord(metadata: RecordMetadata<TextValue, BigTextRecordConfiguration>) {
        setConditionalValidators(this.text, [
            [metadata.isRequired, Validators.required],
            [metadata.configuration.min_length, Validators.minLength(metadata.configuration.min_length)],
            [metadata.configuration.max_length, Validators.maxLength(metadata.configuration.max_length)],
        ]);
        this.registerControlChanges(this.text);
    }

    isValid(): boolean {
        return this.text.valid;
    }

    isEmpty(): boolean {
        return this.text.value === '';
    }

    getValue(): TextValue {
        return { text: this.text.value };
    }

    setValue(value: TextValue) {
        this.text.setValue(value.text || '');
    }
}
