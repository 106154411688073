import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class UserAgentService {
    /**
     * Checks user agent for user preferred language and returns one that fits the list of languages,
     * otherwise it returns null
     * @param {string[]} languages
     * @return {string}
     */
    getPreferredLanguage(languages: readonly string[] = []): string | null {
        if (window.navigator.languages) {
            return this.findLanguage(window.navigator.languages, languages);
        }

        // For those browsers which do not implement navigator.languages
        if (window.navigator.language) {
            return this.findLanguage([window.navigator.language], languages);
        }

        // IE Fallback
        if ((<any>window.navigator).userLanguage) {
            return this.findLanguage([(<any>window.navigator).userLanguage], languages);
        }

        return null;
    }

    private findLanguage(userLanguages: readonly string[], appLanguages: readonly string[]): string | null {
        return userLanguages.map(ul => appLanguages.find(al => ul.includes(al))).filter(Boolean)[0] || null;
    }
}
