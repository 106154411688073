export interface PaymentRequesterDto {
    payment_identifier: string;
    name: string;
    date: string;
    payment_profile: string;
    comment?: string;
    attachments?: Array<{
        file_size: number;
        file_name: string;
        uuid: string;
    }>;
    type: PaymentTypeDto;
    payment_status: string;
    amount: number;
    grant_identifier: string;
    is_originally_from_applicant: boolean;
    balance?: number; // var to store calculated balance
    unique_id?: string; // var to delete and edit payments
    nf_status?: string; // November First status (new feature)
}

export interface PaymentGrantedBlocksDto {
    grant_identifier: string;
    name: string;
    amount: number;
    board_meeting: string;
    economy_group: string;
    attachments?: Array<{
        file_size: number;
        file_name: string;
        uuid: string;
    }>;
    partial_granting_percentage: number;
    original_full_amount: number;
    uuid?: string;
    parentUuid?: string;
}

export interface PaymentStatusDto {
    name: string;
    initial: boolean;
    expected_next_status: string;
    affects_balance: boolean;
}

export enum PaymentTypeDto {
    PAID = 'paid',
    CANCELED = 'canceled',
    REFUNDED = 'refunded',
}
