import { BudgetDto } from '../dto/post-grant-dto/budget-dto';
import { CategoryPaymentDto } from '../dto/post-grant-dto/category-payment-dto';
import { FiscalYearBudgetDto } from '../dto/post-grant-dto/fiscal-year-budget-dto';
import { FiscalYearPaymentDto } from '../dto/post-grant-dto/fiscal-year-payment-dto';
import { PaymentProfileDto } from '../dto/payment-profile-dto/payment-profile-dto';
import { PaymentProfileRecordValueDto } from '../dto/payment-profile-dto/payment-profile-record-value-dto';

export class PaymentMapper {
    public static GetBudget(jsonDataObject: any): BudgetDto {
        const perCat: Array<{
            uuid: string;
            categoryPaymentDto: CategoryPaymentDto;
        }> = [];

        let total: CategoryPaymentDto;
        let yearBefore: number | null;
        let yearAfter: number | null;

        if (jsonDataObject) {
            for (const indKey in jsonDataObject.per_category) {
                perCat.push({
                    uuid: indKey,
                    categoryPaymentDto: new CategoryPaymentDto(
                        this.changeCommasToDots(jsonDataObject.per_category[indKey].budget),
                        this.changeCommasToDots(jsonDataObject.per_category[indKey].suggested),
                        this.changeCommasToDots(jsonDataObject.per_category[indKey].granted),
                        this.changeCommasToDots(jsonDataObject.per_category[indKey].remaining)
                    ),
                });
            }

            if (jsonDataObject.totals) {
                total = new CategoryPaymentDto(
                    this.changeCommasToDots(jsonDataObject.totals.budget),
                    this.changeCommasToDots(jsonDataObject.totals.suggested),
                    this.changeCommasToDots(jsonDataObject.totals.granted),
                    this.changeCommasToDots(jsonDataObject.totals.remaining)
                );
            }
        }

        return new BudgetDto(perCat, total);
    }

    public static GetCategoryBudget(
        jsonDataObject: any
    ): Array<{ uuid: string; categoryPaymentDto: CategoryPaymentDto }> {
        const perSubCat: Array<{
            uuid: string;
            categoryPaymentDto: CategoryPaymentDto;
        }> = [];

        for (const indKey in jsonDataObject) {
            perSubCat.push({
                uuid: indKey,
                categoryPaymentDto: new CategoryPaymentDto(
                    this.changeCommasToDots(jsonDataObject[indKey].budget),
                    this.changeCommasToDots(jsonDataObject[indKey].suggested),
                    this.changeCommasToDots(jsonDataObject[indKey].granted),
                    this.changeCommasToDots(jsonDataObject[indKey].remaining)
                ),
            });
        }
        return perSubCat;
    }

    public static FiscalYearPayment(jsonDataObject: any): FiscalYearPaymentDto {
        const budgets: Array<FiscalYearBudgetDto> = [];

        if (jsonDataObject) {
            jsonDataObject.budgets.forEach(budget => {
                budgets.push(
                    new FiscalYearBudgetDto(this.changeCommasToDots(budget.budget), budget.category_uuid, budget.year)
                );
            });
        }

        return new FiscalYearPaymentDto(
            budgets,
            jsonDataObject.surrounding_years ? jsonDataObject.surrounding_years.before : null,
            jsonDataObject.surrounding_years ? jsonDataObject.surrounding_years.after : null
        );
    }

    public static changeCommasToDots(str: string | number): string {
        if (str || str === 0) {
            return str.toString().replace('.', ',');
        } else {
            return '';
        }
    }

    public static getPaymentProfiles(jsonDataObject: any): Array<PaymentProfileDto> {
        const paymentProfiles: Array<PaymentProfileDto> = [];

        jsonDataObject.forEach(paymentProfile => {
            const recordValues: Array<PaymentProfileRecordValueDto> = [];
            if (paymentProfile.record_values) {
                paymentProfile.record_values.forEach(record => {
                    recordValues.push(
                        new PaymentProfileRecordValueDto(
                            record.uuid,
                            record.type,
                            record.field_size,
                            record.is_required,
                            record.value,
                            record.stringified_value,
                            record.is_editable,
                            record.configuration,
                            record.has_dynamic_prefillings
                        )
                    );
                });
            }
            paymentProfiles.push(
                new PaymentProfileDto(
                    paymentProfile.uuid,
                    paymentProfile.application_uuid,
                    paymentProfile.name,
                    paymentProfile.is_default,
                    recordValues
                )
            );
        });
        return paymentProfiles;
    }
}
