<div *ngIf="!isReadonly && !isOnSearch" class="form-item" [ngClass]="{ half: metadata.size === 1 }">
    <input
        id="{{ metadata.uuid }}"
        type="number"
        step="0.01"
        name="value2"
        placeholder=" "
        [value]="value"
        (input)="update('value', $event.target.value)"
        #valueInput
    />
    <label for="{{ metadata.uuid }}">
        {{ metadata.uuid | translate : 'title' }}
        <span *ngIf="metadata.isRequired" class="mandatory">*</span>
    </label>
    <div class="form-focus"></div>
    <div class="form-help"></div>
</div>

<ng-container *ngIf="!isReadonly && isOnSearch">
    <div style="display: flex; position: relative">
        <div class="form-item between even first">
            <input
                id="{{ metadata.uuid }}-float2"
                type="number"
                step="0.01"
                name="value2"
                placeholder=" "
                [value]="fromValue"
                (input)="update('fromValue', $event.target.value)"
                #fromValueInput
            />
            <label for="{{ metadata.uuid }}-float2">{{ metadata.title }} from</label>
            <div class="form-focus"></div>
        </div>
        <div class="form-item between even second">
            <input
                id="{{ metadata.uuid }}-float3"
                type="number"
                step="0.01"
                name="value2"
                placeholder=" "
                [value]="toValue"
                (input)="update('toValue', $event.target.value)"
                #toValueInput
            />
            <label for="{{ metadata.uuid }}-float3">{{ metadata.title }} to</label>
            <div class="form-focus"></div>
        </div>
    </div>
</ng-container>

<a class="form-item" *ngIf="isReadonly" [ngClass]="{ full: metadata.size === 2 }">
    <p>{{ metadata.uuid | translate : 'title' }}</p>
    <span>{{ metadata.initialValue.text || '-' }}</span>
    <div class="form-focus"></div>
    <div class="form-error"></div>
</a>
