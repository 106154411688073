<ng-container *isNotOnPWA>
    <section class="application-economy">
        <div *ngFor="let income of value; let i = index" class="economy-multiline">
            <div class="form-item">
                <input
                    [id]="'application-economy-income-new-title-' + i"
                    type="text"
                    (ngModelChange)="income.title = $event; onChange()"
                    [ngModel]="income.title"
                    placeholder=" "
                />
                <label [for]="'application-economy-income-new-title-' + i">{{
                    recordUuid | translate : 'income_title'
                }}</label>
                <div class="form-focus"></div>
                <div class="form-error"></div>
            </div>
            <div *ngIf="hasAttachments" class="form-item">
                <attachments-uploader
                    [attachments]="income.attachments"
                    (delete)="deleteFile($event, i); onChange()"
                    (add)="addFile($event, i); onChange()"
                    [mandatory]="hasAttachmentsMandatory"
                ></attachments-uploader>
            </div>
            <div class="form-item valuta">
                <input
                    [id]="'application-economy-income-new-amount-' + i"
                    type="text"
                    [fondaMoneyInput]="income.amount"
                    (fondaMoneyInputChange)="income.amount = $event; onChange()"
                    placeholder="0"
                />
                <label [for]="'application-economy-income-new-amount-income-' + i"></label>
                <div class="form-focus"></div>
                <div class="form-error"></div>
            </div>
            <a class="close-btn red smaller" (click)="deleteLine(i); onChange()"><span class="icon-close"></span></a>
        </div>

        <a class="button color-blue" (click)="addLine(); onChange()">
            {{ 'general' | translate : 'add_new' }}
        </a>

        <div *ngIf="total" class="form-item readonly budget valuta">
            <input
                [id]="'application-economy-income-wanted-amount-'"
                type="text"
                [fondaMoneyInput]="totalValue"
                placeholder="0"
                [disabled]="true"
            />
            <label [for]="'application-economy-income-wanted-amount-'">{{
                recordUuid | translate : 'incomes_overall'
            }}</label>
            <div class="form-focus"></div>
            <div class="form-error"></div>
        </div>
    </section>
</ng-container>

<ng-container *isOnPWA>
    <div class="pwa-mobile-view">
        <div *ngFor="let income of value; let i = index">
            <pwa-input [reset]="true" style="margin-bottom: 0.5rem">
                <input
                    pwaInput
                    [id]="'application-economy-income-new-title-' + i"
                    type="text"
                    (ngModelChange)="income.title = $event; onChange()"
                    [ngModel]="income.title"
                    placeholder="{{ recordUuid | translate : 'income_title' }}"
                />
            </pwa-input>

            <div *ngIf="hasAttachments" class="form-item">
                <pwa-attachments-uploader
                    [attachments]="income.attachments"
                    [mandatory]="hasAttachmentsMandatory"
                    (delete)="deleteFile($event, i); onChange()"
                    (add)="addFile($event, i); onChange()"
                    (reset)="resetFiles(i); onChange()"
                ></pwa-attachments-uploader>
            </div>

            <pwa-input [reset]="true" [currency]="true" style="margin-bottom: 0.5rem">
                <input
                    pwaInput
                    [id]="'application-economy-income-new-amount-' + i"
                    type="text"
                    [fondaMoneyInput]="income.amount"
                    (fondaMoneyInputChange)="income.amount = $event; onChange()"
                    placeholder="0"
                />
            </pwa-input>

            <a pwaButton color="pink" (click)="deleteLine(i); onChange()" style="width: 100%; margin-bottom: 0.5rem">
                {{ 'general' | translate : 'delete' }}
            </a>
        </div>
    </div>
    <div class="pwa-desktop-view">
        <div class="pwa-line pwa-line-head">
            <div class="pwa-column">{{ 'general' | translate : 'title' }}</div>
            <div *ngIf="hasAttachments" class="pwa-column">{{ 'general' | translate : 'attachments' }}</div>
            <div class="pwa-column">{{ 'general' | translate : 'amount' }}</div>
        </div>
        <div class="pwa-row" *ngFor="let income of value; let i = index">
            <div class="pwa-line">
                <div class="pwa-column">
                    <pwa-input>
                        <input
                            pwaInput
                            [id]="'application-economy-income-new-title-' + i"
                            type="text"
                            (ngModelChange)="income.title = $event; onChange()"
                            [ngModel]="income.title"
                            placeholder="{{ recordUuid | translate : 'income_title' }}"
                        />
                    </pwa-input>
                </div>
                <div *ngIf="hasAttachments" class="pwa-column">
                    <pwa-attachments-uploader
                        [attachments]="income.attachments"
                        [hasReset]="false"
                        [mandatory]="hasAttachmentsMandatory"
                        (delete)="deleteFile($event, i); onChange()"
                        (add)="addFile($event, i); onChange()"
                        (reset)="resetFiles(i); onChange()"
                    ></pwa-attachments-uploader>
                </div>
                <div class="pwa-column">
                    <pwa-input [currency]="true">
                        <input
                            pwaInput
                            [id]="'application-economy-income-new-amount-' + i"
                            type="text"
                            [fondaMoneyInput]="income.amount"
                            (fondaMoneyInputChange)="income.amount = $event; onChange()"
                            placeholder="0"
                        />
                    </pwa-input>
                </div>
            </div>
            <div class="pwa-row-button">
                <a pwaButton color="pink" (click)="deleteLine(i)">
                    <span class="icon-delete"></span>
                    {{ 'general' | translate : 'delete' }}
                </a>
            </div>
        </div>
    </div>

    <div class="pwa-add-button">
        <a pwaButton color="gray" (click)="addLine(); onChange()">
            {{ 'general' | translate : 'add_new' }}
        </a>
    </div>

    <pwa-alert *ngIf="total" type="success" class="is-flex-between">
        <span class="dark"> {{ recordUuid | translate : 'incomes_overall' }}: </span>
        <span>
            <strong>
                {{ numToStr(totalValue) | commasInInput }}
                <fonda-currency></fonda-currency>
            </strong>
        </span>
    </pwa-alert>
</ng-container>
