import { Component, ElementRef, ViewChild } from '@angular/core';
import { BaseRecordComponent, RecordMetadata } from '../../base-record.component';

export interface FloatRecordValue {
    text: number | [number, number];
}

export interface FloatRecordConfiguration {
    flag: 'balance' | 'due_balance';
}

@Component({
    selector: 'float-record',
    templateUrl: 'float-record.component.html',
})
export class FloatRecordComponent extends BaseRecordComponent<FloatRecordValue, FloatRecordConfiguration> {
    value: number | null = null;
    fromValue: number | null = null;
    toValue: number | null = null;

    @ViewChild('valueInput') valueInput: ElementRef<HTMLInputElement>;
    @ViewChild('fromValueInput') fromValueInput: ElementRef<HTMLInputElement>;
    @ViewChild('toValueInput') toValueInput: ElementRef<HTMLInputElement>;

    isDisabled = false;

    initRecord(metadata: RecordMetadata<FloatRecordValue, FloatRecordConfiguration>) {
        this.isDisabled = this.hasLockingConfiguration();
    }

    isValid(): boolean {
        return !this.metadata.isRequired || !this.isEmpty();
    }

    isEmpty(): boolean {
        return this.isOnSearch ? this.toValue === null && this.fromValue === null : this.value === null;
    }

    getValue(): FloatRecordValue {
        if (this.isOnSearch) {
            return { text: [this.fromValue, this.toValue] };
        }

        return { text: this.value };
    }

    setValue(value: FloatRecordValue) {
        if (Array.isArray(value.text)) {
            this.fromValue = value.text[0] || null;
            this.toValue = value.text[1] || null;
        } else {
            this.value = value.text || null;
        }
    }

    update(property: 'value' | 'fromValue' | 'toValue', value: string): void {
        let float: number | null = parseFloat(value);
        if (Number.isNaN(float)) float = null;
        (this as any)[property] = float;

        const nativeElement = (this as any)[property + 'Input'].nativeElement;
        if (nativeElement && float !== null) nativeElement.value = float.toString();

        this.onChange.emit(this.getValue());
    }

    private hasLockingConfiguration(): boolean {
        const flag = (this.metadata.configuration && this.metadata.configuration.flag) || '';
        return ['balance', 'due_balance'].includes(flag);
    }
}
