import {
    Component,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    OnInit,
    OnDestroy,
    ChangeDetectorRef,
} from '@angular/core';
import { ILanguage, LanguageStoreService, Language } from '../../../../services/language-store.service';
import { map, startWith, take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { SiteSettingsService } from '../../../../services/site-settings.service';

@Component({
    selector: 'language-select',
    templateUrl: './language-select.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectComponent implements OnInit, OnDestroy {
    languageList$ = this.languageStoreService.languageList$;
    currentLanguage: Language;
    showFlagForLanguages: boolean;
    @Output() handleSwitchTab = new EventEmitter<ILanguage>();

    private sub = Subscription.EMPTY;

    constructor(
        private languageStoreService: LanguageStoreService,
        private _cdRef: ChangeDetectorRef,
        private siteSettings: SiteSettingsService
    ) {}

    ngOnInit(): void {
        this.sub = this.languageStoreService.languageChange
            .pipe(
                map(() => this.languageStoreService.getLanguage()),
                startWith(this.languageStoreService.getLanguage())
            )
            .subscribe(lang => {
                this.currentLanguage = lang;
                this._cdRef.markForCheck();
            });
        this.siteSettings
            .getSettingSingle('show_flags_for_languages')
            .pipe(take(1))
            .subscribe(showFlagForLanguages => {
                this.showFlagForLanguages = showFlagForLanguages.unwrapOr(false);
            });
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    switchLang(language: ILanguage): void {
        this.languageStoreService.setLanguage(language.number);
        this.handleSwitchTab.emit(language);
    }
}
