<div *ngIf="!isOnSearch" class="payment-requester">
    <form ngNoForm>
        <ng-container *ngIf="grantedBlocks.length; else noBlocks">
            <ng-container *ngFor="let block of grantedBlocks">
                <payment-block
                    *ngIf="!block.parentUuid"
                    [payments]="getPaymentsByBlock(block.grant_identifier)"
                    [children]="getGrantedBlockChildren(block.uuid)"
                    [block]="block"
                    [uuid]="uuid"
                    [configuration]="configuration"
                    [paymentProfiles]="paymentProfiles"
                    [readonly]="isReadonly"
                    (addPayment)="addPayment($event)"
                    (editPayment)="editPayment($event)"
                    (deletePayment)="deletePayment($event)"
                ></payment-block>
            </ng-container>
        </ng-container>
        <ng-template #noBlocks>
            <payment-block
                [payments]="payments"
                [uuid]="uuid"
                [configuration]="configuration"
                [paymentProfiles]="paymentProfiles"
                [readonly]="isReadonly"
                [grantedAmount]="grantedAmount"
                (addPayment)="addPayment($event)"
                (editPayment)="editPayment($event)"
                (deletePayment)="deletePayment($event)"
            ></payment-block>
        </ng-template>
    </form>
</div>
