import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PwaCheckboxComponent } from './pwa-checkbox.component';

import { PwaCheckboxDirective } from './pwa-checkbox.directive';

const COMPONENTS = [PwaCheckboxComponent];

const DIRECTIVES = [PwaCheckboxDirective];

@NgModule({
    declarations: [COMPONENTS, DIRECTIVES],
    imports: [CommonModule],
    exports: [COMPONENTS, DIRECTIVES],
})
export class PwaCheckboxModule {}
