import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PwaInputComponent } from './pwa-input.component';
import { PwaInputDirective } from './pwa-input.directive';

const COMPONENTS = [PwaInputComponent];

const DIRECTIVES = [PwaInputDirective];

@NgModule({
    declarations: [COMPONENTS, DIRECTIVES],
    imports: [CommonModule],
    exports: [COMPONENTS, DIRECTIVES],
})
export class PwaInputModule {}
