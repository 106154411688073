import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicSelectComponent } from './basic-select/basic-select.component';
import { TranslationModule } from '../translation/translation.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '../utils/utils.module';
import { OldSelectComponent, SelectComponentOptionTemplateDirective } from './select-component/select.component';
import { FondaBasicDatepickerComponent } from './fonda-basic-datepicker/fonda-basic-datepicker.component';
import { DatepickerModule } from 'ng2-datepicker';
import { FondaDatepickerComponent } from './fonda-datepicker/fonda-datepicker.component';
import { MyDatePickerModule } from 'mydatepicker';
import { MyDateRangePickerModule } from 'mydaterangepicker';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FondaInputComponent } from './fonda-input/fonda-input.component';
import { FondaInputDirective } from './fonda-input/fonda-input.directive';
import { FondaCheckboxComponent } from './fonda-checkbox/fonda-checkbox.component';
import { FondaCheckboxDirective } from './fonda-checkbox/fonda-checkbox.directive';
import { FondaLabelComponent } from './fonda-label/fonda-label.component';
import { FondaInputErrorComponent } from './fonda-input-error/fonda-input-error';
import { ToggleComponent } from './toggle/toggle.component';
import { SelectComponent } from './select/select.component';
import { SelectOptionDirective } from './select/select-option.directive';
import { OptionComponent } from './select/option/option.component';
import { PwaRecordsModule } from '../../pwa/shared/pwa-records/pwa-records.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslationModule,
        UtilsModule,
        DatepickerModule,
        MyDatePickerModule,
        MyDateRangePickerModule,
        PwaRecordsModule,
    ],
    declarations: [
        BasicSelectComponent,
        OldSelectComponent,
        SelectComponentOptionTemplateDirective,
        FondaBasicDatepickerComponent,
        FondaDatepickerComponent,
        FileUploadComponent,
        FondaInputComponent,
        FondaInputDirective,
        FondaCheckboxComponent,
        FondaCheckboxDirective,
        FondaLabelComponent,
        FondaInputErrorComponent,
        ToggleComponent,
        SelectComponent,
        SelectOptionDirective,
        OptionComponent,
    ],
    exports: [
        BasicSelectComponent,
        OldSelectComponent,
        SelectComponentOptionTemplateDirective,
        FondaBasicDatepickerComponent,
        FondaDatepickerComponent,
        FileUploadComponent,
        FondaInputComponent,
        FondaInputDirective,
        FondaCheckboxComponent,
        FondaCheckboxDirective,
        FondaLabelComponent,
        FondaInputErrorComponent,
        ToggleComponent,
        SelectComponent,
        SelectOptionDirective,
    ],
})
export class BasicFormControlsModule {}
