import { WordService } from '../../../../services/word-service';

export function convertToStringAddCommas(num: number): string {
    if (num || num == 0) {
        let str = num.toFixed(2);
        str = str.replace(/\./g, ',');
        return WordService.addCommas(str);
    } else {
        return;
    }
}

export function convertToString(num: number): string {
    if (num || num == 0) {
        const str = num.toString();
        return str.replace('.', ',');
    } else {
        return;
    }
}

export function convertToNumberRemoveCommas(str: string): number {
    if (!str) {
        return;
    } else {
        str = str.toString();
        str = str.replace(/[^\d,-]/g, '');
        str = str.replace(',', '.');
        return Number(str);
    }
}
