import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PwaErrorMessageComponent } from './pwa-error-message.component';

const COMPONENTS = [PwaErrorMessageComponent];

@NgModule({
    declarations: [COMPONENTS],
    imports: [CommonModule],
    exports: [COMPONENTS],
})
export class PwaErrorMessageModule {}
