<ng-container *ngIf="actions.length > 1; else singleActionTemplate">
    <button
        type="button"
        class="action-dropdown-button"
        [dropdownTriggerFor]="actionsDropdown"
        [dropdownPlacement]="dropdownPlacement"
    >
        <span>
            <ng-container *ngTemplateOutlet="labelDirective?.templateRef"></ng-container>
        </span>
        <span class="icon-arrow-down"></span>
    </button>

    <ng-template dropdownList #actionsDropdown="dropdownList">
        <button *ngFor="let action of actions" type="button" dropdownOption (click)="emitAction(action)">
            <ng-container
                *ngTemplateOutlet="actionDirective?.templateRef; context: { $implicit: action }"
            ></ng-container>
        </button>
    </ng-template>
</ng-container>

<ng-template #singleActionTemplate>
    <button *ngIf="actions.length === 1" type="button" class="action-dropdown-button" (click)="emitAction(actions[0])">
        <ng-container
            *ngTemplateOutlet="actionDirective?.templateRef; context: { $implicit: actions[0] }"
        ></ng-container>
    </button>
</ng-template>
