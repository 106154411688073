import { Injectable } from '@angular/core';
import { PaymentGrantedBlocksDto, PaymentRequesterDto, PaymentStatusDto, PaymentTypeDto } from './payment-types';

@Injectable()
export class PaymentsBalanceService {
    getBlockBalance(
        payments: PaymentRequesterDto[],
        blocks: PaymentGrantedBlocksDto[],
        grant_identifier: string,
        paymentStatuses: PaymentStatusDto[]
    ) {
        if (!blocks.length) return 0;
        const granted = blocks.find(g => g.grant_identifier === grant_identifier).amount;
        const paid = this.getPaymentsByBlock(payments, grant_identifier)
            .filter(p => this.affectsBalance(p.payment_status, paymentStatuses))
            .filter(p => p.type === PaymentTypeDto.PAID || p.type === PaymentTypeDto.CANCELED)
            .reduce((sum, payment) => sum + payment.amount, 0);
        const refunded = this.getPaymentsByBlock(payments, grant_identifier)
            .filter(p => this.affectsBalance(p.payment_status, paymentStatuses))
            .filter(p => p.type === PaymentTypeDto.REFUNDED)
            .reduce((sum, payment) => sum + payment.amount, 0);
        return granted - paid + refunded;
    }

    getSingleBlockBalance(payments: PaymentRequesterDto[], grantedAmount: number, paymentStatuses: PaymentStatusDto[]) {
        if (!grantedAmount) return 0;
        const paid = payments
            .filter(p => this.affectsBalance(p.payment_status, paymentStatuses))
            .filter(p => p.type === PaymentTypeDto.PAID || p.type === PaymentTypeDto.CANCELED)
            .reduce((sum, payment) => sum + payment.amount, 0);
        const refunded = payments
            .filter(p => this.affectsBalance(p.payment_status, paymentStatuses))
            .filter(p => p.type === PaymentTypeDto.REFUNDED)
            .reduce((sum, payment) => sum + payment.amount, 0);
        return grantedAmount - paid + refunded;
    }

    getPaymentsByBlock(payments: PaymentRequesterDto[], uuid: string) {
        return payments.filter(p => p.grant_identifier === uuid);
    }

    affectsBalance(status: string, paymentStatuses: PaymentStatusDto[]): boolean {
        if (!status) return true;
        if (!paymentStatuses.length) return true;
        const found = paymentStatuses.find(p => p.name === status);
        return found ? found.affects_balance : true;
    }
}
