import { TranslationDto } from '../dto/translation-dto/translation-dto';
import { TextDto } from '../dto/translation-dto/text-dto';
import { TranslationTypes } from '../../caseworker/administration/pages/administration-translation-manager/translation-helper';
import { TranslationLogDto } from '../dto/translation-dto/translation-log-dto';
import { BackendUserData } from '../dto/backend-user-data';

export class TranslationResponseMapper {
    public static GetTranslation(jsonDataObject: any): Array<TranslationDto> {
        const translationList: object = {};
        jsonDataObject.forEach(translation => {
            translationList[translation.type] = translationList[translation.type] || [];
            translationList[translation.type].push(translation);
        });

        const translationTypes = Object.keys(translationList);

        const translations: Array<TranslationDto> = [];
        translationTypes.forEach(type => {
            const translation = translationList[type];
            const texts: Array<TextDto> = [];

            translation.forEach(text => {
                let referenceDetails: {
                    refUuid: string;
                    type: string;
                    section?: number;
                    sequence?: number;
                    recordType?: string;
                };

                if (text.record_reference && !TranslationResponseMapper.isRecordType(text.type)) {
                    referenceDetails = {
                        refUuid: text.record_reference.ref_uuid,
                        type: text.record_reference.ref_type,
                    };
                }

                if (text.record_reference && TranslationResponseMapper.isRecordType(text.type)) {
                    referenceDetails = {
                        refUuid: text.record_reference.ref_uuid,
                        type: text.record_reference.ref_type,
                        section: text.record_reference.section,
                        sequence: text.record_reference.sequence,
                        recordType: text.record_reference.record_type,
                    };
                }

                texts.push(
                    new TextDto(
                        text.uuid,
                        text.type,
                        text.language,
                        text.identifier,
                        text.sub_identifier,
                        text.sub_sub_identifier,
                        text.text,
                        referenceDetails
                    )
                );
            });

            translations.push(new TranslationDto(type, texts));
        });

        return translations;
    }

    public static isRecordType(type: string): boolean {
        return type === TranslationTypes.Record_Type;
    }

    public static GetTranslationLogs(jsonDataObject: any): Array<TranslationLogDto> {
        const resDto: Array<TranslationLogDto> = [];

        if (jsonDataObject) {
            jsonDataObject.forEach(translation => {
                const profile = translation.changer_profile;
                if (profile) {
                    resDto.push(
                        new TranslationLogDto(
                            new BackendUserData(profile.initials, profile.type, profile.username, profile.uuid),
                            translation.entity_uuid,
                            translation.occurred_date,
                            translation.type,
                            translation.data
                        )
                    );
                }
            });
        }
        return resDto;
    }
}
