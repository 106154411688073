<div class="admin-correspondence">
    <div class="responsible-text">
        <h1 class="title">{{ 'admin_correspondence' | translate : 'account_responsible_title' }}</h1>
        <br />
        {{ 'admin_correspondence' | translate : 'account_responsible_helptext' }}
    </div>
    <fonda-responsible-select
        [options]="options"
        [selected]="selected"
        (selectedChange)="caseworkerSelectedChanged($event)"
    >
        <ng-container *responsibleSelectOption="let caseworker">
            {{ caseworker.email }}
        </ng-container>
    </fonda-responsible-select>
</div>
