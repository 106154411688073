import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { Subject, forkJoin } from 'rxjs';
import { debounceTime, switchMap, map } from 'rxjs/operators';
import { FondaApiService } from '../../../api/fonda-api.service';
import { RecordInputObject } from './record-input-object';
import { RecordValuesEntry } from './record-values-entry';
import * as equal from 'fast-deep-equal';
import { RecordResolverComponent } from '../../records/record-resolver/record-resolver.component';

export interface RecordOutputObjectWithPreffiling {
    readonly uuid: string;
    readonly value: object;
    readonly hasDynamicPrefilling: boolean;
    readonly readonly: boolean;
}

@Component({
    selector: 'record-values-edit',
    templateUrl: './record-values-edit.component.html',
})
export class RecordValuesEditComponent implements OnInit, OnDestroy {
    @ViewChildren('recordResolver') recordResolvers: QueryList<RecordResolverComponent>;
    @Input('readonlyGroup') public readonlyGroup = false;
    @Output('onChange') onChange = new EventEmitter<object>();
    @Output() afterInit = new EventEmitter();
    public isOnCaseworkerMode = false;
    public recordValues: Array<RecordInputObject> = [];

    private changedRecordValues = new Map<string, any>();
    recordsWithPrefilling$ = new Subject<RecordOutputObjectWithPreffiling[]>();

    constructor(private apiService: FondaApiService, private cdr: ChangeDetectorRef) {}

    @Input('recordValuesEditInput')
    public set recordValuesInput(recordValuesEntry: RecordValuesEntry) {
        if (recordValuesEntry) {
            this.recordValues = [...recordValuesEntry.recordValues];
            this.isOnCaseworkerMode = recordValuesEntry.isOnCaseworkerMode;
        }
    }

    @Input() errors: Array<{
        uuid: string;
        message: string;
    }> = [];

    ngOnInit() {
        this.recordsWithPrefilling$
            .pipe(
                debounceTime(750),
                switchMap(records => {
                    return forkJoin(
                        records.map(record => this.apiService.getRecordDynamicPrefillings(record.uuid, record.value))
                    );
                }),
                map(prefillingArray => prefillingArray.reduce((acc, arr) => acc.concat(arr), []).reverse())
            )
            .subscribe((prefillingArray: Array<{ uuid: string; value: any }>) => {
                if (prefillingArray.length) {
                    this.recordValues = this.recordValues.map(record => {
                        if (record.readonly) return record;
                        const prefill = prefillingArray.find(p => p.uuid === record.uuid);
                        if (!prefill) return record;
                        return { ...record, value: prefill.value };
                    });
                }
                this.cdr.markForCheck();
            });
    }

    ngOnDestroy() {
        this.recordsWithPrefilling$.complete();
    }

    onAfterInit() {
        this.afterInit.emit();
    }

    public getArrayOfDivIndexWithMargin(ind: number): boolean {
        const indexesWithMargin: Array<number> = [];
        let iterate = 0;
        this.recordValues.forEach(singleValue => {
            if (singleValue.size === 1) {
                if (iterate++ === 1) {
                    indexesWithMargin.push(this.recordValues.indexOf(singleValue));
                    iterate = 0;
                }
            } else {
                iterate = 0;
            }
        });

        return indexesWithMargin.includes(ind);
    }

    public handleChange() {
        if (this.recordResolvers && this.recordResolvers.length > 0) {
            const recordsWithPrefilling = this.getChangedRecordsWithPrefilling();
            if (recordsWithPrefilling.length) this.recordsWithPrefilling$.next(recordsWithPrefilling);
        }
    }

    getChangedRecordsWithPrefilling(): RecordOutputObjectWithPreffiling[] {
        const onlyChanged = this.getOnlyChanged();
        if (!onlyChanged.length) return [];

        return onlyChanged.filter(r => {
            const previousValue = this.changedRecordValues.get(r.uuid);
            if (!r.hasDynamicPrefilling) return false;
            if (previousValue && equal(r.value, previousValue)) return false;
            this.changedRecordValues.set(r.uuid, { ...r.value });
            return true;
        });
    }

    public getOnlyChanged(): Array<RecordOutputObjectWithPreffiling> {
        if (!this.recordResolvers) return [];
        return this.recordResolvers
            .filter(record => record.isChanged())
            .map(record => {
                return {
                    uuid: record.uuid,
                    value: record.getValue(),
                    hasDynamicPrefilling: record.hasDynamicPrefilling,
                    readonly: record.readonly,
                };
            });
    }

    public getStaticGetChanged(): Array<RecordOutputObjectWithPreffiling> {
        this.handleChange();
        return this.getOnlyChanged();
    }

    public recordTrackBy(index: number, item: RecordInputObject) {
        return item.uuid;
    }

    public getErrorMessageByUuid(uuid) {
        if (!this.errors.length) return;
        const error = this.errors.find(e => e.uuid === uuid);
        return error ? error.message : null;
    }
}
