import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseRecordComponent, RecordMetadata } from '../../base-record.component';
import { CustomListDataService } from '../../../../services/custom-list-data.service';
import { CustomListValueDto } from '../../../../api/dto/custom-list';

export type CustomListDisplayModes = 'radio_buttons' | 'dropdown' | string;

export interface CustomListRecordConfiguration {
    custom_list_uuid: string;
    display_mode: CustomListDisplayModes;
}

export type CustomListRecordOutput = { uuid: string } | [];

@Component({
    selector: 'fonda-custom-list-record',
    templateUrl: './custom-list-record.component.html',
    styleUrls: ['./custom-list-record.component.scss'],
})
export class CustomListRecordComponent extends BaseRecordComponent<
    CustomListRecordOutput,
    CustomListRecordConfiguration
> {
    control = new FormControl();
    customListValues: CustomListValueDto[];

    constructor(private readonly customListDataService: CustomListDataService) {
        super();
    }

    get displayMode(): CustomListDisplayModes {
        return this.isOnSearch ? 'dropdown' : this.metadata.configuration.display_mode;
    }

    initRecord(metadata: RecordMetadata<CustomListRecordOutput, CustomListRecordConfiguration>) {
        this.customListDataService.getCustomListByUuid(metadata.configuration.custom_list_uuid).subscribe(list => {
            this.customListValues = list.values;
        });
        this.registerControlChanges(this.control);
    }

    setValue(value: CustomListRecordOutput) {
        if (Array.isArray(value)) this.control.setValue(null, { emitEvent: false });
        else this.control.setValue(value.uuid, { emitEvent: false });
    }

    getValue(): CustomListRecordOutput {
        if (this.control.value === null) return [];
        return { uuid: this.control.value };
    }

    isEmpty(): boolean {
        return this.control.value === null;
    }

    isValid(): boolean {
        return this.control.value && this.metadata.isRequired;
    }

    isDisabled(valueUuid: string): boolean {
        if (!this.customListValues) return true;
        const value = this.customListValues.find(v => v.uuid === valueUuid);
        return value ? !value.enabled : false;
    }
}
