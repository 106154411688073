import { Component, ElementRef, Input } from '@angular/core';
import { ButtonComponent } from '../button/button.component';

const ICON_CLASSES = {
    pencil: 'icon-pencil',
    trash: 'icon-trash',
    cross: 'icon-cross',
    'arrow-down': 'icon-arrow-down',
    'arrow-right': 'icon-arrow-right',
    message: 'icon-message',
    share: 'icon-share',
};

@Component({
    selector: 'a[icon-btn], button[icon-btn]',
    templateUrl: './icon-button.component.html',
    styleUrls: ['./icon-button.component.scss'],
    // tslint:disable-next-line:no-inputs-metadata-property
    inputs: ['rounded', 'color', 'disabled', 'hidden', 'size'],
    // tslint:disable-next-line:no-host-metadata-property
    host: {
        '[attr.disabled]': 'disabled || null',
        '[attr.aria-hidden]': 'hidden',
    },
})
export class IconButtonComponent extends ButtonComponent {
    // tslint:disable-next-line:no-input-rename
    @Input('icon-btn') icon: keyof typeof ICON_CLASSES;

    constructor(eref: ElementRef) {
        super(eref);
        this.addClass('btn--icon');
        this.outline = true;
    }

    get iconClass() {
        return ICON_CLASSES[this.icon];
    }
}
