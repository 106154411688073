<div class="form-item" [ngClass]="{ half: metadata.size === 1 }" *ngIf="!isReadonly">
    <basic-select
        [choosenInput]="selectedUuid"
        [placeholder]="'application_field' | translate : 'application_schema_uuid'"
        [values]="schemas"
        [translate]="true"
        [argumentTranslation]="'title'"
        (getChoosen)="chooseSchema($event)"
        [label]="'application_field' | translate : 'application_schema_uuid'"
        [mandatory]="metadata.isRequired"
    ></basic-select>
    <div
        class="select-box-label red"
        *ngIf="isOnSearch && selectedUuid"
        style="position: absolute; right: 7px; z-index: 1"
        (click)="chooseSchema(null)"
    >
        {{ 'general' | translate : 'delete' }}
    </div>
    <div class="form-focus"></div>
    <div class="form-error"></div>
</div>
