import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PwaAnchorComponent, PwaButtonComponent } from './pwa-button.component';

const COMPONENTS = [PwaButtonComponent, PwaAnchorComponent];

@NgModule({
    declarations: [COMPONENTS],
    imports: [CommonModule],
    exports: [COMPONENTS],
})
export class PwaButtonModule {}
