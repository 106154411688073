import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PopupModule } from '../shared/popup/popup.module';
import { TranslationModule } from '../shared/translation/translation.module';
import { UtilsModule } from '../shared/utils/utils.module';
import { CookieBannerComponent } from './cookie-banner/cookie-banner.component';
import { CookiesService } from './cookies.service';

@NgModule({
    declarations: [CookieBannerComponent],
    imports: [CommonModule, TranslationModule, UtilsModule, PopupModule],
    entryComponents: [CookieBannerComponent],
})
export class CookiesModule {
    constructor(private cookiesService: CookiesService) {
        this.cookiesService.init();
    }
}
