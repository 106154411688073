import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'pwa-box',
    templateUrl: 'pwa-box.component.html',
    styleUrls: ['pwa-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'pwa-box',
    },
})
export class PwaBoxComponent {}
