import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UUID } from '../../../../../models/uuid';
import { convertToString } from '../economy-converter';

@Component({
    selector: 'economy-income-multiline-edit',
    templateUrl: './economy-income-multiline-edit.component.html',
    styleUrls: ['../economy-record.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EconomyIncomeMultilineEditComponent),
            multi: true,
        },
    ],
})
export class EconomyIncomeMultilineEditComponent implements ControlValueAccessor {
    value: Array<{
        title: string;
        amount: number;
        attachments?: Array<{
            uuid: string;
            file_name: string;
            file_size: string;
        }>;
    }> = [];

    @Input() hasAttachments: boolean = false;
    @Input() hasAttachmentsMandatory: boolean = false;
    @Input() recordUuid: UUID;
    @Input() total: boolean = false;

    disabled: boolean = false;

    _onChange: any = () => {};
    _onTouch: any = () => {};

    get totalValue() {
        return this.value.reduce((sum, income) => sum + income.amount, 0);
    }

    onChange() {
        this._onChange(this.value);
    }

    addLine() {
        if (this.hasAttachments) {
            this.value.push({
                title: '',
                amount: null,
                attachments: [],
            });
        } else {
            this.value.push({
                title: '',
                amount: null,
            });
        }
    }

    deleteLine(index) {
        this.value.splice(index, 1);
    }

    addFile(file, index) {
        this.value[index].attachments = this.value[index].attachments.concat({
            uuid: file.uuid,
            file_name: file.fileName,
            file_size: file.size,
        });
    }

    deleteFile(uuid, index) {
        this.value[index].attachments = this.value[index].attachments.filter(file => file.uuid !== uuid);
    }

    resetFiles(index) {
        this.value[index].attachments = [];
    }

    writeValue(obj: any): void {
        if (!obj || !Array.isArray(obj)) this.value = [];
        this.value = obj;
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouch = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    numToStr(amount) {
        return convertToString(amount);
    }
}
