<ng-container *ngIf="!isOnSearch && !isReadonly">
    <div class="payment-record">
        <h3>
            {{ metadata.uuid | translate : 'title' }}
        </h3>
        <div>
            <button btn size="small" (click)="openPaymentProfile()">
                {{ 'payment_profile' | translate : 'manage_payment_profiles' }}
            </button>
        </div>
    </div>

    <ng-container *ngIf="payments">
        <ng-container *ngIf="hasBlocks; else singleBlock">
            <ng-container *ngFor="let block of grantedBlocks">
                <payment-block
                    *ngIf="!block.parentUuid"
                    [payments]="getPaymentsByBlock(block.grant_identifier)"
                    [block]="block"
                    [uuid]="uuid"
                    [configuration]="configuration"
                    [paymentProfiles]="paymentProfiles"
                    [readonly]="false"
                    [economyGroup]="true"
                    [boardMeetings]="boardMeetings"
                    [nextStatusBtn]="true"
                    [children]="getGrantedBlockChildren(block.uuid)"
                    (addPayment)="addPaymentPopup($event)"
                    (editPayment)="editPaymentPopup($event)"
                    (deletePayment)="deletePayment($event)"
                    (changePaymentStatus)="patchPaymentStatus($event)"
                ></payment-block>
            </ng-container>
        </ng-container>

        <ng-template #singleBlock>
            <payment-block
                [payments]="payments"
                [uuid]="uuid"
                [configuration]="configuration"
                [paymentProfiles]="paymentProfiles"
                [readonly]="false"
                [economyGroup]="true"
                [boardMeetings]="boardMeetings"
                [grantedAmount]="grantedAmount"
                [nextStatusBtn]="true"
                (addPayment)="addPaymentPopup($event)"
                (editPayment)="editPaymentPopup($event)"
                (deletePayment)="deletePayment($event)"
                (changePaymentStatus)="patchPaymentStatus($event)"
            ></payment-block>
        </ng-template>
    </ng-container>
</ng-container>

<ng-container *ngIf="!isOnSearch && isReadonly">
    <div class="payment-record">
        <h3>
            {{ uuid | translate : 'title' }}
        </h3>
        <div>
            <button class="button color-blue smaller" (click)="openPaymentProfile()">
                {{ 'payment_profile' | translate : 'manage_payment_profiles' }}
            </button>
        </div>
    </div>

    <ng-container *ngIf="payments">
        <ng-container *ngIf="hasBlocks; else singleBlockReadonly">
            <payment-block
                *ngFor="let block of grantedBlocks"
                [payments]="getPaymentsByBlock(block.grant_identifier)"
                [block]="block"
                [uuid]="uuid"
                [configuration]="configuration"
                [paymentProfiles]="paymentProfiles"
                [readonly]="true"
                [economyGroup]="true"
                [boardMeetings]="boardMeetings"
            ></payment-block>
        </ng-container>

        <ng-template #singleBlockReadonly>
            <payment-block
                [payments]="payments"
                [uuid]="uuid"
                [configuration]="configuration"
                [paymentProfiles]="paymentProfiles"
                [grantedAmount]="grantedAmount"
                [readonly]="true"
                [economyGroup]="true"
                [boardMeetings]="boardMeetings"
            ></payment-block>
        </ng-template>
    </ng-container>
</ng-container>

<ng-container *ngIf="!isReadonly && isOnSearch">
    <div style="display: flex; position: relative">
        <div class="form-item between even first" [ngClass]="{ half: size === 1 }">
            <input
                id="{{ uuid }}-float1"
                type="text"
                [fondaMoneyInput]="searchValue[0]"
                (fondaMoneyInputChange)="update(0, $event)"
            />
            <label for="{{ uuid }}-float1">{{ metadata.title }} {{ 'general' | translate : 'from' }}</label>
            <div class="form-focus"></div>
        </div>
        <div class="form-item between even second" [ngClass]="{ half: size === 1 }">
            <input
                id="{{ uuid }}-float2"
                type="text"
                [fondaMoneyInput]="searchValue[1]"
                (fondaMoneyInputChange)="update(1, $event)"
            />
            <label for="{{ uuid }}-float2">{{ metadata.title }} {{ 'general' | translate : 'to' }}</label>
            <div class="form-focus"></div>
        </div>
    </div>
</ng-container>
