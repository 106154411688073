<ng-container *isNotOnPWA>
    <div *ngIf="!isReadonly" class="form-item" [ngClass]="{ half: metadata.size === 1 }">
        <input
            id="{{ metadata.uuid }}"
            type="number"
            placeholder=" "
            [value]="value"
            (input)="updateValue($event.target.value)"
            #input
        />
        <label for="{{ metadata.uuid }}">
            {{ metadata.uuid | translate : 'title' }}
            <span *ngIf="metadata.isRequired" class="mandatory">*</span>
        </label>
        <div class="form-focus"></div>
        <div class="form-help"></div>
    </div>

    <a class="form-item" *ngIf="isReadonly" [ngClass]="{ full: metadata.size === 2 }">
        <p>{{ metadata.uuid | translate : 'title' }}</p>
        <span>{{ metadata.initialValue?.text || '-' }}</span>
        <div class="form-focus"></div>
        <div class="form-error"></div>
    </a>
</ng-container>

<ng-container *isOnPWA>
    <ng-container *ngIf="!isReadonly">
        <pwa-list-element [ngClass]="{ half: metadata.size === 1 }">
            <pwa-labelled-text [mandatory]="metadata.isRequired" [errorMessage]="errorMessage">
                <ng-container pwaLabelledTextLabel>
                    {{ metadata.uuid | translate : 'title' }}
                </ng-container>
                <ng-container pwaLabelledTextContent>
                    <input
                        id="{{ metadata.uuid }}"
                        type="number"
                        placeholder=" "
                        [value]="value"
                        (input)="updateValue($event.target.value)"
                        #input
                    />
                </ng-container>
            </pwa-labelled-text>
        </pwa-list-element>
    </ng-container>
    <ng-container *ngIf="isReadonly">
        <pwa-list-element [ngClass]="{ half: metadata.size === 1 }">
            <pwa-labelled-text>
                <ng-container pwaLabelledTextLabel>
                    {{ metadata.uuid | translate : 'title' }}
                </ng-container>
                <ng-container pwaLabelledTextContent>
                    {{ metadata.initialValue?.text || '-' }}
                </ng-container>
            </pwa-labelled-text>
        </pwa-list-element>
    </ng-container>
</ng-container>
