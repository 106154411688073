<ng-container *ngIf="!isReadonly && !isOnSearch">
    <section class="application-economy" style="margin-top: 0">
        <div class="granted-amount__header">
            <h3>{{ uuid | translate : 'title' }}</h3>
            <span *ngIf="metadata.isRequired" class="mandatory">*</span>
        </div>
        <div class="external-investments-wrapper">
            <div style="margin-bottom: 20px" *ngIf="grantedAmounts?.length == 0">
                {{ uuid | translate : 'no_grants_found' }}
            </div>

            <div class="granted-amount-row granted-amount-row-header" *ngIf="grantedAmounts?.length">
                <div class="granted-amount-row-item narrowest"></div>
                <div
                    class="granted-amount-row-item"
                    [class.has-partial]="configuration.has_partial_granting"
                    [class.has-economy-groups]="economyGroups.length"
                >
                    {{ uuid | translate : 'name' }}
                </div>
                <div
                    *ngIf="configuration.has_attachments"
                    class="granted-amount-row-item wide"
                    [class.has-partial]="configuration.has_partial_granting"
                    [class.has-economy-groups]="economyGroups.length"
                >
                    {{ 'general' | translate : 'attachments' }}
                </div>
                <div
                    *ngIf="!configuration.has_partial_granting"
                    class="granted-amount-row-item"
                    [class.has-partial]="configuration.has_partial_granting"
                    [class.has-economy-groups]="economyGroups.length"
                >
                    {{ uuid | translate : 'amount' }}
                </div>
                <div
                    *ngIf="configuration.has_partial_granting"
                    class="granted-amount-row-item"
                    [class.has-partial]="configuration.has_partial_granting"
                    [class.has-economy-groups]="economyGroups.length"
                >
                    {{ uuid | translate : 'original_full_amount' }}
                </div>
                <div
                    *ngIf="economyGroups.length"
                    class="granted-amount-row-item wide"
                    [class.has-partial]="configuration.has_partial_granting"
                    [class.has-economy-groups]="economyGroups.length"
                >
                    <fonda-select
                        [placeholder]="'general' | translate : 'economy_group'"
                        [options]="economyGroups"
                        [value]="commonEconomyGroup"
                        (valueChange)="setCommonEconomyGroup($event); emitValue()"
                        [boxDisplay]="true"
                        [multiple]="false"
                        [canUnselect]="true"
                    >
                        <ng-container *fondaSelectOption="let uuid">
                            {{ uuid | translate }}
                        </ng-container>
                    </fonda-select>
                </div>
                <div
                    class="granted-amount-row-item wide"
                    [class.has-partial]="configuration.has_partial_granting"
                    [class.has-economy-groups]="economyGroups.length"
                >
                    <fonda-select
                        [placeholder]="uuid | translate : 'board_meeting'"
                        [options]="getBoardMeetingUuids()"
                        [value]="commonBoardMeeting"
                        (valueChange)="setCommonBoardMeeting($event); emitValue()"
                        [boxDisplay]="true"
                        [multiple]="false"
                        [canUnselect]="true"
                    >
                        <ng-container *fondaSelectOption="let uuid">
                            {{ getBoardMeetingNameByUuid(uuid) }}
                        </ng-container>
                    </fonda-select>
                </div>
                <div
                    *ngIf="configuration.has_partial_granting"
                    class="granted-amount-row-item narrow"
                    [class.has-partial]="configuration.has_partial_granting"
                    [class.has-economy-groups]="economyGroups.length"
                ></div>
                <div
                    *ngIf="configuration.has_partial_granting"
                    class="granted-amount-row-item"
                    [class.has-partial]="configuration.has_partial_granting"
                    [class.has-economy-groups]="economyGroups.length"
                >
                    {{ uuid | translate : 'amount' }}
                </div>
                <div class="granted-amount-row-item narrow"></div>
            </div>

            <ng-container *ngFor="let grantedObj of grantedAmountsParents; let i = index">
                <div class="granted-amount-row">
                    <div class="form-item granted-amount-row-item narrowest">
                        <fonda-checkbox [label]="'&nbsp;'">
                            <input
                                class="checkbox"
                                type="checkbox"
                                fondaCheckbox
                                [checked]="isChecked(grantedObj.uuid)"
                                (change)="onCheck(grantedObj.uuid)"
                                [disabled]="isParent(grantedObj.uuid)"
                            />
                        </fonda-checkbox>
                    </div>
                    <div
                        class="form-item granted-amount-row-item"
                        [class.has-partial]="configuration.has_partial_granting"
                        [class.has-economy-groups]="economyGroups.length"
                    >
                        <input
                            type="text"
                            [ngModel]="grantedObj.name"
                            (ngModelChange)="grantedObj.name = $event; emitValue()"
                            [placeholder]="uuid | translate : 'name'"
                        />
                        <div class="form-focus"></div>
                    </div>

                    <div
                        *ngIf="configuration.has_attachments"
                        class="form-item granted-amount-row-item wide"
                        [class.has-partial]="configuration.has_partial_granting"
                        [class.has-economy-groups]="economyGroups.length"
                    >
                        <attachments-uploader
                            [attachments]="grantedObj.attachments"
                            (delete)="deleteFile(i, $event)"
                            (add)="addFile($event, i)"
                        ></attachments-uploader>
                    </div>

                    <div
                        *ngIf="configuration.has_partial_granting"
                        class="form-item valuta granted-amount-row-item"
                        [class.has-partial]="configuration.has_partial_granting"
                        [class.has-economy-groups]="economyGroups.length"
                    >
                        <input
                            type="text"
                            OnlyNumber="true"
                            [readonly]="!grantedObj.new_line"
                            [fondaMoneyInput]="grantedObj.original_full_amount"
                            (fondaMoneyInputChange)="grantedObj.original_full_amount = $event; emitValue()"
                            placeholder="0"
                        />
                        <div *ngIf="grantedObj.new_line" class="form-focus"></div>
                    </div>

                    <div
                        *ngIf="!configuration.has_partial_granting"
                        class="form-item valuta granted-amount-row-item"
                        [class.has-partial]="configuration.has_partial_granting"
                        [class.has-economy-groups]="economyGroups.length"
                    >
                        <input
                            type="text"
                            OnlyNumber="true"
                            required
                            [fondaMoneyInput]="grantedObj.amount"
                            (fondaMoneyInputChange)="grantedObj.amount = $event; emitValue()"
                            placeholder="0"
                        />
                        <div class="form-focus"></div>
                    </div>

                    <div
                        *ngIf="economyGroups.length"
                        class="form-item granted-amount-row-item wide"
                        [class.has-partial]="configuration.has_partial_granting"
                        [class.has-economy-groups]="economyGroups.length"
                    >
                        <fonda-select
                            [placeholder]="'general' | translate : 'choose'"
                            [options]="economyGroups"
                            [value]="grantedObj.economy_group"
                            (valueChange)="grantedObj.economy_group = $event; emitValue()"
                            [boxDisplay]="false"
                            [multiple]="false"
                            [canUnselect]="false"
                        >
                            <ng-container *fondaSelectOption="let uuid">
                                {{ uuid | translate }}
                            </ng-container>
                        </fonda-select>
                    </div>

                    <div
                        class="form-item granted-amount-row-item wide"
                        [class.has-partial]="configuration.has_partial_granting"
                        [class.has-economy-groups]="economyGroups.length"
                    >
                        <fonda-select
                            [placeholder]="'general' | translate : 'choose'"
                            [options]="getBoardMeetingUuids()"
                            [value]="grantedObj.board_meeting"
                            (valueChange)="grantedObj.board_meeting = $event; emitValue()"
                            [boxDisplay]="false"
                            [multiple]="false"
                            [canUnselect]="false"
                        >
                            <ng-container *fondaSelectOption="let uuid">
                                {{ getBoardMeetingNameByUuid(uuid) }}
                            </ng-container>
                        </fonda-select>
                    </div>

                    <div
                        *ngIf="configuration.has_partial_granting"
                        class="form-item percentage granted-amount-row-item narrow"
                        [class.has-partial]="configuration.has_partial_granting"
                        [class.has-economy-groups]="economyGroups.length"
                    >
                        <input
                            type="text"
                            OnlyNumber="true"
                            required
                            [ngModel]="grantedObj.partial_granting_percentage"
                            (ngModelChange)="setPercentage(grantedObj, $event); emitValue()"
                            placeholder="0"
                        />
                        <div class="form-focus"></div>
                    </div>

                    <div
                        *ngIf="configuration.has_partial_granting"
                        class="form-item valuta granted-amount-row-item"
                        [class.has-partial]="configuration.has_partial_granting"
                        [class.has-economy-groups]="economyGroups.length"
                    >
                        <input
                            type="text"
                            OnlyNumber="true"
                            name="value2"
                            required
                            [fondaMoneyInput]="grantedObj.amount"
                            (fondaMoneyInputChange)="setAmount(grantedObj, $event); emitValue()"
                            placeholder="0"
                        />
                        <div class="form-focus"></div>
                    </div>

                    <div class="form-item granted-amount-row-item narrow" style="margin: 20px 8px 0px 8px">
                        <a
                            *ngIf="!grantedObj.is_originally_from_applicant"
                            style="margin-left: 5px"
                            class="close-btn red smaller icon"
                            (click)="deleteAmount(grantedObj)"
                        >
                            <span class="icon-close"></span>
                        </a>
                        <a
                            style="margin-left: 5px"
                            class="close-btn smaller icon"
                            (click)="copyGrantToNewLine(i, grantedObj)"
                        >
                            <span class="icon-cross"></span>
                        </a>
                    </div>
                </div>
                <granted-amount-children
                    [children]="getGrantedAmountsChildren(grantedObj.uuid)"
                    [recordUuid]="uuid"
                    [boardMeetings]="boardMeetings"
                    [configuration]="configuration"
                    [economyGroups]="economyGroups"
                    (split)="split(grantedObj.uuid)"
                ></granted-amount-children>
            </ng-container>
            <div class="granted-amount-row-last">
                <button class="button color-green smaller add" (click)="addNewGrant()">
                    {{ 'general' | translate : 'add_new' }}
                </button>
                <button [disabled]="!checked.length" class="button color-green smaller add" (click)="mergeLines()">
                    {{ 'general' | translate : 'merge_lines' }}
                </button>
            </div>
        </div>
    </section>
</ng-container>

<ng-container *ngIf="!isReadonly && isOnSearch">
    <div style="display: flex; position: relative">
        <div class="form-item between even first" [ngClass]="{ half: metadata.size === 1 }">
            <input
                id="{{ metadata.uuid }}-granted-amount-from"
                type="text"
                [fondaMoneyInput]="searchValue[0]"
                (fondaMoneyInputChange)="update(0, $event)"
            />
            <label for="{{ metadata.uuid }}-granted-amount-from">
                {{ metadata.title }} {{ 'general' | translate : 'from' }}
            </label>
            <div class="form-focus"></div>
        </div>
        <div class="form-item between even second" [ngClass]="{ half: metadata.size === 1 }">
            <input
                id="{{ metadata.uuid }}-granted-amount-to"
                type="text"
                [fondaMoneyInput]="searchValue[1]"
                (fondaMoneyInputChange)="update(1, $event)"
            />
            <label for="{{ metadata.uuid }}-granted-amount-to">
                {{ metadata.title }} {{ 'general' | translate : 'to' }}
            </label>
            <div class="form-focus"></div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="isReadonly && !isOnSearch">
    <h3>{{ uuid | translate : 'title' }}</h3>
    <granted-amount-readonly-lines
        [recordUuid]="uuid"
        [configuration]="configuration"
        [grantedLines]="grantedAmounts"
        [economyGroups]="economyGroups"
        [boardMeetings]="boardMeetings"
    ></granted-amount-readonly-lines>
</ng-container>
