import {
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
    selector: 'input[fondaMoneyInput]',
})
export class MoneyInputDirective implements OnInit, OnChanges, OnDestroy {
    // tslint:disable-next-line:no-input-rename
    @Input('fondaMoneyInput') value: number | null = null;
    // tslint:disable-next-line:no-output-rename
    @Output('fondaMoneyInputChange') valueChange = new EventEmitter<number | null>();
    @Input() zeroEmpty = false;

    private internalValue: number | null = null;
    private readonly destroy$ = new Subject<void>();

    constructor(private readonly elementRef: ElementRef<HTMLInputElement>) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.value && changes.value.currentValue !== this.internalValue) {
            this.internalValue = changes.value.currentValue;
            this.elementRef.nativeElement.value = this.formatValue(this.internalValue);
        }
    }

    ngOnInit() {
        fromEvent(this.elementRef.nativeElement, 'input')
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.internalValue = this.parse(this.elementRef.nativeElement.value);
                this.valueChange.emit(this.internalValue);
                this.elementRef.nativeElement.value = this.correctOnType(this.elementRef.nativeElement.value);
            });
        fromEvent(this.elementRef.nativeElement, 'blur')
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.elementRef.nativeElement.value = this.formatValue(this.value);
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    parse(value: string): number {
        value = value
            .replace(/[^0-9,.]/g, '')
            .replace(/(,\d{1,2}).*/, '$1')
            .replace(/[.]/g, '')
            .replace(/,/g, '.');
        if (value === '') return this.zeroEmpty ? 0 : null;
        return parseFloat(value);
    }

    formatValue(value: number | null): string {
        if (!value) return this.zeroEmpty ? '0' : '';
        const strValue = value.toFixed(2);
        const [wholePart, decimalPart] = strValue.split('.');
        return this.separateWithDots(wholePart) + (',' + decimalPart).replace(',00', '');
    }

    correctOnType(value: string): string {
        return value.replace(/[^0-9.,]/g, '').replace(/(,\d{0,2}).*/, '$1');
    }

    separateWithDots(value: string): string {
        const result = [];
        for (let i = 0; i < value.length; i++) {
            if (i !== 0 && i % 3 === 0) result.push('.');
            result.push(value[value.length - i - 1]);
        }
        return result.reverse().join('');
    }
}
