import { ApplicationDto } from '../dto/application-dto';
import { SectionDto } from '../dto/section-dto';
import { RecordValueDto } from '../dto/record-value-dto';
import { CaseworkerApplicationDto } from '../dto/caseworker-application-dto';
import { ApplicationTemplateDto } from '../dto/search-dto/application-template-dto';
import { ApplicationRecordDto } from '../dto/search-dto/application-record-dto';
import { TransitionDto } from '../dto/search-dto/transition-dto';
import { SearchProjectionDto } from '../dto/search-dto/search-projection-dto';

export class ApplicationResponseMapper {
    public static getApplication(jsonDataObject: any): ApplicationDto {
        const applicantSections: Array<SectionDto> = [];
        for (const section of jsonDataObject.applicant_sections) {
            const tmpRecordValues: Array<RecordValueDto> =
                ApplicationResponseMapper.getRecordValuesFromSection(section);
            applicantSections.push(new SectionDto(section.id, tmpRecordValues));
        }

        const caseworkerSections = jsonDataObject.caseworker_sections.map(
            sec => new SectionDto(sec.id, ApplicationResponseMapper.getRecordValuesFromSection(sec))
        );
        const postGrantSections = jsonDataObject.post_grant_sections.map(
            sec => new SectionDto(sec.id, ApplicationResponseMapper.getRecordValuesFromSection(sec))
        );

        return new ApplicationDto(
            applicantSections,
            jsonDataObject.uuid,
            jsonDataObject.applicant_schema_uuid,
            jsonDataObject.category_uuid,
            jsonDataObject.created_at,
            jsonDataObject.public_status,
            jsonDataObject.post_grant_status,
            jsonDataObject.is_draft,
            jsonDataObject.submitted_at,
            jsonDataObject.deletion_date,
            caseworkerSections,
            postGrantSections,
            jsonDataObject.is_closed
        );
    }

    public static getSearchApplication(jsonDataObject: any): ApplicationDto {
        const tmpSections: Array<SectionDto> = [];
        for (const section of jsonDataObject.applicant_sections) {
            tmpSections.push(new SectionDto(section.id, ApplicationResponseMapper.getRecordValuesFromSection(section)));
        }

        const caseworkerSections = jsonDataObject.caseworker_sections.map(
            sec => new SectionDto(sec.id, ApplicationResponseMapper.getRecordValuesFromSection(sec))
        );
        const postGrantSections = jsonDataObject.post_grant_sections.map(
            sec => new SectionDto(sec.id, ApplicationResponseMapper.getRecordValuesFromSection(sec))
        );

        return new ApplicationDto(
            tmpSections,
            jsonDataObject.uuid,
            jsonDataObject.applicant_schema_uuid,
            jsonDataObject.category_uuid,
            jsonDataObject.created_at,
            jsonDataObject.public_status,
            jsonDataObject.post_grant_status,
            jsonDataObject.is_draft,
            jsonDataObject.submitted_at,
            jsonDataObject.deletion_date || null,
            caseworkerSections,
            postGrantSections,
            jsonDataObject.is_closed
        );
    }

    public static getCaseworkerApplication(jsonDataObject: any): CaseworkerApplicationDto {
        const applicantSections: Array<SectionDto> = [];

        jsonDataObject.applicant_sections.forEach(section => {
            const tmpRecordValues: Array<RecordValueDto> =
                ApplicationResponseMapper.getRecordValuesFromSection(section);

            applicantSections.push(new SectionDto(section.id, tmpRecordValues));
        });

        const caseworkerSections: Array<SectionDto> = [];

        jsonDataObject.caseworker_sections.forEach(section => {
            const tmpRecordValues: Array<RecordValueDto> =
                ApplicationResponseMapper.getRecordValuesFromSection(section);

            caseworkerSections.push(new SectionDto(section.id, tmpRecordValues));
        });

        const postGrantSections: Array<SectionDto> = [];

        if (jsonDataObject.post_grant_sections) {
            jsonDataObject.post_grant_sections.forEach(section => {
                const tmpRecordValues: Array<RecordValueDto> =
                    ApplicationResponseMapper.getRecordValuesFromSection(section);

                postGrantSections.push(new SectionDto(section.id, tmpRecordValues));
            });
        }

        return new CaseworkerApplicationDto(
            jsonDataObject.applicant_default_language,
            jsonDataObject.created_at,
            jsonDataObject.applicant_schema_uuid,
            jsonDataObject.applicant_uuid,
            jsonDataObject.uuid,
            jsonDataObject.is_closed,
            jsonDataObject.is_draft,
            jsonDataObject.is_granted,
            jsonDataObject.status,
            jsonDataObject.post_granted_status,
            applicantSections,
            caseworkerSections,
            postGrantSections,
            jsonDataObject.account_email,
            jsonDataObject.is_visible_by_applicant
        );
    }

    public static GetApplicationTemplateInformation(jsonDataObject: any): ApplicationTemplateDto {
        const applicationRecords = Array<ApplicationRecordDto>();

        jsonDataObject.records.forEach(record => {
            const projections: Array<SearchProjectionDto> = [];

            if (record.search_projections) {
                record.search_projections.forEach(projection => {
                    projections.push(
                        new SearchProjectionDto(
                            projection.behaviour,
                            projection.possible_clauses,
                            projection.primitive,
                            projection.projection
                        )
                    );
                });
            }

            applicationRecords.push(
                new ApplicationRecordDto(
                    record.uuid,
                    record.behaviour,
                    record.section,
                    record.sequence,
                    record.field_size,
                    record.type,
                    record.ref_uuid,
                    record.is_required,
                    record.parent_record_uuid,
                    projections,
                    record.configuration
                )
            );
        });

        const statuses = Array<{
            name: string;
            publicName: string;
            transitions: Array<TransitionDto>;
        }>();

        jsonDataObject.status_settings.forEach(status => {
            const transitions: Array<TransitionDto> = [];

            if (status.transitions) {
                status.transitions.forEach(transition => {
                    const requiredRecords: Array<string> = [];
                    transition.required_records.forEach(requiredRecord => {
                        requiredRecords.push(requiredRecord);
                    });

                    transitions.push(
                        new TransitionDto(
                            transition.correspondence_template_uuid,
                            transition.public_action,
                            transition.requiredRecords,
                            transition.status_name
                        )
                    );
                });
            }

            statuses.push({
                name: status.name,
                publicName: status.public_name,
                transitions: transitions,
            });
        });
        const postGrantedStatuses = Array<{
            name: string;
            publicName: string;
            transitions: Array<TransitionDto>;
        }>();
        if (jsonDataObject.post_granted_status_settings) {
            jsonDataObject.post_granted_status_settings.forEach(status => {
                const transitions: Array<TransitionDto> = [];

                if (status.transitions) {
                    status.transitions.forEach(transition => {
                        const requiredRecords: Array<string> = [];
                        transition.required_records.forEach(requiredRecord => {
                            requiredRecords.push(requiredRecord);
                        });
                        transitions.push(
                            new TransitionDto(
                                transition.correspondence_template_uuid,
                                transition.public_action,
                                transition.requiredRecords,
                                transition.status_name
                            )
                        );
                    });
                }
                postGrantedStatuses.push({
                    name: status.name,
                    publicName: status.public_name,
                    transitions: transitions,
                });
            });
        }

        return new ApplicationTemplateDto(
            jsonDataObject.category_uuid,
            applicationRecords,
            statuses,
            postGrantedStatuses,
            jsonDataObject.application_schema_uuids
        );
    }

    public static getRecordValuesFromSection(section: any): Array<RecordValueDto> {
        const tmpRecordValues: Array<RecordValueDto> = [];
        for (const recordValue of section.record_values) {
            tmpRecordValues.push(
                new RecordValueDto(
                    recordValue.uuid,
                    recordValue.is_required,
                    recordValue.type,
                    recordValue.field_size,
                    recordValue.value,
                    recordValue.is_editable,
                    recordValue.configuration,
                    recordValue.parent_record_uuid,
                    recordValue.stringified_value,
                    recordValue.has_dynamic_prefillings
                )
            );
        }

        return tmpRecordValues;
    }
}
