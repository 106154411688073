import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';

@Component({
    selector: 'pwa-accordion-list',
    templateUrl: './pwa-accordion-list.component.html',
    styleUrls: ['./pwa-accordion-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'pwa-accordion-list',
    },
})
export class PwaAccordionListComponent implements OnChanges, AfterViewInit {
    @ViewChild('list', { read: ElementRef }) list: ElementRef;

    @Input() expanded: boolean = false;
    @Input() animation: boolean = true;
    @Output() expandedChange = new EventEmitter<boolean>();

    constructor(private renderer: Renderer2) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.expanded && !changes.expanded.isFirstChange()) {
            this.setListMaxHeight();
        }
    }

    ngAfterViewInit() {
        this.setListMaxHeight();
    }

    onClick() {
        this.expanded = !this.expanded;
        this.setListMaxHeight();
        this.expandedChange.emit(this.expanded);
    }

    setListMaxHeight() {
        if (!this.expanded) {
            this.renderer.setStyle(this.list.nativeElement, 'max-height', '0px');
        } else {
            if (this.animation) {
                this.renderer.setStyle(
                    this.list.nativeElement,
                    'max-height',
                    this.list.nativeElement.scrollHeight + 'px'
                );
            } else {
                this.renderer.setStyle(this.list.nativeElement, 'max-height', 'none');
            }
        }
    }
}
