import { Component, EventEmitter, Input, Output } from '@angular/core';
import { convertToString } from '../../economy-record/economy-converter';
import { GrantedAmountObject } from '../granted-amount';
import { BoardMeetingDto } from '../../../../../api/dto/board-meeting/board-meeting-dto';
import { IGrantedAmountRecordConfigurationData } from '../../../../../shared/record-configuration/configurations/granted-amount-record-configuration/granted-amount-record-configuration.component';

@Component({
    selector: 'tr[granted-amount-readonly-line]',
    templateUrl: 'granted-amount-readonly-line.component.html',
    styleUrls: ['granted-amount-readonly-line.component.scss'],
    host: {
        '[class.parent]': 'parent',
    },
})
export class GrantedAmountReadonlyLine {
    @Input() line: GrantedAmountObject;
    @Input() configuration: IGrantedAmountRecordConfigurationData;
    @Input() economyGroups: Array<string> = [];
    @Input() boardMeetings: Array<BoardMeetingDto> = [];
    @Input() parent: boolean = false;
    @Input() foldable: boolean = false;
    @Input() unfolded: boolean = false;
    @Input() paymentView: boolean = false;

    @Output() toggle = new EventEmitter<string>();

    getBoardMeetingNameByUuid(uuid: string): string {
        if (!uuid || !this.boardMeetings || this.boardMeetings.length == 0) {
            return null;
        }
        return this.boardMeetings.find(bm => bm.uuid === uuid)?.name;
    }

    numToStr(amount) {
        return convertToString(amount);
    }
}
