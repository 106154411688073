<fonda-popup size="medium" (close)="cancel()">
    <div popup-header class="mb-20">
        <h3>{{ isEdit ? ('general' | translate : 'edit') : (uuid | translate : 'create_payment') }}</h3>
    </div>
    <form [formGroup]="paymentForm">
        <div class="form-item">
            {{ 'general' | translate : 'balance' }}: {{ blockBalance | commasInAmount }}
            <fonda-currency></fonda-currency>
        </div>
        <div class="form-item valuta">
            <input
                id="payment-requester-popup-amount"
                type="text"
                [fondaMoneyInput]="paymentForm.value.amount"
                (fondaMoneyInputChange)="paymentForm.patchValue({ amount: $event })"
                placeholder="0"
            />
            <label for="payment-requester-popup-amount">
                {{ 'general' | translate : 'amount' }}
                <span class="mandatory">*</span>
            </label>
            <div class="form-focus"></div>
            <div class="form-error"></div>
        </div>
        <div class="form-item">
            <input id="payment-requester-popup-name" type="text" name="name" formControlName="name" placeholder=" " />
            <label for="payment-requester-popup-name">
                {{ 'general' | translate : 'name' }}
                <span class="mandatory">*</span>
            </label>
            <div class="form-focus"></div>
            <div class="form-error"></div>
        </div>
        <div class="form-item">
            <input
                id="payment-requester-popup-date"
                type="text"
                [value]="paymentForm.value.date | utcDate"
                name="date"
                placeholder=" "
                disabled
            />
            <label for="payment-popup-date">
                {{ 'general' | translate : 'date' }}
                <span class="mandatory">*</span>
            </label>
            <div class="form-focus"></div>
            <div class="form-error"></div>
            <div>
                <fonda-basic-datepicker
                    [date]="paymentForm.value.date"
                    (dateChange)="paymentForm.patchValue({ date: $event })"
                ></fonda-basic-datepicker>
            </div>
        </div>
        <div class="form-item">
            <fonda-select
                placeholder="{{ 'general' | translate : 'behaviours' }}"
                formControlName="type"
                label="{{ 'general' | translate : 'behaviours' }}"
                [options]="types"
                [canUnselect]="false"
                [multiple]="false"
                [boxDisplay]="false"
                [mandatory]="true"
                [readonly]="isEdit"
            >
                <ng-container *fondaSelectOption="let type">
                    {{ uuid | translate : type }}
                </ng-container>
            </fonda-select>
            <div class="form-focus"></div>
            <div class="form-error"></div>
        </div>
        <div class="form-item" *ngIf="config['has_comments']" style="margin-top: -20px">
            <textarea
                autosize
                id="payment-requester-popup-comment"
                name="comment"
                type="text"
                formControlName="comment"
                placeholder=" "
            ></textarea>
            <label for="payment-requester-popup-comment">
                {{ 'general' | translate : 'comment' }}
                <span class="mandatory">*</span>
            </label>
            <div class="form-focus"></div>
            <div class="form-error"></div>
        </div>
        <div class="form-item" *ngIf="config['has_attachments']" style="padding-bottom: 20px">
            <attachments-uploader
                [attachments]="paymentForm.value.attachments"
                [readonly]="false"
                (delete)="deleteFile($event)"
                (add)="addFile($event)"
                [mandatory]="true"
            ></attachments-uploader>
        </div>
        <div class="form-item" *ngIf="config.payment_statuses && config.payment_statuses.length">
            <fonda-select
                placeholder="{{ 'application_field' | translate : 'status' }}"
                formControlName="payment_status"
                label="{{ 'application_field' | translate : 'status' }}"
                [options]="statuses"
                [canUnselect]="false"
                [multiple]="false"
                [boxDisplay]="false"
                [mandatory]="true"
                [readonly]="paymentForm.value.type === 'canceled'"
            >
                <ng-container *fondaSelectOption="let payment_status">
                    {{ 'general' | translate : payment_status }}
                </ng-container>
            </fonda-select>
            <div class="form-focus"></div>
            <div class="form-error"></div>
        </div>
        <div class="form-item">
            <fonda-select
                placeholder="{{ 'payment_profile' | translate : 'payment_profile' }}"
                formControlName="payment_profile"
                label="{{ 'payment_profile' | translate : 'payment_profile' }}"
                [options]="paymentProfileOptions"
                [canUnselect]="false"
                [multiple]="false"
                [boxDisplay]="false"
                [mandatory]="true"
            >
                <ng-container *fondaSelectOption="let profile">
                    {{ getPaymentProfileName(profile) }}
                </ng-container>
            </fonda-select>
            <div class="form-focus"></div>
            <div class="form-error"></div>
        </div>
        <div popup-actions class="mt-20">
            <button style="margin-right: 8px" type="button" btn color="secondary" (click)="cancel()" [outline]="true">
                {{ 'general' | translate : 'cancel' }}
            </button>
            <button btn color="primary" type="button" (click)="save()" [disabled]="!paymentForm.valid">
                <span>{{ isEdit ? ('general' | translate : 'save') : ('general' | translate : 'add') }}</span>
            </button>
        </div>
    </form>
</fonda-popup>
