import { ComponentRef, EmbeddedViewRef, Injectable } from '@angular/core';
import { DownloadableSectionComponent } from './downloadable-section/downloadable-section.component';
import { DownloadSectionInterface } from './downloadable-section/download-section-interface';
import { DOMService } from '../services/dom-service';
import { PDFAssembler, PDFAssemblerOptions } from '../services/pdf-assembler';
import { take } from 'rxjs/operators';
import { SiteSettingsService } from '../services/site-settings.service';

/**
 * top, right, bottom, left are margin values in millimeters
 */
interface PDFSiteSettings {
    top: string;
    right: string;
    bottom: string;
    left: string;
    font_size: string;
}
@Injectable()
export class PdfApplicationService {
    public timeStamp: number;
    readonly filename: string = 'applicant-review-document.pdf';
    readonly options: PDFAssemblerOptions = {
        margin: [8, 24],
        breakBefore: '[data-pdf-next-page]',
        avoidBreaking: '[data-pdf-part]',
    };

    constructor(private domService: DOMService, private siteSettings: SiteSettingsService) {}

    public downloadPDF(sectionsEntry: Array<DownloadSectionInterface>): Promise<void> {
        return this.preparePDF(sectionsEntry).then(pdf => pdf.save(this.filename));
    }

    public getPdf(sectionsEntry: Array<DownloadSectionInterface>): Promise<string> {
        return this.preparePDF(sectionsEntry).then(pdf => pdf.toString());
    }

    private preparePDF(sectionsEntry: Array<DownloadSectionInterface>): Promise<PDFAssembler> {
        const componentRef = this.generateComponent(sectionsEntry);
        const element = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0].querySelector(
            '#downloadableThing'
        );
        return componentRef.instance.afterInit
            .pipe(take(1))
            .toPromise()
            .then(() => this.generatePdf(element))
            .then(pdf => {
                this.domService.removeComponentFromBody(componentRef);
                return pdf;
            });
    }

    private generateComponent(
        sectionsEntry: Array<DownloadSectionInterface>
    ): ComponentRef<DownloadableSectionComponent> {
        const component = this.domService.appendComponentToBody(DownloadableSectionComponent);
        component.instance.sections = sectionsEntry;
        return component;
    }

    private async generatePdf(element: HTMLElement): Promise<PDFAssembler> {
        const maybePdfSettings = await this.siteSettings
            .getSettingSingle<PDFSiteSettings>('pdf_settings')
            .pipe(take(1))
            .toPromise();
        const margin = maybePdfSettings
            .map(
                ({ top, right, bottom, left }) =>
                    [top, right, bottom, left].map(x => parseInt(x)) as [number, number, number, number]
            )
            .unwrapOr([8, 24, 8, 24] as [number, number, number, number]);
        return PDFAssembler.create(element, { ...this.options, margin });
    }
}
