<ng-container *isNotOnPWA>
    <div *ngIf="!isDisabled; else disabledTmpl" class="form-item">
        <input
            type="text"
            [value]="value.text"
            (input)="viewToModelUpdate($event.target.value)"
            (focus)="onTouched()"
            placeholder=" "
        />
        <div class="form-focus"></div>
    </div>

    <ng-template #disabledTmpl>
        <span>{{ value.text || '-' }}</span>
    </ng-template>
</ng-container>

<ng-container *isOnPWA>
    <ng-container *ngIf="!isDisabled">
        <pwa-input>
            <input
                pwaInput
                type="text"
                [value]="value.text"
                (input)="viewToModelUpdate($event.target.value)"
                (focus)="onTouched()"
                placeholder=" "
            />
        </pwa-input>
    </ng-container>
    <ng-container *ngIf="isDisabled">
        {{ value.text || '-' }}
    </ng-container>
</ng-container>
