import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AutofillMonitor } from '@angular/cdk/text-field';
import { Platform } from '@angular/cdk/platform';
import { Directive, ElementRef, HostListener, Inject, Input, Optional, Self } from '@angular/core';
import { FormGroupDirective, NgControl, NgForm, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';

const _PwaInputDirectiveBase = class {
    constructor(public _parentForm: NgForm, public _parentFormGroup: FormGroupDirective, public ngControl: NgControl) {}
};

let nextUniqueId = 0;

@Directive({
    selector: 'input[pwaInput], textarea[pwaInput], select[pwaSelect]',
    host: {
        class: 'pwa-input-element',
        '[attr.id]': 'id',
        '[disabled]': 'disabled',
        '[attr.readonly]': 'readonly && !_isSelect || null',
    },
})
export class PwaInputDirective extends _PwaInputDirectiveBase {
    protected _uid = `pwa-input-element-${nextUniqueId++}`;
    protected _id: string;
    protected _type = 'text';
    protected _disabled = false;
    protected _previousNativeValue: any;

    readonly _isSelect: boolean;
    readonly _isTextarea: boolean;

    private _readonly = false;
    private _inputValueAccessor: { value: any };

    focused: boolean = false;
    autofilled: boolean = false;

    readonly stateChanges: Subject<void> = new Subject<void>();

    constructor(
        protected _elementRef: ElementRef<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
        protected _platform: Platform,
        private _autofillMonitor: AutofillMonitor,
        @Optional() @Self() ngControl: NgControl,
        @Optional() _parentForm: NgForm,
        @Optional() _parentFormGroup: FormGroupDirective,
        @Optional() @Self() @Inject(NG_VALUE_ACCESSOR) inputValueAccessor: any
    ) {
        super(_parentForm, _parentFormGroup, ngControl);

        const element = this._elementRef.nativeElement;
        const nodeName = element.nodeName.toLowerCase();

        this._inputValueAccessor = inputValueAccessor || element;
        this._previousNativeValue = this.value;

        // Force setter to be called in case id was not specified.
        this.id = this.id;

        this._isSelect = nodeName === 'select';
        this._isTextarea = nodeName === 'textarea';
    }

    @Input()
    get id(): string {
        return this._id;
    }
    set id(value: string) {
        this._id = value || this._uid;
    }

    @Input()
    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value || 'text';
        if (!this._isTextarea) {
            (this._elementRef.nativeElement as HTMLInputElement).type = this._type;
        }
    }

    @Input()
    get value(): string {
        return this._inputValueAccessor.value;
    }

    set value(value: string) {
        if (value !== this.value) {
            this._inputValueAccessor.value = value;
            this.stateChanges.next();
        }
    }

    public resetValue() {
        if (this.ngControl) {
            this.ngControl.control.setValue(null);
        } else {
            this._elementRef.nativeElement.value = null;
            this._elementRef.nativeElement.dispatchEvent(new Event('input'));
        }
        this.value = null;
    }

    get valid() {
        if (!this.ngControl) return false;
        return this.ngControl.control?.status === 'VALID' ? true : false;
    }

    @Input()
    get disabled(): boolean {
        if (this.ngControl && this.ngControl.disabled !== null) {
            return this.ngControl.disabled;
        }
        return this._disabled;
    }

    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);

        // Browsers may not fire the blur event if the input is disabled too quickly.
        // Reset from here to ensure that the element doesn't become stuck.
        if (this.focused) {
            this.focused = false;
            this.stateChanges.next();
        }
    }

    @Input()
    get readonly(): boolean {
        return this._readonly;
    }
    set readonly(value: boolean) {
        this._readonly = coerceBooleanProperty(value);
    }

    get empty(): boolean {
        return !this._elementRef.nativeElement.value;
    }

    @HostListener('focus', ['true'])
    @HostListener('blur', ['false'])
    // tslint:enable:no-host-decorator-in-concrete
    _focusChanged(isFocused: boolean) {
        if (isFocused !== this.focused && (!this.readonly || !isFocused)) {
            this.focused = isFocused;
            this.stateChanges.next();
        }
    }

    focus(options?: FocusOptions): void {
        this._elementRef.nativeElement.focus(options);
    }

    ngOnChanges() {
        this.stateChanges.next();
    }

    ngDoCheck() {
        this._dirtyCheckNativeValue();
    }

    ngAfterViewInit() {
        if (this._platform.isBrowser) {
            this._autofillMonitor.monitor(this._elementRef.nativeElement).subscribe(event => {
                this.autofilled = event.isAutofilled;
                this.stateChanges.next();
            });
        }
    }

    ngOnDestroy() {
        if (this._platform.isBrowser) {
            this._autofillMonitor.stopMonitoring(this._elementRef.nativeElement);
        }
        this.stateChanges.complete();
    }

    /** Does some manual dirty checking on the native input `value` property. */
    protected _dirtyCheckNativeValue() {
        const newValue = this._elementRef.nativeElement.value;

        if (this._previousNativeValue !== newValue) {
            this._previousNativeValue = newValue;
            this.stateChanges.next();
        }
    }
}
