<div class="pwa-attachments" #label>
    <a
        class="pwa-attachments-label"
        (click)="show($event)"
        [style.fontSize]="fontSize ? fontSize : readonly ? '1.125rem' : '1rem'"
    >
        {{ attachments?.length || 0 }} {{ 'general' | translate : 'attachments' }}
    </a>
    <span *ngIf="mandatory" class="mandatory">*</span>
</div>
<div #popup class="pwa-attachments-popup" [class.open]="!hidden" [class.empty]="!attachments?.length">
    <ng-container *ngFor="let file of attachments">
        <p>
            <a (click)="downloadFile(file)" [class.full]="readonly">{{ file.file_name }}</a>
            <button pwaButton color="danger" *ngIf="!readonly" (click)="onDelete(file.uuid)">
                <span class="icon-trash"></span>
            </button>
        </p>
    </ng-container>
</div>
