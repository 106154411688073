import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FondaApiService } from '../../../api/fonda-api.service';
import { Pagination } from '../../../caseworker/search/search-page/search-objects';
import { FiltersDto } from '../../../api/dto/search-dto/filters-dto';
import { SearchResponseDto } from '../../../api/dto/search-dto/search-response-dto';
import { PaginationDto } from '../../../api/dto/search-dto/pagination-dto';
import { ResultAdapter } from '../../../caseworker/search/search-page/result-adapter';
import { ResultsInterface } from '../search-results/results-interface';
import { SearchService } from '../../../caseworker/search/services/search-service';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '../../translation/translate.service';
import { RecordsInterface } from '../../../caseworker/search/components/search-filters/records-interface';
import { SearchFiltersService } from '../../../caseworker/search/services/search-filters-service';

@Component({
    templateUrl: './basic-search-popup.component.html',
    selector: 'basic-search-popup',
})
export class BasicSearchPopupComponent implements OnInit {
    @ViewChild('inputSearch', { static: true }) vc: ElementRef;
    public massSearch = '';
    public loadingSearch = false;
    public pagination: Pagination;
    public totalNumberOfApplications = 0;
    public searchResult: ResultsInterface;
    public active = false;
    public height: number;

    private possibleFilters: Array<RecordsInterface> = [];

    constructor(
        private translateService: TranslateService,
        private apiService: FondaApiService,
        private searchFilterService: SearchFiltersService
    ) {}

    public async ngOnInit() {
        this.height = window.innerHeight - 450;
    }

    public onResize() {
        this.height = window.innerHeight - 450;
    }

    public handlePageChange(pagination: Pagination) {
        this.pagination = pagination;
        this.triggerSearch();
    }

    public massSearchTrigger(massSearchText: string) {
        this.massSearch = massSearchText;
        if (massSearchText.length > 2) {
            this.triggerSearch();
        }
    }

    public openPopup() {
        this.active = true;
        setTimeout(() => {
            this.focusOnInput();
        }, 500);
    }

    public triggerSearch() {
        this.loadingSearch = true;
        const pagination = new PaginationDto(
            this.pagination ? this.pagination.pageNumber : 1,
            this.pagination ? this.pagination.applicationsPerPage : 20
        );

        this.searchForData(pagination, this.getSearchFilter())
            .then(res => {
                this.totalNumberOfApplications = res.totalAmount;
                this.searchResult = new ResultAdapter(
                    res,
                    this.translateService,
                    SearchService.getDefaultOrderBy(),
                    this.possibleFilters
                );
                this.loadingSearch = false;
            })
            .catch(error => {
                this.loadingSearch = false;
            });
    }

    private searchForData(pagination: PaginationDto, searchFilters: FiltersDto): Promise<SearchResponseDto> {
        return this.apiService.getApplicationsSearch(pagination, searchFilters).then(searchResponse => {
            return searchResponse;
        });
    }

    private focusOnInput() {
        if (this.vc && this.vc.nativeElement) {
            this.vc.nativeElement.focus();
        }

        if (document.getElementById('inputSearch')) {
            document.getElementById('inputSearch').focus();
        }
    }

    private getSearchFilter(): FiltersDto {
        return new FiltersDto(
            this.massSearch,
            environment.APPLICATION_TEMPLATE_UUID,
            [],
            SearchService.getDeafultColumns(),
            SearchService.getDefaultOrderBy()
        );
    }
}
