import { Component, ElementRef, HostListener, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { BaseRecordComponent, RecordMetadata } from '../../base-record.component';
import { BooleanValue } from '../../values.types';

@Component({
    selector: 'boolean-default-false-record',
    templateUrl: 'boolean-default-false-record.component.html',
    styleUrls: ['boolean-default-false-record.scss'],
})
export class BooleanDefaultFalseRecordComponent extends BaseRecordComponent<BooleanValue, null> {
    booleanValue = false;

    initRecord(metadata: RecordMetadata<BooleanValue, null>) {}

    getValue(): BooleanValue {
        return { boolean: this.booleanValue };
    }

    setValue(value: BooleanValue) {
        this.booleanValue = value.boolean;
    }

    emitValue() {
        this.onChange.emit(this.getValue());
    }

    isValid(): boolean {
        return this.metadata.isRequired && this.booleanValue;
    }

    isEmpty(): boolean {
        return false;
    }
}
