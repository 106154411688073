import { Component } from '@angular/core';
import { format } from 'date-fns';
import { DatepickerOptions } from 'ng2-datepicker';
import { BaseRecordComponent, RecordMetadata } from '../../base-record.component';

type DateRecordValue = [Date | null, Date | null];

export interface DateRecordOutput {
    text: string | [string, string];
}

@Component({
    selector: 'date-record',
    templateUrl: 'date-record.component.html',
})
export class DateRecordComponent extends BaseRecordComponent<DateRecordOutput, null> {
    readonly datepickerOptions: DatepickerOptions = {
        formatTitle: 'MMMM yyyy',
        format: 'dd-mm-yyyy',
        minYear: 1900,
    };

    value: [Date | null, Date | null] = [null, null];

    get strDates() {
        return this.value.map(d => (d && format(d, 'dd-MM-yyyy')) || '');
    }

    get fondaDatepickerValue(): { dateFrom: Date; dateTo: Date } {
        return {
            dateFrom: this.value[0] || new Date(),
            dateTo: this.value[1] || new Date(),
        };
    }

    initRecord(metadata: RecordMetadata<DateRecordOutput, null>): void {}

    emitValue() {
        this.onChange.emit(this.getValue());
    }

    isValid(): boolean {
        return this.metadata.isRequired && this.value[0] !== null;
    }

    isEmpty(): boolean {
        return this.value.every(v => v === null);
    }

    getValue(): DateRecordOutput {
        if (this.isOnSearch) {
            return {
                text: this.value.map(d => (d && d.toISOString()) || '') as [string, string],
            };
        }
        return {
            text: (this.value[0] && this.value[0].toISOString()) || '',
        };
    }

    setValue(value: DateRecordOutput): void {
        if (this.isOnSearch) {
            if (Array.isArray(value.text)) {
                this.value = [toDate(value.text[0]), toDate(value.text[1])];
            }
        } else {
            this.value = [toDate(value.text), null];
        }
    }

    update(index: 0 | 1, date: Date) {
        this.value = this.value.slice() as DateRecordValue;
        this.value.splice(index, 1, date);
        this.emitValue();
    }

    updateBoth(dates: [Date, Date]) {
        this.value = [dates[0], dates[1]];
        this.emitValue();
    }

    updateFromDatepicker(value: { dateFrom: Date; dateTo: Date }) {
        this.value = [value.dateFrom, value.dateTo];
    }
}

function toDate(dateStr: any): Date | null {
    if (typeof dateStr !== 'string') return null;
    const date = new Date(dateStr);
    if (Number.isNaN(date.getTime())) return null;
    return date;
}
