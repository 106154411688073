import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppLoadGuard } from './app-load.guard';
import { CaseworkerLoginGuard } from './caseworker/login/login.guard';
import { DirectLoginComponent } from './components/direct-login/direct-login.component';
import { DirectLoginGuard } from './components/direct-login/direct-login.guard';
import { RecoverPasswordRedirectComponent } from './components/recover-password-redirect/recover-password-redirect.component';
import { LogoutComponent, LogoutGuard } from './logout';

const routes: Routes = [
    {
        path: 'down-for-maintenance',
        loadChildren: () =>
            import('./pages/down-for-maintenance/down-for-maintenance.module').then(m => m.DownForMaintenanceModule),
    },
    {
        path: 'profile-completion',
        loadChildren: () =>
            import('./pages/profile-completion/profile-completion.module').then(m => m.ProfileCompletionModule),
        canActivate: [AppLoadGuard],
    },
    {
        path: 'direct-login',
        component: DirectLoginComponent,
        canActivate: [DirectLoginGuard],
    },
    {
        path: 'logout',
        component: LogoutComponent,
        canActivate: [LogoutGuard],
    },
    {
        path: 'recover-password',
        component: RecoverPasswordRedirectComponent,
        canActivate: [AppLoadGuard],
    },
    {
        path: 'caseworker',
        loadChildren: () => import('./caseworker/caseworker.module').then(m => m.CaseworkerModule),
        canActivate: [AppLoadGuard],
    },
    {
        path: 'backend',
        loadChildren: () => import('./caseworker/login/login.module').then(m => m.CaseworkerLoginModule),
        canActivate: [CaseworkerLoginGuard, AppLoadGuard],
    },
    {
        path: 'azure',
        loadChildren: () => import('./caseworker/azure/azure.module').then(m => m.AzureModule),
        canActivate: [AppLoadGuard],
    },
    {
        path: '',
        loadChildren: () => import('./pwa/core/pwa-core.module').then(m => m.PwaCoreModule),
        canActivateChild: [AppLoadGuard],
    },
    // Make sure it will redirect to the root app when none of the paths were matched before
    {
        path: '**',
        redirectTo: 'landing-page',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: false })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
