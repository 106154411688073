<ng-container *isNotOnPWA>
    <div
        class="form-item acceptance-record"
        [ngClass]="{ half: metadata.size === 1 }"
        *ngIf="!isReadonly && !isOnSearch"
    >
        <div class="checkbox">
            <input
                type="checkbox"
                placeholder=" "
                style="width: 24px; height: 24px"
                [ngModel]="booleanValue"
                (ngModelChange)="booleanValue = $event; emitValue()"
                id="{{ metadata.uuid }}"
            />
            <label for="{{ metadata.uuid }}" style="font-size: 1em">
                <p>
                    {{ metadata.uuid | translate : 'acceptance_text' }}
                    <span *ngIf="metadata.isRequired" class="mandatory">*</span>
                </p>
            </label>
        </div>
        <button style="margin-bottom: 20px" class="button color-blue" (click)="showPopup()">
            {{ metadata.uuid | translate : 'acceptance_button' }}
        </button>
    </div>
</ng-container>

<ng-container *isOnPWA>
    <pwa-list-element class="acceptance-record-pwa" *ngIf="!isReadonly && !isOnSearch">
        <div class="acceptance-record-pwa-element">
            <pwa-checkbox [mandatory]="true" label="{{ metadata.uuid | translate : 'acceptance_text' }}">
                <input
                    type="checkbox"
                    pwaCheckbox
                    [ngModel]="booleanValue"
                    (ngModelChange)="booleanValue = $event; emitValue()"
                />
            </pwa-checkbox>
            <button pwaButton (click)="showPwaPopup()" color="primary">
                {{ metadata.uuid | translate : 'acceptance_button' }}
            </button>
        </div>
        <div *ngIf="errorMessage" class="acceptance-record-pwa-error">
            <pwa-error-message [errorMessage]="errorMessage"></pwa-error-message>
        </div>
    </pwa-list-element>
</ng-container>

<!-- we do not display this record in readonly mode! -->
