import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IPaymentRequesterRecordConfigurationData } from '../../../../../shared/record-configuration/configurations/payment-requester-record-configuration/payment-requester-record-configuration.component';
import { PopupManager } from '../../../../../shared/popup/popup-manager';
import { IPopup } from '../../../../../shared/popup/popup.interface';
import { PaymentRequesterDto } from '../../payment-record/payment-types';

export interface PaymentRequesterRecordPopupDto {
    payment: PaymentRequesterDto;
    blockBalance: number;
    isEdit: boolean;
    uuid: string;
    config: IPaymentRequesterRecordConfigurationData;
}

@Component({
    selector: 'payment-requester-record-popup',
    templateUrl: './payment-requester-record-popup.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentRequesterRecordPopupComponent
    implements OnInit, IPopup<PaymentRequesterDto | null, PaymentRequesterRecordPopupDto>
{
    uuid: string;
    blockBalance: number;
    isEdit: boolean;
    config: IPaymentRequesterRecordConfigurationData;

    paymentForm: FormGroup = new FormGroup({
        payment_identifier: new FormControl(null),
        name: new FormControl(null, [Validators.required]),
        date: new FormControl(null, [Validators.required]),
        payment_profile: new FormControl(null),
        comment: new FormControl(null),
        attachments: new FormControl([]),
        type: new FormControl(null),
        payment_status: new FormControl(null),
        amount: new FormControl(null, [Validators.required]),
        grant_identifier: new FormControl(null),
        is_originally_from_applicant: new FormControl(null),
        unique_id: new FormControl(null),
    });

    constructor(private popupManager: PopupManager<PaymentRequesterDto | null, PaymentRequesterRecordPopupDto>) {}

    ngOnInit() {
        if (this.popupManager.data) {
            this.paymentForm.patchValue(this.popupManager.data.payment);
            this.uuid = this.popupManager.data.uuid;
            this.blockBalance = this.popupManager.data.blockBalance;
            this.isEdit = this.popupManager.data.isEdit;
            this.config = this.popupManager.data.config;
            if (this.config.has_attachments) {
                this.paymentForm.get('attachments').setValidators([Validators.required]);
                this.paymentForm.get('attachments').updateValueAndValidity();
            }
            if (this.config.has_comments) {
                this.paymentForm.get('comment').setValidators([Validators.required]);
                this.paymentForm.get('comment').updateValueAndValidity();
            }
        } else {
            this.cancel();
        }
    }

    close(data) {
        return this.popupManager.close(data);
    }

    cancel() {
        this.close(null);
    }

    save() {
        this.close(this.paymentForm.value);
    }

    addFile(file) {
        this.paymentForm.patchValue({
            attachments: this.paymentForm.value.attachments.concat({
                uuid: file.uuid,
                file_name: file.fileName,
                file_size: file.size,
            }),
        });
    }

    deleteFile(uuid) {
        this.paymentForm.patchValue({
            attachments: this.paymentForm.value.attachments.filter(file => file.uuid !== uuid),
        });
    }
}
