import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { DownloadSectionInterface } from './download-section-interface';
import { FondaApiGlobalVariables } from '../../api/fonda-api-global-variables';
import set = Reflect.set;
import { Subscription } from 'rxjs';

@Component({
    selector: 'downloadable-section',
    templateUrl: 'downloadable-section.component.html',
    styleUrls: ['downloadable-section.component.scss'],
})
export class DownloadableSectionComponent {
    @Input() set sections(sectionInterface: Array<DownloadSectionInterface>) {
        if (sectionInterface) {
            this._sections = [...sectionInterface];
        }
    }

    @ViewChildren('pdfPage') pages: QueryList<ElementRef>;

    constructor(private variables: FondaApiGlobalVariables) {}

    private _isRightNowSomethingInProcess$: Subscription;
    public amountOfSectionsLoaded = 0;

    @Output() afterInit = new EventEmitter();

    public _sections: Array<DownloadSectionInterface>;

    get sectionsWithRecords(): DownloadSectionInterface[] {
        return this._sections.filter(section => section.recordValues && section.recordValues.length > 0);
    }

    onAfterInit() {
        if (++this.amountOfSectionsLoaded == this._sections.filter(s => s.recordValues.length > 0).length) {
            setTimeout(() => {
                if (!this.variables.isRightNowSomethingInProcess.value) {
                    this.afterInit.emit();
                } else {
                    this._isRightNowSomethingInProcess$ = this.variables.isRightNowSomethingInProcess.subscribe(
                        isSmthInProgress => {
                            if (!isSmthInProgress) {
                                setTimeout(() => {
                                    this._isRightNowSomethingInProcess$.unsubscribe();
                                    this.afterInit.emit();
                                }, 10);
                            }
                        }
                    );
                }
            }, 10);
        }
    }
}
