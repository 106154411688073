import { Component, Input } from '@angular/core';
import { GrantedAmountObject } from '../granted-amount';
import { BoardMeetingDto } from '../../../../../api/dto/board-meeting/board-meeting-dto';
import { IGrantedAmountRecordConfigurationData } from '../../../../../shared/record-configuration/configurations/granted-amount-record-configuration/granted-amount-record-configuration.component';

@Component({
    selector: 'granted-amount-readonly-lines',
    templateUrl: 'granted-amount-readonly-lines.component.html',
    styleUrls: ['granted-amount-readonly-lines.component.scss'],
})
export class GrantedAmountReadonlyLines {
    @Input() recordUuid: string;
    @Input() configuration: IGrantedAmountRecordConfigurationData;
    @Input() economyGroups: Array<string> = [];
    @Input() grantedLines: Array<GrantedAmountObject> = [];
    @Input() boardMeetings: Array<BoardMeetingDto> = [];

    unfolded: string[] = [];

    get grantedLinesParents() {
        return this.grantedLines.filter(ga => !ga.parentUuid);
    }

    getGrantedLineChildren(parentUuid: string) {
        return this.grantedLines.filter(ga => ga.parentUuid === parentUuid);
    }

    isFoldable(uuid: string) {
        return !!this.getGrantedLineChildren(uuid).length;
    }

    isUnfolded(uuid: string) {
        return this.unfolded.includes(uuid);
    }

    toggle(uuid: string) {
        if (this.unfolded.includes(uuid)) {
            this.unfolded = this.unfolded.filter(u => u !== uuid);
        } else {
            this.unfolded = this.unfolded.concat(uuid);
        }
    }
}
