import { Component, Input } from '@angular/core';
import { UUID } from '../../../../../models/uuid';
import { convertToString } from '../economy-converter';

@Component({
    selector: 'economy-record-total-view',
    templateUrl: './economy-record-total-view.component.html',
    styleUrls: ['../economy-record.scss'],
})
export class EconomyRecordTotalViewComponent {
    @Input() recordUuid: UUID;
    @Input() value: { total: number };
    @Input() label: string;

    numToStr(amount) {
        return convertToString(amount);
    }
}
