export class NotificationDto {
    constructor(
        readonly application: string | null,
        readonly account: string | null,
        readonly createdAt: Date,
        readonly discarded: boolean,
        readonly isGrouped: boolean,
        readonly params: object,
        readonly seen: boolean,
        readonly type: string,
        readonly user: string,
        readonly uuid: string
    ) {}
}
