<ng-container *isNotOnPWA>
    <div class="form-item" *ngIf="!isReadonly">
        <label style="position: initial">
            <i style="margin-right: 0.25rem">{{ metadata.uuid | translate : 'title' }}</i>
            <span *ngIf="metadata.isRequired" class="mandatory">*</span>
        </label>
    </div>

    <div class="upload" *ngIf="!isReadonly">
        <div class="upload-head" *ngFor="let file of files">
            <div class="upload-filename">{{ file.fileName }}</div>
            <div class="upload-size">{{ file.size | fileSize }}</div>
            <div class="upload-delete" (click)="deleteFile(file.uuid)">
                <a class="icon delete-file"><div class="icon-delete-file"></div></a>
            </div>
            <div class="upload-process"></div>
        </div>

        <div class="upload-head" *ngIf="fileUpload && fileUpload.loading">
            <div class="upload-filename">{{ fileUpload.fileName }}</div>
            <div class="upload-size">{{ fileUpload.fileMBSize }} MB</div>
            <div class="upload-process" [ngStyle]="{ width: fileUpload.loadingProgress + '%' }"></div>
        </div>

        <file-upload-component (fileAdded)="addNewFile($event)" #fileUploadComponent>
            <div class="upload-dragdrop">
                <div class="upload-dragdrop-icon"></div>
                <p>{{ metadata.uuid | translate : 'box' }}</p>
            </div>
        </file-upload-component>
    </div>

    <div class="stable-file-container" *ngIf="isReadonly">
        <label style="position: initial">
            <i>{{ metadata.uuid | translate : 'title' }}</i>
        </label>
        <ul class="files readonly">
            <li class="full">
                <ul class="header">
                    <li>{{ 'general' | translate : 'file_name' }}</li>
                    <li></li>
                    <li>{{ 'general' | translate : 'file_size' }}</li>
                </ul>
                <ng-container *ngIf="files.length > 0">
                    <ul *ngFor="let file of files" (click)="download(file)">
                        <li>{{ file.fileName }}</li>
                        <li></li>
                        <li>{{ file.size | fileSize }}</li>
                    </ul>
                </ng-container>
                <ng-container *ngIf="files.length === 0">
                    <ul class="non-selectable">
                        <li>
                            <i>{{ metadata.uuid | translate : 'no_files_found' }}</i>
                        </li>
                    </ul>
                </ng-container>
            </li>
        </ul>
    </div>
</ng-container>

<ng-container *isOnPWA>
    <ng-container *ngIf="!isReadonly">
        <pwa-list-element>
            <pwa-labelled-text [mandatory]="metadata.isRequired" [errorMessage]="errorMessage">
                <ng-container pwaLabelledTextLabel>
                    {{ metadata.uuid | translate : 'title' }}
                </ng-container>
                <ng-container pwaLabelledTextContent>
                    <div class="pwa-upload">
                        <div class="pwa-upload-head" *ngFor="let file of files">
                            <div class="pwa-upload-head-filename">{{ file.fileName }}</div>
                            <div class="pwa-upload-head-size">{{ file.size | fileSize }}</div>
                            <div class="pwa-upload-head-delete" (click)="deleteFile(file.uuid)">
                                <span class="icon-delete"></span>
                            </div>
                        </div>

                        <div class="pwa-upload-head" *ngIf="fileUpload && fileUpload.loading">
                            <div class="pwa-upload-head-filename">{{ fileUpload.fileName }}</div>
                            <div class="pwa-upload-head-size">{{ fileUpload.fileMBSize }} MB</div>
                            <div
                                class="pwa-upload-head-process"
                                [ngStyle]="{ width: fileUpload.loadingProgress + '%' }"
                            ></div>
                        </div>

                        <file-upload-component (fileAdded)="addNewFile($event)" #fileUploadComponent>
                            <div class="pwa-upload-dragdrop">
                                <p>{{ metadata.uuid | translate : 'box' }}</p>
                                <div class="pwa-upload-dragdrop-fake-button">
                                    <div class="pwa-upload-dragdrop-icon"></div>
                                    <div>{{ 'general' | translate : 'upload_file' }}</div>
                                </div>
                            </div>
                        </file-upload-component>
                    </div>
                </ng-container>
            </pwa-labelled-text>
        </pwa-list-element>
    </ng-container>
    <ng-container *ngIf="isReadonly">
        <pwa-list-element>
            <pwa-labelled-text [mandatory]="metadata.isRequired">
                <ng-container pwaLabelledTextLabel>
                    {{ metadata.uuid | translate : 'title' }}
                </ng-container>
                <ng-container pwaLabelledTextContent>
                    <ng-container *ngIf="files.length > 0">
                        <div class="pwa-upload-file-view" *ngFor="let file of files">
                            <button (click)="download(file)">
                                <span class="icon-download"></span>
                            </button>
                            <div>{{ file.fileName }} ({{ file.size | fileSize }})</div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="files.length === 0">
                        {{ metadata.uuid | translate : 'no_files_found' }}
                    </ng-container>
                </ng-container>
            </pwa-labelled-text>
        </pwa-list-element>
    </ng-container>
</ng-container>
