import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { DatepickerOptions } from 'ng2-datepicker';
import * as daLocale from 'date-fns/locale/da';
import { LanguageStoreService } from '../../../services/language-store.service';
import { startWith, switchMap } from 'rxjs/operators';

@Component({
    selector: 'fonda-basic-datepicker',
    templateUrl: 'fonda-basic-datepicker.component.html',
})
export class FondaBasicDatepickerComponent implements OnInit {
    get date(): Date {
        return this._date;
    }

    @Input() options: DatepickerOptions = {};

    @Input() set date(value: Date) {
        if (value) {
            this._date = this.getInitUTCDate(value);
        }
    }

    @Output() dateChange = new EventEmitter<Date>();

    private _locale: object = daLocale;

    get optionsWithLocale() {
        return { ...this.options, locale: this._locale };
    }

    constructor(private languageStoreService: LanguageStoreService) {}

    private _date: Date = new Date();

    public ngOnInit() {
        this.languageStoreService.languageChange
            .pipe(
                startWith(0),
                switchMap(() => {
                    return import(
                        /* webpackInclude: /(da|en|kl)$/ */ `date-fns/locale/${this.languageStoreService.getAbbrOfCurrentLanguage()}`
                    );
                })
            )
            .subscribe(
                locale => {
                    this._locale = locale;
                },
                () => {
                    this._locale = daLocale;
                }
            );
    }

    public handleDate(date: Date) {
        this.dateChange.emit(this.getEmitUTCDate(date));
    }

    public getInitUTCDate(value: Date | string) {
        // date in format string is in UTC standard
        // or it is a date object with offset
        const date = new Date(value);
        // adjust to user timezone offset
        return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    }

    public getEmitUTCDate(value: Date) {
        // we adjust time to timezone offset
        // as it will be passed as Date object and then transformed to UTC time
        return new Date(value.getTime() - value.getTimezoneOffset() * 60 * 1000);
    }
}
