import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { PaymentPlanObject } from './payment-plan-object';
import { PaymentPlanRowComponent } from './payment-plan-row/payment-plan-row.component';
import { EventsHandlerService } from '../../../../services/events-handler-service';
import { BaseRecordComponent, RecordMetadata } from '../../base-record.component';
import { PaymentStoreService } from '../../../../services/payment-store-service';
import { PaymentProfilePopupService } from '../../../components/payment-profile/payment-profile-popup-service';
import { ActivatedRoute } from '@angular/router';
import { FondaApiService } from '../../../../api/fonda-api.service';
import { PaymentProfileDto } from '../../../../api/dto/payment-profile-dto/payment-profile-dto';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';

export interface PaymentPlanRecordOutput {
    name: string;
    date: string;
    amount: number;
    payment_profile: string;
}

@Component({
    selector: 'payment-plan-record',
    templateUrl: 'payment-plan-record.component.html',
})
export class PaymentPlanRecordComponent
    extends BaseRecordComponent<PaymentPlanRecordOutput[], null>
    implements AfterViewInit, OnDestroy
{
    @ViewChildren('paymentRows') paymentRows: QueryList<PaymentPlanRowComponent>;
    readonly applicationUuid = this.activatedRoute.snapshot.queryParams.uuid;

    changed = false;

    paymentProfiles: Array<PaymentProfileDto> = [];
    payments: Array<PaymentPlanObject> = [];

    private readonly onClosePopupSub: Subscription;

    constructor(
        private activatedRoute: ActivatedRoute,
        @Inject(FondaApiService) private apiService: FondaApiService,
        private eventHandler: EventsHandlerService,
        private changeRef: ChangeDetectorRef,
        private paymentStoreService: PaymentStoreService,
        private paymentProfilePopup: PaymentProfilePopupService
    ) {
        super();
        this.onClosePopupSub = this.paymentProfilePopup.onClosePopup.pipe(startWith({})).subscribe(() => {
            this.fetchPaymentProfiles();
        });
    }

    initRecord(metadata: RecordMetadata<PaymentPlanRecordOutput[], null>) {}

    ngOnDestroy() {
        super.ngOnDestroy();
        this.onClosePopupSub.unsubscribe();
    }

    setValue(value: PaymentPlanRecordOutput[]) {
        this.payments = value.map(
            ({ name, date, amount, payment_profile }) =>
                new PaymentPlanObject(name, date !== null ? new Date(date) : null, amount, payment_profile)
        );
        this.updatePastPayments();
    }

    getValue(): PaymentPlanRecordOutput[] {
        return this.payments.map(payment => {
            return {
                name: payment.name,
                date: payment.date ? payment.date.toISOString() : null,
                amount: payment.amount,
                payment_profile: payment.paymentProfileUuid,
            };
        });
    }

    private updatePastPayments(): void {
        const now = Date.now();
        const pastPlannedPayments = this.payments
            .filter(payment => payment.date)
            .filter(payment => payment.date.getTime() < now)
            .reduce((acc, payment) => acc + payment.amount, 0);
        this.paymentStoreService.pastPaymentPlanEvent.next(pastPlannedPayments);
    }

    emitValue() {
        this.onChange.emit(this.getValue());
    }

    ngAfterViewInit() {
        this.changeRef.detectChanges();
    }

    openPaymentProfile() {
        this.paymentProfilePopup.initPopup();
    }

    save() {
        if (this.paymentRows) {
            this.paymentRows.forEach(pay => pay.onSaveClick());
        }
        if (this.isValid()) {
            this.eventHandler.triggerSavePostGrantedSection.next();
        }
    }

    addNewPayment() {
        this.payments.push(new PaymentPlanObject('', null, 0, this.getDefaultPaymentProfile().uuid));
        this.changed = true;
        this.changeRef.detectChanges();
        this.emitValue();
    }

    getPaymentByUuid(uuid: string): PaymentProfileDto {
        return this.paymentProfiles.find(p => p.uuid === uuid);
    }

    isValid(): boolean {
        if (!this.paymentRows) return;
        this.paymentRows.forEach(row => {
            if (!row || !row.isValid()) {
                return false;
            }
        });
        return true;
    }

    deleteRow(row: PaymentPlanObject) {
        this.payments.splice(this.payments.indexOf(row), 1);
        this.changeRef.detectChanges();
        this.emitValue();
    }

    canSave(): boolean {
        return !(this.paymentRows && this.changed && this.isChanged());
    }

    isEmpty(): boolean {
        return false;
    }

    getAmount(): number {
        if (!this.paymentRows) return 0;
        return this.paymentRows.reduce((acc, row) => {
            const value = row.getValue() || { amount: 0 };
            return acc + value.amount;
        }, 0);
    }

    private fetchPaymentProfiles() {
        this.apiService
            .getApplicationPaymentProfiles(this.applicationUuid)
            .then(profiles => (this.paymentProfiles = [...profiles]));
    }

    private getDefaultPaymentProfile(): PaymentProfileDto {
        return this.paymentProfiles.find(prof => prof.isDefault);
    }
}
