<div class="form-item" [ngClass]="{ half: metadata.size === 1 }" *ngIf="!isReadonly">
    <basic-select
        [choosenInput]="selectedStatus"
        [placeholder]="metadata.title"
        [values]="postGrantStatuses"
        [translate]="true"
        (getChoosen)="selectedStatus = $event; emitValue()"
        [label]="metadata.title"
        [mandatory]="metadata.isRequired"
    ></basic-select>
    <div
        class="select-box-label red"
        *ngIf="selectedStatus"
        style="position: absolute; right: 7px; z-index: 1"
        (click)="reset()"
    >
        {{ 'general' | translate : 'delete' }}
    </div>
    <div class="form-focus"></div>
    <div class="form-error"></div>
</div>
