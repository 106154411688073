import { ChangeDetectorRef, Component } from '@angular/core';
import { BaseRecordComponent, RecordMetadata } from '../../base-record.component';
import { FondaApiService } from '../../../../api/fonda-api.service';
import { BoardMeetingDto } from '../../../../api/dto/board-meeting/board-meeting-dto';
import { LocalStorageService } from '../../../../services/local-storage-service';
import { CaseworkerApplicationDto } from '../../../../api/dto/caseworker-application-dto';
import { RecordsStore } from '../../../../services/records-store';
import { IGrantedAmountRecordConfigurationData } from '../../../../shared/record-configuration/configurations/granted-amount-record-configuration/granted-amount-record-configuration.component';
import { convertToString } from '../economy-record/economy-converter';
import { TranslateService } from '../../../../shared/translation/translate.service';
import { v4 as uuidv4 } from 'uuid';
import { GrantedAmountObject } from './granted-amount';
import { ToasterService } from 'angular2-toaster';

type SearchValue = [number | null, number | null];

interface GrantedAmountSearchData {
    text: [number, number];
}

@Component({
    selector: 'granted-amount-record',
    templateUrl: 'granted-amount-record.component.html',
    styleUrls: ['granted-amount-record.scss'],
})
export class GrantedAmountRecordComponent extends BaseRecordComponent<
    Array<GrantedAmountObject> | GrantedAmountSearchData,
    IGrantedAmountRecordConfigurationData
> {
    constructor(
        private storeService: LocalStorageService,
        private apiService: FondaApiService,
        private translateService: TranslateService,
        private toasterService: ToasterService,
        private cdRef: ChangeDetectorRef
    ) {
        super();
    }

    public grantedAmounts: Array<GrantedAmountObject> = [];
    public searchValue: SearchValue;

    public boardMeetings: Array<BoardMeetingDto> = [];
    public economyGroups: Array<string> = [];

    public commonBoardMeeting: BoardMeetingDto;
    public commonEconomyGroup: string;

    public checked: Array<string> = [];

    get isRequired(): boolean {
        return this.metadata.isRequired;
    }

    get title(): string {
        return this.metadata.title;
    }

    get size(): number {
        return this.metadata.size;
    }

    get uuid(): string {
        return this.metadata.uuid;
    }

    get configuration(): IGrantedAmountRecordConfigurationData {
        return this.metadata.configuration;
    }

    get grantedAmountsParents() {
        return this.grantedAmounts.filter(ga => !ga.parentUuid);
    }

    getGrantedAmountsChildren(parentUuid: string) {
        return this.grantedAmounts.filter(ga => ga.parentUuid === parentUuid);
    }

    initRecord(metadata: RecordMetadata<object | object[], IGrantedAmountRecordConfigurationData>): void {
        if (!this.isOnSearch) {
            this.apiService.getEconomyGroups().subscribe(groups => {
                this.economyGroups = groups;
            });
            this.fetchBoardMeetings();
            this.getActualBoardMeeting(this.storeService.getApplicationInformation());
        }
    }

    public setValue(value) {
        if (this.isOnSearch) {
            this.searchValue = value.text;
        } else {
            this.grantedAmounts = value.map(expense => {
                return {
                    ...expense,
                    new_line: false,
                    uuid: expense.uuid ?? uuidv4(),
                    parentUuid: expense.parentUuid ?? null,
                };
            });
        }

        this.emitValue();
    }

    public getValue(): Array<GrantedAmountObject> | GrantedAmountSearchData {
        if (this.isOnSearch) {
            return { text: this.searchValue };
        } else {
            return this.getOutputObject();
        }
    }

    public emitValue() {
        this.onChange.emit(this.getValue());
    }

    public isValid(): boolean {
        return !(this.isRequired && !this.grantedAmounts['text']);
    }

    public isEmpty(): boolean {
        return !this.grantedAmounts || !this.grantedAmounts.length;
    }

    public addNewGrant() {
        const obj = new GrantedAmountObject();
        obj.board_meeting = this.getActualBoardMeeting(this.storeService.getApplicationInformation());
        if (this.economyGroups.length) obj.economy_group = null;
        obj.name = null;
        obj.amount = null;
        obj.original_full_amount = null;
        obj.partial_granting_percentage = null;
        if (this.configuration.has_attachments) {
            obj.attachments = [];
        }
        obj.new_line = true;
        obj.is_originally_from_applicant = false;
        obj.uuid = uuidv4();
        obj.parentUuid = null;
        this.grantedAmounts.push(obj);
    }

    public copyGrantToNewLine(index, grant: GrantedAmountObject) {
        const obj = { ...grant };
        obj.new_line = true;
        obj.is_originally_from_applicant = false;
        obj.uuid = uuidv4();
        obj.parentUuid = null;
        this.grantedAmounts.splice(index + 1, 0, obj);
    }

    public mergeLines() {
        const checkedGranted = this.grantedAmounts.filter(ga => this.checked.includes(ga.uuid));
        const uniqueEconomyGroups = [...new Set(checkedGranted.map(cg => cg.economy_group))].filter(eg => !!eg); // filter out null / undefined values
        const checkedUuids = checkedGranted.map(cg => cg.uuid);

        // we do not allow more than one economy group
        if (this.checked.length && uniqueEconomyGroups.length > 1) {
            this.toasterService.pop(
                'error',
                this.translateService.get([this.uuid, 'two_or_more_economy_groups_selected'])
            );
            return;
        }

        if (!this.checked.length) return;

        const total = new GrantedAmountObject();
        total.uuid = uuidv4();
        total.parentUuid = null;
        total.board_meeting = null;
        total.name = uniqueEconomyGroups.length ? this.translateService.get([uniqueEconomyGroups[0]]) : 'Total';
        total.economy_group = uniqueEconomyGroups[0];
        total.amount = null;
        total.original_full_amount = this.grantedAmounts
            .filter(ga => checkedUuids.includes(ga.uuid))
            .reduce((sum, item) => (sum += item.original_full_amount), 0);
        total.attachments = [];
        total.new_line = true;
        total.is_originally_from_applicant = false;

        this.grantedAmounts.push(total);
        this.grantedAmounts
            .filter(ga => checkedUuids.includes(ga.uuid))
            .forEach(ga => {
                ga.parentUuid = total.uuid;
            });

        this.checked = [];
    }

    public getBoardMeetingNames(): Array<string> {
        if (!this.boardMeetings || this.boardMeetings.length == 0) {
            return [];
        }
        return this.boardMeetings.map(bm => bm.name);
    }

    public getBoardMeetingUuids(): Array<string> {
        if (!this.boardMeetings || this.boardMeetings.length == 0) {
            return [];
        }
        return this.boardMeetings.map(bm => bm.uuid);
    }

    public getBoardMeetingByName(name: string): BoardMeetingDto {
        if (!name || !this.boardMeetings || this.boardMeetings.length == 0) {
            return null;
        }
        return this.boardMeetings.find(bm => bm.name === name);
    }

    public getBoardMeetingNameByUuid(uuid: string) {
        return this.getBoardMeetingByUuid(uuid) ? this.getBoardMeetingByUuid(uuid).name : null;
    }

    public getBoardMeetingByUuid(uuid: string): BoardMeetingDto {
        if (!uuid || !this.boardMeetings || this.boardMeetings.length == 0) {
            return null;
        }
        return this.boardMeetings.find(bm => bm.uuid === uuid);
    }

    public deleteAmount(amount: GrantedAmountObject) {
        this.grantedAmounts.splice(this.grantedAmounts.indexOf(amount), 1);
    }

    setCommonEconomyGroup(uuid) {
        this.commonEconomyGroup = uuid;
        this.grantedAmounts = this.grantedAmounts.map(granted => {
            return {
                ...granted,
                economy_group: uuid,
            };
        });
    }

    setCommonBoardMeeting(uuid) {
        this.commonBoardMeeting = uuid;
        this.grantedAmounts = this.grantedAmounts.map(granted => {
            return {
                ...granted,
                board_meeting: uuid,
            };
        });
    }

    public setPercentage(grantedObj, value) {
        if (!value) {
            grantedObj.partial_granting_percentage = 0;
        } else {
            if (value > 100) {
                grantedObj.partial_granting_percentage = 100;
            } else if (value < 0) {
                grantedObj.partial_granting_percentage = 0;
            } else {
                grantedObj.partial_granting_percentage = Number(value);
            }
        }
        grantedObj.amount = (grantedObj.original_full_amount * grantedObj.partial_granting_percentage) / 100;
    }

    public setAmount(grantedObj, value) {
        if (!value) {
            grantedObj.amount = 0;
        } else {
            if (value > grantedObj.original_full_amount) {
                grantedObj.amount = grantedObj.original_full_amount;
            } else if (value < 0) {
                grantedObj.amount = 0;
            } else {
                grantedObj.amount = value;
            }
        }
        grantedObj.partial_granting_percentage = Number(
            ((grantedObj.amount / grantedObj.original_full_amount) * 100).toFixed(0)
        );
        if (isNaN(grantedObj.partial_granting_percentage)) grantedObj.partial_granting_percentage = 0;
    }

    private getOutputObject(): Array<any> {
        if (!this.configuration.has_attachments) {
            this.grantedAmounts = this.grantedAmounts.map(grant => {
                delete grant['attachments'];
                return grant;
            });
        }

        if (!this.configuration.has_partial_granting) {
            this.grantedAmounts = this.grantedAmounts.map(grant => {
                delete grant['original_full_amount'];
                delete grant['partial_granting_percentage'];
                return grant;
            });
        }

        if (!this.economyGroups.length) {
            this.grantedAmounts = this.grantedAmounts.map(grant => {
                if (grant['economy_group'] === null) {
                    delete grant['economy_group'];
                }
                return grant;
            });
        } else {
            this.grantedAmounts = this.grantedAmounts.map(grant => {
                if (grant['economy_group'] === undefined) {
                    grant['economy_group'] = null;
                }
                return grant;
            });
        }

        this.grantedAmounts = this.grantedAmounts.map(grant => {
            if (grant['board_meeting'] === undefined) {
                grant['board_meeting'] = null;
            }
            return grant;
        });

        this.grantedAmounts = this.grantedAmounts.map(grant => {
            if (grant['parentUuid'] === null) delete grant['parentUuid'];
            return grant;
        });

        return this.grantedAmounts;
    }

    public addFile(file, index) {
        this.grantedAmounts[index].attachments = this.grantedAmounts[index].attachments.concat({
            uuid: file.uuid,
            file_name: file.fileName,
            file_size: file.size,
        });
    }

    public deleteFile(index, uuid) {
        this.grantedAmounts[index].attachments = this.grantedAmounts[index].attachments.filter(
            file => file.uuid !== uuid
        );
    }

    private fetchBoardMeetings() {
        this.apiService
            .getBoardMeetings()
            .then(res => {
                this.boardMeetings = [...res];
            })
            .catch(err => {});
    }

    private getActualBoardMeeting(caseworkerApplicationDto: CaseworkerApplicationDto): string {
        let uuid = '';
        if (caseworkerApplicationDto && caseworkerApplicationDto.caseworkerSections) {
            caseworkerApplicationDto.caseworkerSections.forEach(section => {
                const record = section.recordValues.find(_record => _record.type === RecordsStore.BoardMeetingRecord);
                if (record) {
                    uuid = record.value['uuid'];
                }
            });
        }
        return uuid;
    }

    onCheck(uuid: string) {
        if (this.checked.includes(uuid)) {
            this.checked = this.checked.filter(u => u !== uuid);
        } else {
            this.checked = this.checked.concat(uuid);
        }
    }

    split(parentUuid: string) {
        this.grantedAmounts.forEach(ga => {
            if (ga.parentUuid === parentUuid) ga.parentUuid = null;
        });
        this.grantedAmounts = this.grantedAmounts.filter(ga => ga.uuid !== parentUuid);
    }

    // removeChild(childUuid: string, parentUuid: string) {
    //     const child = this.grantedAmounts.find(ga => ga.uuid == childUuid);
    //     const parent = this.grantedAmounts.find(ga => ga.uuid === parentUuid);
    //     if (!child) return;
    //     child.parentUuid = null;
    //     if (!parent) return;
    //     parent.original_full_amount -= child.original_full_amount;
    // }

    isChecked(uuid: string) {
        return this.checked.includes(uuid);
    }

    isParent(uuid: string) {
        return !!this.getGrantedAmountsChildren(uuid).length;
    }

    update(index: 0 | 1, value: number) {
        this.searchValue = this.searchValue.slice() as SearchValue;
        this.searchValue.splice(index, 1, value);
        this.emitValue();
    }

    numToStr(amount) {
        return convertToString(amount);
    }
}
