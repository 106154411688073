import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImpersonatorToastComponent } from './impersonator-toast/impersonator-toast.component';
import { ImpersonatorLoginComponent } from './impersonator-login/impersonator-login.component';
import { SharedModule } from '../shared/shared.module';
import { ImpersonatorLoginPopupComponent } from './impersonator-login/impersonator-login-popup.component';

@NgModule({
    declarations: [ImpersonatorToastComponent, ImpersonatorLoginComponent, ImpersonatorLoginPopupComponent],
    entryComponents: [ImpersonatorLoginPopupComponent],
    exports: [ImpersonatorToastComponent, ImpersonatorLoginComponent],
    imports: [CommonModule, SharedModule],
})
export class ImpersonatorModule {}
