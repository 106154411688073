<ng-container *isNotOnPWA>
    <div *ngIf="!isDisabled; else disabledTmpl" class="form-item">
        <fonda-select
            placeholder="-"
            [options]="customListValues | pluck : 'uuid'"
            [value]="value.uuid"
            (valueChange)="viewToModelUpdate($event)"
        >
            <ng-container *fondaSelectOption="let uuid">
                {{ 'custom_list_value' | translate : uuid }}
            </ng-container>
        </fonda-select>
    </div>

    <ng-template #disabledTmpl>
        <span *ngIf="value.uuid">{{ 'custom_list_value' | translate : value.uuid }}</span>
        <span *ngIf="!value.uuid">-</span>
    </ng-template>
</ng-container>

<ng-container *isOnPWA>
    <ng-container *ngIf="!isDisabled">
        <pwa-select
            placeholder="-"
            [options]="customListValues | pluck : 'uuid'"
            [value]="value.uuid"
            (valueChange)="viewToModelUpdate($event)"
            [noBorder]="true"
        >
            <ng-container *pwaSelectOption="let uuid">
                {{ 'custom_list_value' | translate : uuid }}
            </ng-container>
        </pwa-select>
    </ng-container>
    <ng-container *ngIf="isDisabled">
        {{ value.uuid ? ('custom_list_value' | translate : value.uuid) : '-' }}
    </ng-container>
</ng-container>
