import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecordResolverComponent } from './record-resolver/record-resolver.component';
import { EmailRecordComponent } from './complex-records/email-record/email-record.component';
import { TextRecordComponent } from './complex-records/text-record/text-record.component';
import { BankRecordComponent } from './complex-records/bank-record/bank-record.component';
import { CompanyRecordComponent } from './complex-records/company-record/company-record.component';
import { SubcategoryRecordComponent } from './complex-records/subcategory-record/subcategory-record.component';
import { DateRecordComponent } from './complex-records/date-record/date-record.component';
import { IntegerRecordComponent } from './complex-records/integer-record/integer-record.component';
import { FloatRecordComponent } from './complex-records/float-record/float-record.component';
import { MoneyAmountRecordComponent } from './complex-records/money-amount-record/money-amount-record.component';
import { GrantedAmountRecordComponent } from './complex-records/granted-amount-record/granted-amount-record.component';
import { PostcodeRecordComponent } from './complex-records/postcode-record/postcode-record.component';
import { MobileRecordComponent } from './complex-records/mobile-record/mobile-record.component';
import { BigTextRecordComponent } from './complex-records/big-text-record/big-text-record.component';
import { UrlRecordComponent } from './complex-records/url-record/url-record.component';
import { BoardMeetingRecordComponent } from './complex-records/board-meeting-record/board-meeting-record.component';
import { SchemaRecordComponent } from './complex-records/schema-record/schema-record.component';
import { ResponsibleCaseworkerRecordComponent } from './complex-records/responsible-caseworker-record/responsible-caseworker-record.component';
import { AssigneeUserRecordComponent } from './complex-records/assignee-user-record/assignee-user-record.component';
import { BooleanDefaultFalseRecordComponent } from './complex-records/boolean-default-false-record/boolean-default-false-record.component';
import { AcceptanceRecordComponent } from './complex-records/acceptance-record/acceptance-record.component';
import { UploadRecordComponent } from './complex-records/upload-record/upload-record-component';
import { CaseworkerUploadRecordComponent } from './caseworker-records/caseworker-upload-record/caseworker-upload-record.component';
import { CorrespondenceTemplatePdfGeneratorComponent } from './complex-records/correspondence-template-pdf-generator/correspondence-template-pdf-generator.component';
import { EconomyRecordComponent } from './complex-records/economy-record/economy-record.component';
import { CountryRecordComponent } from './complex-records/country-record/country-record.component';
import { PaymentRecordComponent } from './complex-records/payment-record/payment-record.component';
import { PaymentPlanRecordComponent } from './complex-records/payment-plan-record/payment-plan-record.component';
import { PostGrantStatusRecordComponent } from './complex-records/post-grant-status-record/post-grant-status-record.component';
import { PrimitiveTextRecordComponent } from './primitive-records/primitive-text-record/primitive-text-record.component';
import { PaymentRowComponent } from './complex-records/payment-record/payment-row/payment-row.component';
import { PaymentPlanRowComponent } from './complex-records/payment-plan-record/payment-plan-row/payment-plan-row.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslationModule } from '../translation/translation.module';
import { BasicFormControlsModule } from '../basic-form-controls/basic-form-controls.module';
import { UtilsModule } from '../utils/utils.module';
import { CaseworkerUploadFilesComponent } from './caseworker-records/caseworker-upload-record/caseworker-upload-files/caseworker-upload-files.component';
import { CustomListRecordComponent } from './complex-records/custom-list-record/custom-list-record.component';
import { FilterCustomListValuesPipe } from './complex-records/custom-list-record/filter-custom-list-values.pipe';
import { MultiLineRecordComponent } from './complex-records/multi-line-record/multi-line-record.component';
import { IntegerSubrecordComponent } from './complex-records/multi-line-record/subrecords/integer-subrecord/integer-subrecord.component';
import { CustomListSubrecordComponent } from './complex-records/multi-line-record/subrecords/custom-list-subrecord/custom-list-subrecord.component';
import { TextSubrecordComponent } from './complex-records/multi-line-record/subrecords/text-subrecord/text-subrecord.component';
import { ButtonsModule } from '../buttons/buttons.module';
import { AttachmentsDisplayComponent } from './complex-records/economy-record/attachments-display/attachments-display.component';
import { EconomyExpenseMultilineEditComponent } from './complex-records/economy-record/economy-expense-multiline-edit/economy-expense-multiline-edit.component';
import { EconomyIncomeMultilineEditComponent } from './complex-records/economy-record/economy-income-multiline-edit/economy-income-multiline-edit.component';
import { EconomyRecordTotalEditComponent } from './complex-records/economy-record/economy-record-total-edit/economy-record-total-edit.component';
import { EconomyExpenseMultilineViewComponent } from './complex-records/economy-record/economy-expense-multiline-view/economy-expense-multiline-view.component';
import { EconomyRecordTotalViewComponent } from './complex-records/economy-record/economy-record-total-view/economy-record-total-view.component';
import { EconomyIncomeMultilineViewComponent } from './complex-records/economy-record/economy-income-multiline-view/economy-income-multiline-view.component';
import { EconomyRecordWillApplyForEdit } from './complex-records/economy-record/economy-record-will-apply-for-edit/economy-record-will-apply-for-edit.component';
import { PaymentRequesterRecordComponent } from './complex-records/payment-requester-record/payment-requester-record.component';
import { AttachmentsUploaderComponent } from './complex-records/economy-record/attachments-uploader/attachments-uploader.component';
import { PaymentRequesterRecordPopupComponent } from './complex-records/payment-requester-record/payment-requester-record-dialog/payment-requester-record-popup.component';
import { PopupModule } from '../popup/popup.module';
import { PaymentBlockComponent } from './complex-records/payment-requester-record/payment-block/payment-block.component';
import { PaymentRecordPopupComponent } from './complex-records/payment-record/payment-record-popup/payment-record-popup.component';
import { BackendUserRecordComponent } from './complex-records/backend-user-record/backend-user-record.component';
import { BackendUserSubrecordComponent } from './complex-records/multi-line-record/subrecords/backend-user-subrecord/backend-user-subrecord.component';
import { AcceptanceRecordPopupComponent } from './complex-records/acceptance-record/acceptance-record-popup/acceptance-record-popup.component';
import { PaymentsBalanceService } from './complex-records/payment-record/payment-balance.service';
import { CurrencyModule } from '../currency/currency.module';

import { PwaRecordsModule } from '../../pwa/shared/pwa-records/pwa-records.module';
import { PwaListModule } from '../../pwa/shared/pwa-list/pwa-list-module';
import { PwaLabelledTextModule } from '../../pwa/shared/pwa-labelled-text/pwa-labelled-text.module';
import { PwaBoxModule } from '../../pwa/shared/pwa-box/pwa-box.module';
import { PwaAlertModule } from '../../pwa/shared/pwa-alert/pwa-alert.module';
import { PwaInputModule } from '../../pwa/shared/pwa-input/pwa-input.module';
import { PwaButtonModule } from '../../pwa/shared/pwa-button/pwa-button.module';
import { PwaSelectModule } from '../../pwa/shared/pwa-select/pwa-select.module';
import { PwaAttachmentsModule } from '../../pwa/shared/pwa-attachments/pwa-attachments.module';
import { PwaCheckboxModule } from '../../pwa/shared/pwa-checkbox/pwa-checkbox.module';
import { PwaPopupModule } from '../../pwa/shared/pwa-popup/pwa-popup.module';
import { PwaRadioModule } from '../../pwa/shared/pwa-radio/pwa-radio.module';
import { PwaErrorMessageModule } from '../../pwa/shared/pwa-error-message/pwa-error-message.module';
import { GrantedAmountChildrenComponent } from './complex-records/granted-amount-record/granted-amount-children/granted-amount-children.component';
import { GrantedAmountReadonlyLines } from './complex-records/granted-amount-record/granted-amount-readonly-lines/granted-amount-readonly-lines.component';
import { GrantedAmountReadonlyLine } from './complex-records/granted-amount-record/granted-amount-readonly-line/granted-amount-readonly-line.component';
import { PaymentBlockChildrenComponent } from './complex-records/payment-requester-record/payment-block-children/payment-block-children.component';

const records = [
    EmailRecordComponent,
    TextRecordComponent,
    BankRecordComponent,
    CompanyRecordComponent,
    SubcategoryRecordComponent,
    DateRecordComponent,
    IntegerRecordComponent,
    FloatRecordComponent,
    MoneyAmountRecordComponent,
    GrantedAmountRecordComponent,
    PostcodeRecordComponent,
    MobileRecordComponent,
    BigTextRecordComponent,
    UrlRecordComponent,
    BoardMeetingRecordComponent,
    SchemaRecordComponent,
    ResponsibleCaseworkerRecordComponent,
    BackendUserRecordComponent,
    AssigneeUserRecordComponent,
    BooleanDefaultFalseRecordComponent,
    AcceptanceRecordComponent,
    UploadRecordComponent,
    CaseworkerUploadRecordComponent,
    CorrespondenceTemplatePdfGeneratorComponent,
    EconomyRecordComponent,
    CountryRecordComponent,
    PaymentRecordComponent,
    PaymentPlanRecordComponent,
    PostGrantStatusRecordComponent,
    PrimitiveTextRecordComponent,
    CustomListRecordComponent,
    MultiLineRecordComponent,
    PaymentRequesterRecordComponent,
    PaymentRequesterRecordPopupComponent,
    PaymentRecordPopupComponent,
];

const additionalDeclarations = [
    RecordResolverComponent,
    CaseworkerUploadFilesComponent,
    FilterCustomListValuesPipe,
    IntegerSubrecordComponent,
    CustomListSubrecordComponent,
    TextSubrecordComponent,
    BackendUserSubrecordComponent,
    PaymentPlanRowComponent,
    PaymentRowComponent,
    AttachmentsDisplayComponent,
    AttachmentsUploaderComponent,
    EconomyExpenseMultilineEditComponent,
    EconomyExpenseMultilineViewComponent,
    EconomyIncomeMultilineEditComponent,
    EconomyIncomeMultilineViewComponent,
    EconomyRecordTotalEditComponent,
    EconomyRecordTotalViewComponent,
    EconomyRecordWillApplyForEdit,
    PaymentBlockComponent,
    PaymentBlockChildrenComponent,
    AcceptanceRecordPopupComponent,
    GrantedAmountChildrenComponent,
    GrantedAmountReadonlyLines,
    GrantedAmountReadonlyLine,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslationModule,
        BasicFormControlsModule,
        UtilsModule,
        ButtonsModule,
        PopupModule,
        CurrencyModule,
        PwaRecordsModule,
        PwaListModule,
        PwaLabelledTextModule,
        PwaBoxModule,
        PwaAlertModule,
        PwaInputModule,
        PwaButtonModule,
        PwaSelectModule,
        PwaAttachmentsModule,
        PwaCheckboxModule,
        PwaPopupModule,
        PwaRadioModule,
        PwaErrorMessageModule,
    ],
    declarations: [records, additionalDeclarations],
    exports: [RecordResolverComponent],
    entryComponents: [records],
    providers: [PaymentsBalanceService],
})
export class RecordsModule {}
