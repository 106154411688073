import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CurrencyService } from '../currency.service';

@Component({
    selector: 'fonda-currency',
    templateUrl: 'currency.component.html',
})
export class FondaCurrencyComponent implements OnInit {
    currency$: Observable<string>;

    constructor(private currencyService: CurrencyService) {}

    ngOnInit(): void {
        this.currency$ = this.currencyService.getCurrencyObservable();
    }
}
