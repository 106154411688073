import { ApplicationRef, Component, OnDestroy, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Subject } from 'rxjs/Subject';
import { first, switchMap, takeUntil } from 'rxjs/operators';
import { interval } from 'rxjs';

@Component({
    selector: 'fonda-app-update-popup',
    templateUrl: './app-update-popup.component.html',
    styleUrls: ['./app-update-popup.component.scss'],
})
export class AppUpdatePopupComponent implements OnInit, OnDestroy {
    showPopup = false;
    displayLoading = false;
    private destroyed = new Subject();

    constructor(private swUpdate: SwUpdate, private appRef: ApplicationRef) {}

    ngOnInit(): void {
        const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
        const everyHour$ = interval(60 * 60 * 1000);
        const everyHourAfterAppIsStable$ = appIsStable$.pipe(switchMap(() => everyHour$));

        everyHourAfterAppIsStable$.subscribe(() => {
            if (this.swUpdate.isEnabled) {
                this.swUpdate.checkForUpdate();
            }
        });

        this.swUpdate.available.pipe(takeUntil(this.destroyed)).subscribe(() => {
            this.showPopup = true;
        });

        this.swUpdate.unrecoverable.pipe(takeUntil(this.destroyed)).subscribe(event => {
            if (
                window.prompt(
                    `An error occurred that we cannot recover from:\n${event.reason}\n\nPlease reload the page.`,
                    'Reload'
                )
            ) {
                window.location.reload();
            }
        });
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }

    activateNewVersionAndReload(): void {
        this.displayLoading = true;
        this.swUpdate.activateUpdate().then(() => {
            window.location.reload();
        });
    }
}
