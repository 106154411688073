import { Component, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { PaymentPlanObject } from '../payment-plan-object';
import { PaymentProfileDto } from '../../../../../api/dto/payment-profile-dto/payment-profile-dto';
import { PaymentColors } from '../../payment-record/payment-colors';
import { BasicSelectComponentEntryWithColor } from '../../../../basic-form-controls/basic-select/basic-select.component';

@Component({
    selector: '[payment-plan-row]',
    templateUrl: 'payment-plan-row.component.html',
})
export class PaymentPlanRowComponent {
    @Input('rowEntry') public set rowEntry(rowEntry: PaymentPlanObject) {
        if (rowEntry) {
            this.rowDate = rowEntry.date;
            this.name = rowEntry.name;
            this.amount = this.convertToString(rowEntry.amount);
            this.choosenPaymentProfileUuid = rowEntry.paymentProfileUuid;
            this.startingSnapshot = rowEntry;
        }
    }

    @Input() set paymentProfiles(paymentProfiles: Array<PaymentProfileDto>) {
        this._paymentProfiles = [...paymentProfiles];
        if (this.choosenPaymentProfileUuid) {
            this.choosenPaymentProfile = this._paymentProfiles.find(
                prof => prof.uuid === this.choosenPaymentProfileUuid
            );
        }
        this.paymentProfilesForDropdown = this.getProfiles();
    }

    public rowDate: Date;
    public name: string;
    public amount: string;
    public newDate = new Date();
    private startingSnapshot: PaymentPlanObject;
    public _paymentProfiles: Array<PaymentProfileDto> = [];
    public choosenPaymentProfileUuid: string;
    public choosenPaymentProfile: PaymentProfileDto;
    public paymentProfilesForDropdown: Array<BasicSelectComponentEntryWithColor> = [];
    @Output('onDelete') onDelete = new EventEmitter();
    @Output('onChange') onChange = new EventEmitter();
    @Input() ind = 0;

    @Input() uuid = '';

    public saveClicked = false;

    public onSaveClick() {
        this.saveClicked = true;
    }

    public getProfiles(): Array<BasicSelectComponentEntryWithColor> {
        if (this._paymentProfiles && this._paymentProfiles.length > 0) {
            return this._paymentProfiles.map(prof => ({
                value: prof.name,
                color: prof.isDefault ? PaymentColors.Green : PaymentColors.Orange,
            }));
        } else {
            return [];
        }
    }

    public handlePaymentProfileChange(name: string) {
        const profile = this._paymentProfiles.find(prof => prof.name == name);
        this.choosenPaymentProfileUuid = profile.uuid;
        this.choosenPaymentProfile = profile;
        this.changeValue();
    }

    public clickDelete() {
        this.onDelete.emit();
    }

    public isChanged(): boolean {
        return JSON.stringify(this.getValue()) !== JSON.stringify(this.startingSnapshot);
    }

    public changeValue() {
        this.onChange.emit();
    }

    public isValid(): boolean {
        return !!(this.amount && this.rowDate && this.name);
    }

    public getValue(): PaymentPlanObject {
        return new PaymentPlanObject(
            this.name,
            this.rowDate,
            this.convertToNumber(this.amount),
            this.choosenPaymentProfileUuid
        );
    }

    private convertToNumber(str: string): number {
        if (!str) {
            return 0;
        }
        str = str.replace(/[^\d,-]/g, '');
        str = str.replace(',', '.');
        return Number(str);
    }

    private convertToString(num: number): string {
        if (num) {
            const str = num.toString();
            return str.replace('.', ',');
        } else {
            return;
        }
    }
}
