import { Component, ElementRef } from '@angular/core';

@Component({
    selector: '[dropdownOption]',
    template: `<ng-content></ng-content>`,
    styleUrls: ['dropdown-option.component.scss'],
})
export class DropdownOptionComponent {
    constructor(private readonly elementRef: ElementRef<HTMLElement>) {
        this.elementRef.nativeElement.classList.add('dropdown-option');
    }
}
