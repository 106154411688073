<a
    class="economy-attachments-label"
    (click)="hidden = false"
    [style.fontSize]="fontSize + 'rem'"
    [style.fontWeight]="bold ? 'bold' : ''"
>
    {{ attachments.length }} {{ 'general' | translate : 'attachments' }}
    <span *ngIf="mandatory" class="mandatory">*</span>
</a>
<div *ngIf="!hidden && attachments.length" class="is-relative">
    <div class="economy-attachments-popup">
        <ng-container *ngFor="let file of attachments">
            <p>
                <a (click)="downloadFile(file)">{{ file.file_name }}</a>
                <button btn color="danger" *ngIf="!readonly" (click)="onDelete(file.uuid)">
                    <span class="icon-trash"></span>
                </button>
            </p>
        </ng-container>
    </div>
</div>
