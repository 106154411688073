import { Component, ElementRef, ViewChild } from '@angular/core';
import { BaseRecordComponent, RecordMetadata } from '../../base-record.component';
import { TextValue } from '../../values.types';

@Component({
    selector: 'integer-record',
    templateUrl: 'integer-record.component.html',
})
export class IntegerRecordComponent extends BaseRecordComponent<TextValue> {
    @ViewChild('input') input: ElementRef<HTMLInputElement>;

    value = '';

    initRecord(metadata: RecordMetadata<TextValue>) {}

    isValid(): boolean {
        return !this.metadata.isRequired || this.value !== '';
    }

    isEmpty(): boolean {
        return this.value !== '';
    }

    getValue(): TextValue {
        return { text: this.value };
    }

    setValue(value: TextValue) {
        this.value = value.text;
    }

    updateValue(value: string): void {
        this.value = value.replace(/[^0-9]|^0+(?=.)/g, '');
        if (this.input.nativeElement) this.input.nativeElement.value = this.value;
        this.onChange.emit(this.getValue());
    }
}
