import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { RedirectionService } from '../services/redirection-service';
import { Injectable } from '@angular/core';

@Injectable()
export class CaseworkerGuard implements CanActivate, CanActivateChild {
    constructor(private authService: AuthService, private redirectionService: RedirectionService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.authService.isCaseworker()) {
            return true;
        } else if (this.authService.isApplicant()) {
            this.redirectionService.toApplicantLandingPage();
            return false;
        } else if (this.authService.isBoardMeetingViewer()) {
            this.redirectionService.toBoardMeetingViewer();
            return false;
        } else {
            this.redirectionService.toLandingPage(state.url);
            return false;
        }
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(childRoute, state);
    }
}
