import { AbstractControl } from '@angular/forms';

export class ApplicantValidator {
    public static dateRegex = new RegExp('^(0[1-9]|1\\d|2\\d|3[01])\\-(0[1-9]|1[0-2])\\-(19|20)\\d{2}$');

    public static ApplicantDate(control: AbstractControl): { [key: string]: any } {
        const dateRegex = new RegExp('^(0[1-9]|1\\d|2\\d|3[01])\\-(0[1-9]|1[0-2])\\-(19|20)\\d{2}$');
        if (control.value && !dateRegex.test(control.value)) {
            return {
                isValidDate: true,
            };
        }
        return null;
    }

    public static FieldRequired(control: AbstractControl): { [key: string]: any } {
        if (!control.value) {
            return {
                valid: false,
                isFieldNotFilled: true,
            };
        }
        return null;
    }

    public static InitialsRequired(control: AbstractControl): { [key: string]: any } {
        const initialsRegex = new RegExp('^(?:[A-Z][a-z]*.)+$');

        if (!control.value) {
            return {
                requiredError: true,
            };
        }

        if (control.value && !initialsRegex.test(control.value)) {
            return {
                isNotValidInitial: true,
            };
        }
        return null;
    }

    public static EmailRequired(control: AbstractControl): { [key: string]: any } {
        const emailRegex = new RegExp(
            "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
        );
        if (!emailRegex.test(control.value)) {
            return {
                isNotValidEmail: true,
            };
        }
        return null;
    }

    public static PasswordRequired(control: AbstractControl): { [key: string]: any } {
        if (!control.value || control.value.length < 8) {
            return {
                isNotValidPassword: true,
            };
        }
        return null;
    }

    public static UrlRequired(control: AbstractControl): { [key: string]: any } {
        const urlRegex = new RegExp(
            '(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{1,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{1,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]\\.[^\\s]{1,}|www\\.[a-zA-Z0-9]\\.[^\\s]{1,})'
        );

        if (control.value && !urlRegex.test(control.value)) {
            return {
                isNotValidUrl: true,
            };
        }
    }
}
