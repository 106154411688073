import { Component, Input } from '@angular/core';
import { IPaymentRequesterRecordConfigurationData } from '../../../../../shared/record-configuration/configurations/payment-requester-record-configuration/payment-requester-record-configuration.component';

@Component({
    selector: 'tr[payment-block-children]',
    templateUrl: 'payment-block-children.component.html',
    styleUrls: ['payment-block-children.component.scss'],
})
export class PaymentBlockChildrenComponent {
    readonly GRANTED_AMOUNT_RECORD_UUID = '91408361-dc1d-4cc9-b7bf-cf86256558e2'; // GrantedAmountRecord

    @Input() children = [];
    @Input() boardMeetings = [];
    @Input() configuration: IPaymentRequesterRecordConfigurationData;

    get colspan() {
        let value = 7;
        if (this.configuration.has_comments) value++;
        if (this.configuration.has_attachments) value++;
        if (this.configuration.payment_statuses?.length) value++;
        return value;
    }
}
