import { BackendUserData } from './backend-user-data';

export class ApplicationLogDto {
    constructor(
        readonly id: number,
        readonly changerProfile: BackendUserData,
        readonly applicationUuid: string,
        readonly occurredDate: string,
        readonly type: string,
        readonly data: object,
        readonly recordConfiguration: object
    ) {}

    public recordUuid: string;
    public recordBehaviour: string;
}
