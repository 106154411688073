<div class="pwa-popup-content-container" [class]="getSize()" (escPressed)="onClose()">
    <header>
        <div>
            <ng-content select="[pwa-popup-header]"></ng-content>
        </div>
        <button *ngIf="closeable" (click)="onClose()">
            <span class="icon-close"></span>
        </button>
    </header>
    <div class="pwa-popup-content">
        <ng-content></ng-content>
    </div>
    <div>
        <ng-content select="[pwa-popup-actions]"></ng-content>
    </div>
</div>
