import { Component, Inject, OnDestroy } from '@angular/core';
import { BaseRecordComponent, RecordMetadata } from '../../base-record.component';
import { PaymentStoreService } from '../../../../services/payment-store-service';
import { Subscription } from 'rxjs/Subscription';
import { PaymentProfilePopupService } from '../../../components/payment-profile/payment-profile-popup-service';
import { PaymentProfileDto } from '../../../../api/dto/payment-profile-dto/payment-profile-dto';
import { ActivatedRoute } from '@angular/router';
import { FondaApiService } from '../../../../api/fonda-api.service';
import { PopupService } from '../../../../shared/popup/popup.service';
import { IPaymentRequesterRecordConfigurationData } from '../../../../shared/record-configuration/configurations/payment-requester-record-configuration/payment-requester-record-configuration.component';
import { BoardMeetingDto } from '../../../../api/dto/board-meeting/board-meeting-dto';
import { PaymentRecordPopupComponent } from './payment-record-popup/payment-record-popup.component';
import cloneDeep from 'lodash/cloneDeep';
import { PaymentsBalanceService } from './payment-balance.service';
import { PaymentGrantedBlocksDto, PaymentRequesterDto, PaymentStatusDto } from './payment-types';
import { v4 as uuidv4 } from 'uuid';
import { SaveApplicationService } from '../../../../services/save-application.service';

let PAYMENT_ID = 0;

type PaymentRecordSearchTextValue = [number | null, number | null];
export interface PaymentRecordValue {
    payments: Array<PaymentRequesterDto>;
    grant_blocks: Array<PaymentGrantedBlocksDto>;
}

export interface PaymentRecordSearchValue {
    text: [number, number];
}

export type PaymentRecordOutput = PaymentRecordValue | PaymentRecordSearchValue;

@Component({
    selector: 'payment-record',
    templateUrl: 'payment-record.component.html',
    styleUrls: ['payment-record.component.scss'],
})
export class PaymentRecordComponent
    extends BaseRecordComponent<PaymentRecordOutput, IPaymentRequesterRecordConfigurationData>
    implements OnDestroy
{
    constructor(
        private activatedRoute: ActivatedRoute,
        @Inject(FondaApiService) private apiService: FondaApiService,
        private paymentStoreService: PaymentStoreService,
        private paymentProfilePopup: PaymentProfilePopupService,
        private popupService: PopupService,
        private paymentsBalanceService: PaymentsBalanceService,
        private saveApplicationService: SaveApplicationService
    ) {
        super();
    }

    public value: PaymentRecordValue = { payments: [], grant_blocks: [] };
    public searchValue: PaymentRecordSearchTextValue = [null, null];

    public boardMeetings: Array<BoardMeetingDto> = [];
    public paymentProfiles: Array<PaymentProfileDto> = [];

    public grantedAmount; // var to calc singleBlock balance

    private onClosePopupSub$: Subscription;
    private grantedAmountSub$: Subscription;

    readonly applicationUuid = this.activatedRoute.snapshot.queryParams.uuid;

    get payments(): Array<PaymentRequesterDto> {
        return this.value.payments;
    }

    set payments(value: Array<PaymentRequesterDto>) {
        this.value.payments = value;
    }

    get grantedBlocks(): Array<PaymentGrantedBlocksDto> {
        return this.value.grant_blocks;
    }

    getGrantedBlockChildren(parentUuid: string): Array<PaymentGrantedBlocksDto> {
        if (!parentUuid) return [];
        return this.value.grant_blocks.filter(gb => gb.parentUuid === parentUuid) || [];
    }

    get hasBlocks(): boolean {
        return this.grantedBlocks.length ? true : false;
    }

    get configuration(): IPaymentRequesterRecordConfigurationData {
        return this.metadata.configuration;
    }

    get uuid(): string {
        return this.metadata.uuid;
    }

    get size(): number {
        return this.metadata.size;
    }

    get title(): string {
        return this.metadata.title;
    }

    get paymentStatuses(): PaymentStatusDto[] {
        if (!this.configuration.payment_statuses) return [];
        return this.configuration.payment_statuses;
    }

    getPaymentsByBlock(uuid) {
        return this.payments.filter(p => p.grant_identifier === uuid);
    }

    initRecord(metadata: RecordMetadata<PaymentRecordOutput, IPaymentRequesterRecordConfigurationData>) {
        this.fetchPaymentProfiles();
        this.fetchBoardMeetings();
        this.onClosePopupSub$ = this.paymentProfilePopup.onClosePopup.subscribe(() => {
            this.fetchPaymentProfiles();
        });
        this.grantedAmountSub$ = this.paymentStoreService.grantedAmountPlanEvent.subscribe(grantedAmount => {
            this.grantedAmount = grantedAmount;
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.onClosePopupSub$.unsubscribe();
        this.grantedAmountSub$.unsubscribe();
    }

    setValue(value: PaymentRecordValue | PaymentRecordSearchValue) {
        if (this.isOnSearch) {
            this.searchValue = (value as PaymentRecordSearchValue).text;
        } else {
            this.value = value as PaymentRecordValue;
            this.value.payments = this.value.payments.map(p => {
                p.unique_id = 'payment-' + PAYMENT_ID++;
                return p;
            });
            this.emitValue();
        }
    }

    getValue(): PaymentRecordOutput {
        if (this.isOnSearch) return { text: this.searchValue };
        const paymentsCopy = cloneDeep(this.payments).map(payment => {
            delete payment['balance'];
            delete payment['unique_id'];
            if (!this.configuration.has_attachments) delete payment['attachments'];
            if (!this.configuration.has_comments) delete payment['comment'];
            return payment;
        });
        return {
            grant_blocks: this.grantedBlocks,
            payments: paymentsCopy,
        };
    }

    isEmpty(): boolean {
        return false;
    }

    isValid(): boolean {
        return true;
    }

    emitValue() {
        this.onChange.emit(this.getValue());
    }

    addPaymentPopup(block: PaymentGrantedBlocksDto | null) {
        this.popupService
            .spawnPopup(PaymentRecordPopupComponent, {
                payment: {
                    payment_identifier: uuidv4(),
                    name: null,
                    amount: null,
                    comment: null,
                    date: null,
                    attachments: [],
                    grant_identifier: block ? block.grant_identifier : null,
                    payment_profile: null,
                    type: null,
                    payment_status: null,
                    unique_id: 'payment-' + PAYMENT_ID++,
                    is_originally_from_applicant: false,
                },
                uuid: this.uuid,
                isEdit: false,
                blockBalance: block
                    ? this.paymentsBalanceService.getBlockBalance(
                          this.payments,
                          this.grantedBlocks,
                          block.grant_identifier,
                          this.paymentStatuses
                      )
                    : this.paymentsBalanceService.getSingleBlockBalance(
                          this.payments,
                          this.grantedAmount,
                          this.paymentStatuses
                      ),
                config: this.configuration,
                paymentProfiles: this.paymentProfiles,
            })
            .subscribe((data: PaymentRequesterDto) => {
                if (data) {
                    this.payments = this.payments.concat(data);
                    this.emitValue();
                    this.saveApplicationService.save();
                }
            });
    }

    editPaymentPopup(payment) {
        this.popupService
            .spawnPopup(PaymentRecordPopupComponent, {
                payment: { ...payment },
                uuid: this.uuid,
                isEdit: true,
                blockBalance: payment.grant_identifier
                    ? this.paymentsBalanceService.getBlockBalance(
                          this.payments,
                          this.grantedBlocks,
                          payment.grant_identifier,
                          this.paymentStatuses
                      )
                    : this.paymentsBalanceService.getSingleBlockBalance(
                          this.payments,
                          this.grantedAmount,
                          this.paymentStatuses
                      ),
                config: this.configuration,
                paymentProfiles: this.paymentProfiles,
            })
            .subscribe((data: PaymentRequesterDto) => {
                if (data) {
                    this.payments = this.payments.map(p => {
                        if (p.unique_id === data.unique_id) {
                            return data;
                        }
                        return p;
                    });
                    this.emitValue();
                    this.saveApplicationService.save();
                }
            });
    }

    patchPaymentStatus(data: { payment: PaymentRequesterDto; status: string }) {
        this.payments = this.payments.map(p => {
            if (p.unique_id === data.payment.unique_id) {
                p.payment_status = data.status;
            }
            return p;
        });
        this.saveApplicationService.save();
        this.emitValue();
    }

    deletePayment(payment) {
        this.payments = this.payments.filter(p => p.unique_id !== payment.unique_id);
        this.emitValue();
    }

    public update(index: 0 | 1, value: number) {
        this.searchValue = this.searchValue.slice() as PaymentRecordSearchTextValue;
        this.searchValue.splice(index, 1, value);
        this.emitValue();
    }

    public openPaymentProfile() {
        this.paymentProfilePopup.initPopup();
    }

    private fetchPaymentProfiles() {
        this.apiService.getApplicationPaymentProfiles(this.applicationUuid).then(profiles => {
            this.paymentProfiles = [...profiles];
        });
    }

    private fetchBoardMeetings() {
        this.apiService
            .getBoardMeetings()
            .then(res => {
                this.boardMeetings = [...res];
            })
            .catch(err => {});
    }
}
