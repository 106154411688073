import { Component, ContentChild, Directive, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Directive({ selector: '[actionDropdownLabel]' })
export class ActionDropdownLabelDirective {
    constructor(public readonly templateRef: TemplateRef<unknown>) {}
}

@Directive({ selector: '[actionDropdownOption]' })
export class ActionDropdownOptionDirective<T = any> {
    constructor(public readonly templateRef: TemplateRef<T>) {}
}

@Component({
    selector: 'fonda-action-dropdown',
    templateUrl: './action-dropdown.component.html',
    styleUrls: ['./action-dropdown.component.scss'],
})
export class ActionDropdownComponent<T = any> {
    @ContentChild(ActionDropdownLabelDirective, { static: true }) labelDirective: ActionDropdownLabelDirective;
    @ContentChild(ActionDropdownOptionDirective, { static: true }) actionDirective: ActionDropdownOptionDirective;

    @Input() placement: 'left' | 'right' = 'left';
    @Input() actions: T[] = [];
    @Output() action = new EventEmitter<T>();

    get dropdownPlacement() {
        return this.placement === 'right' ? 'bottom-end' : 'bottom-start';
    }

    emitAction(action: T): void {
        this.action.emit(action);
    }
}
