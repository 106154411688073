import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PwaRecordsComponent } from './pwa-records.component';

import { PwaIsNotOnPwaDirective, PwaIsOnPwaDirective } from './pwa-records.directive';

const COMPONENTS = [PwaRecordsComponent];

const DIRECTIVES = [PwaIsOnPwaDirective, PwaIsNotOnPwaDirective];

@NgModule({
    declarations: [COMPONENTS, DIRECTIVES],
    imports: [CommonModule],
    exports: [COMPONENTS, DIRECTIVES],
})
export class PwaRecordsModule {}
