<ng-container *ngFor="let recordValue of recordValues; let ind = index; trackBy: recordTrackBy">
    <record-resolver
        class="records"
        #recordResolver
        (afterInit)="onAfterInit()"
        [size]="recordValue.size"
        [isRequired]="recordValue.isRequired"
        [uuid]="recordValue.uuid"
        [type]="recordValue.type"
        [value]="recordValue.value"
        [onSearch]="recordValue.onSearch"
        [title]="recordValue.title"
        [readonly]="recordValue.readonly || readonlyGroup || !recordValue.editable"
        [configuration]="recordValue.configuration"
        [hasDynamicPrefilling]="recordValue.hasDynamicPrefilling"
        [errorMessage]="getErrorMessageByUuid(recordValue.uuid)"
        (onChange)="onChange.emit($event); handleChange()"
        [ngClass]="{
            second: getArrayOfDivIndexWithMargin(ind),
            readonly: recordValue.readonly || readonlyGroup || !recordValue.editable
        }"
    ></record-resolver>
</ng-container>
