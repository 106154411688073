import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class SaveApplicationService {
    private saved$ = new Subject<void>();

    save() {
        this.saved$.next();
    }

    notify(): Observable<void> {
        return this.saved$.asObservable();
    }
}
