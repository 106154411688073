import { Component } from '@angular/core';
import { BaseRecordComponent, RecordMetadata } from '../../base-record.component';
import { FormControl, Validators } from '@angular/forms';
import { TextValue } from '../../values.types';
import { TextRecordConfiguration } from '../text-record/text-record.component';
import { setConditionalValidators } from '../../conditional-validators';

@Component({
    selector: 'postcode-record',
    templateUrl: 'postcode-record.component.html',
})
export class PostcodeRecordComponent extends BaseRecordComponent<TextValue, TextRecordConfiguration> {
    text = new FormControl('');

    initRecord(metadata: RecordMetadata<TextValue, TextRecordConfiguration>) {
        setConditionalValidators(this.text, [
            [metadata.isRequired, Validators.required],
            [metadata.configuration.min_length, Validators.minLength(metadata.configuration.min_length)],
            [metadata.configuration.max_length, Validators.maxLength(metadata.configuration.max_length)],
        ]);
        this.registerControlChanges(this.text);
    }

    setValue(value: TextValue) {
        this.text.setValue(value.text);
    }

    getValue(): TextValue {
        return { text: this.text.value };
    }

    emitValue() {
        this.onChange.emit(this.getValue());
    }

    isValid(): boolean {
        return this.text.valid;
    }

    isEmpty(): boolean {
        return this.text.value === '';
    }
}
