<ng-container *isNotOnPWA>
    <div *ngIf="!isReadonly">
        <section class="application-economy" style="margin: 0">
            <h3>{{ metadata.uuid | translate : 'title' }}</h3>
            <div class="bank-record">
                <div class="form-item half">
                    <input
                        id="application-economy-bank-regnr{{ uniqueId }}"
                        type="string"
                        [formControl]="registrationNumber"
                        placeholder=""
                    />
                    <label for="application-economy-bank-regnr{{ uniqueId }}">
                        {{ metadata.uuid | translate : 'bank_details_registration_number' }}
                        <span *ngIf="metadata.isRequired" class="mandatory">*</span>
                    </label>
                    <div class="form-focus"></div>
                    <div
                        class="form-error"
                        [ngClass]="{ active: registrationNumber.value && !registrationNumber.valid }"
                    >
                        Udfyld dette felt med 4 cifre
                    </div>
                </div>
                <div class="form-item half half-last">
                    <input
                        id="application-economy-bank-account{{ uniqueId }}"
                        name="ec"
                        type="string"
                        [formControl]="accountNumber"
                        placeholder=""
                    />
                    <label for="application-economy-bank-account{{ uniqueId }}">
                        {{ metadata.uuid | translate : 'bank_details_account_number' }}
                        <span *ngIf="metadata.isRequired" class="mandatory">*</span>
                    </label>
                    <div class="form-focus"></div>
                    <div class="form-error" [ngClass]="{ active: accountNumber.value && !accountNumber.valid }">
                        Udfyld dette felt med 6 - 10 cifre
                    </div>
                </div>
            </div>
        </section>
    </div>

    <ng-container *ngIf="isReadonly" class="full">
        <a class="form-item">
            <p>{{ metadata.uuid | translate : 'bank_details_registration_number' }}</p>
            <span>{{ registrationNumber.value ? registrationNumber.value : '-' }}</span>
            <div class="form-focus"></div>
            <div class="form-error"></div>
        </a>
        <a class="form-item second">
            <p>{{ metadata.uuid | translate : 'bank_details_account_number' }}</p>
            <span>{{ accountNumber.value ? accountNumber.value : '-' }}</span>
            <div class="form-focus"></div>
            <div class="form-error"></div>
        </a>
    </ng-container>
</ng-container>

<ng-container *isOnPWA>
    <ng-container *ngIf="!isReadonly">
        <pwa-list-element>
            <pwa-labelled-text [mandatory]="metadata.isRequired" [errorMessage]="errorMessage">
                <ng-container pwaLabelledTextLabel>
                    {{ metadata.uuid | translate : 'title' }}
                </ng-container>
                <ng-container pwaLabelledTextContent>
                    <div class="pwa-bank-record-content">
                        <pwa-labelled-text
                            [hasError]="
                                !!(
                                    (registrationNumber.dirty || registrationNumber.touched) &&
                                    registrationNumber.errors
                                )
                            "
                        >
                            <ng-container pwaLabelledTextLabel>
                                {{ metadata.uuid | translate : 'bank_details_registration_number' }}
                            </ng-container>
                            <ng-container pwaLabelledTextContent>
                                <input
                                    style="width: 80%"
                                    id="application-economy-bank-regnr{{ uniqueId }}"
                                    type="string"
                                    [formControl]="registrationNumber"
                                    placeholder=""
                                />
                                <div class="pwa-labelled-text-content-info">
                                    <span class="pwa-labelled-text-mark-error">
                                        {{ registrationNumber.value ? registrationNumber.value.toString().length : 0 }}
                                    </span>
                                    / 4
                                </div>
                            </ng-container>
                        </pwa-labelled-text>
                        <pwa-labelled-text
                            [hasError]="!!((accountNumber.dirty || accountNumber.touched) && accountNumber.errors)"
                        >
                            <ng-container pwaLabelledTextLabel>
                                {{ metadata.uuid | translate : 'bank_details_account_number' }}
                            </ng-container>
                            <ng-container pwaLabelledTextContent>
                                <input
                                    style="width: 80%"
                                    id="application-economy-bank-account{{ uniqueId }}"
                                    name="ec"
                                    type="string"
                                    [formControl]="accountNumber"
                                    placeholder=""
                                />
                                <div class="pwa-labelled-text-content-info">
                                    <span class="pwa-labelled-text-mark-error">
                                        {{ accountNumber.value ? accountNumber.value.toString().length : 0 }}
                                    </span>
                                    / 10
                                </div>
                            </ng-container>
                        </pwa-labelled-text>
                    </div>
                </ng-container>
            </pwa-labelled-text>
        </pwa-list-element>
    </ng-container>
    <ng-container *ngIf="isReadonly">
        <pwa-list-element>
            <pwa-labelled-text>
                <ng-container pwaLabelledTextLabel>
                    {{ metadata.uuid | translate : 'title' }}
                </ng-container>
                <ng-container pwaLabelledTextContent>
                    <div class="pwa-bank-record-content">
                        <pwa-labelled-text>
                            <ng-container pwaLabelledTextLabel>
                                {{ metadata.uuid | translate : 'bank_details_registration_number' }}
                            </ng-container>
                            <ng-container pwaLabelledTextContent>
                                {{ registrationNumber.value ? registrationNumber.value : '-' }}
                            </ng-container>
                        </pwa-labelled-text>
                        <pwa-labelled-text>
                            <ng-container pwaLabelledTextLabel>
                                {{ metadata.uuid | translate : 'bank_details_account_number' }}
                            </ng-container>
                            <ng-container pwaLabelledTextContent>
                                {{ accountNumber.value ? accountNumber.value : '-' }}
                            </ng-container>
                        </pwa-labelled-text>
                    </div>
                </ng-container>
            </pwa-labelled-text>
        </pwa-list-element>
    </ng-container>
</ng-container>
