import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

export type PwaPopupSize = 'small' | 'medium' | 'large';

@Component({
    selector: 'pwa-popup',
    templateUrl: 'pwa-popup.component.html',
    styleUrls: ['pwa-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'pwa-popup',
    },
})
export class PwaPopupComponent {
    @Output() close = new EventEmitter<void>();
    @Input() size: PwaPopupSize = 'small';
    @Input() closeable: boolean = true;

    onClose() {
        this.close.emit();
    }

    getSize(): PwaPopupSize {
        return ['small', 'medium', 'large'].includes(this.size) ? this.size : 'small';
    }
}
