import { PatchTranslationDto } from './patch-translation.dto';

export interface CustomListValue {
    translations: Array<{
        language: string;
        text: string;
    }>;
    values: Array<{
        sequence: number;
        uuid: string | null;
        translations: Array<{ language: string; text: string }>;
    }>;
}

export class InvalidCustomListSequence extends Error {
    constructor(uuid: string, sequence: any) {
        super(
            `Invalid sequence given for enabled custom list value (${uuid}). Expected number, given ${typeof sequence}.`
        );
    }
}

export class CustomListValueDto {
    constructor(
        public readonly uuid: string,
        public readonly sequence: number | null,
        public readonly enabled: boolean
    ) {
        if (enabled === true && typeof sequence !== 'number') {
            throw new InvalidCustomListSequence(uuid, sequence);
        }
    }
}

export class CustomListDto {
    public readonly uuid: string;
    public readonly values: CustomListValueDto[];

    constructor(uuid: string, values: CustomListValueDto[]) {
        this.uuid = uuid;
        this.values = [...values].sort((a, b) => {
            if (a.sequence === null && b.sequence === null) return 0;
            if (a.sequence === null) return 1;
            if (b.sequence === null) return -1;
            return a.sequence - b.sequence;
        });
    }
}

export class CreateCustomListValueDto {
    constructor(public readonly sequence: number, public readonly translations: PatchTranslationDto[]) {}
}

export class CreateCustomListDto {
    static fromValue(value: CustomListValue): CreateCustomListDto {
        const titleTranslations = value.translations.map(
            ({ language, text }) => new PatchTranslationDto(language, text)
        );
        const values = value.values.map(value => {
            const translations = value.translations.map(
                ({ language, text }) => new PatchTranslationDto(language, text)
            );
            return new CreateCustomListValueDto(value.sequence, translations);
        });
        return new CreateCustomListDto(titleTranslations, values);
    }

    constructor(
        public readonly translations: PatchTranslationDto[],
        public readonly values: CreateCustomListValueDto[]
    ) {}
}

export interface GetCustomListApiResponse {
    uuid: string;
    values: Array<{ uuid: string; enabled: boolean; sequence: number | null }>;
}

export type GetCustomListCollectionApiResponse = Array<GetCustomListApiResponse>;

export class CustomListMapper {
    static fromCollectionResponse(res: GetCustomListCollectionApiResponse): CustomListDto[] {
        return res.map(CustomListMapper.fromResponse);
    }

    static fromResponse(res: GetCustomListApiResponse): CustomListDto {
        const values = res.values.map(value => new CustomListValueDto(value.uuid, value.sequence, value.enabled));
        return new CustomListDto(res.uuid, values);
    }
}

export class UpdateCustomListValueDto {
    constructor(
        public readonly uuid: string | null,
        public readonly sequence: number,
        public readonly translations: PatchTranslationDto[]
    ) {}
}

export class UpdateCustomListDto {
    static fromValue(uuid: string, value: CustomListValue): UpdateCustomListDto {
        const titleTranslations = value.translations.map(
            ({ language, text }) => new PatchTranslationDto(language, text)
        );
        const values = value.values.map(({ uuid, sequence, translations }) => {
            const trans = translations.map(({ language, text }) => new PatchTranslationDto(language, text));
            return new UpdateCustomListValueDto(uuid, sequence, trans);
        });
        return new UpdateCustomListDto(uuid, titleTranslations, values);
    }

    constructor(
        public readonly uuid: string,
        public readonly translations: PatchTranslationDto[],
        public readonly values: UpdateCustomListValueDto[]
    ) {}
}
