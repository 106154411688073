import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FondaApiService } from '../../api/fonda-api.service';
import { CaseworkerDto } from '../../api/dto/caseworker-dto';

@Component({
    selector: 'fonda-applicant-responsible-select',
    templateUrl: './applicant-responsible-select.component.html',
    styleUrls: ['./applicant-responsible-select.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicantResponsibleSelectComponent implements OnInit {
    @Input() applicantUuid: string;

    options: CaseworkerDto[] = [];
    selected: CaseworkerDto[] = [];

    public constructor(private apiService: FondaApiService, private cdRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.apiService
            .getBackendUsersCaseworker()
            .then(caseworkers => {
                this.options = caseworkers;
                return this.apiService.getApplicantByUuid(this.applicantUuid).toPromise();
            })
            .then(res => {
                this.selected = res.responsibleCaseworkersUuids.map(uuid => this.getCaseworker(uuid));
                this.cdRef.detectChanges();
            });
    }

    public caseworkerSelectedChanged(caseworkers: CaseworkerDto[]) {
        this.selected = caseworkers;
        this.save();
        this.cdRef.detectChanges();
    }

    public save() {
        this.apiService
            .putBackendApplicantResponsibleCaseworkers(
                this.applicantUuid,
                this.selected.map(cas => cas.uuid)
            )
            .subscribe();
    }

    private getCaseworker(uuid: string): CaseworkerDto {
        const res = this.options.filter(cas => cas.uuid === uuid);

        if (res.length === 0) {
            throw new Error('No caseworker found with uuid ' + uuid);
        }

        return res[0];
    }
}
