import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { FondaApiGlobalVariables } from '../../../../api/fonda-api-global-variables';
import { FondaApiService } from '../../../../api/fonda-api.service';
import { TranslateService } from '../../../translation/translate.service';
import { BaseRecordComponent, RecordMetadata } from '../../base-record.component';
import { UuidValue } from '../../values.types';
import { FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'subcategory-record',
    templateUrl: 'subcategory-record.component.html',
})
export class SubcategoryRecordComponent extends BaseRecordComponent<UuidValue> implements OnDestroy {
    control = new FormControl(null);
    subcategories: (string | null)[];
    private sub: Subscription;

    constructor(
        private apiService: FondaApiService,
        private translateService: TranslateService,
        private storeService: FondaApiGlobalVariables
    ) {
        super();
    }

    initRecord(metadata: RecordMetadata<UuidValue>) {
        if (this.metadata.isRequired) this.control.setValidators([Validators.required]);

        this.sub = this.storeService.actualCategoryUuidEmitter
            .pipe(startWith(this.storeService.actualCategoryUuid))
            .subscribe(uuid => this.setupSubcategories(uuid));

        this.registerControlChanges(this.control);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.sub.unsubscribe();
    }

    isValid(): boolean {
        return this.control.valid;
    }

    isEmpty(): boolean {
        return this.control.value === null;
    }

    getValue(): UuidValue {
        if (this.control.value === null) return [];
        return { uuid: this.control.value };
    }

    setValue(value: UuidValue) {
        this.control.setValue((!Array.isArray(value) && value.uuid) || null);
    }

    private async setupSubcategories(uuid: string) {
        const subcategoriesPromise = uuid
            ? this.apiService.getCategorySubCategories(uuid)
            : this.apiService.getAllSubcategories();

        // we add null in front cause that's the none value
        this.subcategories = await subcategoriesPromise.then(subcategories =>
            [null].concat(subcategories.map(subcategory => subcategory.uuid))
        );
    }
}
