import { Component } from '@angular/core';
import { BaseRecordComponent, RecordMetadata } from '../../base-record.component';
import { FondaApiService } from '../../../../api/fonda-api.service';
import { TextValue } from '../../values.types';

export interface SchemaRecordOutput {
    text: string | null;
}

@Component({
    selector: 'schema-record',
    templateUrl: 'schema-record.component.html',
})
export class SchemaRecordComponent extends BaseRecordComponent<SchemaRecordOutput, null> {
    schemas: Array<string> = [];
    selectedUuid: string | null = null;
    show = false;

    constructor(private apiService: FondaApiService) {
        super();
    }

    initRecord(metadata: RecordMetadata<TextValue, null>): void {
        this.apiService.getApplicationSchemaUuids().then(res => {
            this.schemas = res;
        });
    }

    emitValue() {
        this.onChange.emit(this.getValue());
    }

    chooseSchema(uuid: string | null) {
        this.selectedUuid = uuid;
        this.emitValue();
    }

    isValid(): boolean {
        return this.metadata.isRequired && !this.isEmpty();
    }

    isEmpty(): boolean {
        return this.selectedUuid === null;
    }

    getValue(): SchemaRecordOutput {
        return { text: this.selectedUuid ? this.selectedUuid : null };
    }

    setValue(value: SchemaRecordOutput): void {
        this.selectedUuid = value.text;
    }
}
