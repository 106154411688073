import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';

@Component({
    selector: 'pwa-option',
    templateUrl: 'pwa-option.component.html',
    styleUrls: ['pwa-option.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'pwa-option',
        '[class.hidden]': 'hidden',
    },
})
export class PwaOptionComponent<T> {
    @Input() value?: T;
    @Input() selected: boolean = false;
    @Input() searchText: string = '';
    @Output() select = new EventEmitter<void>();
    @ViewChild('option') option: ElementRef<HTMLButtonElement>;

    get text(): string {
        return this.option.nativeElement ? this.option.nativeElement.textContent : '';
    }

    get hidden() {
        return (
            !this.selected && this.searchText && this.text.toLowerCase().indexOf(this.searchText.toLowerCase()) === -1
        );
    }

    onSelect() {
        this.select.emit();
    }
}
