import { Component } from '@angular/core';
import { BaseRecordComponent, RecordMetadata } from '../../base-record.component';
import { FormControl, Validators } from '@angular/forms';
import { TextValue } from '../../values.types';
import { setConditionalValidators } from '../../conditional-validators';

export interface TextRecordConfiguration {
    min_length: number;
    max_length: number | null;
}

@Component({
    selector: 'text-record',
    templateUrl: 'text-record.component.html',
})
export class TextRecordComponent extends BaseRecordComponent<TextValue, TextRecordConfiguration> {
    control = new FormControl();

    initRecord(metadata: RecordMetadata<TextValue, TextRecordConfiguration>) {
        setConditionalValidators(this.control, [
            [metadata.isRequired, Validators.required],
            [metadata.configuration.min_length, Validators.minLength(metadata.configuration.min_length)],
            [metadata.configuration.max_length, Validators.maxLength(metadata.configuration.max_length)],
        ]);
        this.registerControlChanges(this.control);
    }

    getValue(): TextValue {
        return { text: this.control.value };
    }

    setValue(value: TextValue) {
        this.control.setValue(value.text);
    }

    isValid(): boolean {
        return this.control.valid;
    }

    isEmpty(): boolean {
        return this.control.value === '';
    }
}
