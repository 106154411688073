import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';

@Pipe({
    name: 'utcDate',
})
export class UtcDatePipe implements PipeTransform {
    // value may be given in two different forms
    // 1. UTC date string from databse
    // 1. 2022-05-01T00:00:00.000Z
    // or
    // 2. Date object with offset from FondaBasicDatepickerComponent
    // 2. Sat Apr 30 2022 16:00:00 GMT-0800 (Alaska Daylight Time)
    transform(value: string | Date) {
        if (!value) return '';
        const offset = this.calcOffset(value);
        // formatting to display in input
        return format(offset, 'dd-MM-yyyy');
    }

    calcOffset(value: string | Date) {
        const offset = new Date(new Date(value).getTime() + new Date(value).getTimezoneOffset() * 60 * 1000);
        return offset;
    }
}
