<ng-container>
    <label for="filePicker{{ uniqueID }}" dropFile (fileChangeEmiter)="onFileUpload($event)">
        <div class="file-inload-container">
            <ng-content> </ng-content>
        </div>
    </label>
    <input
        type="file"
        #fileInput
        style="display: none"
        [multiple]="true"
        accept="allowedMimeTypes"
        id="filePicker{{ uniqueID }}"
        (change)="onFileUpload($event)"
    />
</ng-container>
