import {
    Component,
    ChangeDetectionStrategy,
    forwardRef,
    AfterViewInit,
    ElementRef,
    ChangeDetectorRef,
    ViewChild,
    Input,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface IntegerSubrecordValue {
    text: string;
}

@Component({
    selector: 'fonda-integer-subrecord',
    templateUrl: './integer-subrecord.component.html',
    styleUrls: ['./integer-subrecord.component.scss'],
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => IntegerSubrecordComponent), multi: true }],
})
export class IntegerSubrecordComponent implements ControlValueAccessor, AfterViewInit {
    @Input() configuration: any;
    @ViewChild('input', { read: ElementRef }) inputRef: ElementRef<HTMLInputElement>;

    value: IntegerSubrecordValue = { text: '' };
    disabled = false;
    onchange: (value: IntegerSubrecordValue) => void = () => {};
    ontouched: () => void = () => {};

    constructor(private readonly cdRef: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
        this.updateView();
    }

    writeValue(obj: any): void {
        if (obj && obj.text && typeof obj.text === 'string') {
            this.value = { text: this.ensureNumber(obj.text) };
        } else {
            this.value = { text: '' };
        }

        this.cdRef.markForCheck();
        this.updateView();
    }

    registerOnChange(fn: any): void {
        this.onchange = fn;
    }

    registerOnTouched(fn: any): void {
        this.ontouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this.cdRef.markForCheck();
    }

    viewToModelUpdate(value: string): void {
        this.value = { text: this.ensureNumber(value) };
        this.onchange(this.value);
        this.updateView();
    }

    private updateView() {
        if (this.inputRef.nativeElement) {
            this.inputRef.nativeElement.value = this.value.text;
        }
    }

    private ensureNumber(text: string): string {
        // removes everything that is not a number and leading zeros
        return text.replace(/[^0-9]|^0+(?=.)/g, '');
    }
}
