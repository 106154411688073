import { ChangeDetectionStrategy, Component, ContentChild, Input, ViewEncapsulation } from '@angular/core';
import { PwaCheckboxDirective } from './pwa-checkbox.directive';

@Component({
    selector: 'pwa-checkbox',
    templateUrl: 'pwa-checkbox.component.html',
    styleUrls: ['pwa-checkbox.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'pwa-checkbox',
    },
})
export class PwaCheckboxComponent {
    @ContentChild(PwaCheckboxDirective, { static: true }) checkbox: PwaCheckboxDirective;
    @Input() label: string;
    @Input() mandatory: boolean = false;
}
