import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FileUploadComponent } from '../../basic-form-controls/file-upload/file-upload.component';
import { UploadedFondaFile } from '../../../models/uploaded-fonda-file';
import { CkeditorPopupService } from './ckeditor-popup.service';
import { GlobalVariables } from '../../../services/global-variables';
import { TranslateService } from '../../translation/translate.service';

declare var CKEDITOR: any;

@Component({
    selector: 'text-editor',
    templateUrl: 'text-editor.component.html',
    styleUrls: ['text-editor.scss'],
})
export class TextEditorComponent implements OnInit, AfterViewInit {
    public config;
    public noOption: string = GlobalVariables.NO_ONE_VARIABLE;
    public option: string = this.noOption;

    @ViewChild('ckeditor', { static: true }) ckeditor: any;
    @ViewChild('fileUpload', { static: true }) fileUpload: FileUploadComponent;
    public clearOnEnter = false;
    @Output() textChange = new EventEmitter<string>();
    @Output() onFileAdding = new EventEmitter<UploadedFondaFile>();
    @Output() onEnterPress = new EventEmitter<string>();
    @Input() allowTokensAdding = false;
    @Input() onTranslation = false;
    @Input('config') additionalConfig: any;

    constructor(private translateService: TranslateService, private ckeditorTokensSerivce: CkeditorPopupService) {}

    public _text = '';

    public get text(): string {
        return this._text;
    }

    @Input()
    public set text(initialText: string) {
        this._text = initialText;
        if (this.getPlainText(this._text)) {
            this.textChange.emit(initialText);
        } else {
            this.textChange.emit('');
        }
    }

    @Input() set tokens(tokens: Array<string>) {
        if (tokens) {
            this.ckeditorTokensSerivce.putTokens(tokens);
        }
    }

    @HostListener('document:click', ['$event'])
    onClick($event): void {
        if (
            $event &&
            $event.target &&
            $event.target.className === this.ckeditorTokensSerivce.CKEDITOR_BACKGROUND_CLASSES
        ) {
            const closeButton: any = document.getElementsByClassName(
                this.ckeditorTokensSerivce.CKEDITOR_DIALOG_CLOSE_BUTTON_CLASSES
            );
            if (closeButton && closeButton[0]) {
                closeButton[0].click();
            }
        }
    }

    emitPopupTokensValue(value: string) {
        this.ckeditorTokensSerivce.option = value;
    }

    public allowFilesAdding(): boolean {
        return this.onFileAdding.observers && this.onFileAdding.observers.length > 0;
    }

    public clear() {
        this.text = '';
        this.ckeditor.instance.setData('');
    }

    public ngOnInit() {
        this.config = {
            uiColor: '#F1F1F1',
            extraPlugins: 'divarea,placeholder,table,contextmenu,tabletools,tableselection',
            entities: false,
            entities_latin: false,
            basic_entities: false,
            enterMode: CKEDITOR.ENTER_BR,
            autoParagraph: false,
            baseFloatZIndex: 1e5,
            removePlugins: 'elementspath',
        };

        if (this.additionalConfig) this.config = Object.assign({}, this.config, this.additionalConfig);

        if (this.onTranslation) {
            this.config.toolbar = [
                { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'TextColor'] },
                { name: 'styles', items: ['Format'] },
                {
                    name: 'paragraph',
                    items: this.allowFilesAdding() ? ['uploadButton', 'BulletedList'] : ['BulletedList'],
                },
                { name: 'links', items: ['Link'] },
                { name: 'insert', items: ['Table', 'HorizontalRule'] },
            ];
        } else {
            this.config.toolbar = [
                {
                    name: 'basicstyles',
                    items: this.allowTokensAdding
                        ? ['Bold', 'Italic', 'Underline', 'CreatePlaceholder']
                        : ['Bold', 'Italic', 'Underline'],
                },
                {
                    name: 'paragraph',
                    items: this.allowFilesAdding() ? ['uploadButton', 'BulletedList'] : ['BulletedList'],
                },
                { name: 'insert', items: ['Table', 'HorizontalRule'] },
            ];
        }
        this.config.format_tags = 'p;h1;h2;h3';
        this.config.contentsCss = ['body {font-size: 1em;}', "body {font-family: 'Oxygen', sans-serif;}"];
        this.clearOnEnter = this.onEnterPress.observers.length > 0;
    }

    public ngAfterViewInit() {
        this.ckeditor &&
            this.ckeditor.instance &&
            this.ckeditor.instance.on('key', event => {
                if (event.data && event.data.keyCode === 13 && this.clearOnEnter) {
                    if (this.getPlainText(this._text)) {
                        this.onEnterPress.emit(this._text);
                    }
                    setTimeout(() => {
                        this.ckeditor.instance.setData('');
                    }, 10);
                }
            });
        CKEDITOR.on('instanceReady', ev => {
            ev.editor.dataProcessor.writer.setRules('*', {
                indent: false,
                breakBeforeOpen: true,
                breakAfterOpen: false,
                breakBeforeClose: false,
                breakAfterClose: true,
            });
        });
        CKEDITOR.on('dialogDefinition', function (ev) {
            const dialogName = ev.data.name;
            const dialogDefinition = ev.data.definition;
            if (dialogName == 'table' || dialogName == 'tableProperties') {
                const advTab = dialogDefinition.getContents('advanced');
                const styleClassesField = advTab.get('advCSSClasses');
                styleClassesField['default'] = 'basic-letter-table';
            }
        });
        // $(".cke_button_icon cke_button__bold_icon").removeClass('cke_button__bold_icon');
        // $(".cke_button_icon cke_button__italic_icon").removeClass('cke_button__italic_icon');
        // $(".cke_button_icon cke_button__underline_icon").removeClass('cke_button__underline_icon');
        // $(".cke_button_icon cke_button__bulletedlist_icon").removeClass('cke_button__bulletedlist_icon');
    }

    private getPlainText(strSrc): string | null {
        let resultStr = '';

        // Ignore the <p> tag if it is in very start of the text
        if (!strSrc) {
            return null;
        }
        if (strSrc.indexOf('<p>') == 0) {
            resultStr = strSrc.substring(3);
        } else {
            resultStr = strSrc;
        }
        // Replace <p> with two newlines
        resultStr = resultStr.replace(/<p>/gi, '');
        resultStr = resultStr.replace(/<\/p>/gi, '');
        // Replace <br /> with one newline
        resultStr = resultStr.replace(/<br \/>/gi, '');
        resultStr = resultStr.replace(/<br>/gi, '');
        resultStr = resultStr.replace(/&nbsp;/gi, '');
        resultStr = resultStr.replace(/\s/g, '');

        return resultStr.replace(/<[^<|>]+?>/gi, '');
    }
}
