import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownTriggerDirective } from './dropdown-trigger.directive';
import { DropdownListDirective } from './dropdown-list.directive';
import { DropdownOptionComponent } from './dropdown-option.component';
import { DropdownContainerComponent } from './dropdown-container/dropdown-container.component';
import { PortalModule } from '@angular/cdk/portal';

const EXPORTED_DECLARATIONS = [DropdownTriggerDirective, DropdownListDirective, DropdownOptionComponent];

@NgModule({
    imports: [CommonModule, PortalModule],
    declarations: [EXPORTED_DECLARATIONS, DropdownContainerComponent],
    exports: EXPORTED_DECLARATIONS,
    entryComponents: [DropdownContainerComponent],
})
export class DropdownModule {}
