import { Constructor } from './constructor';
import { HasElementRef } from './has-element-ref';
import { safeAddClass, safeRemoveClass } from './safe-classlist';

export interface HasColor {
    color: string;
}

export type HasColorCtor = Constructor<HasColor>;

const AVAILABLE_COLORS = ['primary', 'success', 'secondary', 'gray', 'danger', 'pink'];

export function mixinColor<T extends Constructor<HasElementRef>>(base: T): T & HasColorCtor {
    return class extends base implements HasColor {
        private _color = '';

        get color(): string {
            return this._color;
        }
        set color(value: string) {
            const prev = this.color;

            if (AVAILABLE_COLORS.includes(value)) {
                this._color = value;
            }

            safeRemoveClass(this._elementRef, `btn-${prev}`);
            if (value) safeAddClass(this._elementRef, `btn-${this.color}`);
        }

        constructor(...args: any[]) {
            super(...args);
            if (this.color) safeAddClass(this._elementRef, `btn-${this.color}`);
        }
    };
}
