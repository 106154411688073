import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    forwardRef,
    Inject,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    Renderer2,
    TemplateRef,
    ViewChild,
    ViewChildren,
    ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PwaOptionComponent } from './pwa-option/pwa-option.component';
import { PwaSelectOptionDirective } from './pwa-select-option.directive';
import { createPopper, Instance as PopperInstance } from '@popperjs/core';
import { DOCUMENT } from '@angular/common';

export interface OptionTemplateContext<T> {
    readonly $implicit: T;
}

export type PwaSelectValue<T> = null | T | T[];

@Component({
    selector: 'pwa-select',
    templateUrl: 'pwa-select.component.html',
    styleUrls: ['pwa-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'pwa-select',
        '[class.is-open]': 'open',
    },
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PwaSelectComponent), multi: true }],
})
export class PwaSelectComponent<T> implements OnInit, OnDestroy, ControlValueAccessor, AfterViewInit {
    @Input() value: PwaSelectValue<T>;
    @Input() placeholder: string = '';
    @Input() options: T[] = [];
    @Input() multiple: boolean = false;
    @Input() canUnselect = false;
    @Input() disabled: boolean = false;
    @Input() showSearch: boolean = false;
    @Input() mandatory: boolean = false;
    @Input() boxDisplay: boolean = true;
    @Input() noBorder: boolean = false;
    @Output() valueChange = new EventEmitter<PwaSelectValue<T>>();

    searchText: string;
    open: boolean = false;
    popperInstance?: PopperInstance;

    @ViewChild('noTemplate', { read: TemplateRef, static: true }) noTemplate: TemplateRef<OptionTemplateContext<T>>;
    @ViewChildren(PwaOptionComponent, { read: PwaOptionComponent }) optionComponents: QueryList<PwaOptionComponent<T>>;
    @ContentChild(PwaSelectOptionDirective, { static: true }) selectOption?: PwaSelectOptionDirective<T>;

    @ViewChild('button', { static: true }) button: ElementRef<HTMLDivElement>;
    @ViewChild('list', { static: true }) list: ElementRef<HTMLDivElement>;

    private readonly destroy$ = new Subject<void>();

    constructor(
        private readonly elementRef: ElementRef<HTMLElement>,
        private cdRef: ChangeDetectorRef,
        private ngZone: NgZone,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private document: Document
    ) {}

    _onChange = v => {};
    _onTouch = () => {};

    get selectOptionTemplate(): TemplateRef<OptionTemplateContext<T>> {
        return this.selectOption ? this.selectOption.template : this.noTemplate;
    }

    getContext(option: T): OptionTemplateContext<T> {
        return { $implicit: option };
    }

    ngOnInit(): void {
        fromEvent(document, 'click')
            .pipe(takeUntil(this.destroy$))
            .subscribe((event: MouseEvent) => {
                if (!this.elementRef.nativeElement.contains(event.target as Node)) {
                    this.open = false;
                    this.cdRef.markForCheck();
                }
            });
    }

    createPopper(): void {
        this.document.body.appendChild(this.list.nativeElement);
        this.renderer.setStyle(this.list.nativeElement, 'width', this.button.nativeElement.offsetWidth + 'px');
        this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                this.popperInstance?.destroy();
                this.popperInstance = createPopper(this.button.nativeElement, this.list.nativeElement, {
                    placement: 'bottom-start',
                });
            });
        });
    }

    destroyPopper(): void {
        if (this.popperInstance && this.document.body.contains(this.list.nativeElement)) {
            this.document.body.removeChild(this.list.nativeElement);
        }
        this.popperInstance?.destroy();
        this.popperInstance = undefined;
    }

    isSelected(option) {
        if (this.multiple) {
            return (this.value as T[]).includes(option);
        } else {
            return this.value === option;
        }
    }

    onSelect(option) {
        if (this.multiple) {
            this.setMultipleValue(option);
        } else {
            this.setSingleValue(option);
        }
        this.open = false;
        this._onChange(this.value);
        this.valueChange.emit(this.value);
        this.cdRef.markForCheck();
    }

    setSingleValue(option) {
        if (this.canUnselect && this.isSelected(option)) {
            this.value = null;
        } else {
            this.value = option;
        }
    }

    setMultipleValue(option) {
        if (this.isSelected(option)) {
            this.value = (this.value as T[]).filter(v => v !== option);
        } else {
            this.value = (this.value as T[]).concat(option);
        }
    }

    getButtonLabel() {
        if (this.multiple) {
            return (this.value as T[]).length ? this.getMultipleLabel() : this.placeholder;
        } else {
            return this.value ? this.getSingleLabel() : this.placeholder;
        }
    }

    getSingleLabel() {
        if (!this.optionComponents) return this.value;
        return this.optionComponents.find(o => o.value === this.value)?.text;
    }

    getMultipleLabel() {
        if (!this.optionComponents) return this.value;
        return (this.value as T[])
            .map(option => this.optionComponents.find(o => o.value === option))
            .filter(Boolean)
            .map(o => o.text)
            .join(', ');
    }

    onToggle() {
        this.open = !this.open;
        if (this.open) {
            this.createPopper();
        } else {
            this.destroyPopper();
        }
        this.cdRef.markForCheck();
    }

    writeValue(value: PwaSelectValue<T>): void {
        if (this.multiple) {
            this.value = Array.isArray(value) ? value : [];
        } else {
            this.value = value;
        }
        this.cdRef.markForCheck();
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouch = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    onTouch() {
        this._onTouch();
        this.cdRef.markForCheck();
    }

    ngAfterViewInit() {
        this.cdRef.detectChanges();
    }

    ngOnDestroy(): void {
        this.destroyPopper();
        this.destroy$.next();
        this.destroy$.complete();
    }
}
