import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PopupManager } from '../../../../shared/popup/popup-manager';
import { IPopup } from '../../../../shared/popup/popup.interface';

@Component({
    selector: 'pwa-delete-confirmation-popup',
    templateUrl: 'pwa-delete-confirmation-popup.component.html',
    styleUrls: ['pwa-delete-confirmation-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PwaDeleteConfirmationPopupComponent implements IPopup<{ confirmed: boolean }, void> {
    constructor(public popupManager: PopupManager<{ confirmed: boolean } | void>) {}

    close(confirmed: boolean) {
        this.popupManager.close({ confirmed });
    }

    cancel() {
        this.close(false);
    }

    confirm() {
        this.close(true);
    }
}
