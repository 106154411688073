import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IPopup } from '../../../../../shared/popup/popup.interface';
import { PopupManager } from '../../../../../shared/popup/popup-manager';

@Component({
    selector: 'acceptance-record-popup',
    templateUrl: 'acceptance-record-popup.component.html',
    styleUrls: ['acceptance-record-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcceptanceRecordPopupComponent implements IPopup<void, void> {
    constructor(public popupManager: PopupManager<void | void>) {}

    close() {
        this.popupManager.close();
    }
}
