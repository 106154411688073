import { RecordValueDto } from './record-value-dto';

export class ApplicantDto {
    constructor(
        readonly recordValues: Array<RecordValueDto>,
        readonly isCompleteProfile: boolean,
        readonly uuid: string,
        readonly responsibleCaseworkersUuids: string[]
    ) {}
}
