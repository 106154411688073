import { ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CaseworkerDto } from '../../../../../../api/dto/caseworker-dto';
import { UuidValue } from '../../../../../../shared/records/values.types';
import { FondaApiService } from '../../../../../../api/fonda-api.service';

@Component({
    selector: 'fonda-backend-user-subrecord',
    templateUrl: 'backend-user-subrecord.component.html',
    styleUrls: ['backend-user-subrecord.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => BackendUserSubrecordComponent), multi: true },
    ],
})
export class BackendUserSubrecordComponent implements ControlValueAccessor {
    @Input() configuration;

    value: UuidValue;
    users: CaseworkerDto[] = [];
    isDisabled = false;

    constructor(private apiService: FondaApiService, private cdRef: ChangeDetectorRef) {}

    get options(): string[] {
        return this.users.map(c => c.uuid);
    }

    get currentUser(): string | null {
        return Array.isArray(this.value) ? null : this.value.uuid;
    }

    _onChange: (value: UuidValue) => void = () => {};
    _onTouched: () => void = () => {};

    ngOnInit() {
        this.apiService.getBackendUsers(true, 'board_member').then(users => {
            this.users = users;
        });
    }

    setValue(value) {
        if (value) {
            this.value = { uuid: value };
        } else {
            this.value = [];
        }
        this._onChange(this.value);
    }

    writeValue(obj: any): void {
        this.value = obj;
        this.cdRef.markForCheck();
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        this.cdRef.markForCheck();
    }

    getUsername(uuid: string): string {
        if (!uuid) return '';
        const user = this.users.find(c => c.uuid === uuid);
        return user ? user.username : '';
    }
}
