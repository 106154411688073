<div class="form-item" [ngClass]="{ half: metadata.size === 1 }" *ngIf="!isReadonly">
    <basic-select
        [choosenInput]="selectedUuid"
        [placeholder]="''"
        [values]="countryUuids"
        (getChoosen)="chooseCountry($event)"
        [label]="metadata.uuid | translate : 'title'"
        [translate]="true"
        class="required"
        [mandatory]="metadata.isRequired"
    ></basic-select>
    <div
        class="select-box-label red"
        *ngIf="isOnSearch && selectedUuid"
        style="position: absolute; right: 7px; z-index: 1"
        (click)="reset()"
    >
        {{ 'general' | translate : 'delete' }}
    </div>

    <div class="form-focus"></div>
    <div class="form-error"></div>
</div>

<a class="form-item" *ngIf="isReadonly" [ngClass]="{ full: metadata.size === 2 }">
    <p>{{ metadata.uuid | translate : 'title' }}</p>
    <span>{{ selectedName ? (selectedUuid | translate) : '-' }}</span>
    <div class="form-focus"></div>
    <div class="form-error"></div>
</a>
