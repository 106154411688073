import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FondaApiService } from '../../api/fonda-api.service';
import { UploadedFondaFile } from '../../models/uploaded-fonda-file';
import { FilesTableComponent } from '../../shared/components/files-table/files-table.component';
import { AuthService } from '../../auth/auth.service';
import { TranslateService } from '../../shared/translation/translate.service';

@Component({
    templateUrl: './account-correspondence-popup.component.html',
    selector: 'account-correspondence-popup',
    styleUrls: ['account-correspondence-popup.scss'],
})
export class AccountCorrespondencePopupComponent {
    public accountEmail = '';
    public isOpen = false;
    public isApplicant = false;
    public errorMessage: string;
    public bodyText = '';
    public headerText = '';
    public loading = false;
    public popupTitle = '';

    @Output('messageSend') messageSend = new EventEmitter<{
        threadTitle: string;
        messageBody: string;
        statusName: string | null;
        filesUploaded: Array<UploadedFondaFile>;
        correspondenceTemplateUuid?: string | null;
    }>();

    @ViewChild('filesTable', { static: true }) filesTable: FilesTableComponent;

    @Output() closed = new EventEmitter();

    @Input('hideSubjectDropDown') hideSubjectDropDown = false;
    @Input('applicantUuid') applicantUuid: string;

    constructor(
        private apiService: FondaApiService,
        private translateService: TranslateService,
        private auth: AuthService,
        public changeDet: ChangeDetectorRef
    ) {}

    public addFile(uploadedNcfFile: UploadedFondaFile, preventDeleting?: boolean) {
        this.filesTable.addFile({ ...uploadedNcfFile, preventDeleting: preventDeleting });
    }

    public onFileDelete(uuid: string) {
        // Legacy method
    }

    public initManualMessagePopupComponent() {
        this.isOpen = true;
        this.isApplicant = this.auth.isApplicant();
        this.changeDet.detectChanges();
    }

    public clickClose() {
        this.closed.emit();
        this.resetData();
    }

    public clickSend() {
        this.errorMessage = '';
        if (this.canSend()) {
            this.messageSend.emit({
                threadTitle: this.headerText,
                messageBody: this.bodyText,
                statusName: null,
                filesUploaded: [...this.filesTable.files, ...this.filesTable.staticFiles],
                correspondenceTemplateUuid: null,
            });
            this.clickClose();
        }
    }

    private canSend(): boolean {
        if (!this.bodyText) {
            this.errorMessage = this.translateService.get(['messages', 'popup_message_mandatory']);
            return false;
        }

        if (!this.headerText) {
            this.errorMessage = this.translateService.get(['messages', 'popup_subject_mandatory']);
            return false;
        }

        return true;
    }

    private resetData() {
        this.headerText = '';
        this.bodyText = '';
        if (this.filesTable) {
            this.filesTable.files = [];
        }
    }
}
