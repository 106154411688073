import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { BaseRecordComponent, RecordMetadata } from '../../base-record.component';
import { FondaApiService } from '../../../../api/fonda-api.service';
import { IEconomyRecordConfigurationData } from '../../../../shared/record-configuration/configurations/economy-record-configuration/economy-record-configuration.component';
import { FormControl, FormGroup } from '@angular/forms';
import { convertToString } from './economy-converter';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';

type SearchValue = [number | null, number | null];

interface EconomyRecordData {
    expenses: object;
    incomes: object;
    willApplyFor: number;
}

interface EconomyRecordSearchData {
    text: [number, number];
}

@Component({
    selector: 'economy-record',
    templateUrl: 'economy-record.component.html',
    styleUrls: ['economy-record.scss'],
})
export class EconomyRecordComponent
    extends BaseRecordComponent<EconomyRecordData | EconomyRecordSearchData, IEconomyRecordConfigurationData>
    implements OnDestroy
{
    form = new FormGroup({
        expenses: new FormControl(null),
        incomes: new FormControl(null),
        willApplyFor: new FormControl(null),
    });

    searchValue: SearchValue;

    formSub$: Subscription;

    economyGroups: Array<string> = [];
    financialNeeds: number;

    get configuration(): IEconomyRecordConfigurationData {
        return this.metadata.configuration;
    }

    get uuid(): string {
        return this.metadata.uuid;
    }

    get size(): number {
        return this.metadata.size;
    }

    get willApplyFor(): number {
        return this.form.value.willApplyFor;
    }

    constructor(private cdr: ChangeDetectorRef, private apiService: FondaApiService) {
        super();
    }

    public isValid(): boolean {
        return true;
    }

    public isEmpty(): boolean {
        return !this.form.value;
    }

    public getValue(): EconomyRecordData | EconomyRecordSearchData {
        if (!this.isOnSearch) {
            // workaround requested from backend
            if (!this.configuration.show_apply_for) {
                // @ts-ignore
                return {
                    ...this.form.value,
                    willApplyFor: this.financialNeeds > 0 ? this.financialNeeds : 0,
                };
            }
            // @ts-ignore
            return this.form.value;
        } else {
            return { text: this.searchValue };
        }
    }

    initRecord(
        metadata: RecordMetadata<EconomyRecordData | EconomyRecordSearchData, IEconomyRecordConfigurationData>
    ): void {
        this.apiService.getEconomyGroups().subscribe(groups => {
            this.economyGroups = groups;
        });
    }

    public setValue(value) {
        if (this.isOnSearch) {
            this.searchValue = value.text;
        } else {
            this.form.patchValue(value);
        }
        this.emitValue();
    }

    public emitValue() {
        this.formSub$ = this.form.valueChanges.pipe(startWith(null)).subscribe(() => {
            this.financialNeeds = this.getFinancingNeeds();
            this.onChange.emit(this.getValue());
        });
    }

    public getFinancingNeeds(): number {
        let expenses = 0;
        let incomes = 0;

        if (this.configuration.expenses_display_mode === 'total_only') {
            expenses = this.form.value.expenses.total;
        } else {
            this.form.value.expenses.forEach(expense => {
                expenses += expense.amount;
            });
        }

        if (this.configuration.incomes_display_mode === 'total_only') {
            incomes = this.form.value.incomes.total;
        } else {
            this.form.value.incomes.forEach(income => {
                incomes += income.amount;
            });
        }
        return Number((expenses - incomes).toFixed(2));
    }

    public ngOnDestroy() {
        this.cdr.detach();
        if (this.formSub$) this.formSub$.unsubscribe();
    }

    update(index: 0 | 1, value: number) {
        this.searchValue = this.searchValue.slice() as SearchValue;
        this.searchValue.splice(index, 1, value);
        this.emitValue();
    }

    numToStr(amount) {
        return convertToString(amount);
    }
}
