import { Component, Input } from '@angular/core';

@Component({
    selector: 'buttons',
    templateUrl: './buttons.component.html',
    // tslint:disable-next-line:no-host-metadata-property
    host: {
        class: 'buttons',
        '[class.is-right]': 'isRight',
        '[class.has-addons]': 'hasAddons',
    },
})
export class ButtonsComponent {
    @Input() isRight = false;
    @Input() hasAddons = false;
}
