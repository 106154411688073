<div *ngIf="!isReadonly && !isOnSearch" class="form-item" [ngClass]="{ half: metadata.size === 1 }">
    <input
        id="application-float"
        type="text"
        [fondaMoneyInput]="value[0]"
        (fondaMoneyInputChange)="update(0, $event)"
    />
    <label for="application-float">
        {{ metadata.uuid | translate : 'title' }}
        <span *ngIf="metadata.isRequired" class="mandatory">*</span>
    </label>
    <div class="form-focus"></div>
    <div class="form-help"></div>
</div>

<ng-container *ngIf="!isReadonly && isOnSearch">
    <div style="display: flex; position: relative">
        <div class="form-item between even first" [ngClass]="{ half: metadata.size === 1 }">
            <input
                id="{{ metadata.uuid }}-float2"
                type="text"
                [fondaMoneyInput]="value[0]"
                (fondaMoneyInputChange)="update(0, $event)"
            />
            <label for="{{ metadata.uuid }}-float2">{{ metadata.title }} from</label>
            <div class="form-focus"></div>
        </div>
        <div class="form-item between even second" [ngClass]="{ half: metadata.size === 1 }">
            <input
                id="{{ metadata.uuid }}-float3"
                type="text"
                [fondaMoneyInput]="value[1]"
                (fondaMoneyInputChange)="update(1, $event)"
            />
            <label for="{{ metadata.uuid }}-float3">{{ metadata.title }} to</label>
            <div class="form-focus"></div>
        </div>
    </div>
</ng-container>

<a class="form-item" *ngIf="isReadonly" [ngClass]="{ full: metadata.size === 2 }">
    <p>{{ metadata.uuid | translate : 'title' }}</p>
    <span>{{ metadata.initialValue?.text ? (metadata.initialValue.text | commasInInput) : '-' }}</span>
    <div class="form-focus"></div>
    <div class="form-error"></div>
</a>
