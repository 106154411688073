<div class="granted-amount-children" *ngIf="children.length">
    <table>
        <tr>
            <th>
                {{ recordUuid | translate : 'name' }}
            </th>
            <th>
                {{ 'general' | translate : 'attachments' }}
            </th>
            <th>
                {{ recordUuid | translate : 'original_full_amount' }}
            </th>
            <th>
                {{ 'general' | translate : 'economy_group' }}
            </th>
            <th>
                {{ recordUuid | translate : 'board_meeting' }}
            </th>
            <th></th>
            <th>
                {{ recordUuid | translate : 'amount' }}
            </th>
            <th></th>
        </tr>
        <tr
            *ngFor="let child of children"
            granted-amount-readonly-line
            [line]="child"
            [configuration]="configuration"
            [economyGroups]="economyGroups"
            [boardMeetings]="boardMeetings"
        ></tr>
        <tr>
            <td colspan="8" class="right">
                <button class="close-btn red smaller icon" (click)="split.emit()">
                    <span>{{ 'general' | translate : 'split_lines' }}</span>
                    <span class="icon-split"></span>
                </button>
            </td>
        </tr>
    </table>
</div>
