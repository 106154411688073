import { Injectable, isDevMode } from '@angular/core';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { Maybe } from 'true-myth';
import { XmlService } from './xml-service';
import { LanguageStoreService } from '../../services/language-store.service';
import { environment } from '../../../environments/environment';
import { FondaLoggerService } from '../../services/fonda-logger.service';

export interface TranslateObj {
    [key: string]: string | TranslateObj;
}

@Injectable({
    providedIn: 'root',
})
export class TranslateService {
    private data: TranslateObj;
    private _data$ = new ReplaySubject<TranslateObj>(1);
    private _reload$ = new Subject<void>();
    private _reloaded$ = new Subject<TranslateObj>();

    constructor(
        private xmlService: XmlService,
        private languageStoreService: LanguageStoreService,
        private logger: FondaLoggerService
    ) {
        if (isDevMode()) {
            (window as any).translate = this;
        }

        this._reload$
            .pipe(
                switchMap(() =>
                    this.xmlService.loadData().pipe(
                        catchError(err => {
                            return of({});
                        })
                    )
                )
            )
            .subscribe(data => {
                this.data = data;
                this._reloaded$.next(data);
                this._data$.next(data);
            });

        this._reload$.next();
    }

    select(
        translatePath: string[],
        language = this.languageStoreService.getNameOfCurrentLanguage()
    ): Observable<string> {
        return this.selectMaybe(translatePath, language).pipe(
            map(Maybe.unwrapOr([...translatePath, language].join('->')))
        );
    }

    selectMaybe(
        translatePath: string[],
        language = this.languageStoreService.getNameOfCurrentLanguage()
    ): Observable<Maybe<string>> {
        return this._data$.pipe(
            map(data => this.resolveTranslation([...translatePath, language], data)),
            map(Maybe.of)
        );
    }

    get(translatePath: Array<string>, language = this.languageStoreService.getNameOfCurrentLanguage()): string {
        const resolvedTranslation = this.resolveTranslation([...translatePath, language], this.data);
        if (typeof resolvedTranslation !== 'string') {
            if (!environment.IGNORE_MISSING_TRANSLATIONS) {
                this.logger.error('Missing translation for ', [...translatePath, language].join('->'));
            }
            return [...translatePath, language].join('->');
        }

        return resolvedTranslation;
    }

    has(translatePath: Array<string>, language = this.languageStoreService.getNameOfCurrentLanguage()): boolean {
        return this.resolveTranslation([...translatePath, language], this.data) !== null;
    }

    getCurrentLanguageName(): string {
        return this.languageStoreService.getNameOfCurrentLanguage();
    }

    whenReady(): Observable<TranslateObj> {
        return this._data$.asObservable().pipe(take(1));
    }

    getMaybe(translatePath: string[], language = this.languageStoreService.getNameOfCurrentLanguage()): Maybe<string> {
        return Maybe.of(this.resolveTranslation([...translatePath, language], this.data));
    }

    reload(): Observable<TranslateObj>;
    reload<T>(returnData: T): Observable<T>;
    reload<T>(returnData?: T): Observable<T | TranslateObj> {
        this._reload$.next();
        return this._reloaded$.pipe(
            take(1),
            map(translateObj => (typeof returnData === undefined ? translateObj : returnData))
        );
    }

    at(
        translatePath: Array<string>,
        language = this.languageStoreService.getNameOfCurrentLanguage()
    ): TranslateObj | string | null {
        return this.resolveTranslation([...translatePath, language], this.data);
    }

    private resolveTranslation(
        translatePath: Array<string>,
        translateObj: null | TranslateObj | string
    ): string | null {
        // make sure we ignore null undefined and '' in the path
        translatePath = translatePath.filter(t => t !== '' && t !== undefined && t !== null);

        if (translatePath.length > 0 && translateObj) {
            const args = [...translatePath];
            const arg = args.shift();
            return this.resolveTranslation(args, translateObj[arg]);
        }

        return translateObj !== undefined ? (translateObj as string) : null;
    }
}
