import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UUID } from '../../../../../models/uuid';

@Component({
    selector: 'economy-record-will-apply-for-edit',
    templateUrl: './economy-record-will-apply-for-edit.component.html',
    styleUrls: ['../economy-record.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EconomyRecordWillApplyForEdit),
            multi: true,
        },
    ],
})
export class EconomyRecordWillApplyForEdit implements ControlValueAccessor {
    @Input() recordUuid: UUID;

    value: number;

    disabled: boolean = false;

    _onChange: any = () => {};
    _onTouch: any = () => {};

    onChange() {
        this._onChange(this.value);
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouch = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
