import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

/**
 * Whether to leave an iframe visible after pdf generation
 */
const DEBUG_IFRAME = false;

/**
 * Whether to append rendered canvas to the document body for debugging
 */
const DEBUG_CANVAS = false;

export type PDFAssemblerOptionsMargin = number | [number, number] | [number, number, number, number];
export interface PDFAssemblerOptions {
    /**
     * Margin for pdf page, can be either a {number} indicating margin from all
     * sides, [vertical, horizontal] margins or [top, right, bottom, left]
     * margins (like in css)
     */
    margin?: PDFAssemblerOptionsMargin;
    breakBefore?: string;
    avoidBreaking?: string;
}

type Margin = [number, number, number, number];
export class PDFAssembler {
    private margin: [number, number, number, number];
    private breakBefore?: string;
    private avoidBreaking?: string;
    private pdf: jsPDF;
    private sealed = false;

    static async create(element: HTMLElement, options?: PDFAssemblerOptions): Promise<PDFAssembler> {
        const instance = new PDFAssembler(options);
        await instance.addElement(element);
        return instance;
    }

    constructor(options?: PDFAssemblerOptions) {
        options = options || {};
        this.margin = this.normalizeMargin(options.margin);
        this.avoidBreaking = options.avoidBreaking;
        this.breakBefore = options.breakBefore;
        this.pdf = new jsPDF({
            orientation: 'p',
            unit: 'px',
            format: 'a4',
            hotfixes: ['px_scaling'],
        } as any);
    }

    get marginTop(): number {
        return Math.floor(this.millimetersToPixels(this.margin[0]));
    }

    get marginRight(): number {
        return Math.floor(this.millimetersToPixels(this.margin[1]));
    }

    get marginBottom(): number {
        return Math.floor(this.millimetersToPixels(this.margin[2]));
    }

    get marginLeft(): number {
        return Math.floor(this.millimetersToPixels(this.margin[3]));
    }

    // https://stackoverflow.com/questions/44757411/what-is-pixel-width-and-length-for-jspdfs-default-a4-format
    private get pageWidth(): number {
        return this.pdf.internal.pageSize.getWidth();
    }

    private get pageHeight(): number {
        return this.pdf.internal.pageSize.getHeight();
    }

    /**
     * Calculates how many px is one mm, knowing that our page is A4 format,
     * and this format is 210mm wide, then we can calculate how much px is one
     * millimeter in the pdf we're creating.
     *
     * Probably not siutable to be used outside of this pdf for calculations.
     * @param mm
     * @returns
     */
    millimetersToPixels(mm: number): number {
        return (this.pageWidth / 210) * mm;
    }

    save(name: string): Promise<void> {
        this.seal();
        return (this.pdf.save(name, { returnPromise: true }) as unknown as Promise<void>).then(() => {
            this.restore();
        });
    }

    toString(): string {
        this.seal();
        const res = this.pdf.output('dataurlstring');
        this.restore();
        return res;
    }

    async addElement(element: HTMLElement): Promise<PDFAssembler> {
        if (this.sealed) throw new Error(`Cannot add elements to sealed PDF!`);

        const root = element.cloneNode(true) as HTMLElement;
        document.body.appendChild(root);

        const start = root.getBoundingClientRect().top;

        // style root for pdf
        root.style.display = 'block';
        root.style.width = this.pageWidth + 'px';
        root.style.boxSizing = 'border-box';
        root.style.margin = '0';
        root.style.padding = `0 ${this.marginRight}px 0 ${this.marginLeft}px`;

        const addSpacer = (h: number, el: Element) => {
            const spacer = document.createElement('div');
            spacer.style.height = h + 'px';
            spacer.dataset.pdfAssemblerSpacer = '';
            el.parentElement.insertBefore(spacer, el);
        };

        addSpacer(this.marginTop, root.children[0]);

        // We need to loop through every critical elements in the pdf
        // and add spacing to either move element's to the next page
        // when they need to be on separated page, or ensure they are
        // not broken by a page end, so we're adding a spacer before
        // those elements, if their position requires it
        const criticalElementsSelector = [this.avoidBreaking, this.breakBefore].filter(Boolean).join(',');
        if (criticalElementsSelector !== '') {
            root.querySelectorAll(criticalElementsSelector).forEach(el => {
                const { top, height } = el.getBoundingClientRect();
                const elPageY = (top - start) % this.pageHeight;
                const breaks = elPageY > this.marginTop && elPageY + height > this.pageHeight - this.marginBottom;
                if (el.matches(this.breakBefore) || breaks) {
                    const spacerHeight = this.pageHeight - elPageY + this.marginTop;
                    addSpacer(spacerHeight, el);
                } else if (elPageY < this.marginTop) {
                    addSpacer(this.marginTop - elPageY, el);
                }
            });
        }

        const canvas = await html2canvas(root, {
            scale: window.devicePixelRatio,
            removeContainer: !DEBUG_IFRAME,
            foreignObjectRendering: false,
            logging: false,
            letterRendering: true,
            height: root.getBoundingClientRect().height,
            scrollY: 0,
            onclone: (doc: Document) => {
                document.body.removeChild(root);

                if (DEBUG_IFRAME) {
                    doc.querySelectorAll('board-meeting-creator-navigation,caseworker-review-navigation').forEach(e =>
                        e.remove()
                    );
                    document.querySelectorAll('iframe').forEach(iframe => {
                        if (iframe.style.visibility === 'hidden') {
                            iframe.style.visibility = 'visible';
                            iframe.style.left = '0';
                            iframe.style.height = '100vh';
                            iframe.style.width = '100vw';
                            iframe.style.overflow = 'hidden';
                            iframe.scrolling = 'yes';
                            iframe.style.zIndex = '1000000000';
                        }
                    });
                }
            },
        } as any);

        if (canvas.width === 0 || canvas.height === 0) return this;

        // Generated image height might be higher then one page, to render it
        // correctly we're splitting the main image generated by html2canvas.
        // To do so we should render it into another canvas that has a dedicated
        // size (that is equal pdf's page size).
        const tempCanvas = document.createElement('canvas');
        const tempCtx = tempCanvas.getContext('2d');
        tempCanvas.width = this.pageWidth * window.devicePixelRatio;
        tempCanvas.height = this.pageHeight * window.devicePixelRatio;

        let imgY = 0;
        while (imgY < canvas.height) {
            // restrict height so we're not rendering out of the original canvas
            const renderHeight = Math.min(canvas.height - imgY, tempCanvas.height);

            tempCtx.fillStyle = '#ffffff';
            tempCtx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);
            tempCtx.drawImage(canvas, 0, imgY, canvas.width, renderHeight, 0, 0, canvas.width, renderHeight);
            this.pdf.addImage(
                tempCanvas.toDataURL('image/jpeg', 1.0),
                'jpeg',
                0,
                0,
                this.pdf.internal.pageSize.getWidth(),
                this.pdf.internal.pageSize.getHeight(),
                '',
                'SLOW',
                0
            );
            this.pdf.addPage();
            imgY += tempCanvas.height;
        }

        if (DEBUG_CANVAS) {
            const clearBtn = document.createElement('button');
            clearBtn.textContent = 'Remove canvas';
            clearBtn.style.marginLeft = '290px';
            clearBtn.addEventListener('click', () => {
                document.body.removeChild(canvas);
                document.body.removeChild(clearBtn);
            });
            document.body.appendChild(clearBtn);
            document.body.appendChild(canvas);
            canvas.style.marginLeft = '290px';
        }

        return this;
    }

    private normalizeMargin(margin: number | [number, number] | Margin): Margin {
        const m: Margin = [0, 0, 0, 0];

        if (typeof margin === 'number') {
            m.fill(margin, 0, 4);
        } else if (Array.isArray(margin) && margin.length === 2) {
            m[0] = margin[0];
            m[1] = margin[1];
            m[2] = margin[0];
            m[3] = margin[1];
        } else if (Array.isArray(margin) && margin.length === 4) {
            for (let i = 0; i < 4; i++) {
                m[i] = margin[i];
            }
        }

        return m;
    }

    private seal() {
        const internal: any = this.pdf.internal;
        this.pdf.deletePage(internal.getCurrentPageInfo().pageNumber);
        this.sealed = true;
    }

    private restore() {
        this.pdf.addPage();
        this.sealed = false;
    }
}
