import { BoardMeetingAgendaDto } from '../../api/dto/board-meeting/board-meeting-agenda-dto';
import { CategoryZip } from './zip-service';
import { SectionData, SectionListInterface } from '../../pdf-application/pdf-list-service';
import { BoardMeetingAgendaSectionsType } from '../../api/mappers/board-meeting-mapper';
import { AgendaSectionsManual } from '../../api/dto/board-meeting/agenda-sections-manual';

export class SectionListAdapter implements SectionListInterface {
    constructor(private _sections: CategoryZip[], readonly boardMeetingData: BoardMeetingAgendaDto) {
        this.sections = _sections.map(section => ({
            sectionName: section.sequence + 1 + '. ' + section.categoryName.toUpperCase(),
            isCategory: section.category,
            manualText: this.getText(section.sequence),
            applications: section.applications
                .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
                .map(app => {
                    return {
                        applicationName: app.directoryName,
                        files: app.fileUuids,
                    };
                }),
        }));
    }

    readonly sections: Array<SectionData>;

    private getText(sectionSequence: number): string | null {
        const manualSections = <Array<AgendaSectionsManual>>(
            this.boardMeetingData.agendaSections.filter(a => a.type === BoardMeetingAgendaSectionsType.Manual)
        );
        const section = manualSections.find(sec => sec.sequence == sectionSequence);
        if (section) {
            return section.content.text;
        } else {
            return null;
        }
    }
}
