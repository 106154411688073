import { SectionDto } from './section-dto';

export class ApplicationDto {
    constructor(
        readonly sections: SectionDto[],
        readonly uuid: string,
        readonly applicationSchemaUuid: string,
        readonly categoryUuid: string,
        readonly createdAt: string,
        readonly publicStatus: string,
        readonly postGrantStatus: string | null,
        readonly isDraft: boolean,
        readonly submittedAt: string,
        readonly deletionDate: string,
        readonly caseworkerSections: SectionDto[],
        readonly postGrantSections: SectionDto[],
        readonly isClosed: boolean
    ) {}
}

export enum ApplicationType {
    ApplicantApplication = 'applicant_application',
    CaseworkerApplication = 'caseworker_application',
    PostGrantApplication = 'post_grant_application',
}
