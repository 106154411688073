import { Component, ContentChild, Directive, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Directive({
    selector: '[responsibleSelectOption]',
})
export class ResponsibleSelectOptionDirective<T = any> {
    constructor(public readonly template: TemplateRef<{ $implicit: T }>) {}
}

@Component({
    selector: 'fonda-responsible-select',
    templateUrl: './responsible-select.component.html',
    styleUrls: ['./responsible-select.component.sass'],
})
export class ResponsibleSelectComponent<T> {
    @Input() options: T[];
    @Input() selected: T[];
    @Output() selectedChange = new EventEmitter<T[]>();
    @ContentChild(ResponsibleSelectOptionDirective, { read: TemplateRef, static: true }) optionTemplate: TemplateRef<{
        $implicit: T;
    }>;

    addSelected(option: T) {
        // Dont modify the input, let the parent handle it. It might lose consistency if we change internal structure here
        // and so will do parent
        this.selectedChange.emit([...this.selected, option]);
    }

    removeSelected(option: T) {
        this.selectedChange.emit(this.selected.filter(o => o !== option));
    }

    uniqueOptions() {
        return this.options.filter(item => !this.selected.includes(item));
    }
}
