import { EventEmitter, OnDestroy, Injectable } from '@angular/core';
import * as equal from 'fast-deep-equal';
import { AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs/Subject';
import { takeUntil } from 'rxjs/operators';
import cloneDeep from 'lodash/cloneDeep';

let UNIQUE_ID = 0;

@Injectable()
export abstract class BaseRecordComponent<V = object | Array<object>, C = object> implements OnDestroy {
    onChange = new EventEmitter<V>();
    isReadonly = false;
    isOnSearch = false;
    errorMessage: string;
    readonly uniqueId = UNIQUE_ID++;
    private unsubscribe = new Subject<void>();

    private _metadata: RecordMetadata<V, C>;
    get metadata(): RecordMetadata<V, C> {
        return this._metadata;
    }

    abstract initRecord(metadata: RecordMetadata<V, C>): void;
    abstract getValue(): V;
    abstract setValue(value: V): void;
    abstract isValid(): boolean;
    abstract isEmpty(): boolean;

    /**
     * Internal method, please override and implement initRecord method and leave this alone.
     * The initRecord method is called right away so go have your fun there!
     *
     * @param recordMetadata - metadata of the record to preserve
     * @param isReadonly - whether component should be initialised as readonly
     * @param isOnSearch - whether component should be initialised as on search
     */
    _init(recordMetadata: RecordMetadata<V, C>, isReadonly: boolean, isOnSearch: boolean, errorMessage: string): void {
        this._metadata = recordMetadata;
        this.setReadonly(isReadonly);
        this.setOnSearch(isOnSearch);
        this.setErrorMessage(errorMessage);
        this.initRecord(recordMetadata);
        this._setValue(cloneDeep(this.metadata.initialValue));
        this.onChange.emit(this.getValue());
    }

    /**
     * Internal method used to set value only when it is not nullish
     *
     * @param value - value to check for nullish values and pass to the component's setValue method
     */
    _setValue(value: V | null | undefined) {
        if (typeof value !== 'undefined' && value !== null) {
            this.setValue(value);
        }
    }

    setReadonly(isReadonly: boolean): void {
        this.isReadonly = isReadonly;
    }

    setOnSearch(isOnSearch: boolean): void {
        this.isOnSearch = isOnSearch;
    }

    setErrorMessage(message: string): void {
        this.errorMessage = message;
    }

    isChanged(): boolean {
        return !this.isReadonly && !equal(this.getValue(), this.metadata.initialValue);
    }

    registerControlChanges(control: AbstractControl) {
        control.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.onChange.emit(this.getValue());
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}

export class RecordMetadata<V = object | object[], C = object> {
    constructor(
        public readonly uuid: string,
        public readonly initialValue: V,
        public readonly isRequired: boolean,
        public readonly size: number,
        public readonly title: string,
        public readonly configuration: C,
        public readonly hasDynamicPrefilling: boolean
    ) {}
}
