<pwa-popup (close)="close()" size="large">
    <ng-container pwa-popup-header>
        {{ 'general' | translate : 'declaration_of_consent_pop_up_title' }}
    </ng-container>
    <div [innerHTML]="'general' | translate : 'declaration_of_consent_pop_up_text'"></div>
    <div class="acceptance-record-popup-actions" pwa-popup-actions>
        <button pwaButton color="gray" (click)="close()">
            {{ 'general' | translate : 'cancel' }}
        </button>
    </div>
</pwa-popup>
