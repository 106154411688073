import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CaseworkerCorrespondencePopupService } from '../../../../correspondence/correspondence-popup/caseworker-correspondence-popup.service';
import { FondaApiService } from '../../../../api/fonda-api.service';
import { LocalStorageService } from '../../../../services/local-storage-service';
import { CorrespondenceTemplateManualDto } from '../../../../api/dto/correspondence-dto/correspondence-template-manual-dto';
import { EventsHandlerService } from '../../../../services/events-handler-service';
import { CorrespondenceTemplateStorage } from '../../../../caseworker/review/services/correspondence-template-storage';
import { CorrespondencePdfService } from '../../../../services/correspondence-pdf-service';
import { PdfFileCreator } from '../../../../services/pdf-file-creator';
import { FileService } from '../../../../services/file.service';
import { BaseRecordComponent, RecordMetadata } from '../../base-record.component';
import { PdfGenerateOutput } from '../../../../correspondence/correspondence-popup/correspondence-popup.component';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '../../../translation/translate.service';

export interface CorrespondenceTemplatePdfGeneratorOutput {
    text: string;
    name: string;
}

@Component({
    selector: 'app-correspondence-template-pdf-generator',
    templateUrl: './correspondence-template-pdf-generator.component.html',
    styleUrls: ['./correspondence-template-pdf-generator.component.scss'],
    providers: [CaseworkerCorrespondencePopupService],
})
export class CorrespondenceTemplatePdfGeneratorComponent extends BaseRecordComponent<
    CorrespondenceTemplatePdfGeneratorOutput,
    null
> {
    loadingPopup = false;
    applicationUuid = '';
    template: CorrespondenceTemplateManualDto;
    value: CorrespondenceTemplatePdfGeneratorOutput = {
        name: '',
        text: '',
    };

    constructor(
        private activatedRoute: ActivatedRoute,
        private popupService: CaseworkerCorrespondencePopupService,
        private apiService: FondaApiService,
        private localStorageService: LocalStorageService,
        private fileService: FileService,
        private translateService: TranslateService,
        private eventHandlerService: EventsHandlerService,
        private templateStorage: CorrespondenceTemplateStorage,
        private correspondencePdfService: CorrespondencePdfService,
        private pdfCreator: PdfFileCreator
    ) {
        super();
    }

    initRecord(metadata: RecordMetadata<CorrespondenceTemplatePdfGeneratorOutput, null>) {
        this.fetchApplicationUuid();
        this.templateStorage
            .getTemplate(this.localStorageService.getApplicationInformation().applicantSchemaUuid)
            .then(templates => {
                this.template = templates.find(template => template.pdfGeneratorRecordUuid === metadata.uuid);
            });
    }

    setValue(value: CorrespondenceTemplatePdfGeneratorOutput) {
        this.value = {
            text: value.text,
            name: value.name,
        };
    }

    getValue(): CorrespondenceTemplatePdfGeneratorOutput {
        return { ...this.value };
    }

    emitValue() {
        this.onChange.emit(this.getValue());
        this.eventHandlerService.triggerSaveCaseworkerSection.next();
    }

    isValid(): boolean {
        return true;
    }

    isEmpty(): boolean {
        return false;
    }

    async previewPdf() {
        const { value, tokens } = await this.correspondencePdfService.encodeTokens(this.value.text);
        this.pdfCreator.downloadPdf(this.correspondencePdfService.decodeTokensWithContentValue(value, tokens));
    }

    deleteFile() {
        this.value = { name: '', text: '' };
        this.emitValue();
    }

    async initPopup() {
        this.loadingPopup = true;
        const popup = await this.popupService.initOnlyPdfTemplateEditor(
            this.applicationUuid,
            this.template.uuid,
            this.translateService.get([this.metadata.uuid, 'title']),
            this.value.text
        );
        this.loadingPopup = false;

        popup.pdfGenerated.pipe(takeUntil(popup.closed)).subscribe((file: PdfGenerateOutput) => {
            this.value = {
                text: file.bodyText,
                name: file.fileName,
            };
            this.emitValue();
        });
    }

    private fetchApplicationUuid() {
        this.applicationUuid = this.activatedRoute.snapshot.queryParams.uuid;
    }
}
