import { Component, ViewContainerRef } from '@angular/core';
import { BaseRecordComponent, RecordMetadata } from '../../base-record.component';
import { Sizes } from '../../../components/confirmation-popup/confirmation-popup.component';
import { DynamicPopupService } from '../../../components/confirmation-popup/dynamic-popup-service';
import { TranslateService } from '../../../translation/translate.service';
import { BooleanValue } from '../../values.types';
import { PopupService } from '../../../../shared/popup/popup.service';
import { AcceptanceRecordPopupComponent } from './acceptance-record-popup/acceptance-record-popup.component';

@Component({
    selector: 'acceptance-record',
    templateUrl: 'acceptance-record.component.html',
    styleUrls: ['acceptance-record.scss'],
})
export class AcceptanceRecordComponent extends BaseRecordComponent<BooleanValue, null> {
    showError = false;
    booleanValue = false;

    constructor(
        private readonly translateService: TranslateService,
        private readonly confirmationPopupService: DynamicPopupService<void>,
        private readonly viewContainerRef: ViewContainerRef,
        private popupService: PopupService
    ) {
        super();
    }

    initRecord(metadata: RecordMetadata<BooleanValue, null>) {}

    setValue(value: BooleanValue) {
        this.booleanValue = value.boolean || false;
    }

    getValue(): BooleanValue {
        this.showError = !this.booleanValue;
        return { boolean: this.booleanValue };
    }

    emitValue() {
        this.onChange.emit(this.getValue());
    }

    showPopup() {
        this.confirmationPopupService.initPopup(
            this.translateService.get(['general', 'declaration_of_consent_pop_up_title']),
            this.translateService.get(['general', 'declaration_of_consent_pop_up_text']),
            '',
            '',
            this.viewContainerRef,
            Sizes.Big
        );
    }

    showPwaPopup() {
        this.popupService.spawnPopup(AcceptanceRecordPopupComponent).subscribe();
    }

    isValid(): boolean {
        return this.metadata.isRequired && this.booleanValue;
    }

    isEmpty(): boolean {
        return false;
    }
}
