import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Constructor } from './constructor';

export interface CanDisable {
    disabled: boolean;
}

export type CanDisableCtor = Constructor<CanDisable>;

export function mixinDisabled<T extends Constructor<{}>>(base: T): CanDisableCtor & T {
    return class extends base implements CanDisable {
        private _disabled = false;

        get disabled() {
            return this._disabled;
        }
        set disabled(value: boolean) {
            this._disabled = coerceBooleanProperty(value);
        }

        constructor(...args: any[]) {
            super(...args);
        }
    };
}
