import { Directive, HostBinding, Input } from '@angular/core';

let uniqueId = 0;

@Directive({
    selector: 'input[pwaRadio]',
})
export class PwaRadioDirective {
    @HostBinding() type = 'radio';
    @Input() @HostBinding() id = 'pwa-radio-' + (++uniqueId).toString();
}
