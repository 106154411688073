import { Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Maybe } from 'true-myth';
import { TokenDto } from '../api/dto/token.dto';
import { FondaApiService } from '../api/fonda-api.service';
import { AuthService } from '../auth/auth.service';
import { PackageManagerService } from '../package-manager/package-manager.service';

@Injectable({
    providedIn: 'root',
})
export class ImpersonatorService {
    private returnUrl = '';

    constructor(
        private api: FondaApiService,
        private authService: AuthService,
        private router: Router,
        private packageManagerService: PackageManagerService
    ) {}

    /**
     * Starts impersonation of applicant with given email address
     *
     * @param email Appicant email
     * @param url Url to navigate to after successful impersonation
     * @returns Applicant impersonator token
     */
    startImpersonating(email: string, url = '/'): Observable<TokenDto> {
        return this.api.getAccountTokenAsImpersonator(email).pipe(
            tap(token => {
                this.authService.setImpersonatorToken(this.authService.getToken());
                this.authService.setIdentity(token.token);
                this.returnUrl = this.router.url;
                this.router.navigateByUrl(url);
            })
        );
    }

    stopImpersonating() {
        if (this.authService.isImpersonating()) {
            const fallbackUrl = '/caseworker/applicant-management/applicant/' + this.authService.getUuid();
            this.authService.setIdentity(this.authService.getImpersonatorToken());
            this.authService.clearImpersonatorToken();
            this.router.navigateByUrl(this.returnUrl || fallbackUrl);
        }
    }

    isFeatureEnabled(): boolean {
        const packages = Maybe.of(this.packageManagerService.packages());
        const hasApplicantImpersonator = packages.map(p => p.isApplicantModuleImpersonator).unwrapOr(false);
        return isDevMode() || hasApplicantImpersonator;
    }

    isFeatureEnabled$(): Observable<boolean> {
        return this.packageManagerService
            .packages$()
            .pipe(map(packages => isDevMode() || packages.isApplicantModuleImpersonator));
    }
}
