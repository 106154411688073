<div *ngIf="!isReadonly && !isOnSearch" class="form-item date" [ngClass]="{ half: metadata.size === 1 }">
    <input id="{{ metadata.uuid }}" name="value1" placeholder=" " type="text" [value]="value[0] | utcDate" disabled />
    <label for="{{ metadata.uuid }}">
        {{ metadata.uuid | translate : 'title' }}
        <span *ngIf="metadata.isRequired" class="mandatory">*</span>
    </label>
    <div class="form-focus"></div>
    <div class="form-error"></div>
    <div class="only-when-not-in-search">
        <fonda-basic-datepicker
            [date]="value[0]"
            (dateChange)="update(0, $event)"
            [options]="datepickerOptions"
        ></fonda-basic-datepicker>
    </div>
</div>

<ng-container *ngIf="!isReadonly && isOnSearch">
    <div style="display: flex; position: relative">
        <div class="form-item between first" [ngClass]="{ half: metadata.size === 1 }">
            <input
                id="{{ metadata.uuid }}-from"
                name="value1"
                placeholder=" "
                type="text"
                [value]="strDates[0]"
                disabled
            />
            <label for="{{ metadata.uuid }}-from">{{ metadata.title }} from</label>
            <div class="form-focus"></div>
        </div>
        <div class="form-item between second" [ngClass]="{ half: metadata.size === 1 }">
            <input
                id="{{ metadata.uuid }}-to"
                name="value1"
                placeholder=" "
                type="text"
                [value]="strDates[1]"
                disabled
            />
            <label for="{{ metadata.uuid }}-to">{{ metadata.title }} to</label>
            <div class="form-focus"></div>
        </div>
        <fonda-datepicker [dates]="fondaDatepickerValue" (onSetDates)="updateFromDatepicker($event)"></fonda-datepicker>
    </div>
</ng-container>

<a class="form-item" *ngIf="isReadonly" [ngClass]="{ full: metadata.size === 2 }">
    <p>{{ metadata.uuid | translate : 'title' }}</p>
    <span>{{ metadata.initialValue?.text | date : 'dd-MM-yyyy' || '-' }}</span>
    <div class="form-focus"></div>
    <div class="form-error"></div>
</a>
