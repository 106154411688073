<section *ngIf="hasActiveBullets()">
    <div style="padding: 40px 0px; margin: auto; max-width: 1000px">
        <!-- DESKTOP -->
        <div class="container-desktop">
            <div class="circles">
                <ng-container *ngFor="let point of bulletPoints; let i = index; let even = even; let last = last">
                    <div
                        *ngIf="point.is_activated"
                        class="circle"
                        [class.circle-gray]="i == lastActivatedIndex"
                        [class.circle-small]="even"
                        [class.circle-large]="!even"
                        [style.left]="point.left + '%'"
                        [style.top]="point.top + '%'"
                    >
                        {{ i + 1 }}
                    </div>
                    <div
                        *ngIf="point.is_activated"
                        class="description"
                        [style.left]="even ? point.left + 4 + '%' : ''"
                        [style.right]="!even ? 105 - point.left + '%' : ''"
                        [style.top]="even ? point.top - 11 + '%' : point.top - 12.5 + '%'"
                    >
                        <p class="bullet-title">
                            {{ 'login_page' | translate : 'diagram_bullet_point_' + (i + 1) + '_title' }}
                        </p>
                        <p>
                            {{ 'login_page' | translate : 'diagram_bullet_point_' + (i + 1) + '_tooltip' }}
                        </p>
                    </div>
                </ng-container>
            </div>
            <div class="titles">
                <ng-container *ngFor="let point of bulletPoints; let i = index; let even = even">
                    <div
                        *ngIf="point.is_activated"
                        class="title"
                        [style.left]="!even ? point.left + 3.5 + '%' : ''"
                        [style.right]="even ? 102.5 - point.left + '%' : ''"
                        [style.top]="point.top - 1.5 + '%'"
                    >
                        {{ 'login_page' | translate : 'diagram_bullet_point_' + (i + 1) + '_title' }}
                    </div>
                </ng-container>
            </div>

            <svg style="position: absolute; width: 100%; height: 100%">
                <ng-container *ngFor="let point of bulletPoints; let i = index; let even = even">
                    <line
                        *ngIf="point.is_activated && lastActivatedIndex != i"
                        [attr.y1]="bulletPoints[i].top + '%'"
                        [attr.x1]="bulletPoints[i].left + '%'"
                        [attr.y2]="bulletPoints[i + 1].top + '%'"
                        [attr.x2]="bulletPoints[i + 1].left + '%'"
                        style="stroke: #cdcdcd; stroke-width: 1.25"
                    />
                    <line
                        *ngIf="point.has_rejected_outcome"
                        [attr.x1]="point.left + '%'"
                        [attr.y1]="point.top + '%'"
                        [attr.x2]="getRejectedLeft(point, even)"
                        [attr.y2]="getRejectedTop(point, even)"
                        style="stroke: #cdcdcd; stroke-width: 1; stroke-dasharray: 5, 5"
                    />
                    <circle
                        *ngIf="point.has_rejected_outcome"
                        [attr.cx]="getRejectedLeft(point, even)"
                        [attr.cy]="getRejectedTop(point, even)"
                        r="3.5"
                        stroke="#cdcdcd"
                        stroke-width="1"
                        fill="#cdcdcd"
                    />
                    <text
                        *ngIf="point.has_rejected_outcome"
                        [attr.x]="getRejectedLeft(point, even, 0.75)"
                        [attr.y]="getRejectedTop(point, even, 0.65)"
                        fill="#cdcdcd"
                        font-size="11"
                    >
                        {{ 'general' | translate : 'rejected' }}
                    </text>
                </ng-container>
            </svg>
        </div>
        <!-- MOBILE -->
        <div class="container-mobile">
            <div #line class="line"></div>
            <ng-container *ngFor="let point of bulletPoints; let i = index">
                <div *ngIf="point.is_activated" class="container-mobile-row">
                    <div #mobileCircle class="circle-mobile" [class.circle-gray]="i == lastActivatedIndex">
                        {{ i + 1 }}
                        <div *ngIf="point.has_rejected_outcome" class="circle-mobile-rejected">
                            {{ 'general' | translate : 'rejected' }}
                        </div>
                    </div>
                    <div class="container-mobile-row-content">
                        <p class="content-title">
                            {{ 'login_page' | translate : 'diagram_bullet_point_' + (i + 1) + '_title' }}
                        </p>
                        <p>
                            {{ 'login_page' | translate : 'diagram_bullet_point_' + (i + 1) + '_tooltip' }}
                        </p>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section>
