import { Component } from '@angular/core';
import { BaseRecordComponent, RecordMetadata } from '../../base-record.component';
import { FormControl, Validators } from '@angular/forms';
import { TextRecordConfiguration } from '../text-record/text-record.component';
import { TextValue } from '../../values.types';
import { setConditionalValidators } from '../../conditional-validators';

@Component({
    selector: 'email-record',
    templateUrl: 'email-record.component.html',
})
export class EmailRecordComponent extends BaseRecordComponent<TextValue, TextRecordConfiguration> {
    control = new FormControl();

    initRecord(metadata: RecordMetadata<TextValue, TextRecordConfiguration>): void {
        setConditionalValidators(this.control, [
            [metadata.isRequired, Validators.required],
            [metadata.configuration.min_length, Validators.minLength(metadata.configuration.min_length)],
            [metadata.configuration.max_length, Validators.maxLength(metadata.configuration.max_length)],
        ]);
        this.registerControlChanges(this.control);
    }

    setValue(value: TextValue): void {
        this.control.setValue(value.text);
    }

    getValue(): TextValue {
        return { text: this.control.value };
    }

    isValid(): boolean {
        return this.control.valid;
    }

    isEmpty(): boolean {
        return !this.control.value;
    }
}
