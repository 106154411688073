<div
    class="popup-content-container"
    [ngClass]="popupClasses"
    (clickedOutside)="close.emit()"
    (escPressed)="close.emit()"
>
    <header>
        <div>
            <ng-content select="[popup-header]"></ng-content>
        </div>
        <button
            *ngIf="closeable"
            icon-btn="cross"
            color="primary"
            class="close-button"
            (click)="onCloseButtonClicked()"
        >
            <span class="icon-close"></span>
        </button>
    </header>
    <div>
        <ng-content></ng-content>
    </div>
    <div>
        <ng-content select="[popup-actions]"></ng-content>
    </div>
</div>
