import { Component } from '@angular/core';
import { BaseRecordComponent, RecordMetadata } from '../../base-record.component';
import { FormControl, Validators } from '@angular/forms';
import { TextValue } from '../../values.types';
import { TextRecordConfiguration } from '../text-record/text-record.component';

@Component({
    selector: 'mobile-record',
    templateUrl: 'mobile-record.component.html',
})
export class MobileRecordComponent extends BaseRecordComponent<TextValue, TextRecordConfiguration> {
    text = new FormControl('');

    initRecord(metadata: RecordMetadata<TextValue, TextRecordConfiguration>) {
        if (metadata.isRequired) {
            this.text.setValidators(Validators.required);
        }

        this.registerControlChanges(this.text);
    }

    emitValue() {
        this.onChange.emit(this.getValue());
    }

    isValid(): boolean {
        return this.text.valid;
    }

    isEmpty(): boolean {
        return this.text.value === '';
    }

    setValue(value: TextValue) {
        this.text.setValue(value.text);
    }

    getValue(): TextValue {
        return { text: this.text.value };
    }
}
