<div
    class="form-item"
    [ngClass]="{ half: metadata.size === 1, full: metadata.size === 2 }"
    *ngIf="!isReadonly && !isOnSearch"
>
    <div class="checkbox">
        <input
            type="checkbox"
            placeholder=" "
            style="width: 24px; height: 24px; flex-shrink: 0"
            [ngModel]="booleanValue"
            (ngModelChange)="booleanValue = $event; emitValue()"
            id="{{ metadata.uuid }}"
        />
        <label style="font-size: 1em" for="{{ metadata.uuid }}" class="only-icon"> </label>
        <label style="font-size: 1em" (click)="booleanValue = !booleanValue; emitValue()">
            {{ metadata.uuid | translate : 'title' }}
            <span *ngIf="metadata.isRequired" class="mandatory">*</span>
        </label>
    </div>
</div>

<div
    class="form-item"
    [ngClass]="{ half: metadata.size === 1, full: metadata.size === 2 }"
    *ngIf="!isReadonly && isOnSearch"
>
    <div class="checkbox search">
        <input
            type="checkbox"
            placeholder=" "
            style="width: 24px; height: 24px; flex-shrink: 0"
            [ngModel]="booleanValue"
            (ngModelChange)="booleanValue = $event; emitValue()"
            id="{{ metadata.uuid }}-on-search"
        />
        <label class="on-search" for="{{ metadata.uuid }}-on-search">{{ metadata.title }}</label>
    </div>
</div>

<a *ngIf="isReadonly" class="form-item readonly" [ngClass]="{ full: metadata.size === 2 }">
    <div class="checkbox">
        <input
            type="checkbox"
            placeholder=" "
            style="width: 24px; height: 24px; cursor: auto; flex-shrink: 0"
            [value]="booleanValue"
            disabled
            id="{{ metadata.uuid }}-readonly"
            name=""
        />
        <label style="font-size: 1em" for="{{ metadata.uuid }}-readonly" class="only-icon"> </label>
        <label for="{{ metadata.uuid }}-readonly" style="font-size: 1em">
            {{ metadata.uuid | translate : 'title' }}
        </label>
    </div>
</a>
