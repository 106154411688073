import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BaseRecordComponent, RecordMetadata } from '../../base-record.component';

export interface BankRecordValue {
    registrationNumber: string | null;
    accountNumber: string | null;
}

@Component({
    selector: 'bank-record',
    templateUrl: 'bank-record.component.html',
    styleUrls: ['bank-record.scss'],
})
export class BankRecordComponent extends BaseRecordComponent<BankRecordValue> {
    registrationNumber = new FormControl('', [Validators.pattern('^[0-9]{4}$')]);
    accountNumber = new FormControl('', [Validators.pattern('^([0-9]{6}|[0-9]{10})$')]);

    initRecord(metadata: RecordMetadata<BankRecordValue>) {
        this.registerControlChanges(this.registrationNumber);
        this.registerControlChanges(this.accountNumber);
    }

    getValue(): BankRecordValue {
        return {
            registrationNumber: this.registrationNumber.value ? this.registrationNumber.value : null, // req
            accountNumber: this.accountNumber.value ? this.accountNumber.value : null, // req
        };
    }

    setValue(value: BankRecordValue) {
        this.registrationNumber.setValue(value.registrationNumber);
        this.accountNumber.setValue(value.accountNumber);
    }

    isValid(): boolean {
        return this.registrationNumber.valid && this.accountNumber.valid;
    }

    isEmpty(): boolean {
        return this.registrationNumber.value === '' && this.accountNumber.value === '';
    }
}
