import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaymentObject, PaymentTypes } from '../payment-object';
import { PaymentProfileDto } from '../../../../../api/dto/payment-profile-dto/payment-profile-dto';
import { PaymentColors } from '../payment-colors';
import { BasicSelectComponentEntryWithColor } from '../../../../basic-form-controls/basic-select/basic-select.component';
import { TranslateService } from '../../../../translation/translate.service';
import { PaymentRequesterDto } from '../payment-types';

@Component({
    selector: '[payment-row]',
    templateUrl: 'payment-row.component.html',
})
export class PaymentRowComponent implements OnInit {
    public title: string;
    public type: { value: string; title: string };
    public rowDate: Date;
    public amount: string;
    public newDate = new Date();
    public choosenPaymentProfileUuid: string;
    public choosenPaymentProfile: PaymentProfileDto;
    public paymentProfilesForDropdown: Array<BasicSelectComponentEntryWithColor> = [];
    public saveClicked = false;
    @Output('onDelete') onDelete = new EventEmitter<PaymentRequesterDto>();
    @Output('onChange') onChange = new EventEmitter();
    @Input() ind = 0;
    @Input() uuid = '';
    private types: Array<{ value: string; title: string }> = [];
    private startingSnapshot: PaymentRequesterDto;

    private _rowEntry: PaymentRequesterDto;

    constructor(private translateService: TranslateService) {}

    @Input('rowEntry')
    public set rowEntry(rowEntry: PaymentRequesterDto) {
        if (rowEntry) {
            this.initTypes();
            this.title = rowEntry.name;
            this.type = {
                value: rowEntry.type,
                title:
                    this.types.find(type => type.value == rowEntry.type) &&
                    this.types.find(type => type.value == rowEntry.type).title,
            };
            this.choosenPaymentProfileUuid = rowEntry.payment_profile;
            this.rowDate = new Date(rowEntry.date);
            this.amount = this.convertToString(rowEntry.amount);
            this.startingSnapshot = rowEntry;
        }
        this._rowEntry = rowEntry;
    }

    public _paymentProfiles: Array<PaymentProfileDto> = [];

    get paymentProfiles(): Array<PaymentProfileDto> {
        return this._paymentProfiles;
    }

    @Input() set paymentProfiles(paymentProfiles: Array<PaymentProfileDto>) {
        this._paymentProfiles = [...paymentProfiles];
        if (this.choosenPaymentProfileUuid) {
            this.choosenPaymentProfile = this._paymentProfiles.find(
                prof => prof.uuid === this.choosenPaymentProfileUuid
            );
        }
        this.paymentProfilesForDropdown = this.getProfiles();
    }

    public ngOnInit() {
        this.initTypes();
    }

    public getProfiles(): Array<BasicSelectComponentEntryWithColor> {
        if (this._paymentProfiles && this._paymentProfiles.length > 0) {
            return this._paymentProfiles.map(prof => ({
                value: prof.name,
                color: prof.isDefault ? PaymentColors.Green : PaymentColors.Orange,
            }));
        } else {
            return [];
        }
    }

    public handleTypeChange(value: string) {
        this.type = this.types.find(type => type.title === value);
    }

    public handlePaymentProfileChange(name: string) {
        const profile = this.paymentProfiles.find(prof => prof.name == name);
        this.choosenPaymentProfileUuid = profile.uuid;
        this.choosenPaymentProfile = profile;
        this.changeValue();
    }

    public onSaveClick() {
        this.saveClicked = true;
    }

    public clickDelete() {
        this.onDelete.emit(this._rowEntry);
    }

    public changeValue() {
        this.onChange.emit();
    }

    public isChanged(): boolean {
        return JSON.stringify(this.getValue()) !== JSON.stringify(this.startingSnapshot);
    }

    public isValid(): boolean {
        return !!(this.title && this.type && this.type.value && this.amount && this.rowDate);
    }

    public getValue(): PaymentObject {
        return new PaymentObject(
            this.rowDate,
            this.title,
            this.convertToNumber(this.amount),
            this.type.value,
            this.choosenPaymentProfileUuid
        );
    }

    public getTitles(): Array<string> {
        return this.types.map(type => type.title);
    }

    private initTypes() {
        this.types = [];
        PaymentTypes.GetTypes().forEach(payment => {
            this.types.push({
                value: payment.value,
                title: this.translateService.get([this.uuid, payment.title]),
            });
        });
    }

    private convertToNumber(str: string): number {
        if (!str) {
            return 0;
        }
        str = str.replace(/[^\d,-]/g, '');
        str = str.replace(',', '.');
        return Number(str);
    }

    private convertToString(num: number): string {
        if (num) {
            const str = num.toString();
            return str.replace('.', ',');
        } else {
            return;
        }
    }
}
