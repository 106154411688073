<div
    class="form-item"
    [style.margin-bottom]="isOnSearch ? '' : '40px'"
    [style.padding-right]="isOnSearch ? '16px' : ''"
    [ngClass]="{ half: metadata.size === 1 }"
    *ngIf="!isReadonly"
>
    <fonda-select
        [label]="metadata.uuid | translate : 'title'"
        [placeholder]="metadata.uuid | translate : 'title'"
        [options]="boardMeetingUuids"
        [multiple]="this.isOnSearch"
        [canUnselect]="true"
        [value]="selectedBoardMeetingUuids"
        [boxDisplay]="false"
        [showSearch]="true"
        (valueChange)="chooseBoardMeeting($event)"
        [mandatory]="metadata.isRequired"
    >
        <ng-container *fondaSelectOption="let uuid">
            {{ displayName(uuid) }}
            <ng-container *ngIf="nextBoardMeetingUuid === uuid">
                --{{ 'general' | translate : 'next' }}--
            </ng-container>
            <ng-container *ngIf="previousBoardMeetingUuid === uuid">
                --{{ 'general' | translate : 'previous' }}--
            </ng-container>
        </ng-container>
    </fonda-select>
</div>

<a class="form-item" *ngIf="isReadonly" [ngClass]="{ full: metadata.size === 2 }" [style.margin-bottom]="'40px'">
    <p>{{ metadata.uuid | translate : 'title' }}</p>
    <span>{{ selectedName }}</span>
</a>
