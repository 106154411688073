import { Component } from '@angular/core';
import { BaseRecordComponent, RecordMetadata } from '../../base-record.component';
import { FondaApiService } from '../../../../api/fonda-api.service';
import { ResponsibleCaseworkerData } from './responsible-caseworker-data';
import { UuidValue } from '../../values.types';

@Component({
    selector: 'responsible-caseworker-record',
    templateUrl: 'responsible-caseworker-record.component.html',
})
export class ResponsibleCaseworkerRecordComponent extends BaseRecordComponent<UuidValue, null> {
    responsibleCaseworker: string | null = null;
    caseworkers: ResponsibleCaseworkerData[] = [];

    constructor(private apiService: FondaApiService) {
        super();
    }

    get options(): string[] {
        return this.caseworkers.map(c => c.uuid);
    }

    initRecord(metadata: RecordMetadata<UuidValue, null>) {
        this.fetchData();
    }

    setValue(value: UuidValue) {
        this.responsibleCaseworker = (!Array.isArray(value) && value.uuid) || null;
    }

    getValue(): UuidValue {
        if (this.responsibleCaseworker === null) return [];
        return { uuid: this.responsibleCaseworker };
    }

    emitValue() {
        this.onChange.emit(this.getValue());
    }

    chooseCaseworker(caseworker: string | null) {
        this.responsibleCaseworker = caseworker;
        this.emitValue();
    }

    isValid(): boolean {
        return this.metadata.isRequired && this.responsibleCaseworker !== null;
    }

    isEmpty(): boolean {
        return this.responsibleCaseworker === null;
    }

    private fetchData() {
        this.apiService
            .getBackendUsersCaseworker()
            .then(caseworkers =>
                caseworkers.map(
                    caseworker =>
                        new ResponsibleCaseworkerData(
                            caseworker.uuid,
                            caseworker.confirmed,
                            caseworker.createdDate,
                            caseworker.email,
                            caseworker.type,
                            caseworker.roles,
                            caseworker.username,
                            caseworker.initials
                        )
                )
            )
            .then(caseworkers => (this.caseworkers = caseworkers));
    }

    getUsername(uuid: string): string {
        if (!uuid) return '';
        const caseworker = this.caseworkers.find(c => c.uuid === uuid);
        return caseworker ? caseworker.username : '';
    }
}
