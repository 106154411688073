<ng-container *ngIf="isImpersonating">
    <div class="impersonator-toast-placeholder"></div>
    <div class="impersonator-toast">
        <p>
            {{ 'general' | translate : 'browsing_as' }} <strong>{{ email }}</strong>
        </p>
        <span class="impersonator-toast__stop-button underline" (click)="stopImpersonating()">
            {{ 'general' | translate : 'stop_impersonating' }}
        </span>
    </div>
</ng-container>
