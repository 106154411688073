import { Injectable } from '@angular/core';
import { NavigationExtras, Router, UrlTree } from '@angular/router';
import { AuthService } from '../../auth/auth.service';

@Injectable({ providedIn: 'root' })
export class PwaRedirectionService {
    readonly base = '/applicant';

    constructor(private readonly router: Router, private authService: AuthService) {}

    toUserLandingPage(): Promise<boolean> {
        if (this.authService.isApplicant()) {
            return this.toApplicantCasesPage();
        }

        if (this.authService.isCaseworker()) {
            return this.toCaseworkerLandingPage();
        }

        if (this.authService.isBoardMeetingViewer()) {
            return this.toBoardMeetingViewer();
        }

        if (this.authService.isAdministrator()) {
            return this.toAdministration();
        }
    }

    toUserUrlTree(): UrlTree {
        if (this.authService.isApplicant()) {
            return this.router.createUrlTree(['applicant/my-cases']);
        }

        if (this.authService.isCaseworker()) {
            return this.router.createUrlTree(['caseworker/landing-page']);
        }

        if (this.authService.isBoardMeetingViewer()) {
            return this.router.createUrlTree(['caseworker/board-meeting/viewer']);
        }

        if (this.authService.isAdministrator()) {
            return this.router.createUrlTree(['caseworker/administration']);
        }
    }

    toLandingPage(extras?: NavigationExtras): Promise<boolean> {
        return this.router.navigate(['landing-page'], extras);
    }

    toCaseworkerLandingPage() {
        return this.router.navigate(['caseworker', 'landing-page']);
    }

    toLoginPage(extras?: NavigationExtras): Promise<boolean> {
        return this.router.navigate(['login'], extras);
    }

    toLoginUrlTree(returnUrl): UrlTree {
        return this.router.createUrlTree(['login'], {
            queryParams: { returnUrl: returnUrl },
        });
    }

    toCreateUserPage(extras?: NavigationExtras): Promise<boolean> {
        return this.router.navigate(['create-user'], extras);
    }

    toApplicantProfilePage(extras?: NavigationExtras): Promise<boolean> {
        return this.router.navigate([this.base, 'profile'], extras);
    }

    toApplicantCasesPage(extras?: NavigationExtras): Promise<boolean> {
        return this.router.navigate([this.base, 'my-cases'], extras);
    }

    toApplicantCorrespondencePage(extras?: NavigationExtras): Promise<boolean> {
        return this.router.navigate([this.base, 'correspondence'], extras);
    }

    toApplicationCorrespondencePage(uuid: string, extras?: NavigationExtras): Promise<boolean> {
        return this.router.navigate([this.base, 'application', uuid, 'correspondence'], extras);
    }

    toApplication(uuid: string, extras?: NavigationExtras): Promise<boolean> {
        return this.router.navigate([this.base, 'application', uuid], extras);
    }

    toChooseApplicationSchemaPage(extras?: NavigationExtras): Promise<boolean> {
        return this.router.navigate([this.base, 'choose-application-schema'], extras);
    }

    toApplicationPaymentRequests(params?: { [key: string]: string }) {
        if (params) {
            return this.router.navigate([this.base, 'payment-requests', 'list'], {
                queryParams: params,
            });
        }
        return this.router.navigate([this.base, 'payment-requests', 'list']);
    }

    toRequestPayment(params: { [key: string]: string }) {
        return this.router.navigate([this.base, 'payment-requests', 'request'], {
            queryParams: params,
        });
    }

    toAdministration(): Promise<boolean> {
        return this.router.navigate(['caseworker', 'administration']);
    }

    toBoardMeetingViewer(uuid?: string) {
        return this.router.navigate(['caseworker', 'board-meeting', 'viewer'], { queryParams: { uuid: uuid } });
    }

    toUrl(url: string) {
        return this.router.navigateByUrl(url);
    }
}
