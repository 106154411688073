export class CategoryDto {
    public static fromApiResponse(response: { uuid: string; is_deletable: boolean }): CategoryDto {
        return new CategoryDto(response.uuid, response.is_deletable);
    }

    public static toCategoryUuid(category: CategoryDto): string {
        return category.uuid;
    }

    constructor(public readonly uuid: string, public readonly isDeletable: boolean) {}
}
