<div *ngIf="showPopup" class="app-update-banner">
    <h2 class="app-update-banner-header">
        {{ 'general' | translate : 'new-version-available' }}
    </h2>
    <p class="app-update-banner-text">
        {{ 'general' | translate : 'new-version-information' }}
    </p>
    <button type="button" class="app-update-banner-close" (click)="showPopup = false">&times;</button>
    <div class="app-update-banner-actions">
        <button type="button" class="app-update-banner-button" (click)="activateNewVersionAndReload()">
            {{ 'general' | translate : 'reload' }}
        </button>
    </div>
</div>

<fonda-loader *ngIf="displayLoading"></fonda-loader>
