import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'pwa-attachments-uploader',
    templateUrl: 'pwa-attachments-uploader.component.html',
    styleUrls: ['pwa-attachments-uploader.component.scss'],
})
export class PwaAttachmentsUploaderComponent {
    @Input() attachments: Array<{
        uuid: string;
        file_name: string;
        file_size: string;
    }> = [];
    @Input() readonly: boolean = false;
    @Output() delete = new EventEmitter<string>();
    @Output() add = new EventEmitter<any>();
    @Output() reset = new EventEmitter<any>();
    @Input() mandatory: boolean = false;
    @Input() hasReset: boolean = true;

    constructor() {}

    onDelete(uuid) {
        this.delete.emit(uuid);
    }

    onAdd(event) {
        this.add.emit(event);
    }

    onReset() {
        this.reset.emit();
    }
}
