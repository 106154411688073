import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslationDescriptor } from './translation-descriptor';
import { ILanguage, LanguageStoreService } from '../../services/language-store.service';
import { TranslationEditorValue } from './translation-editor-value';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { TranslateService } from '../translation/translate.service';

@Component({
    selector: 'translation-editor',
    templateUrl: './translation-editor.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TranslationEditorComponent), multi: true }],
})
export class TranslationEditorComponent implements OnChanges, OnDestroy, ControlValueAccessor {
    @Input() translations: Array<TranslationDescriptor> = [];
    @Input() translationsPrefix: string[] = [];
    @Output() stateChange = new EventEmitter<string>();

    form = new FormGroup({});

    private _disableValueUpdate = false;
    private _onChangeSub = Subscription.EMPTY;
    private _stateChangeSub = Subscription.EMPTY;

    private _onChange: (value: any) => void;
    private _onTouched: () => void;

    constructor(
        private languageStoreService: LanguageStoreService,
        private translateService: TranslateService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this._onChange = () => {};
        this._onTouched = () => {};
    }

    get languages(): readonly ILanguage[] {
        return this.languageStoreService.getLanguageList();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.translations) {
            this.recreateForm();
        }
    }

    ngOnDestroy(): void {
        this._onChangeSub.unsubscribe();
        this._stateChangeSub.unsubscribe();
    }

    writeValue(obj: any): void {
        if (!this._checkForCorrectType(obj)) return;
        const arr: Array<TranslationEditorValue> = obj;

        this._disableValueUpdate = true;
        arr.forEach(value => {
            const langGroup = this.form.get(value.language) as FormGroup;
            if (langGroup) {
                const control = langGroup.get(value.name) as FormControl;
                if (control) {
                    control.setValue(value.value);
                }
            }
        });
        this._disableValueUpdate = false;

        this.changeDetectorRef.markForCheck();
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
        this._onChange(this.mapFormValueToTranslationEditorValue(this.form.value));
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    private mapFormValueToTranslationEditorValue(value) {
        const arr: Array<TranslationEditorValue> = [];
        Object.keys(value).forEach(language => {
            Object.keys(value[language]).forEach(name => {
                arr.push({
                    language,
                    name,
                    value: value[language][name],
                });
            });
        });
        return arr;
    }

    private recreateForm() {
        const prev = this.form;
        this.form = new FormGroup({});

        this.languages.forEach(language => {
            const prevLanguageGroup = prev.get(language.name) as FormGroup;
            const languageGroup = new FormGroup({});
            this.translations.forEach(translationDescriptor => {
                const prevControl = (prevLanguageGroup && prevLanguageGroup.get(translationDescriptor.name)) || null;
                const translationValue = translationDescriptor.value
                    ? this.translateService.get(translationDescriptor.value, language.name)
                    : '';
                const value = (prevControl && prevControl.value) || translationValue || '';
                languageGroup.addControl(translationDescriptor.name, new FormControl(value));
            });
            this.form.addControl(language.name, languageGroup);
        });

        if (prev.disabled) this.form.disable();

        this._onChangeSub.unsubscribe();
        this._onChangeSub = this.form.valueChanges
            .pipe(
                filter(() => !this._disableValueUpdate),
                map(value => this.mapFormValueToTranslationEditorValue(value))
            )
            .subscribe(value => {
                this._onChange(value);
            });

        this._stateChangeSub.unsubscribe();
        this._stateChangeSub = this.form.statusChanges.subscribe(status => this.stateChange.emit(status));
    }

    private _checkForCorrectType(value: any) {
        if (!Array.isArray(value)) return false;

        const acceptableLanguages = this.languages.map(language => language.name);
        if (
            value.some(v => {
                return (
                    typeof v.name !== 'string' ||
                    typeof v.value !== 'string' ||
                    typeof v.language !== 'string' ||
                    !acceptableLanguages.includes(v.language)
                );
            })
        ) {
            return false;
        }

        return true;
    }
}
