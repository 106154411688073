<ng-container *isNotOnPWA>
    <section class="application-economy">
        <div *ngFor="let expense of value; let i = index" class="economy-multiline">
            <div class="form-item">
                <input
                    [id]="'application-economy-expense-new-title-' + i"
                    type="text"
                    (ngModelChange)="expense.title = $event; onChange()"
                    [ngModel]="expense.title"
                    placeholder=" "
                />
                <label [for]="'application-economy-expense-new-title-' + i">{{
                    recordUuid | translate : 'expense_title'
                }}</label>
                <div class="form-focus"></div>
                <div class="form-error"></div>
            </div>
            <div *ngIf="hasAttachments" class="form-item">
                <attachments-uploader
                    [attachments]="expense.attachments"
                    (delete)="deleteFile($event, i); onChange()"
                    (add)="addFile($event, i); onChange()"
                    [mandatory]="hasAttachmentsMandatory"
                ></attachments-uploader>
            </div>
            <div *ngIf="hasEconomyGroup" class="form-item">
                <fonda-select
                    [placeholder]="'general' | translate : 'choose'"
                    [options]="economyGroups"
                    [value]="expense.economy_group"
                    (valueChange)="expense.economy_group = $event; onChange()"
                    [boxDisplay]="false"
                    [multiple]="false"
                    [canUnselect]="false"
                >
                    <ng-container *fondaSelectOption="let uuid">
                        {{ uuid | translate }}
                    </ng-container>
                </fonda-select>
            </div>
            <div class="form-item valuta">
                <input
                    [id]="'application-economy-expense-new-amount-' + i"
                    type="text"
                    [fondaMoneyInput]="expense.amount"
                    (fondaMoneyInputChange)="expense.amount = $event; onChange()"
                    placeholder="0"
                />
                <label [for]="'application-economy-expense-new-amount-' + i"></label>
                <div class="form-focus"></div>
                <div class="form-error"></div>
            </div>
            <a class="close-btn red smaller" (click)="deleteLine(i); onChange()"><span class="icon-close"></span></a>
        </div>

        <a class="button color-blue" (click)="addLine(); onChange()">
            {{ 'general' | translate : 'add_new' }}
        </a>

        <div *ngIf="total" class="form-item readonly budget valuta">
            <input
                [id]="'application-economy-expense-wanted-amount'"
                type="text"
                [fondaMoneyInput]="totalValue"
                placeholder="0"
                [disabled]="true"
            />
            <label [for]="'application-economy-expense-wanted-amount'">{{
                recordUuid | translate : 'expenses_overall'
            }}</label>
            <div class="form-focus"></div>
            <div class="form-error"></div>
        </div>
    </section>
</ng-container>

<ng-container *isOnPWA>
    <div class="pwa-mobile-view">
        <div *ngFor="let expense of value; let i = index">
            <pwa-input [reset]="true" style="margin-bottom: 0.5rem">
                <input
                    pwaInput
                    [id]="'application-economy-expense-new-title-' + i"
                    type="text"
                    (ngModelChange)="expense.title = $event; onChange()"
                    [ngModel]="expense.title"
                    placeholder="{{ recordUuid | translate : 'expense_title' }}"
                />
            </pwa-input>

            <div *ngIf="hasAttachments" style="margin-bottom: 0.5rem">
                <pwa-attachments-uploader
                    [attachments]="expense.attachments"
                    [mandatory]="hasAttachmentsMandatory"
                    (delete)="deleteFile($event, i); onChange()"
                    (add)="addFile($event, i); onChange()"
                    (reset)="resetFiles(i); onChange()"
                ></pwa-attachments-uploader>
            </div>

            <div *ngIf="hasEconomyGroup" style="margin-bottom: 0.5rem">
                <pwa-select
                    [placeholder]="'general' | translate : 'choose'"
                    [options]="economyGroups"
                    [value]="expense.economy_group"
                    (valueChange)="expense.economy_group = $event; onChange()"
                    [multiple]="false"
                    [canUnselect]="false"
                >
                    <ng-container *pwaSelectOption="let uuid">
                        {{ uuid | translate }}
                    </ng-container>
                </pwa-select>
            </div>

            <pwa-input [reset]="true" [currency]="true" style="margin-bottom: 0.5rem">
                <input
                    pwaInput
                    [id]="'application-economy-expense-new-amount-' + i"
                    type="text"
                    [fondaMoneyInput]="expense.amount"
                    (fondaMoneyInputChange)="expense.amount = $event; onChange()"
                    placeholder="0"
                />
            </pwa-input>

            <a pwaButton color="pink" (click)="deleteLine(i)" style="width: 100%; margin-bottom: 0.5rem">
                {{ 'general' | translate : 'delete' }}
            </a>
        </div>
    </div>
    <div class="pwa-desktop-view">
        <div class="pwa-line pwa-line-head">
            <div class="pwa-column">{{ 'general' | translate : 'title' }}</div>
            <div *ngIf="hasAttachments" class="pwa-column">{{ 'general' | translate : 'attachments' }}</div>
            <div *ngIf="hasEconomyGroup" class="pwa-column">{{ 'general' | translate : 'economy_group' }}</div>
            <div class="pwa-column">{{ 'general' | translate : 'amount' }}</div>
        </div>

        <div class="pwa-row" *ngFor="let expense of value; let i = index">
            <div class="pwa-line">
                <div class="pwa-column">
                    <pwa-input>
                        <input
                            pwaInput
                            [id]="'application-economy-expense-new-title-' + i"
                            type="text"
                            (ngModelChange)="expense.title = $event; onChange()"
                            [ngModel]="expense.title"
                            placeholder="{{ recordUuid | translate : 'expense_title' }}"
                        />
                    </pwa-input>
                </div>
                <div *ngIf="hasAttachments" class="pwa-column">
                    <pwa-attachments-uploader
                        [attachments]="expense.attachments"
                        [hasReset]="false"
                        [mandatory]="hasAttachmentsMandatory"
                        (delete)="deleteFile($event, i); onChange()"
                        (add)="addFile($event, i); onChange()"
                        (reset)="resetFiles(i); onChange()"
                    ></pwa-attachments-uploader>
                </div>
                <div *ngIf="hasEconomyGroup" class="pwa-column">
                    <pwa-select
                        [placeholder]="'general' | translate : 'choose'"
                        [options]="economyGroups"
                        [value]="expense.economy_group"
                        (valueChange)="expense.economy_group = $event; onChange()"
                        [multiple]="false"
                        [canUnselect]="false"
                    >
                        <ng-container *pwaSelectOption="let uuid">
                            {{ uuid | translate }}
                        </ng-container>
                    </pwa-select>
                </div>
                <div class="pwa-column">
                    <pwa-input [currency]="true">
                        <input
                            pwaInput
                            [id]="'application-economy-expense-new-amount-' + i"
                            type="text"
                            [fondaMoneyInput]="expense.amount"
                            (fondaMoneyInputChange)="expense.amount = $event; onChange()"
                            placeholder="0"
                        />
                    </pwa-input>
                </div>
            </div>
            <div class="pwa-row-button">
                <a pwaButton color="pink" (click)="deleteLine(i); onChange()">
                    <span class="icon-delete"></span>
                    {{ 'general' | translate : 'delete' }}
                </a>
            </div>
        </div>
    </div>

    <div class="pwa-add-button">
        <a pwaButton color="gray" (click)="addLine(); onChange()">
            {{ 'general' | translate : 'add_new' }}
        </a>
    </div>

    <pwa-alert *ngIf="total" type="success" class="is-flex-between">
        <span class="dark"> {{ recordUuid | translate : 'expenses_overall' }}: </span>
        <span>
            <strong>
                {{ numToStr(totalValue) | commasInInput }}
                <fonda-currency></fonda-currency>
            </strong>
        </span>
    </pwa-alert>
</ng-container>
