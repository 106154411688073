import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GrantedAmountObject } from '../granted-amount';
import { IGrantedAmountRecordConfigurationData } from '../../../../../shared/record-configuration/configurations/granted-amount-record-configuration/granted-amount-record-configuration.component';

@Component({
    selector: 'granted-amount-children',
    templateUrl: 'granted-amount-children.component.html',
    styleUrls: ['granted-amount-children.component.scss'],
})
export class GrantedAmountChildrenComponent {
    @Input() children: GrantedAmountObject[] = [];
    @Input() boardMeetings = [];
    @Input() recordUuid: string;
    @Input() configuration: IGrantedAmountRecordConfigurationData;
    @Input() economyGroups: Array<string> = [];

    @Output() split = new EventEmitter<void>();
}
