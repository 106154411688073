import { Pipe, PipeTransform } from '@angular/core';
import { WordService } from '../../../services/word-service';

@Pipe({
    name: 'commasInAmount',
})
export class CommasInAmountPipe implements PipeTransform {
    transform(word: string): string {
        return WordService.addCommas(word , true);
    }
}
