export class UploadedFondaFile {
    constructor(
        readonly uuid: string,
        readonly fileName: string,
        readonly mimeType: string,
        readonly size: number,
        readonly sequence?: number
    ) {}
    public headContent: string;
    public blob: Blob;
}
