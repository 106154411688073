import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'pluck',
})
export class PluckPipe implements PipeTransform {
    transform<T extends object, K extends keyof T>(value: T[], key: K): Array<T[K]> {
        if (!value) return null;
        return value.map(o => o[key]);
    }
}
