import { Component, ViewChild } from '@angular/core';
import { BaseRecordComponent, RecordMetadata } from '../../base-record.component';
import { FileUploadComponent } from '../../../basic-form-controls/file-upload/file-upload.component';
import { UploadedFondaFile } from '../../../../models/uploaded-fonda-file';
import { FileService } from '../../../../services/file.service';

export interface UploadRecordOutputElement {
    uuid: string;
    file_name: string;
    file_size: number;
}

export type UploadRecordOutput = UploadRecordOutputElement[];

@Component({
    selector: 'upload-record',
    templateUrl: 'upload-record.component.html',
    styleUrls: ['upload-record.component.scss'],
})
export class UploadRecordComponent extends BaseRecordComponent<UploadRecordOutput, null> {
    files: Array<UploadedFondaFile> = [];
    @ViewChild('fileUploadComponent') fileUpload: FileUploadComponent;

    constructor(private fileService: FileService) {
        super();
    }

    initRecord(metadata: RecordMetadata<UploadRecordOutput, null>) {}

    setValue(value: UploadRecordOutput) {
        this.files = value.map(
            ({ uuid, file_name, file_size }) => new UploadedFondaFile(uuid, file_name, '', file_size)
        );
    }

    getValue(): UploadRecordOutput {
        return this.files.map(file => ({ uuid: file.uuid, file_name: file.fileName, file_size: file.size }));
    }

    emitValue() {
        this.onChange.emit(this.getValue());
    }

    download(file: UploadedFondaFile) {
        this.fileService.downloadFile(file.uuid).then(res => {
            this.fileService.downloadFileToOwnMachine(res);
        });
    }

    isValid(): boolean {
        return !this.metadata.isRequired || !this.isEmpty();
    }

    isEmpty(): boolean {
        return this.files.length === 0;
    }

    addNewFile(uploadedNcfFile: UploadedFondaFile) {
        this.files = [...this.files, uploadedNcfFile];
        this.emitValue();
    }

    deleteFile(uuid: string) {
        this.files = this.files.filter(file => file.uuid !== uuid);
        this.emitValue();
    }
}
