import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaymentProfileDto } from '../../../../api/dto/payment-profile-dto/payment-profile-dto';
import { FondaApiService } from '../../../../api/fonda-api.service';
import { PopupService } from '../../../../shared/popup/popup.service';
import { IPaymentRequesterRecordConfigurationData } from '../../../../shared/record-configuration/configurations/payment-requester-record-configuration/payment-requester-record-configuration.component';
import { BaseRecordComponent, RecordMetadata } from '../../base-record.component';
import { PaymentRequesterRecordPopupComponent } from './payment-requester-record-dialog/payment-requester-record-popup.component';
import cloneDeep from 'lodash/cloneDeep';
import { Subscription } from 'rxjs';
import { PaymentStoreService } from '../../../../services/payment-store-service';
import { PaymentsBalanceService } from '../payment-record/payment-balance.service';
import { SaveApplicationService } from '../../../../services/save-application.service';
import {
    PaymentGrantedBlocksDto,
    PaymentRequesterDto,
    PaymentStatusDto,
    PaymentTypeDto,
} from '../payment-record/payment-types';
import { v4 as uuidv4 } from 'uuid';

let PAYMENT_ID = 0;

export interface PaymentRequesterRecordDto {
    payments: Array<PaymentRequesterDto>;
    grant_blocks: Array<PaymentGrantedBlocksDto>;
}

@Component({
    selector: 'payment-requester-record',
    templateUrl: 'payment-requester-record.component.html',
    styleUrls: ['payment-requester-record.component.scss'],
})
export class PaymentRequesterRecordComponent extends BaseRecordComponent<
    PaymentRequesterRecordDto,
    IPaymentRequesterRecordConfigurationData
> {
    value: {
        payments: Array<PaymentRequesterDto>;
        grant_blocks: Array<PaymentGrantedBlocksDto>;
    } = { payments: [], grant_blocks: [] };

    constructor(
        private popupService: PopupService,
        private activatedRoute: ActivatedRoute,
        private apiService: FondaApiService,
        private paymentStoreService: PaymentStoreService,
        private paymentsBalanceService: PaymentsBalanceService,
        private saveApplicationService: SaveApplicationService
    ) {
        super();
    }

    readonly applicationUuid = this.activatedRoute.snapshot.queryParams.uuid;

    get payments(): Array<PaymentRequesterDto> {
        return this.value.payments;
    }

    set payments(value: Array<PaymentRequesterDto>) {
        this.value.payments = value;
    }

    get grantedBlocks(): Array<PaymentGrantedBlocksDto> {
        return this.value.grant_blocks;
    }

    get configuration(): IPaymentRequesterRecordConfigurationData {
        return this.metadata.configuration;
    }

    get uuid(): string {
        return this.metadata.uuid;
    }

    get paymentStatuses(): PaymentStatusDto[] {
        if (!this.configuration.payment_statuses) return [];
        return this.configuration.payment_statuses;
    }

    getGrantedBlockChildren(parentUuid: string): Array<PaymentGrantedBlocksDto> {
        if (!parentUuid) return [];
        return this.value.grant_blocks.filter(gb => gb.parentUuid === parentUuid) || [];
    }

    paymentProfiles: Array<PaymentProfileDto> = [];

    grantedAmount; // var to calc singleBlock balance
    grantedAmountSub$: Subscription;

    getPaymentsByBlock(uuid) {
        return this.payments.filter(p => p.grant_identifier === uuid);
    }

    initRecord(metadata: RecordMetadata<PaymentRequesterRecordDto, IPaymentRequesterRecordConfigurationData>): void {
        this.apiService.getApplicationPaymentProfiles(this.applicationUuid).then(profiles => {
            this.paymentProfiles = [...profiles];
        });
        this.grantedAmountSub$ = this.paymentStoreService.grantedAmountPlanEvent.subscribe(grantedAmount => {
            this.grantedAmount = grantedAmount;
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.grantedAmountSub$.unsubscribe();
    }

    setValue(value): void {
        this.value = value;
        this.value.payments = this.value.payments.map(p => {
            p.unique_id = 'payment-' + PAYMENT_ID++;
            return p;
        });
        this.emitValue();
    }

    getValue(): PaymentRequesterRecordDto {
        const paymentsCopy = cloneDeep(this.payments).map(payment => {
            delete payment['balance'];
            delete payment['unique_id'];
            if (!this.configuration.has_attachments) delete payment['attachments'];
            if (!this.configuration.has_comments) delete payment['comment'];
            return payment;
        });
        return {
            grant_blocks: this.grantedBlocks,
            payments: paymentsCopy,
        };
    }

    isEmpty(): boolean {
        return !this.value;
    }

    isValid(): boolean {
        return true;
    }

    emitValue() {
        this.onChange.emit(this.getValue());
    }

    addPayment(block: PaymentGrantedBlocksDto | null) {
        this.popupService
            .spawnPopup(PaymentRequesterRecordPopupComponent, {
                payment: {
                    payment_identifier: uuidv4(),
                    name: null,
                    amount: null,
                    comment: null,
                    date: null,
                    attachments: [],
                    grant_identifier: block ? block.grant_identifier : null,
                    payment_profile: this.getDefaultPaymentProfile(),
                    type: PaymentTypeDto.PAID,
                    payment_status: this.getInitialPaymentStatus(),
                    unique_id: 'payment-' + PAYMENT_ID++,
                    is_originally_from_applicant: true,
                },
                uuid: this.uuid,
                isEdit: false,
                blockBalance: block
                    ? this.paymentsBalanceService.getBlockBalance(
                          this.payments,
                          this.grantedBlocks,
                          block.grant_identifier,
                          this.paymentStatuses
                      )
                    : this.paymentsBalanceService.getSingleBlockBalance(
                          this.payments,
                          this.grantedAmount,
                          this.paymentStatuses
                      ),
                config: this.configuration,
            })
            .subscribe((data: PaymentRequesterDto) => {
                if (data) {
                    this.payments = this.payments.concat(data);
                    this.emitValue();
                    this.saveApplicationService.save();
                }
            });
    }

    editPayment(payment) {
        this.popupService
            .spawnPopup(PaymentRequesterRecordPopupComponent, {
                payment: { ...payment },
                uuid: this.uuid,
                isEdit: true,
                blockBalance: payment.grant_identifier
                    ? this.paymentsBalanceService.getBlockBalance(
                          this.payments,
                          this.grantedBlocks,
                          payment.grant_identifier,
                          this.paymentStatuses
                      )
                    : this.paymentsBalanceService.getSingleBlockBalance(
                          this.payments,
                          this.grantedAmount,
                          this.paymentStatuses
                      ),
                config: this.configuration,
            })
            .subscribe((data: PaymentRequesterDto) => {
                if (data) {
                    this.payments = this.payments.map(p => {
                        if (p.unique_id === data.unique_id) {
                            return data;
                        }
                        return p;
                    });
                    this.emitValue();
                }
            });
    }

    deletePayment(payment) {
        this.payments = this.payments.filter(p => p.unique_id !== payment.unique_id);
        this.emitValue();
    }

    getDefaultPaymentProfile() {
        const profile = this.paymentProfiles.find(p => p.isDefault === true);
        return profile ? profile.uuid : null;
    }

    getInitialPaymentStatus() {
        if (!this.paymentStatuses.length) return null;
        const initialStatus = this.paymentStatuses.find(s => s.initial === true);
        return initialStatus ? initialStatus.name : null;
    }
}
