<ng-container *ngIf="languageList$ | async as languages">
    <ul *ngIf="languages.length > 1" class="language-select">
        <ng-container *ngIf="showFlagForLanguages; else noFlags">
            <ng-container *ngFor="let language of languages">
                <li *ngIf="language.number !== currentLanguage">
                    <a class="language" (click)="switchLang(language)" [attr.data-language]="language.abbr">
                        <img style="width: 25px; height: 20px" [src]="'/assets/flags/' + language.abbr + '.svg'" />
                    </a>
                </li>
            </ng-container>
        </ng-container>
        <ng-template #noFlags>
            <li *ngFor="let language of languages">
                <a class="language" [attr.data-language]="language.abbr" (click)="switchLang(language)">
                    <span
                        [class.active]="language.number === currentLanguage"
                        style="display: block; margin-bottom: 6px"
                    >
                        {{ language.abbr | uppercase }}
                    </span>
                </a>
            </li>
        </ng-template>
    </ul>
</ng-container>
