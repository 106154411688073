<ng-container *isNotOnPWA>
    <div class="title">
        {{ metadata.uuid | translate : 'title' }}
        <span *ngIf="metadata.isRequired && !isReadonly" class="mandatory">*</span>
    </div>

    <div class="line line-head is-readonly">
        <div *ngFor="let _ of metadata.configuration.columns; let column = index" class="column">
            <p>{{ metadata.uuid | translate : column.toString() }}</p>
        </div>
    </div>

    <div *ngFor="let columnControls of controls; let line = index" class="line" [class.is-readonly]="isReadonly">
        <div *ngFor="let columnControl of columnControls; let column = index" class="column">
            <ng-container [ngSwitch]="getColumnBehaviour(column)">
                <fonda-text-subrecord
                    *ngSwitchCase="'TextRecord'"
                    [formControl]="columnControl"
                    [configuration]="getColumnConfiguration(column)"
                ></fonda-text-subrecord>
                <fonda-integer-subrecord
                    *ngSwitchCase="'IntegerRecord'"
                    [formControl]="columnControl"
                    [configuration]="getColumnConfiguration(column)"
                ></fonda-integer-subrecord>
                <fonda-custom-list-subrecord
                    *ngSwitchCase="'CustomListRecord'"
                    [formControl]="columnControl"
                    [configuration]="getColumnConfiguration(column)"
                ></fonda-custom-list-subrecord>
                <fonda-backend-user-subrecord
                    *ngSwitchCase="'BackendUserRecord'"
                    [formControl]="columnControl"
                    [configuration]="getColumnConfiguration(column)"
                ></fonda-backend-user-subrecord>
            </ng-container>
        </div>
        <div class="column column-delete">
            <button
                *ngIf="!isReadonly"
                icon-btn="trash"
                color="danger"
                [outline]="true"
                size="smallest"
                type="button"
                (click)="removeLine(line)"
            ></button>
        </div>
    </div>

    <div *ngIf="form.controls.length === 0 && isReadonly" class="line">
        <div class="column column-info">
            <p>{{ 'general' | translate : 'none' }}</p>
        </div>
    </div>

    <div class="line line-add" *ngIf="!isReadonly">
        <button btn color="success" type="button" (click)="addLine()" size="small">
            {{ 'general' | translate : 'add_new' }}
        </button>
    </div>
</ng-container>

<ng-container *isOnPWA>
    <div style="margin-top: -20px"></div>
    <!-- decrease enormous :host margin-top for PWA -->
    <pwa-list-element>
        <pwa-labelled-text [mandatory]="metadata.isRequired && !isReadonly" [errorMessage]="errorMessage">
            <ng-container pwaLabelledTextLabel>
                {{ metadata.uuid | translate : 'title' }}
            </ng-container>
            <ng-container pwaLabelledTextContent>
                <div class="pwa-multiline-record-table">
                    <div #row class="pwa-line pwa-line-header">
                        <div *ngFor="let _ of metadata.configuration.columns; let column = index" class="pwa-column">
                            {{ metadata.uuid | translate : column.toString() }}
                        </div>
                    </div>
                    <div
                        #row
                        class="pwa-row"
                        *ngFor="let columnControls of controls; let line = index"
                        [class.readonly]="isReadonly"
                    >
                        <div class="pwa-line">
                            <div *ngFor="let columnControl of columnControls; let column = index" class="pwa-column">
                                <ng-container [ngSwitch]="getColumnBehaviour(column)">
                                    <fonda-text-subrecord
                                        *ngSwitchCase="'TextRecord'"
                                        [formControl]="columnControl"
                                        [configuration]="getColumnConfiguration(column)"
                                    ></fonda-text-subrecord>
                                    <fonda-integer-subrecord
                                        *ngSwitchCase="'IntegerRecord'"
                                        [formControl]="columnControl"
                                        [configuration]="getColumnConfiguration(column)"
                                    ></fonda-integer-subrecord>
                                    <fonda-custom-list-subrecord
                                        *ngSwitchCase="'CustomListRecord'"
                                        [formControl]="columnControl"
                                        [configuration]="getColumnConfiguration(column)"
                                    ></fonda-custom-list-subrecord>
                                    <fonda-backend-user-subrecord
                                        *ngSwitchCase="'BackendUserRecord'"
                                        [formControl]="columnControl"
                                        [configuration]="getColumnConfiguration(column)"
                                    ></fonda-backend-user-subrecord>
                                </ng-container>
                            </div>
                        </div>
                        <button
                            *ngIf="!isReadonly"
                            class="pwa-row-button"
                            pwaButton
                            color="pink"
                            (click)="removeLine(line)"
                        >
                            <span class="icon-delete"></span>
                            {{ 'general' | translate : 'delete' }}
                        </button>
                    </div>
                </div>
                <div *ngIf="form.controls.length === 0 && isReadonly">
                    {{ 'general' | translate : 'none' }}
                </div>
                <button
                    *ngIf="!isReadonly"
                    class="pwa-add-line-button"
                    type="button"
                    pwaButton
                    color="primary"
                    (click)="addLine()"
                >
                    {{ 'general' | translate : 'add_new' }}
                </button>
            </ng-container>
        </pwa-labelled-text>
    </pwa-list-element>
</ng-container>
