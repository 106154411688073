import { Pipe, PipeTransform } from '@angular/core';
import { CustomListValueDto } from '../../../../api/dto/custom-list';

@Pipe({
    name: 'filterCustomListValues',
})
export class FilterCustomListValuesPipe implements PipeTransform {
    transform(values: CustomListValueDto[], selectedValueUuid: string | null): CustomListValueDto[] {
        if (!Array.isArray(values)) return [];
        return values.filter(value => value.enabled || value.uuid === selectedValueUuid);
    }
}
