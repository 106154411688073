import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { CreateCustomListDto, CustomListDto, UpdateCustomListDto } from '../api/dto/custom-list';
import { BehaviorSubject } from 'rxjs';
import { FondaApiService } from '../api/fonda-api.service';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { TranslateService } from '../shared/translation/translate.service';

@Injectable({
    providedIn: 'root',
})
export class CustomListDataService {
    private loaded = false;
    private readonly loading = new BehaviorSubject<boolean>(false);
    private readonly storage = new BehaviorSubject<CustomListDto[]>([]);
    private readonly load = new Subject();

    constructor(private readonly api: FondaApiService, private translateService: TranslateService) {
        this.load
            .pipe(
                tap(() => this.loading.next(true)),
                switchMap(() => this.api.getCustomLists()),
                tap(() => this.loading.next(false))
            )
            .subscribe(list => {
                this.storage.next(list);
            });
    }

    get loading$(): Observable<boolean> {
        return this.loading.asObservable();
    }

    getCustomLists(): Observable<CustomListDto[]> {
        this.initialLoad();
        return this.storage.asObservable();
    }

    getCustomListByUuid(uuid: string): Observable<CustomListDto | null> {
        this.initialLoad();
        const compareUuid = (cl: CustomListDto) => cl.uuid === uuid;
        return this.storage.pipe(
            filter(ls => ls.some(compareUuid)),
            map(ls => ls.find(compareUuid))
        );
    }

    create(data: CreateCustomListDto): Observable<string> {
        return this.api.postCustomList(data).pipe(
            tap(() => this.forceLoad()),
            switchMap(value => this.reloadTranslations(value))
        );
    }

    update(dto: UpdateCustomListDto): Observable<CustomListDto> {
        return this.api.patchCustomList(dto).pipe(
            tap(updated => this.updateElement(updated)),
            switchMap(value => this.reloadTranslations(value))
        );
    }

    private initialLoad() {
        if (!this.loaded) {
            this.loaded = true;
            this.load.next();
        }
    }

    private forceLoad() {
        this.load.next();
    }

    private updateElement(dto: CustomListDto): CustomListDto[] {
        const collection = [...this.storage.getValue()];

        const index = collection.findIndex(list => list.uuid === dto.uuid);
        if (index !== -1) {
            collection.splice(index, 1, dto);
        } else {
            collection.push(dto);
        }

        this.storage.next(collection);
        return collection;
    }

    private reloadTranslations<T>(value: T): Observable<T> {
        return this.translateService.reload(value);
    }
}
