<ng-container *isNotOnPWA>
    <div class="economy-readonly-item" [style.padding-top]="'10px'">
        <p>{{ recordUuid | translate : label }}:</p>
        <div>
            <span class="valuta">{{ numToStr(value.total) | commasInInput }}</span>
        </div>
    </div>
</ng-container>

<ng-container *isOnPWA>
    <pwa-alert type="success" class="is-flex-between" style="margin-top: 0.5rem">
        <span class="dark"> {{ recordUuid | translate : label }}: </span>
        <span>
            <strong>
                {{ numToStr(value.total) | commasInInput }}
                <fonda-currency></fonda-currency>
            </strong>
        </span>
    </pwa-alert>
</ng-container>
