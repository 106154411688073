import { Component, EventEmitter, ViewChild, Input, Output, ChangeDetectorRef, OnInit } from '@angular/core';
import { FileService } from '../../../services/file.service';
import { UploadedFondaFile } from '../../../models/uploaded-fonda-file';
import { FondaApiGlobalVariables } from '../../../api/fonda-api-global-variables';
import { FondaFile } from '../../../models/fonda-file';

let UNIQUE_NUM = 0;
@Component({
    selector: 'file-upload-component',
    templateUrl: 'file-upload.component.html',
})
export class FileUploadComponent implements OnInit {
    public errorMessage = '';
    readonly uniqueID: string = (UNIQUE_NUM++).toString();
    public loading: boolean;
    public loadingProgress = 0;
    public fileName: string;
    public fileMBSize: number;

    @ViewChild('fileInput', { static: true }) public fileInput;

    @Input() showLoader = false;
    @Input('mimeTypes')
    public set mimeTypes(mimeTypes: Array<string>) {
        this._allowedMimeTypes = mimeTypes;
    }
    @Input()
    public set fileUuid(uuid: string) {
        if (uuid) {
            this._fileUuid = uuid;
            this.fileService.downloadMetaFile(uuid).then(file => {
                this.fileName = file.fileName;
                this.fileMBSize = Math.round((file.size * 100) / 1000000) / 100;
                this.loadingProgress = 100;
            });
        }
    }

    @Output('fileAdded') fileAdded = new EventEmitter<UploadedFondaFile>();

    private _fileUuid: string | null;
    private _processingFile: FondaFile | null;
    private _allowedMimeTypes: Array<string> = [
        'image/jpeg', // jpeg
        'image/jpg', // jpg
        'image/png', // png
        'image/tiff', // tiff
        'text/plain', // txt
        'application/pdf', // pdf
        'application/msword', // doc
        'application/octet-stream', // binary
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
        'application/vnd.ms-excel', // xls
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
        'application/vnd.oasis.opendocument.text', // odt
        'application/vnd.oasis.opendocument.spreadsheet', // ods
        'application/zip', // zip file
    ];

    constructor(
        private fileService: FileService,
        private globalVariables: FondaApiGlobalVariables,
        private changeRef: ChangeDetectorRef
    ) {
        this.globalVariables.uploadingFileProgress.subscribe(value => {
            this.loadingProgress = value;
        });
    }

    public get allowedMimeTypes(): Array<string> {
        return this._allowedMimeTypes;
    }

    public get processingFile(): FondaFile {
        return this._processingFile;
    }

    public ngOnInit() {
        this.changeRef.detectChanges();
    }

    public forceAddFileClick() {
        this.fileInput.nativeElement.click();
    }

    public resetInput() {
        this.fileInput.nativeElement.value = '';
    }

    public onFileUpload(uploadEvent): void {
        const files: Array<File> = this.getFilesFromEvent(uploadEvent);
        this.errorMessage = '';

        for (const file of Object.values(files)) {
            this.loading = true;
            this.fileService
                .loadFile(file)
                .then(ncfFile => {
                    this.resetInput();
                    this.fileName = ncfFile.fileName;
                    this.fileMBSize = Math.round((ncfFile.size * 0.75 * 10) / 1000000) / 10;
                    this.fileService
                        .uploadFile(ncfFile)
                        .then(uploadedNcfFile => {
                            this.fileService.downloadMetaFile(uploadedNcfFile.uuid).then(_file => {
                                this._fileUuid = _file.uuid;
                                this.fileName = _file.fileName;
                                this.fileMBSize = Math.round((file.size * 100) / 1000000) / 100;
                                this.loading = false;
                                this.fileAdded.emit(_file);
                            });
                        })
                        .catch(error => {
                            this.errorMessage = error;
                            this.loading = false;
                        });
                })
                .catch(() => {
                    this.loading = false;
                    this.fileName = '';
                    this.fileMBSize = 0;
                    this.resetInput();
                });
        }
    }

    public getUuid(): string {
        return this._fileUuid;
    }

    public deleteFile() {
        this._fileUuid = '';
        this.fileName = '';
        this.fileMBSize = 0;
    }

    private getFilesFromEvent(uploadEvent): Array<File> {
        let file: Array<File>;
        if (uploadEvent.type === 'drop') {
            file = uploadEvent.dataTransfer.files;
        } else {
            file = uploadEvent.target.files;
        }
        return file;
    }
}
