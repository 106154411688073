import {
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    ElementRef,
    HostListener,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    ViewContainerRef,
} from '@angular/core';
import { DropdownListDirective } from './dropdown-list.directive';
import {
    DROPDOWN_LIST,
    DROPDOWN_ELEMENT,
    DropdownContainerComponent,
} from './dropdown-container/dropdown-container.component';
import { Placement as PopperPlacement } from '@popperjs/core';
import { take } from 'rxjs/operators';

@Directive({
    selector: '[dropdownTriggerFor]',
})
export class DropdownTriggerDirective implements OnChanges, OnDestroy {
    @Input() dropdownTriggerFor?: DropdownListDirective;
    @Input() dropdownPlacement: PopperPlacement;
    private renderedContainer: ComponentRef<DropdownContainerComponent> | null = null;
    private readonly containerFactory: ComponentFactory<DropdownContainerComponent>;

    constructor(
        public readonly elementRef: ElementRef<HTMLElement>,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly parentInjector: Injector,
        componentFactoryResolver: ComponentFactoryResolver
    ) {
        this.containerFactory = componentFactoryResolver.resolveComponentFactory(DropdownContainerComponent);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.dropdownTriggerFor) {
            this.close();
        }

        if (changes.dropdownPlacement && this.renderedContainer) {
            this.renderedContainer.instance.placement = changes.dropdownPlacement.currentValue;
        }
    }

    ngOnDestroy() {
        this.close();
    }

    @HostListener('click')
    handleClick() {
        if (this.renderedContainer) this.close();
        else this.open();
    }

    private close() {
        if (this.renderedContainer) {
            this.viewContainerRef.remove(this.viewContainerRef.indexOf(this.renderedContainer.hostView));
            this.renderedContainer = null;
        }
    }

    private open() {
        if (this.renderedContainer) throw new Error('Template already mounted, first unmount template!');
        if (!this.dropdownTriggerFor) throw new Error('DropdownTriggerDirective#mount() called without the template!');

        const injector = Injector.create({
            providers: [
                { provide: DROPDOWN_LIST, useValue: this.dropdownTriggerFor.template, multi: false },
                { provide: DROPDOWN_ELEMENT, useValue: this.elementRef, multi: false },
            ],
            parent: this.parentInjector,
        });

        this.renderedContainer = this.viewContainerRef.createComponent(
            this.containerFactory,
            this.viewContainerRef.length,
            injector
        );
        this.renderedContainer.instance.placement = this.dropdownPlacement;
        this.renderedContainer.instance.close.pipe(take(1)).subscribe(() => {
            this.close();
        });
    }
}
