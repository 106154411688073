import { Inject, Injectable } from '@angular/core';
import { FileService } from './file.service';

export class PdfToken {
    constructor(public displayName: string, public content: string, public origin: string) {}
}

@Injectable()
export class CorrespondencePdfService {
    constructor(@Inject(FileService) private fileService: FileService) {}

    public getFileName(str: string): string {
        return (
            this.transformToSnakeCase(str) +
            '_' +
            new Date().getFullYear() +
            (new Date().getMonth() + 1) +
            new Date().getDate()
        );
    }

    public transformToSnakeCase(str: string): string {
        return str
            .replace(/\.?([A-Z])/g, function (x, y) {
                return '_' + y.toLowerCase();
            })
            .replace(/^_/, '')
            .replace(/ /g, '_');
    }

    public async encodeTokens(data: string): Promise<{ value: string; tokens: Array<PdfToken> }> {
        // get tokens from the string
        const tokens = [];
        const tokensRegex = /{~.*?~}/g;
        let match;
        do {
            match = tokensRegex.exec(data);
            if (match) {
                tokens.push(match[0]);
            }
        } while (match);

        // resolve tokens
        const resolvedTokens = await Promise.all(
            tokens.map(token => {
                const fileReg = /^{~file\.(.*)~}$/;
                if (fileReg.test(token)) {
                    // this is a file
                    const uuid = fileReg.exec(token)[1];
                    return this.fileService.downloadFileWithBase64(uuid).then(res => {
                        return new PdfToken(
                            res.fileName,
                            `<img style="width: 150px;" alt="" src="${res.headContent}" />`,
                            token
                        );
                    });
                } else {
                    return new PdfToken(token, token, token);
                }
            })
        );

        let returnValue = data;
        resolvedTokens.forEach(token => {
            returnValue = returnValue.replace(token.origin, `[[${token.displayName}]]`);
        });

        return {
            value: returnValue,
            tokens: resolvedTokens,
        };
    }

    public decodeTokenToOriginValues(data: string, tokens: Array<PdfToken>): string {
        let returnValue = data;
        tokens.forEach(token => {
            returnValue = returnValue.replace(`[[${token.displayName}]]`, token.origin);
        });
        return returnValue;
    }

    public decodeTokensWithContentValue(data: string, tokens: Array<PdfToken>): string {
        let returnValue = data;
        tokens.forEach(token => {
            returnValue = returnValue.replace(`[[${token.displayName}]]`, token.content);
        });
        return returnValue;
    }
}
