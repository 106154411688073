import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'counterArray',
})
export class CounterArrayPipe implements PipeTransform {
    transform(value: number, start: number = 0): number[] {
        if (typeof value !== 'number' || Number.isNaN(value)) return [];
        return new Array(value - start).fill(0).map((_, i) => i + start);
    }
}
