import { CorrespondenceTemplateManualDto } from '../../api/dto/correspondence-dto/correspondence-template-manual-dto';
import { CorrespondencePopupModel } from '../../correspondence/correspondence-popup/correspondence-popup-model';
import { TranslateService } from '../../shared/translation/translate.service';

export class CorrespondenceNamingService {
    public getModel(
        translateService: TranslateService,
        dto: CorrespondenceTemplateManualDto
    ): CorrespondencePopupModel {
        return new CorrespondencePopupModel(
            dto.uuid,
            dto.refUuid,
            translateService.get([dto.uuid, 'name']),
            translateService.get([dto.uuid, 'subject']),
            translateService.get([dto.uuid, 'body']),
            translateService.get([dto.uuid, 'pdf_body']),
            dto.parentUuid,
            dto.statusTransition,
            dto.staticTokens,
            dto.files.filter(file => file.language == translateService.getCurrentLanguageName()).map(file => file.uuid),
            dto.pdfGeneratorRecordUuid
        );
    }
}
