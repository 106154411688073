import { FiltersDto } from '../dto/search-dto/filters-dto';
import { GroupFiltersDto } from '../dto/search-dto/group-filters-dto';

export class CallSearchMapper {
    public static getFilterJsonFromFilterObject(filters: FiltersDto): object {
        const jsonApplicationFilters = Array<any>();
        filters.filters.forEach(filter => {
            jsonApplicationFilters.push({
                reference: filter.reference,
                reference_type: filter.referenceType,
                params: {
                    type: filter.params.type,
                    values: filter.params.values,
                },
                projection: filter.projectionType,
            });
        });

        const jsonColumns = Array<any>();
        filters.columns.forEach(column => {
            jsonColumns.push({
                reference: column.reference,
                reference_type: column.referenceType,
                projection: column.projectionType,
            });
        });

        const jsonOrderBy = Array<any>();
        filters.orderBy.forEach(orderBy => {
            if (orderBy) {
                jsonOrderBy.push({
                    reference: orderBy.reference,
                    reference_type: orderBy.referenceType,
                    order_type: orderBy.orderType,
                    projection: orderBy.projectionType,
                });
            }
        });

        return {
            mass_text_search_filter: filters.massTextSearchFilter,
            application_template_uuid: filters.applicationTemplateUuid,
            filters: jsonApplicationFilters,
            columns: jsonColumns,
            order_by: jsonOrderBy,
        };
    }

    public static GetGroupSearchDto(filters: GroupFiltersDto): any {
        const jsonApplicationFilters = Array<any>();
        filters.filters.forEach(filter => {
            jsonApplicationFilters.push({
                reference: filter.reference,
                reference_type: filter.referenceType,
                params: {
                    type: filter.params.type,
                    values: filter.params.values,
                },
            });
        });

        const groupColumns: Array<any> = [];
        filters.groupColumns.forEach(column => {
            groupColumns.push({
                reference_type: column.referenceType,
                reference: column.reference,
            });
        });
        const group = {
            group_by: {
                reference_type: filters.groupBy.referenceType,
                reference: filters.groupBy.reference,
            },
            group_columns: groupColumns,
        };

        return {
            application_template_uuid: filters.applicationTemplateUuid,
            filters: jsonApplicationFilters,
            columns: [],
            order_by: [],
            group: group,
        };
    }
}
