<div class="overlay" style="z-index: 99999" [ngClass]="{ applicant: isApplicant }">
    <div class="loader-container" style="position: absolute" *ngIf="!isOpen"><div class="loader"></div></div>

    <div class="correspondence-popup" [ngClass]="{ active: isOpen }" (click)="$event.stopPropagation()" setMaxHeight>
        <div class="correspondence-container" [ngClass]="{ 'no-options': !showPdf() || onlyPdf }">
            <div class="flex items-center between">
                <h2>{{ popupTitle || ('messages' | translate : 'popup_title') }}</h2>
                <a class="close-btn smaller" (click)="clickClose()"><span class="icon-close"></span></a>
            </div>
            <basic-select
                *ngIf="!hideSubjectDropDown && templateNames?.length > 0"
                [choosenInput]="choosenTemplate"
                [placeholder]="'Skabelon'"
                [values]="templateNames"
                (getChoosen)="chooseManualTemplate($event)"
            ></basic-select>

            <mat-tab-group [(selectedIndex)]="selectedIndex">
                <mat-tab label="{{ 'messages' | translate : 'title' }}">
                    <form>
                        <div class="warning-box" *ngIf="showPdf() && !pdfFileUuid && !loading">
                            <div class="box-container">
                                <a class="icon"></a>
                                <div class="description">
                                    <h3>{{ 'messages' | translate : 'no_pdf_attached_warning_title' }}</h3>
                                    <p>{{ 'messages' | translate : 'no_pdf_attached_warning_text' }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="overlay" style="position: absolute" *ngIf="loading">
                            <div style="position: relative; height: 100%; width: 100%">
                                <div class="loader-container" style="position: absolute">
                                    <div class="loader"></div>
                                </div>
                            </div>
                        </div>
                        <div class="form-item" style="float: none">
                            <label>{{ 'messages' | translate : 'popup_subject_title' }}</label>
                            <input
                                type="text"
                                name="header"
                                [(ngModel)]="headerText"
                                placeholder="{{ 'messages' | translate : 'popup_subject_placeholder' }}"
                            />
                            <div class="form-focus"></div>
                        </div>
                        <div class="texting popup">
                            <div>
                                <label></label>
                                <text-editor
                                    (onFileAdding)="addFile($event)"
                                    [(text)]="bodyText"
                                    [class.with-warning]="showPdf() && !pdfFileUuid && !loading"
                                ></text-editor>
                            </div>

                            <app-files-table (fileDeleted)="onFileDelete($event)" #filesTable></app-files-table>

                            <fonda-checkbox
                                [label]="'messages' | translate : 'uniconta_allocation_of_grant'"
                            >
                                <input
                                    name="sendToUniconta"
                                    class="checkbox"
                                    type="checkbox"
                                    fondaCheckbox
                                    [(ngModel)]="sendToUnicontaChecked"
                                    checked
                                />
                            </fonda-checkbox>
                            <fonda-checkbox
                                [label]="'messages' | translate : 'send_message_and_letter'"
                            >
                                <input
                                    name="sendToMessage"
                                    class="checkbox"
                                    type="checkbox"
                                    fondaCheckbox
                                    [(ngModel)]="sendToMessageChecked"
                                    checked
                                />
                            </fonda-checkbox>
                            <div class="actions">
                                <a class="button" (click)="clickSend()">
                                    <span *ngIf="isApplicant">
                                        {{ 'general' | translate : 'send_message' }}
                                    </span>
                                    <span *ngIf="!isApplicant">
                                        {{ 'general' | translate : 'send_message_to' }} {{ accountEmail }}
                                    </span>
                                </a>
                                <b style="color: red">{{ errorMessage }}</b>
                            </div>
                        </div>
                    </form>
                </mat-tab>
                <mat-tab label="{{ 'messages' | translate : 'pdf_title' }}">
                    <correspondence-pdf #pdfComponent (pdfGenerate)="onGeneratePdf($event)"> </correspondence-pdf>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>
