import {
    Component,
    OnDestroy,
    ViewChildren,
    QueryList,
    ElementRef,
    AfterViewInit,
    Renderer2,
    HostListener,
} from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BaseRecordComponent, RecordMetadata } from '../../base-record.component';

export interface TextRecordConfiguration {
    min_length: number;
    max_length: number;
}

export interface CustomListRecordConfiguration {
    custom_list_uuid: string;
    display_mode: 'radio_buttons' | 'dropdown';
}

export type MultiLineRecordBehaviours = 'IntegerRecord' | 'TextRecord' | 'CustomListRecord' | 'BackendUserRecord';

export interface MultiLineRecordColumn {
    behaviour: MultiLineRecordBehaviours;
    sequence: number;
    configuration: TextRecordConfiguration | CustomListRecordConfiguration | null;
}

export interface MultiLineRecordConfiguration {
    columns: Array<MultiLineRecordColumn>;
}

export type SubRecordValue = { text: string } | { uuid: string | null } | [];

export interface ColumnValue {
    value: SubRecordValue;
    sequence: number;
}
export type LineValue = Array<ColumnValue>;
export type MultiLineRecordValue = Array<LineValue>;

@Component({
    selector: 'fonda-multi-line-record',
    templateUrl: './multi-line-record.component.html',
    styleUrls: ['./multi-line-record.component.scss'],
})
export class MultiLineRecordComponent
    extends BaseRecordComponent<MultiLineRecordValue, MultiLineRecordConfiguration>
    implements OnDestroy, AfterViewInit
{
    @ViewChildren('row') rows: QueryList<ElementRef>;
    form: FormArray = new FormArray([]);

    private changesSub: Subscription;

    constructor(private renderer: Renderer2) {
        super();
    }

    get numberOfColumns(): number {
        return this.metadata.configuration.columns.length;
    }

    get controls(): Array<Array<FormControl>> {
        return this.form.controls.map((array: FormArray) => array.controls as FormControl[]);
    }

    initRecord(metadata: RecordMetadata<MultiLineRecordValue, MultiLineRecordConfiguration>) {
        if (this.isReadonly) this.form.disable();
        this.initOnChanges();
    }

    ngAfterViewInit(): void {
        this.resizeRows();
    }

    ngOnDestroy() {
        super.ngOnDestroy();

        if (this.changesSub && !this.changesSub.closed) {
            this.changesSub.unsubscribe();
        }
    }

    getValue(): MultiLineRecordValue {
        return this.form.value.map(line => line.map((value, sequence) => ({ value, sequence })));
    }

    setValue(value: MultiLineRecordValue) {
        const numberOfLines = value.length;

        this.form = new FormArray([]);
        for (let line = 0; line < numberOfLines; line++) {
            this.form.push(this.createLineControl(line, value));
        }

        this.initOnChanges();

        if (this.isReadonly) this.form.disable();
    }

    initOnChanges(): void {
        if (this.changesSub && !this.changesSub.closed) this.changesSub.unsubscribe();
        this.changesSub = this.form.valueChanges.subscribe(() => {
            this.onChange.emit(this.getValue());
        });
    }

    setReadonly(isReadonly: boolean) {
        super.setReadonly(isReadonly);
        if (isReadonly) this.form.disable();
        else this.form.enable();
    }

    createLineControl(lineNumber: number, value?: MultiLineRecordValue): FormArray {
        const lineControl = new FormArray([]);
        for (let column = 0; column < this.numberOfColumns; column++) {
            const line = this.getValueForLineAndColumn(lineNumber, column, value);
            lineControl.push(new FormControl(line));
        }
        return lineControl;
    }

    getValueForLineAndColumn(line: number, column: number, value?: MultiLineRecordValue): SubRecordValue | null {
        if (!value) return this.getDefaultValueForColumn(column);

        const lineValue = value[line];
        if (!lineValue) return this.getDefaultValueForColumn(column);

        const columnValue = lineValue.find(v => v.sequence === column);
        if (!columnValue) return this.getDefaultValueForColumn(column);

        return columnValue.value;
    }

    getDefaultValueForColumn(column: number): SubRecordValue {
        const behaviour = this.getColumnBehaviour(column);
        switch (behaviour) {
            case 'TextRecord':
            case 'IntegerRecord':
                return { text: '' };
            case 'CustomListRecord':
                return { uuid: null };
            case 'BackendUserRecord':
                return [];
            default:
                throw new Error(
                    `Cannot get default value for column with unknown behaviour. Behaviour ${behaviour} given!`
                );
        }
    }

    getColumnBehaviour(column: number): MultiLineRecordBehaviours {
        return this.metadata.configuration.columns.find(def => def.sequence === column).behaviour;
    }

    getColumnConfiguration(column: number): TextRecordConfiguration | CustomListRecordConfiguration | null {
        return this.metadata.configuration.columns.find(def => def.sequence === column).configuration;
    }

    addLine() {
        this.form.push(this.createLineControl(this.form.controls.length));
        this.resizeRows();
    }

    removeLine(index: number) {
        this.form.removeAt(index);
    }

    isEmpty(): boolean {
        return this.getValue().length === 0;
    }

    isValid(): boolean {
        return this.metadata.isRequired ? this.getValue().length > 0 : true;
    }

    getColumnControls(columnIndex: number): FormControl[] {
        return (this.form.get([columnIndex]) as FormArray).controls as FormControl[];
    }

    resizeRows() {
        setTimeout(() => {
            if (this.rows.length) {
                this.rows.forEach(row => {
                    const width = row.nativeElement.scrollWidth;
                    if (width) {
                        this.renderer.setStyle(row.nativeElement, 'width', width + 'px');
                    }
                });
            }
        });
    }
}
