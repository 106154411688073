import { RecordAdapter } from '../search-page/record-adapter';
import { DefaultNonRecordFilters } from './search-service';
import { ApplicationTemplateDto } from '../../../api/dto/search-dto/application-template-dto';
import { RecordsInterface } from '../components/search-filters/records-interface';
import { SearchProjectionModel } from '../search-page/search-projection-model';
import { Injectable } from '@angular/core';
import { FondaApiService } from '../../../api/fonda-api.service';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '../../../shared/translation/translate.service';

export class FiltersOutput {
    constructor(
        readonly statuses: Array<string>,
        readonly postGrantStatuses: Array<string>,
        readonly possibleFilters: Array<RecordsInterface>
    ) {}
}

@Injectable({ providedIn: 'root' })
export class SearchFiltersService {
    constructor(private apiService: FondaApiService) {}

    public async getFilters(
        templateDto: ApplicationTemplateDto,
        translateService: TranslateService
    ): Promise<FiltersOutput> {
        let possibleFilters = [];
        templateDto.applicationRecord.forEach(record => {
            possibleFilters.push(new RecordAdapter(record, translateService));
        });

        possibleFilters = possibleFilters.concat(DefaultNonRecordFilters.GetArray(translateService)).sort();
        possibleFilters = possibleFilters.concat(await this.getNewFiltersToActions(translateService));

        return new FiltersOutput(
            await this.getStatuses(environment.APPLICATION_TEMPLATE_UUID),
            await this.getPostGrantStatuses(environment.APPLICATION_TEMPLATE_UUID),
            possibleFilters
        );
    }

    private async getNewFiltersToActions(translateService: TranslateService): Promise<Array<RecordsInterface>> {
        const _templates = await this.apiService.getApplicationTemplateCorrespondenceTemplate(
            environment.APPLICATION_TEMPLATE_UUID
        );
        const templates: Array<string> = _templates.filter(t => t.isLogged).map(t => t.uuid);
        return templates.map(templateUuid => {
            return {
                uuid: 'started-thread-from-parent-' + templateUuid,
                type: 'action_performed:correspondence_thread_started',
                title:
                    translateService.get([templateUuid, 'subject']) + ' - ' + translateService.get(['general', 'sent']),
                projections: [
                    new SearchProjectionModel(
                        'BooleanDefaultFalseRecord',
                        ['equals'],
                        'bool',
                        'main',
                        translateService.get([templateUuid, 'subject']) +
                            ' - ' +
                            translateService.get(['general', 'sent'])
                    ),
                ],
            };
        });
    }

    private async getStatuses(templateUuid: string): Promise<Array<string>> {
        const statuses: string[] = await this.apiService.getApplicationTemplateStatuses(templateUuid);
        return Array.from(new Set(statuses));
    }

    private async getPostGrantStatuses(templateUuid: string): Promise<Array<string>> {
        const postGrantStatuses: string[] = await this.apiService.getApplicationTemplatePostGrantStatuses(templateUuid);
        return Array.from(new Set(postGrantStatuses));
    }
}
