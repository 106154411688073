import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { AccountCaseworkerCorrespondencePopupService } from '../account-correspondence-popup/account-caseworker-correspondence-popup.service';
import { AccountCorrespondenceThreadData } from '../account-correspondence-threads/account-correspondence-threads-data';
import { CorrespondenceService } from '../correspondence.service';

@Component({
    selector: 'account-correspondence-teaser',
    templateUrl: './account-correspondence-teaser.component.html',
    providers: [AccountCaseworkerCorrespondencePopupService],
})
export class AccountCorrespondenceTeaserComponent {
    private static MAX_MESSAGES = 8;

    private _applicantUuid: string | null;
    public threads: Array<AccountCorrespondenceThreadData> = [];

    public constructor(private correspondenceService: CorrespondenceService, private changeRef: ChangeDetectorRef) {}

    @Input('applicantUuid') set applicantUuid(applicantUuid: string) {
        this._applicantUuid = applicantUuid;
        this.correspondenceService.fetchApplicantThreads(applicantUuid).then(threads => {
            this.threads = threads
                .sort(this.compareThreads)
                .splice(0, AccountCorrespondenceTeaserComponent.MAX_MESSAGES);
            this.changeRef.detectChanges();
        });
    }

    @Output() openCorrespondence = new EventEmitter<void>();

    public getLastMessageFirstWords(messages): string | undefined {
        if (!messages.length) return;
        const text = messages[messages.length - 1].body.split(' ').slice();
        if (text.length > 8) {
            return this.removeTags(text.slice(0, 8).join(' ')) + '...';
        }
        return this.removeTags(text.join(' '));
    }

    private removeTags(text: string): string {
        const div = document.createElement('div');
        div.innerHTML = text;
        return div.textContent;
    }

    private compareThreads(a: AccountCorrespondenceThreadData, b: AccountCorrespondenceThreadData) {
        return (
            new Date(b.messages[b.messages.length - 1].sentAt).getTime() -
            new Date(a.messages[a.messages.length - 1].sentAt).getTime()
        );
    }

    public onItemClick() {
        this.openCorrespondence.emit();
    }
}
