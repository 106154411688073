import { ChangeDetectionStrategy, Component, ContentChild, Input, ViewEncapsulation } from '@angular/core';
import { PwaRadioDirective } from './pwa-radio.directive';

@Component({
    selector: 'pwa-radio',
    templateUrl: 'pwa-radio.component.html',
    styleUrls: ['pwa-radio.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'pwa-radio',
    },
})
export class PwaRadioComponent {
    @ContentChild(PwaRadioDirective, { static: true }) radio: PwaRadioDirective;
    @Input() label: string;
}
