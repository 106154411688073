import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { fromEvent, identity, Subject } from 'rxjs';
import { filter, map, pairwise, skip, startWith, takeUntil, throttleTime } from 'rxjs/operators';

interface ScrollPosition {
    sH: number;
    sT: number;
    cH: number;
}

const DEFAULT_SCROLL_POSITION: ScrollPosition = {
    sH: 0,
    sT: 0,
    cH: 0,
};

@Directive({
    selector: '[appInfiniteScroller]',
})
export class InfiniteScrollerDirective implements AfterViewInit, OnDestroy {
    @Input() immediateCallback: boolean = false;
    @Input() scrollPercent: number = 70;

    @Output('appInfiniteScroller') scrolled = new EventEmitter<void>();

    private _destroyed = new Subject<void>();

    constructor(private elementRef: ElementRef) {}

    ngAfterViewInit(): void {
        fromEvent(this.elementRef.nativeElement, 'scroll', { passive: true })
            .pipe(
                map((e: any) => {
                    return {
                        sH: e.target.scrollHeight,
                        sT: e.target.scrollTop,
                        cH: e.target.clientHeight,
                    };
                }),
                pairwise(),
                filter(positions => {
                    return this.isUserScrollingDown(positions) && this.isScrollExpectedPercent(positions[1]);
                }),
                startWith([DEFAULT_SCROLL_POSITION, DEFAULT_SCROLL_POSITION]),
                this.immediateCallback ? identity : skip(1),
                takeUntil(this._destroyed),
                throttleTime(1000)
            )
            .subscribe(() => {
                this.scrolled.emit();
            });
    }

    ngOnDestroy(): void {
        this._destroyed.next(null);
        this._destroyed.complete();
    }

    private isUserScrollingDown(positions) {
        return positions[0].sT < positions[1].sT;
    }

    private isScrollExpectedPercent(position) {
        return (position.sT + position.cH) / position.sH > this.scrollPercent / 100;
    }
}
