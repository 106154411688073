<div class="loader-container" style="position: absolute">
    <div class="loader"></div>
</div>

<table class="search-result">
    <tbody>
        <tr>
            <th></th>
            <th
                *ngFor="let searchRes of searchResults && searchResults.applications[0].applicationValues"
                (click)="titleClick(searchRes)"
                [ngClass]="{ 'non-order-able': !searchRes.orderAble }"
            >
                {{ searchRes.title | removeCamelcase }}
                <a
                    class="order"
                    [ngClass]="{ desc: asc }"
                    *ngIf="
                        searchResults &&
                        searchResults.orderBy &&
                        getUuid(searchRes.uuid) === getUuid(searchResults.orderBy[0].reference)
                    "
                ></a>
            </th>
        </tr>

        <tr
            *ngFor="let searchRes of searchResults && searchResults.applications"
            (click)="redirectToReview(searchRes.applicationUuid)"
        >
            <td>
                <div
                    *ngIf="searchRes.lockedByOtherCaseworker"
                    title="Denne ansøgning redigeres af en anden sagsbehandler"
                    class="icon-locked-by-other"
                ></div>
            </td>
            <td *ngFor="let records of searchResults && searchRes.applicationValues">
                <ng-container *ngIf="!(records.value && records.value.getMonth)">
                    {{ records.value || records.value == 0 || records.value == '' ? records.value : '--' }}
                </ng-container>
                <ng-container *ngIf="records.value && records.value.getMonth">
                    {{ records.value ? (records.value | date : 'dd-MM-yyyy') : '--' }}
                </ng-container>
            </td>
        </tr>
    </tbody>
</table>
