import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { UploadedFondaFile } from '../../../../../models/uploaded-fonda-file';
import { FileService } from '../../../../../services/file.service';
import { UUID } from '../../../../../models/uuid';

@Component({
    selector: 'attachments-display',
    templateUrl: './attachments-display.component.html',
    styleUrls: ['./attachments-display.component.scss'],
})
export class AttachmentsDisplayComponent implements OnDestroy {
    @Input() readonly = false;
    @Input() attachments: Array<{
        uuid: string;
        file_name: string;
        file_size: string;
    }> = [];
    @Input() fontSize: number = 1;
    @Input() bold: boolean = false;
    @Output() delete = new EventEmitter<UUID>();
    @Input() mandatory = false;

    hidden: boolean = true;

    documentSub$: Subscription;

    constructor(private elementRef: ElementRef, private fileService: FileService) {
        this.documentSub$ = fromEvent(document, 'click').subscribe(event => {
            if (!this.elementRef.nativeElement.contains(event.target)) {
                this.hidden = true;
            }
        });
    }

    public downloadFile(file: UploadedFondaFile) {
        this.fileService.downloadFile(file.uuid).then(res => {
            this.fileService.downloadFileToOwnMachine(res);
        });
    }

    onDelete(uuid: UUID) {
        this.delete.emit(uuid);
        this.hidden = true;
    }

    ngOnDestroy() {
        this.documentSub$.unsubscribe();
    }
}
