import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'pwa-labelled-text',
    templateUrl: 'pwa-labelled-text.component.html',
    styleUrls: ['pwa-labelled-text.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'pwa-labelled-text',
        '[class.has-error]': 'hasError',
    },
})
export class PwaLabelledTextComponent {
    @Input() isTitle: boolean = false;
    @Input() mandatory: boolean = false;
    @Input() hasError: boolean = false;
    @Input() errorMessage: string;
}
