<ng-container *isNotOnPWA>
    <section class="application-economy">
        <div class="form-item budget valuta">
            <input
                id="application-economy-wanted-amount-apply"
                type="text"
                [fondaMoneyInput]="value"
                (fondaMoneyInputChange)="value = $event; onChange()"
                placeholder="0"
            />
            <label for="application-economy-wanted-amount-apply">
                {{ recordUuid | translate : 'financial_will_apply_for' }}
            </label>
            <div class="form-focus"></div>
            <div class="form-error"></div>
        </div>
    </section>
</ng-container>

<ng-container *isOnPWA>
    <pwa-input [reset]="true">
        <input
            pwaInput
            id="application-economy-wanted-amount-apply"
            type="text"
            [fondaMoneyInput]="value"
            (fondaMoneyInputChange)="value = $event; onChange()"
            placeholder="0"
        />
    </pwa-input>
</ng-container>
