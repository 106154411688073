<div class="pwa-labelled-text-label" data-testid="label">
    <div class="pwa-labelled-text-label-container">
        <ng-content select="[pwaLabelledTextLabel]"></ng-content>
        <span *ngIf="mandatory" class="mandatory">*</span>
    </div>
    <pwa-error-message *ngIf="errorMessage" [errorMessage]="errorMessage"></pwa-error-message>
</div>
<div class="pwa-labelled-text-content" [class.is-title]="isTitle" data-testid="content">
    <ng-content select="[pwaLabelledTextContent]"></ng-content>
    <div class="pwa-labelled-text-error-icon"></div>
</div>
