<div
    class="attachments-uploader"
    [style.padding-bottom]="readonly ? '6px' : ''"
    [class.border-blue]="!readonly && attachments && attachments.length"
>
    <attachments-display
        [readonly]="readonly"
        (delete)="onDelete($event)"
        [attachments]="attachments"
        [mandatory]="mandatory"
    ></attachments-display>
    <file-upload-component *ngIf="!readonly" (fileAdded)="onAdd($event)">
        <div class="upload-icon"></div>
    </file-upload-component>
</div>
