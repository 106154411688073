import { UploadedFondaFile } from '../../../models/uploaded-fonda-file';

export class BoardMeetingDto {
    constructor(
        readonly name: string,
        readonly fiscalYear: number,
        readonly date: string,
        readonly acceptingApplicationLastDate: string,
        readonly summary: UploadedFondaFile | null,
        readonly hasAgenda?: boolean
    ) {}

    public isSelectable: boolean;
    public isPublished: boolean;
    public uuid: string;
    public hasApplication: boolean;

    public getDate(){
        return this.getUTCOnlyDate(this.date);
    }

    public getAcceptingApplicationLastDate(){
        return this.getUTCOnlyDate(this.acceptingApplicationLastDate);
    }

    private getUTCOnlyDate(date: string){
        let date1 = new Date(date);
        return `${date1.getFullYear()}-${date1.getMonth() + 1}-${date1.getDate()}T00:00:00.000Z`;
    }

}
