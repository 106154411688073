import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    InjectionToken,
    NgZone,
    OnDestroy,
    Output,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { TemplatePortal } from '@angular/cdk/portal';
import { createPopper, Instance as PopperInstance, Placement as PopperPlacement } from '@popperjs/core';
import { element } from 'protractor';

export const DROPDOWN_ELEMENT = new InjectionToken<ElementRef<HTMLElement>>('DROPDOWN_ELEMENT');
export const DROPDOWN_LIST = new InjectionToken<TemplateRef<undefined>>('DROPDOWN_LIST');

@Component({
    selector: 'fonda-dropdown-container',
    templateUrl: './dropdown-container.component.html',
    styleUrls: ['./dropdown-container.component.scss'],
})
export class DropdownContainerComponent implements AfterViewInit, OnDestroy {
    readonly templatePortal: TemplatePortal<undefined>;
    private popperInstance: PopperInstance;

    @Output() close = new EventEmitter();

    constructor(
        @Inject(DROPDOWN_LIST) readonly dropdownList: TemplateRef<undefined>,
        @Inject(DROPDOWN_ELEMENT) private readonly dropdownElementRef: ElementRef<HTMLElement>,
        private readonly elementRef: ElementRef<HTMLElement>,
        private readonly ngZone: NgZone,
        viewContainerRef: ViewContainerRef
    ) {
        this.templatePortal = new TemplatePortal(dropdownList, viewContainerRef);
    }

    private _placement?: PopperPlacement;
    get placement(): PopperPlacement {
        return this._placement || 'bottom-start';
    }
    set placement(placement: PopperPlacement) {
        this._placement = placement;
        if (this.popperInstance) {
            this.ngZone.runOutsideAngular(() => {
                this.popperInstance.setOptions({
                    placement: this.placement,
                });
            });
        }
    }

    ngAfterViewInit() {
        this.ngZone.runOutsideAngular(() => {
            this.popperInstance = createPopper(this.dropdownElementRef.nativeElement, this.elementRef.nativeElement, {
                placement: this.placement,
                strategy: 'fixed',
            });
        });
    }

    ngOnDestroy() {
        this.popperInstance.destroy();
    }

    @HostListener('document:click', ['$event'])
    handleDocumentClick(event: MouseEvent): void {
        const clickedElement = event.target as Element;
        const containers = [this.elementRef.nativeElement, this.dropdownElementRef.nativeElement];

        if (!containers.some(container => container.contains(clickedElement))) {
            this.close.emit();
        }

        if (
            this.elementRef.nativeElement.contains(clickedElement) &&
            ['A', 'BUTTON'].includes(clickedElement.tagName)
        ) {
            this.close.emit();
        }
    }

    @HostListener('document:keydown', ['$event'])
    handleDocumentKeydown(event: KeyboardEvent): void {
        if (event.key === 'Escape') {
            this.close.emit();
        }
    }
}
