<ng-container *isNotOnPWA>
    <div>
        <ng-container *ngFor="let income of value">
            <div class="economy-readonly-item">
                <div class="economy-readonly-item-data">
                    <p>{{ income.title }}</p>
                    <p *ngIf="hasAttachments">
                        <attachments-display [readonly]="true" [attachments]="income.attachments"></attachments-display>
                    </p>
                    <p></p>
                </div>
                <div>
                    <span class="valuta">{{ numToStr(income.amount) | commasInInput }}</span>
                </div>
            </div>
        </ng-container>
    </div>

    <div *ngIf="total" class="economy-readonly-item" [style.padding-top]="'10px'">
        <p>{{ recordUuid | translate : 'incomes_overall' }}:</p>
        <div>
            <span class="valuta">{{ numToStr(totalValue) | commasInInput }}</span>
        </div>
    </div>
</ng-container>

<ng-container *isOnPWA>
    <div class="pwa-mobile-view">
        <pwa-list *ngFor="let income of value" style="margin-top: 0.5rem">
            <pwa-list-element>
                <pwa-labelled-text>
                    <ng-container pwaLabelledTextLabel>{{ 'general' | translate : 'title' }}</ng-container>
                    <ng-container pwaLabelledTextContent>{{ income.title }}</ng-container>
                </pwa-labelled-text>
            </pwa-list-element>
            <pwa-list-element *ngIf="hasAttachments">
                <pwa-labelled-text>
                    <ng-container pwaLabelledTextLabel>{{ 'general' | translate : 'attachments' }}</ng-container>
                    <ng-container pwaLabelledTextContent>
                        <pwa-attachments-display
                            [readonly]="true"
                            [attachments]="income.attachments"
                        ></pwa-attachments-display>
                    </ng-container>
                </pwa-labelled-text>
            </pwa-list-element>
            <pwa-list-element>
                <pwa-labelled-text>
                    <ng-container pwaLabelledTextLabel>{{ 'general' | translate : 'amount' }}</ng-container>
                    <ng-container pwaLabelledTextContent>
                        {{ numToStr(income.amount) | commasInInput }}
                        <fonda-currency></fonda-currency>
                    </ng-container>
                </pwa-labelled-text>
            </pwa-list-element>
        </pwa-list>
    </div>
    <div class="pwa-desktop-view">
        <div class="pwa-line pwa-line-head border">
            <div class="pwa-column">{{ 'general' | translate : 'title' }}</div>
            <div class="pwa-column" *ngIf="hasAttachments">{{ 'general' | translate : 'attachments' }}</div>
            <div class="pwa-column pwa-column-right">{{ 'general' | translate : 'amount' }}</div>
        </div>
        <div class="pwa-line border" *ngFor="let income of value">
            <div class="pwa-column">{{ income.title }}</div>
            <div class="pwa-column" *ngIf="hasAttachments">
                <pwa-attachments-display [readonly]="true" [attachments]="income.attachments"></pwa-attachments-display>
            </div>
            <div class="pwa-column pwa-column-right">
                {{ numToStr(income.amount) | commasInInput }}
                <fonda-currency></fonda-currency>
            </div>
        </div>
    </div>
    <pwa-alert *ngIf="total" type="success" class="is-flex-between" style="margin-top: 0.5rem">
        <span class="dark"> {{ recordUuid | translate : 'incomes_overall' }}: </span>
        <span>
            <strong>
                {{ numToStr(totalValue) | commasInInput }}
                <fonda-currency></fonda-currency>
            </strong>
        </span>
    </pwa-alert>
</ng-container>
