<div class="granted-amount-readonly-lines">
    <table>
        <tr>
            <th>
                {{ recordUuid | translate : 'name' }}
            </th>
            <th *ngIf="configuration.has_attachments">
                {{ 'general' | translate : 'attachments' }}
            </th>
            <th *ngIf="!configuration.has_partial_granting">
                {{ recordUuid | translate : 'amount' }}
            </th>
            <th *ngIf="configuration.has_partial_granting">
                {{ recordUuid | translate : 'original_full_amount' }}
            </th>
            <th *ngIf="economyGroups.length">
                {{ 'general' | translate : 'economy_group' }}
            </th>
            <th>
                {{ recordUuid | translate : 'board_meeting' }}
            </th>
            <th *ngIf="configuration.has_partial_granting"></th>
            <th *ngIf="configuration.has_partial_granting">
                {{ recordUuid | translate : 'amount' }}
            </th>
            <th></th>
        </tr>
        <ng-container *ngIf="grantedLines.length > 0">
            <ng-container *ngFor="let grantedLine of grantedLinesParents">
                <tr
                    granted-amount-readonly-line
                    [line]="grantedLine"
                    [configuration]="configuration"
                    [economyGroups]="economyGroups"
                    [boardMeetings]="boardMeetings"
                    [parent]="true"
                    [foldable]="isFoldable(grantedLine.uuid)"
                    [unfolded]="isUnfolded(grantedLine.uuid)"
                    (toggle)="toggle($event)"
                ></tr>
                <ng-container *ngIf="isUnfolded(grantedLine.uuid)">
                    <tr
                        *ngFor="let child of getGrantedLineChildren(grantedLine.uuid)"
                        granted-amount-readonly-line
                        [line]="child"
                        [configuration]="configuration"
                        [economyGroups]="economyGroups"
                        [boardMeetings]="boardMeetings"
                    ></tr>
                </ng-container>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="grantedLines.length == 0">
            <tr>
                <td>
                    <i>{{ recordUuid | translate : 'no_grants_found' }}</i>
                </td>
            </tr>
        </ng-container>
    </table>
</div>
