<ng-container *isNotOnPWA>
    <div *ngIf="!disabled; else disabledView" class="form-item">
        <input
            type="text"
            (input)="viewToModelUpdate($event.target.value)"
            (focus)="ontouched()"
            placeholder=" "
            #input
        />
        <div class="form-focus"></div>
    </div>

    <ng-template #disabledView></ng-template>
</ng-container>

<ng-container *isOnPWA>
    <ng-container *ngIf="!disabled">
        <pwa-input>
            <input
                pwaInput
                type="text"
                (input)="viewToModelUpdate($event.target.value)"
                (focus)="ontouched()"
                placeholder=" "
                #input
            />
        </pwa-input>
    </ng-container>
    <ng-container *ngIf="disabled">
        {{ value.text || '-' }}
    </ng-container>
</ng-container>
