import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    NgZone,
    OnDestroy,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { UploadedFondaFile } from '../../../../models/uploaded-fonda-file';
import { UUID } from '../../../../models/uuid';
import { FileService } from '../../../../services/file.service';
import { createPopper, Instance as PopperInstance } from '@popperjs/core';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'pwa-attachments-display',
    templateUrl: 'pwa-attachments-display.component.html',
    styleUrls: ['pwa-attachments-display.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PwaAttachmentsDisplayComponent implements OnDestroy {
    @Input() readonly = false;
    @Input() attachments: Array<{
        uuid: string;
        file_name: string;
        file_size: string;
    }> = [];
    @Input() mandatory: boolean = false;
    @Input() fontSize: string;
    @Output() delete = new EventEmitter<UUID>();
    @ViewChild('popup', { static: true }) popup: ElementRef<HTMLDivElement>;
    @ViewChild('label', { static: true }) label: ElementRef<HTMLDivElement>;

    hidden: boolean = true;
    documentSub$: Subscription;
    popperInstance?: PopperInstance;

    constructor(
        private elementRef: ElementRef,
        private fileService: FileService,
        private cdRef: ChangeDetectorRef,
        private ngZone: NgZone,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.documentSub$ = fromEvent(document, 'click').subscribe(event => {
            if (!this.elementRef.nativeElement.contains(event.target)) {
                this.hidden = true;
                this.destroyPopper();
                this.cdRef.markForCheck();
            }
        });
    }

    public downloadFile(file: UploadedFondaFile) {
        this.fileService.downloadFile(file.uuid).then(res => {
            this.fileService.downloadFileToOwnMachine(res);
        });
    }

    createPopper(): void {
        this.document.body.appendChild(this.popup.nativeElement);
        this.renderer.setStyle(this.popup.nativeElement, 'width', this.label.nativeElement.offsetWidth + 'px');
        this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                this.popperInstance?.destroy();
                this.popperInstance = createPopper(this.label.nativeElement, this.popup.nativeElement, {
                    placement: 'bottom-start',
                });
            });
        });
    }

    destroyPopper(): void {
        if (this.popperInstance && this.document.body.contains(this.popup.nativeElement)) {
            this.document.body.removeChild(this.popup.nativeElement);
        }
        this.popperInstance?.destroy();
        this.popperInstance = undefined;
    }

    show(e) {
        e.stopPropagation();
        this.hidden = !this.hidden;
        if (this.hidden) {
            this.destroyPopper();
        } else {
            this.createPopper();
        }
        this.cdRef.markForCheck();
    }

    onDelete(uuid: UUID) {
        this.delete.emit(uuid);
        this.hidden = true;
        this.destroyPopper();
        this.cdRef.markForCheck();
    }

    ngOnDestroy() {
        this.documentSub$.unsubscribe();
        this.destroyPopper();
    }
}
