import { ElementRef } from '@angular/core';

export function safeAddClass(elementRef: ElementRef, classToAdd: string) {
    if (elementRef && elementRef.nativeElement && elementRef.nativeElement.classList) {
        if ('add' in elementRef.nativeElement.classList) {
            elementRef.nativeElement.classList.add(classToAdd);
        }
    }
}

export function safeRemoveClass(elementRef: ElementRef, classToRemove: string): void {
    if (elementRef && elementRef.nativeElement && elementRef.nativeElement.classList) {
        if ('remove' in elementRef.nativeElement.classList) {
            elementRef.nativeElement.classList.remove(classToRemove);
        }
    }
}
