import { BackendUserData } from './backend-user-data';

export class CaseworkerFileDto {
    constructor(
        readonly applicationUuid: string,
        readonly resourceType: string,
        readonly resource: string,
        readonly uploader: BackendUserData,
        readonly deletable: true,
        readonly fileUuid: string,
        readonly fileName: string,
        readonly fileSize: number,
        readonly subResourceType: string,
        readonly sequence?: number
    ) {}
}
