import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewEncapsulation } from '@angular/core';
import { mixinColor } from '../common-behaviours/color';
import { mixinDisabled } from '../common-behaviours/disable';
import { safeAddClass } from '../common-behaviours/safe-classlist';

const _PwaButtonBase = mixinColor(
    mixinDisabled(
        class {
            constructor(public _elementRef: ElementRef) {}
        }
    )
);

@Component({
    selector: 'button[pwaButton]',
    templateUrl: './pwa-button.component.html',
    styleUrls: ['./pwa-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: ['type', 'disabled', 'color'],
    host: {
        '[attr.type]': 'type',
        '[attr.disabled]': 'disabled || null',
    },
    encapsulation: ViewEncapsulation.None,
})
export class PwaButtonComponent extends _PwaButtonBase {
    @Input() type: string = 'button';

    constructor(elementRef: ElementRef) {
        super(elementRef);
        safeAddClass(elementRef, 'pwa-button');
    }
}

@Component({
    selector: 'a[pwaButton]',
    templateUrl: './pwa-button.component.html',
    styleUrls: ['./pwa-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: ['type', 'disabled', 'color', 'tabIndex'],
    host: {
        '[attr.tabindex]': 'disabled ? -1 : (tabIndex || 0)',
        '[attr.disabled]': 'disabled || null',
        '(click)': 'preventClickIfDisabled($event)',
    },
    encapsulation: ViewEncapsulation.None,
})
export class PwaAnchorComponent extends PwaButtonComponent {
    @Input() tabIndex: number;

    constructor(elementRef: ElementRef) {
        super(elementRef);
    }

    preventClickIfDisabled(event: MouseEvent) {
        if (this.disabled) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
    }
}
