import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PwaOptionComponent } from './pwa-option/pwa-option.component';
import { PwaSelectComponent } from './pwa-select.component';

import { PwaSelectOptionDirective } from './pwa-select-option.directive';
import { TranslationModule } from '../../../shared/translation/translation.module';
import { FormsModule } from '@angular/forms';

const COMPONENTS = [PwaSelectComponent, PwaOptionComponent];

const DIRECTIVES = [PwaSelectOptionDirective];

@NgModule({
    declarations: [COMPONENTS, DIRECTIVES],
    imports: [CommonModule, TranslationModule, FormsModule],
    exports: [COMPONENTS, DIRECTIVES],
})
export class PwaSelectModule {}
