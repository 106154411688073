import { Component } from '@angular/core';
import { BaseRecordComponent, RecordMetadata } from '../../base-record.component';
import { FondaApiService } from '../../../../api/fonda-api.service';
import { AssigneeUserData } from './assignee-user-data';
import { CaseworkerApplicationDto } from '../../../../api/dto/caseworker-application-dto';
import { LocalStorageService } from '../../../../services/local-storage-service';
import { TranslateService } from '../../../translation/translate.service';
import { UuidValue } from '../../values.types';

@Component({
    selector: 'assignee-user-record',
    templateUrl: 'assignee-user-record.component.html',
})
export class AssigneeUserRecordComponent extends BaseRecordComponent<UuidValue, null> {
    assignee: string | null = null;
    caseworkers: AssigneeUserData[] = [];

    constructor(
        private apiService: FondaApiService,
        private applicationStorageService: LocalStorageService,
        private translateService: TranslateService
    ) {
        super();
    }

    get options(): string[] {
        const getUuid = (c: AssigneeUserData): string => c.uuid;
        if (this.isOnSearch) return this.caseworkers.map(getUuid);
        const initialValue = (!Array.isArray(this.metadata.initialValue) && this.metadata.initialValue.uuid) || null;
        return this.caseworkers.filter(c => c.active || c.uuid === initialValue).map(getUuid);
    }

    initRecord(metadata: RecordMetadata<UuidValue, null>) {
        this.fetchData();
    }

    getValue(): UuidValue {
        if (this.assignee === null) return [];
        return { uuid: this.assignee };
    }

    setValue(value: UuidValue) {
        this.assignee = (!Array.isArray(value) && value.uuid) || null;
    }

    emitValue() {
        this.onChange.emit(this.getValue());
    }

    chooseCaseworker(assignee: string | null) {
        this.assignee = assignee;
        this.emitValue();
    }

    isValid(): boolean {
        return this.metadata.isRequired && !this.isEmpty();
    }

    isEmpty(): boolean {
        return this.assignee === null;
    }

    getUsername(uuid: string): string {
        if (!uuid) return '';
        const caseworker = this.caseworkers.find(c => c.uuid === uuid);
        return caseworker ? caseworker.username : '';
    }

    private async fetchData() {
        const caseworkers = (await this.apiService.getBackendUsersCaseworker()).map(
            caseworker =>
                new AssigneeUserData(
                    caseworker.uuid,
                    caseworker.confirmed,
                    caseworker.createdDate,
                    caseworker.email,
                    caseworker.type,
                    caseworker.roles,
                    caseworker.username,
                    caseworker.initials,
                    caseworker.active
                )
        );

        if (this.applicationStorageService.isApplicationInformation()) {
            const applicationDto = this.applicationStorageService.getApplicationInformation();
            const applicantFullName =
                this.getApplicantFullName(applicationDto) + ' ' + this.translateService.get(['general', 'applicant']);
            caseworkers.push(
                new AssigneeUserData(
                    applicationDto.applicantUuid,
                    true,
                    applicationDto.createdAt,
                    '',
                    'applicant',
                    [],
                    applicantFullName,
                    '',
                    true
                )
            );
        }

        this.caseworkers = caseworkers;
    }

    private getApplicantFullName(caseworkerApplicationDto: CaseworkerApplicationDto): string {
        let fullName = '';
        caseworkerApplicationDto.applicantSections.forEach(section => {
            if (section.recordValues.find(value => value.type === 'FirstnameRecord')) {
                fullName =
                    section.recordValues.find(value => value.type === 'FirstnameRecord').value['text'] +
                    ' ' +
                    section.recordValues.find(value => value.type === 'LastnameRecord').value['text'];
            }
        });
        return fullName;
    }
}
