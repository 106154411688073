<ng-container *isNotOnPWA>
    <div *ngIf="!isReadonly" class="form-item" [ngClass]="{ half: metadata.size === 1 }">
        <input id="{{ metadata.uuid }}" type="text" [formControl]="control" placeholder=" " />
        <label for="{{ metadata.uuid }}">
            {{ metadata.title ? metadata.title : (metadata.uuid | translate : 'title') }}
            <span *ngIf="metadata.isRequired" class="mandatory">*</span>
        </label>
        <div class="form-focus"></div>
        <div class="form-error" [ngClass]="{ active: control.errors?.minlength }">
            Indsæt mindst {{ metadata.configuration.min_length }} tegn
        </div>
        <div class="form-error configuration active" *ngIf="control.value && metadata.configuration.max_length">
            {{ control.value.length }} / {{ metadata.configuration.max_length }}
        </div>
    </div>

    <a class="form-item" *ngIf="isReadonly" [ngClass]="{ full: metadata.size === 2 }">
        <p>{{ metadata.title ? metadata.title : (metadata.uuid | translate : 'title') }}</p>
        <span>{{ control.value || '-' }}</span>
        <div class="form-focus"></div>
        <div class="form-error"></div>
    </a>
</ng-container>

<ng-container *isOnPWA>
    <ng-container *ngIf="!isReadonly">
        <pwa-list-element [ngClass]="{ half: metadata.size === 1 }">
            <pwa-labelled-text
                [mandatory]="metadata.isRequired"
                [hasError]="
                    metadata.configuration.max_length && !!((control.dirty || control.touched) && control.errors)
                "
                [errorMessage]="errorMessage"
            >
                <ng-container pwaLabelledTextLabel>
                    {{ metadata.title ? metadata.title : (metadata.uuid | translate : 'title') }}
                </ng-container>
                <ng-container pwaLabelledTextContent>
                    <input
                        style="width: 80%"
                        [id]="metadata.uuid"
                        type="text"
                        [formControl]="control"
                        placeholder=" "
                    />
                    <div *ngIf="metadata.configuration.max_length" class="pwa-labelled-text-content-info">
                        <span class="pwa-labelled-text-mark-error">
                            {{ control.value ? control.value.length : 0 }}
                        </span>
                        / {{ metadata.configuration.max_length }}
                    </div>
                </ng-container>
            </pwa-labelled-text>
        </pwa-list-element>
    </ng-container>
    <ng-container *ngIf="isReadonly">
        <pwa-list-element [ngClass]="{ half: metadata.size === 1 }">
            <pwa-labelled-text>
                <ng-container pwaLabelledTextLabel>
                    {{ metadata.title ? metadata.title : (metadata.uuid | translate : 'title') }}
                </ng-container>
                <ng-container pwaLabelledTextContent>
                    {{ control.value || '-' }}
                </ng-container>
            </pwa-labelled-text>
        </pwa-list-element>
    </ng-container>
</ng-container>
