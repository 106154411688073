import { Directive, HostBinding, Input } from '@angular/core';

let uniqueId = 0;

@Directive({
    selector: 'input[pwaCheckbox]',
})
export class PwaCheckboxDirective {
    @HostBinding() type = 'checkbox';
    @Input() @HostBinding() id = 'pwa-checkbox-' + (++uniqueId).toString();
}
