import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SiteSettingsService } from '../../services/site-settings.service';

@Injectable()
export class CurrencyService {
    private currency$: BehaviorSubject<string> = new BehaviorSubject('');
    private currency: string;
    private readonly renderer2: Renderer2;

    constructor(
        private siteSettings: SiteSettingsService,
        private rendererFactory2: RendererFactory2,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.renderer2 = this.rendererFactory2.createRenderer(document.head, null);
        this.siteSettings.getSettingSingle('currency').subscribe(c => {
            this.currency = c.unwrapOr('kr');
            this.currency$.next(this.currency);
            this.updateCssCurrency();
        });
    }

    getCurrencyObservable(): Observable<string> {
        return this.currency$.asObservable();
    }

    getCurrency() {
        return this.currency;
    }

    private rootCurrency(): string {
        return `
            :root {
                --currency: '${this.currency}';
            }
        `;
    }

    private updateCssCurrency() {
        const styleEl = this.getHeadEl('site-setting-currency', 'style');
        const css = this.rootCurrency();
        this.renderer2.setProperty(styleEl, 'innerHTML', css);
    }

    private getHeadEl(id: string, elementType: string): HTMLElement {
        const el = this.document.getElementById(id);
        if (el) return el;

        const newEl = this.renderer2.createElement(elementType);
        this.renderer2.setAttribute(newEl, 'id', id);
        this.renderer2.appendChild(document.head, newEl);
        return newEl;
    }
}
