import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BaseRecordComponent, RecordMetadata } from '../../base-record.component';
import { Subscription } from 'rxjs/Subscription';

export type CompanyRecordValue = { is_company: boolean; company_name: string | null } | { boolean: boolean };

export interface CompanyRecordConfiguration {
    min_length: number;
    max_length: number;
    locked_on: null | 'company';
}

@Component({
    selector: 'company-record',
    templateUrl: 'company-record.component.html',
    styleUrls: ['company-record.scss'],
})
export class CompanyRecordComponent
    extends BaseRecordComponent<CompanyRecordValue, CompanyRecordConfiguration>
    implements OnDestroy, OnInit
{
    @ViewChild('companyInput') companyInput?: ElementRef;
    isCompany = new FormControl(false);
    companyName = new FormControl('');
    isCompanySub$ = Subscription.EMPTY;

    private changesSubscription: Subscription;

    get showCheckbox(): boolean {
        return this.metadata.configuration.locked_on !== 'company';
    }

    initRecord(metadata: RecordMetadata<CompanyRecordValue, CompanyRecordConfiguration>) {
        if (metadata.configuration.locked_on === 'company') {
            this.isCompany.setValue(true);
            this.isCompany.disable();
        }

        if (metadata.isRequired) {
            this.companyName.setValidators([Validators.required]);
        }

        this.changesSubscription = this.isCompany.valueChanges.subscribe(isCompany => {
            if (isCompany) {
                this.companyName.enable();
            } else {
                this.companyName.disable();
            }
        });

        this.registerControlChanges(this.isCompany);
        this.registerControlChanges(this.companyName);
    }

    ngOnInit() {
        if (this.showCheckbox) {
            this.isCompanySub$ = this.isCompany.valueChanges.subscribe(value => {
                if (value && this.companyInput) {
                    this.companyInput.nativeElement.focus();
                }
            });
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.changesSubscription.unsubscribe();
        if (this.isCompanySub$) this.isCompanySub$.unsubscribe();
    }

    isValid(): boolean {
        return this.companyName.valid && this.isCompany.valid;
    }

    isEmpty(): boolean {
        return this.companyName.value === '' && this.isCompany.value;
    }

    getValue(): CompanyRecordValue {
        if (this.isOnSearch) {
            return {
                boolean: this.isCompany.value,
            };
        }

        return {
            is_company: this.isCompany.value,
            company_name: this.isCompany.value ? this.companyName.value : null,
        };
    }

    setValue(value: CompanyRecordValue) {
        if ('boolean' in value) {
            this.isCompany.setValue(value.boolean);
        } else {
            this.isCompany.setValue(value.is_company);
            this.companyName.setValue(value.company_name);
        }
    }
}
