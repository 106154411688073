import { Component } from '@angular/core';
import { CaseworkerDto } from '../../../../api/dto/caseworker-dto';
import { FondaApiService } from '../../../../api/fonda-api.service';
import { BaseRecordComponent, RecordMetadata } from '../../base-record.component';
import { UuidValue } from '../../values.types';

@Component({
    selector: 'backend-user-record',
    templateUrl: 'backend-user-record.component.html',
    styleUrls: ['backend-user-record.component.scss'],
})
export class BackendUserRecordComponent extends BaseRecordComponent<UuidValue, null> {
    value: UuidValue;
    users: CaseworkerDto[] = [];

    constructor(private apiService: FondaApiService) {
        super();
    }

    get options(): string[] {
        return this.users.map(c => c.uuid);
    }

    get currentUser(): string | null {
        return Array.isArray(this.value) ? null : this.value.uuid;
    }

    initRecord(metadata: RecordMetadata<UuidValue, null>) {
        this.apiService.getBackendUsers().then(users => {
            this.users = users;
        });
    }

    setValue(value: UuidValue) {
        this.value = value;
    }

    getValue(): UuidValue {
        return this.value;
    }

    isValid(): boolean {
        return this.metadata.isRequired && !Array.isArray(this.value);
    }

    isEmpty(): boolean {
        return Array.isArray(this.value);
    }

    emitValue() {
        this.onChange.emit(this.getValue());
    }

    chooseUser(user: string | null) {
        if (user) {
            this.value = { uuid: user };
        } else {
            this.value = [];
        }
        this.emitValue();
    }

    getUsername(uuid: string): string {
        if (!uuid) return '';
        const user = this.users.find(c => c.uuid === uuid);
        return user ? user.username : '';
    }
}
