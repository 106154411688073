import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { safeAddClass, safeRemoveClass } from '../common-behaviours/safe-classlist';

@Component({
    selector: 'pwa-alert',
    templateUrl: 'pwa-alert.component.html',
    styleUrls: ['pwa-alert.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'pwa-alert',
    },
})
export class PwaAlertComponent implements OnChanges {
    @Input() type: 'success' | 'error' = 'success';

    constructor(private elementRef: ElementRef) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.type) {
            if (changes.type.previousValue) {
                safeRemoveClass(this.elementRef, changes.type.previousValue);
            }
            safeAddClass(this.elementRef, this.type);
        }
    }
}
