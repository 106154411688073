import { BoardMeetingAgendaDto } from '../dto/board-meeting/board-meeting-agenda-dto';
import { BoardMeetingApplicationDetailsDto } from '../dto/board-meeting/board-meeting-application-details-dto';
import { AgendaSectionsCategory } from '../dto/board-meeting/agenda-sections-category';
import { AgendaSectionsManual } from '../dto/board-meeting/agenda-sections-manual';
import { AgendaSectionsCategoryContentDto } from '../dto/board-meeting/agenda-sections-category-content-dto';
import { AgendaSectionsCategoryContentApplicationDto } from '../dto/board-meeting/agenda-sections-category-content-application-dto';
import { ShownReferencesDto } from '../dto/board-meeting/shown-references-dto';
import { GetBoardMeetingNoteDto } from '../dto/board-meeting/get-board-meeting-note-dto';
import { UploadedFondaFile } from '../../models/uploaded-fonda-file';

export enum BoardMeetingAgendaSectionsType {
    Manual = 'manual',
    Category = 'category',
}

export class BoardMeetingMapper {
    public static GetBoardMeetingAgenda(jsonDataObject): BoardMeetingAgendaDto {
        const applications: Array<BoardMeetingApplicationDetailsDto> = [];
        const sections: Array<AgendaSectionsCategory | AgendaSectionsManual> = [];

        if (jsonDataObject.agenda_sections) {
            jsonDataObject.agenda_sections.forEach(section => {
                if (section.type === BoardMeetingAgendaSectionsType.Manual) {
                    sections.push(new AgendaSectionsManual(section.content, section.sequence, section.type));
                } else if (section.type === BoardMeetingAgendaSectionsType.Category) {
                    const arr: Array<AgendaSectionsCategoryContentApplicationDto> = [];

                    if (section.content.applications) {
                        section.content.applications.forEach(app => {
                            const notes: Array<GetBoardMeetingNoteDto> = [];

                            if (app.notes) {
                                app.notes.forEach(note => {
                                    notes.push(
                                        new GetBoardMeetingNoteDto(
                                            note.created_at,
                                            note.created_by,
                                            note.is_public,
                                            note.text,
                                            note.uuid
                                        )
                                    );
                                });
                            }

                            arr.push(
                                new AgendaSectionsCategoryContentApplicationDto(
                                    app.uuid,
                                    app.sequence,
                                    app.files,
                                    app.processed
                                )
                            );
                        });
                    }

                    const shownReferences: Array<ShownReferencesDto> = [];

                    if (section.content.shown_references) {
                        section.content.shown_references.forEach(reference => {
                            shownReferences.push(
                                new ShownReferencesDto(
                                    reference.pre_show,
                                    reference.reference,
                                    reference.reference_type,
                                    reference.show_stringified_value,
                                    reference.sequence
                                )
                            );
                        });
                    }

                    const content = new AgendaSectionsCategoryContentDto(
                        arr,
                        section.content.category_uuid,
                        shownReferences
                    );

                    sections.push(new AgendaSectionsCategory(content, section.sequence, section.type));
                }
            });
        }

        if (jsonDataObject.applications) {
            jsonDataObject.applications.forEach(application => {
                applications.push(
                    new BoardMeetingApplicationDetailsDto(application.application_uuid, application.category_uuid)
                );
            });
        }

        const summary = jsonDataObject.summary_file
            ? new UploadedFondaFile(
                  jsonDataObject.summary_file.uuid,
                  jsonDataObject.summary_file.file_name,
                  '',
                  jsonDataObject.summary_file.file_size
              )
            : null;

        return new BoardMeetingAgendaDto(
            jsonDataObject.uuid,
            jsonDataObject.name,
            jsonDataObject.fiscal_year,
            jsonDataObject.date,
            jsonDataObject.accepting_application_last_date,
            jsonDataObject.is_agenda_published,
            jsonDataObject.is_selectable,
            applications,
            sections,
            jsonDataObject.agenda_locked_by_another_caseworker,
            summary
        );
    }

    public static PutBoardMeetingAgenda(sections: Array<AgendaSectionsCategory | AgendaSectionsManual>): any {
        const json: Array<object> = [];

        sections.forEach(section => {
            const sequence = section.sequence;
            const type = section.type;
            const content = {};

            if (section.type === BoardMeetingAgendaSectionsType.Category) {
                const _section = <AgendaSectionsCategory>section;
                content['category_uuid'] = _section.content.categoryUuid;

                const applications: Array<object> = [];

                _section.content.applications.forEach(app => {
                    applications.push({
                        uuid: app.uuid,
                        sequence: app.sequence,
                        files: app.files,
                        processed: app.processed,
                    });
                });

                content['applications'] = applications;
                const shownReferences: Array<object> = [];
                _section.content.shownReference.forEach(reference => {
                    shownReferences.push({
                        pre_show: reference.preShow,
                        reference: reference.reference,
                        reference_type: reference.referenceType,
                        show_stringified_value: reference.showStringifiedValue,
                        sequence: reference.sequence,
                    });
                });
                content['shown_references'] = shownReferences;
            } else if (section.type === BoardMeetingAgendaSectionsType.Manual) {
                const _section = <AgendaSectionsManual>section;
                content['files'] = _section.content.files;
                content['text'] = _section.content.text;
                content['manual_type'] = _section.content.manual_type;
            } else {
                // TODO: Slack notification
            }

            json.push({
                sequence: sequence,
                type: type,
                content: content,
            });
        });

        return json;
    }
}
