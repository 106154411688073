import { Injectable } from '@angular/core';
import { GlobalVariables } from '../../../services/global-variables';
import { TranslateService } from '../../translation/translate.service';

declare var CKEDITOR: any;

@Injectable()
export class CkeditorPopupService {
    public option: string = GlobalVariables.NO_ONE_VARIABLE;
    readonly CKEDITOR_BACKGROUND_CLASSES = 'cke_dialog_background_cover';
    readonly CKEDITOR_POPUP_CONTENT_CLASSES = 'cke_dialog_ui_vbox_child';
    readonly CKEDITOR_POPUP_LABEL_PLACEHOLDER = 'cke_dialog_title';
    readonly CKEDITOR_POPUP_LABEL_SELECT_PLACEHOLDER = 'cke_dialog_ui_labeled_label cke_required';
    readonly CKEDITOR_HYPERLINKS_DIALOG_CLASSES = 'cke_reset_all cke_1 cke_editor_editor1_dialog ';
    readonly CKEDITOR_DIALOG_CLOSE_BUTTON_CLASSES = 'cke_dialog_close_button';

    constructor(private translateService: TranslateService) {}

    public getOption() {
        return this.option;
    }

    public putTokens(__tokens: Array<string>) {
        const _tokens = __tokens.sort((a, b) => (a < b ? -1 : 1));
        const tokens = _tokens.map(t => [t]);
        CKEDITOR.on('dialogDefinition', event => {
            if ('placeholder' == event.data.name) {
                const input = event.data.definition.getContents('info').get('name');
                input.type = 'select';
                input.items = tokens.map(t => Array.from(t));
                this.renameLabelsOnPopup();
                this.addSecondDropdownToPopup();
                const that = this;
                input.setup = function (widget) {
                    this.setValue(widget.data.name);
                };
                input.commit = function (widget) {
                    widget.setData(
                        'name',
                        this.getValue() +
                            (that.getOption() !== GlobalVariables.NO_ONE_VARIABLE ? '.' + that.getOption() : '')
                    );
                };
            }
        });
    }

    private renameLabelsOnPopup() {
        setTimeout(() => {
            const elementLabel = document.getElementsByClassName(this.CKEDITOR_POPUP_LABEL_PLACEHOLDER);
            const elementSelectLabel = document.getElementsByClassName(this.CKEDITOR_POPUP_LABEL_SELECT_PLACEHOLDER);
            if (elementLabel[0]) {
                elementLabel[0].innerHTML = this.translateService.get([
                    'correspondence_template_editor',
                    'token_pop_up_title',
                ]);
            }
            if (elementLabel[0]) {
                elementSelectLabel[0].innerHTML = this.translateService.get([
                    'correspondence_template_editor',
                    'token_pop_up_dropdown_name',
                ]);
            }
        }, 50);
    }

    private addSecondDropdownToPopup() {
        setTimeout(() => {
            const popupContent: any = document.getElementsByClassName(this.CKEDITOR_POPUP_CONTENT_CLASSES);
            const selectComponent = document.getElementById('tokens-options-adder');
            for (const content of popupContent) {
                if (
                    content &&
                    selectComponent &&
                    content.getElementsByClassName('ckeditor-tokens-second-options').length == 0
                ) {
                    content.appendChild(selectComponent);
                }
            }
        }, 50);
    }
}
