import { SectionDto } from './section-dto';

export class CaseworkerApplicationDto {
    constructor(
        public readonly applicantDefaultLanguage: string,
        public readonly createdAt: Date,
        public readonly applicantSchemaUuid: string,
        public readonly applicantUuid: string,
        public readonly uuid: string,
        public readonly isClosed: boolean,
        public readonly isDraft: boolean,
        public readonly isGranted: boolean,
        public readonly status: string,
        public readonly subStatus: string,
        public readonly applicantSections: Array<SectionDto>,
        public readonly caseworkerSections: Array<SectionDto>,
        public readonly postGrantSections: Array<SectionDto>,
        public readonly accountEmail: string,
        public readonly isVisibleToApplicant: boolean
    ) {}
}
