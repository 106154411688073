import { Component } from '@angular/core';
import { FondaApiService } from '../../../../api/fonda-api.service';
import { CountryDto } from '../../../../api/dto/country-dto';
import { BaseRecordComponent, RecordMetadata } from '../../base-record.component';
import { UuidValue } from '../../values.types';

@Component({
    selector: 'country-record',
    templateUrl: './country-record.component.html',
})
export class CountryRecordComponent extends BaseRecordComponent<UuidValue, null> {
    countries: CountryDto[] = [];
    selectedUuid: string | null = null;

    constructor(private apiService: FondaApiService) {
        super();
    }

    get countryUuids(): string[] {
        return this.countries.map(c => c.uuid);
    }

    get selectedName(): string {
        if (!this.selectedUuid) return '';
        const country = this.countries.find(c => c.uuid === this.selectedUuid);
        return country ? country.name : '';
    }

    initRecord(metadata: RecordMetadata<UuidValue, null>) {
        this.fetchCountries();
    }

    emitValue() {
        this.onChange.emit(this.getValue());
    }

    chooseCountry(uuid: string | null) {
        this.selectedUuid = uuid || null;
        this.emitValue();
    }

    reset() {
        this.chooseCountry(null);
    }

    isValid(): boolean {
        return this.metadata.isRequired && !this.isEmpty();
    }

    isEmpty(): boolean {
        return !this.selectedUuid;
    }

    getValue(): UuidValue {
        if (this.selectedUuid === null) return [];
        return { uuid: this.selectedUuid };
    }

    setValue(value: UuidValue) {
        this.selectedUuid = (!Array.isArray(value) && value.uuid) || null;
    }

    private fetchCountries(): Promise<CountryDto[]> {
        return this.apiService
            .getCountries()
            .then(res => res.map(country => new CountryDto(country.uuid, country.name)))
            .then(res => (this.countries = res));
    }
}
