<div class="form-item" [ngClass]="{ half: metadata.size === 1 }" *ngIf="!isReadonly">
    <fonda-select
        [placeholder]="metadata.uuid | translate : 'title'"
        [options]="subcategories"
        [label]="metadata.uuid | translate : 'title'"
        [formControl]="control"
        [canUnselect]="false"
        [mandatory]="metadata.isRequired"
    >
        <ng-container *fondaSelectOption="let uuid">
            {{ uuid ? (uuid | translate) : ('general' | translate : 'none') }}
        </ng-container>
    </fonda-select>
    <div class="form-focus"></div>
    <div class="form-error"></div>
</div>
<a class="form-item" *ngIf="isReadonly" [ngClass]="{ full: metadata.size === 2 }">
    <p>{{ metadata.uuid | translate : 'title' }}</p>
    <span>{{ control.value ? (control.value | translate) : ('general' | translate : 'none') }}</span>
    <div class="form-focus"></div>
    <div class="form-error"></div>
</a>
