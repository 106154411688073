import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CorrespondencePopupComponent } from './correspondence-popup/correspondence-popup.component';
import { AccountCorrespondencePopupComponent } from './account-correspondence-popup/account-correspondence-popup.component';
import { CaseworkerCorrespondencePopupService } from './correspondence-popup/caseworker-correspondence-popup.service';
import { AccountCaseworkerCorrespondencePopupService } from './account-correspondence-popup/account-caseworker-correspondence-popup.service';
import { AccountCorrespondenceThreadsComponent } from './account-correspondence-threads/account-correspondence-threads.component';
import { CorrespondenceThreadsComponent } from './correspondence-threads/correspondence-threads.component';
import { SharedModule } from '../shared/shared.module';
import { AccountCorrespondenceTeaserComponent } from './account-correspondence-teaser/account-correspondence-teaser.component';
import { CorrespondenceService } from './correspondence.service';
import { CorrespondenceSharedModule } from './correspondence-shared.module';
import { ApplicantResponsibleSelectComponent } from './applicant-responsible-select/applicant-responsible-select.component';

const EXPORTED_DECLARATIONS = [
    AccountCorrespondenceThreadsComponent,
    AccountCorrespondencePopupComponent,
    CorrespondenceThreadsComponent,
    CorrespondencePopupComponent,
    AccountCorrespondenceTeaserComponent,
    ApplicantResponsibleSelectComponent,
];

@NgModule({
    declarations: EXPORTED_DECLARATIONS,
    exports: [EXPORTED_DECLARATIONS, CorrespondenceSharedModule],
    imports: [CommonModule, SharedModule, CorrespondenceSharedModule],
    entryComponents: [AccountCorrespondencePopupComponent, CorrespondencePopupComponent],
    providers: [
        CaseworkerCorrespondencePopupService,
        AccountCaseworkerCorrespondencePopupService,
        CorrespondenceService,
    ],
})
export class CorrespondenceModule {}
