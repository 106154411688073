import { Component, Input } from '@angular/core';
import { UUID } from '../../../../../models/uuid';
import { convertToString } from '../economy-converter';

@Component({
    selector: 'economy-income-multiline-view',
    templateUrl: './economy-income-multiline-view.component.html',
    styleUrls: ['../economy-record.scss'],
})
export class EconomyIncomeMultilineViewComponent {
    @Input() value: Array<{
        title: string;
        amount: number;
        attachments: Array<{
            uuid: string;
            file_name: string;
            mime_type: string;
            size: string;
        }>;
    }> = [];

    @Input() hasAttachments: boolean = false;
    @Input() recordUuid: UUID;
    @Input() total: boolean = false;

    numToStr(amount) {
        return convertToString(amount);
    }

    get totalValue() {
        let amount = 0;
        this.value.forEach(expense => {
            amount += expense.amount;
        });
        return amount;
    }
}
