import { Component, ElementRef } from '@angular/core';
import { FondaApiService } from '../../../../api/fonda-api.service';
import { BaseRecordComponent, RecordMetadata } from '../../base-record.component';
import { BoardMeetingData } from './board-meeting-data';
import { format } from 'date-fns';

export type BoardMeetingRecordOutput = { uuid: string | string[] } | [];

@Component({
    selector: 'board-meeting-record',
    templateUrl: 'board-meeting-record.component.html',
})
export class BoardMeetingRecordComponent extends BaseRecordComponent<BoardMeetingRecordOutput> {
    public selectedBoardMeetingUuids: string | string[] | null = null;
    public boardMeetings: Array<BoardMeetingData> = [];
    public boardMeetingUuids: string[] = [];
    public previousBoardMeetingUuid: string | null = null;
    public nextBoardMeetingUuid: string | null = null;

    constructor(private apiService: FondaApiService, private _eref: ElementRef) {
        super();
    }

    get selectedName(): string {
        if (Array.isArray(this.selectedBoardMeetingUuids)) {
            if (this.selectedBoardMeetingUuids.length === 0) return '-';
            return this.selectedBoardMeetingUuids.map(uuid => this.displayName(uuid)).join(', ');
        }

        if (this.selectedBoardMeetingUuids === null) return '-';
        return this.displayName(this.selectedBoardMeetingUuids);
    }

    initRecord(metadata: RecordMetadata<BoardMeetingRecordOutput, null>) {
        this.apiService.getBoardMeetings().then(res => {
            this.boardMeetings = res.map(BoardMeetingData.fromDto);
            this.boardMeetingUuids = this.boardMeetings.map(bm => bm.uuid);
            this.calculateNextAndPreviousBoardMeeting(this.boardMeetings);
        });

        if (this.isOnSearch) {
            this.selectedBoardMeetingUuids = [];
        }
    }

    setValue(value: BoardMeetingRecordOutput) {
        if (this.isOnSearch) {
            this.selectedBoardMeetingUuids = (value as { uuid: string[] }).uuid.slice();
        } else {
            this.selectedBoardMeetingUuids = (!Array.isArray(value) && value.uuid) || null;
        }
    }

    getValue(): BoardMeetingRecordOutput {
        if (this.selectedBoardMeetingUuids === null) return [];
        return { uuid: this.selectedBoardMeetingUuids };
    }

    emitValue() {
        this.onChange.emit(this.getValue());
    }

    chooseBoardMeeting(uuid: string | null | string[]) {
        this.selectedBoardMeetingUuids = uuid;
        this.emitValue();
    }

    isValid(): boolean {
        return this.metadata.isRequired && !this.isEmpty();
    }

    isEmpty(): boolean {
        return (
            (Array.isArray(this.selectedBoardMeetingUuids) && this.selectedBoardMeetingUuids.length === 0) ||
            this.selectedBoardMeetingUuids === null
        );
    }

    displayName(uuid: string): string {
        const bm = this.findBoardMeetingByUuid(uuid);
        if (!bm) return '';
        return `${bm.name} - ${this.formatDate(bm.date)}`;
    }

    formatDate(date: string): string {
        return format(new Date(date), 'yyyy-MM-dd');
    }

    calculateNextAndPreviousBoardMeeting(boardMeetings: BoardMeetingData[]): void {
        const now = Date.now();
        const getTime = (d: string) => new Date(d).getTime();

        const nextBoardMeeting = boardMeetings.filter(b => getTime(b.date) > now);
        if (nextBoardMeeting.length) this.nextBoardMeetingUuid = nextBoardMeeting[nextBoardMeeting.length - 1].uuid;

        const previousBoardMeeting = boardMeetings.filter(b => getTime(b.date) < now);
        if (previousBoardMeeting.length) this.previousBoardMeetingUuid = previousBoardMeeting[0].uuid;
    }

    findBoardMeetingByUuid(uuid: string): BoardMeetingData | undefined {
        return this.boardMeetings.find(b => b.uuid === uuid);
    }
}
