import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PwaRadioComponent } from './pwa-radio.component';

import { PwaRadioDirective } from './pwa-radio.directive';

const COMPONENTS = [PwaRadioComponent];

const DIRECTIVES = [PwaRadioDirective];

@NgModule({
    declarations: [COMPONENTS, DIRECTIVES],
    imports: [CommonModule],
    exports: [COMPONENTS, DIRECTIVES],
})
export class PwaRadioModule {}
