export class RecordInputObject {
    constructor(
        readonly uuid: string,
        readonly value: object,
        readonly size: number,
        readonly isRequired: boolean,
        readonly type: string,
        readonly editable: boolean,
        readonly configuration?: object,
        readonly hasDynamicPrefilling?: boolean
    ) {}

    public title = '';
    public readonly: boolean;
    public onSearch: boolean;
    public sequence: number;
}
