<ng-container *isNotOnPWA>
    <!-- DESKTOP -->

    <table>
        <tr *ngIf="block || hasGrantedAmount" class="block-header">
            <th [attr.colspan]="configuration.has_comments ? '4' : '3'">
                <ng-container *ngIf="block">
                    <span>{{ block.name }}</span>
                    <span *ngIf="economyGroup">, {{ block.economy_group | translate }}</span>
                    <span *ngIf="boardMeetings.length">, {{ getBoardMeetingName(block.board_meeting) }}</span>
                </ng-container>
            </th>
            <th *ngIf="configuration.has_attachments">
                <ng-container *ngIf="block && block.attachments && block.attachments.length">
                    <attachments-display
                        style="display: block; margin-bottom: 6px"
                        [readonly]="true"
                        [attachments]="block.attachments"
                        [bold]="true"
                    ></attachments-display>
                </ng-container>
            </th>
            <th></th>
            <th [attr.colspan]="configuration.payment_statuses?.length ? '3' : '2'" style="text-align: right">
                <span *ngIf="block">
                    {{ 'correspondence_template_editor' | translate : 'granted_amount' }}
                    {{
                        block.partial_granting_percentage === 100 ? '' : '(' + block.partial_granting_percentage + '%)'
                    }}
                    : {{ numToStr(block.amount) | commasInInput }} <fonda-currency></fonda-currency>
                </span>
                <span *ngIf="!block && hasGrantedAmount">{{ numToStr(grantedAmount) | commasInInput }} kr</span>
            </th>
            <th>
                <div *ngIf="!readonly" class="payment-block-action">
                    <a *ngIf="block" type="button" (click)="onAddBlock(block)" btn size="small">
                        <span class="icon-cross"></span>
                    </a>
                    <a *ngIf="!block" type="button" (click)="onAdd()" btn size="small">
                        <span class="icon-cross"></span>
                    </a>
                    <button
                        *ngIf="children.length"
                        (click)="showChildren = !this.showChildren"
                        class="btn-expand"
                        [class.unfolded]="showChildren"
                    ></button>
                </div>
            </th>
        </tr>
        <tr
            *ngIf="showChildren && children.length"
            payment-block-children
            [children]="children"
            [boardMeetings]="boardMeetings"
            [configuration]="configuration"
        ></tr>
        <tr>
            <th>{{ 'general' | translate : 'name' }}</th>
            <th>{{ 'general' | translate : 'date' }}</th>
            <th>{{ 'payment_profile' | translate : 'payment_profile' }}</th>
            <th *ngIf="configuration.has_comments">{{ 'general' | translate : 'comment' }}</th>
            <th *ngIf="configuration.has_attachments">
                {{ 'general' | translate : 'attachments' }}
            </th>
            <th>{{ 'general' | translate : 'behaviours' }}</th>
            <th *ngIf="configuration.payment_statuses?.length">{{ 'application_field' | translate : 'status' }}</th>
            <th style="text-align: right">{{ 'general' | translate : 'amount' }}</th>
            <th *ngIf="block || hasGrantedAmount" style="text-align: right">
                {{ 'general' | translate : 'balance' }}
            </th>
            <th></th>
        </tr>
        <tr *ngFor="let payment of payments; let i = index">
            <td>{{ payment.name }}</td>
            <td style="min-width: 90px">{{ payment.date | utcDate }}</td>
            <td>{{ getPaymentProfileName(payment.payment_profile) }}</td>
            <td *ngIf="configuration.has_comments">{{ payment.comment }}</td>
            <td *ngIf="configuration.has_attachments">
                <attachments-display
                    style="display: block; margin-bottom: 6px"
                    [readonly]="true"
                    [attachments]="payment.attachments ? payment.attachments : []"
                    [fontSize]="0.9"
                ></attachments-display>
            </td>
            <td>{{ uuid | translate : payment.type }}</td>
            <td *ngIf="configuration.payment_statuses?.length">
                <div class="payment-block-statuses">
                    {{ 'general' | translate : payment.payment_status }}
                    <span
                        *ngIf="payment.nf_status"
                        class="payment-block-statuses--n1st"
                        [fondaTooltip]="payment.nf_status"
                        placement="right"
                    ></span>
                </div>
            </td>
            <td class="payment-block-row-amount">
                {{ payment.type === 'refunded' ? '+ ' : '- ' }}
                {{ numToStr(payment.amount) | commasInInput }} <fonda-currency></fonda-currency>
            </td>
            <td *ngIf="block || hasGrantedAmount" class="payment-block-row-amount">
                <ng-container *ngIf="affectsBalance(payment.payment_status)">
                    {{ numToStr(payment.balance) | commasInInput }} <fonda-currency></fonda-currency>
                </ng-container>
            </td>
            <td class="payment-block-row-actions">
                <button
                    *ngIf="
                        !readonly &&
                        nextStatusBtn &&
                        payment.payment_status &&
                        getExpectedNextStatus(payment.payment_status) &&
                        payment.type !== 'canceled' &&
                        isNextStatusShown(payment.payment_status)
                    "
                    (click)="onStatus(payment)"
                    btn
                    size="small"
                    class="expected-status-btn"
                >
                    {{ 'general' | translate : getExpectedNextStatusPointer(payment.payment_status) }}
                </button>
                <button
                    *ngIf="!readonly && payment.payment_status !== 'paid'"
                    (click)="onEdit(payment)"
                    icon-btn="pencil"
                    type="button"
                    size="small"
                    color="primary"
                    [outline]="true"
                    style="margin-left: 8px"
                ></button>
                <button
                    *ngIf="!readonly && payment.payment_status !== 'paid' && !payment.is_originally_from_applicant"
                    (click)="onDelete(payment)"
                    icon-btn="trash"
                    type="button"
                    size="small"
                    color="danger"
                    [outline]="true"
                    style="margin-left: 8px"
                ></button>
            </td>
        </tr>
    </table>

    <!-- MOBILE / TABLET -->

    <div class="mobile-block">
        <div *ngIf="block || hasGrantedAmount" class="mobile-block-header">
            <p *ngIf="block">{{ block.name }}</p>
            <p *ngIf="configuration.has_comments">
                <ng-container *ngIf="block && block.attachments && block.attachments.length">
                    <attachments-display
                        style="display: block; margin-bottom: 6px"
                        [readonly]="true"
                        [attachments]="block.attachments"
                        [bold]="true"
                    ></attachments-display>
                </ng-container>
            </p>
            <p *ngIf="block">
                {{ 'correspondence_template_editor' | translate : 'granted_amount' }}
                {{ block.partial_granting_percentage === 100 ? '' : '(' + block.partial_granting_percentage + '%)' }} :
                {{ numToStr(block.amount) | commasInInput }} <fonda-currency></fonda-currency>
            </p>
            <p *ngIf="!block && hasGrantedAmount">{{ numToStr(grantedAmount) | commasInInput }} kr</p>
            <p *ngIf="!readonly" class="payment-block-action">
                <a *ngIf="block" type="button" (click)="onAddBlock(block)" btn size="small">
                    <span class="icon-cross"></span>
                </a>
                <a *ngIf="!block" type="button" (click)="onAdd()" btn size="small">
                    <span class="icon-cross"></span>
                </a>
            </p>
        </div>
        <div class="mobile-block-column" *ngFor="let payment of payments; let i = index">
            <div class="mobile-block-item">
                <p class="mobile-block-item-header">{{ 'general' | translate : 'name' }}</p>
                <p>{{ payment.name }}</p>
            </div>
            <div class="mobile-block-item">
                <p class="mobile-block-item-header">{{ 'general' | translate : 'date' }}</p>
                <p>{{ payment.date | utcDate }}</p>
            </div>
            <div class="mobile-block-item">
                <p class="mobile-block-item-header">{{ 'payment_profile' | translate : 'payment_profile' }}</p>
                <p>{{ getPaymentProfileName(payment.payment_profile) }}</p>
            </div>
            <div *ngIf="configuration.has_comments" class="mobile-block-item">
                <p class="mobile-block-item-header">{{ 'general' | translate : 'comment' }}</p>
                <p>{{ payment.comment }}</p>
            </div>
            <div *ngIf="configuration.has_attachments" class="mobile-block-item">
                <p class="mobile-block-item-header">{{ 'general' | translate : 'attachments' }}</p>
                <p>
                    <attachments-display
                        style="display: block; margin-bottom: 6px"
                        [readonly]="true"
                        [attachments]="payment.attachments ? payment.attachments : []"
                        [fontSize]="0.9"
                    ></attachments-display>
                </p>
            </div>
            <div class="mobile-block-item">
                <p class="mobile-block-item-header">{{ 'general' | translate : 'behaviours' }}</p>
                <p>{{ uuid | translate : payment.type }}</p>
            </div>
            <div *ngIf="configuration.payment_statuses?.length" class="mobile-block-item">
                <p class="mobile-block-item-header">{{ 'application_field' | translate : 'status' }}</p>
                <p class="payment-block-statuses">
                    {{ 'general' | translate : payment.payment_status }}
                    <span
                        *ngIf="payment.nf_status"
                        class="payment-block-statuses--n1st"
                        [fondaTooltip]="payment.nf_status"
                        placement="right"
                    ></span>
                </p>
            </div>
            <div class="mobile-block-item">
                <p class="mobile-block-item-header">{{ 'general' | translate : 'amount' }}</p>
                <p>
                    {{ payment.type === 'refunded' ? '+ ' : '- ' }}
                    {{ numToStr(payment.amount) | commasInInput }} <fonda-currency></fonda-currency>
                </p>
            </div>
            <div *ngIf="block || hasGrantedAmount" class="mobile-block-item">
                <p class="mobile-block-item-header">{{ 'general' | translate : 'balance' }}</p>
                <p>
                    <ng-container *ngIf="affectsBalance(payment.payment_status)">
                        {{ numToStr(payment.balance) | commasInInput }} <fonda-currency></fonda-currency>
                    </ng-container>
                </p>
            </div>
            <div
                class="mobile-block-item mobile-block-item-actions"
                *ngIf="!readonly && payment.payment_status && payment.payment_status !== 'paid'"
            >
                <button
                    (click)="onStatus(payment)"
                    *ngIf="
                        !readonly &&
                        nextStatusBtn &&
                        payment.payment_status &&
                        getExpectedNextStatus(payment.payment_status) &&
                        payment.type !== 'canceled' &&
                        isNextStatusShown(payment.payment_status)
                    "
                    btn
                    size="small"
                    style="margin-right: 8px"
                >
                    {{ 'general' | translate : getExpectedNextStatusPointer(payment.payment_status) }}
                </button>
                <button
                    (click)="onEdit(payment)"
                    icon-btn="pencil"
                    type="button"
                    size="small"
                    color="primary"
                    [outline]="true"
                    style="margin-right: 8px"
                ></button>
                <button
                    *ngIf="!payment.is_originally_from_applicant"
                    (click)="onDelete(payment)"
                    icon-btn="trash"
                    type="button"
                    size="small"
                    color="danger"
                    [outline]="true"
                ></button>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *isOnPWA>
    <pwa-accordion-list [expanded]="expanded === block.grant_identifier" [animation]="isDesktop ? true : false">
        <ng-container pwaAccordionTitle>
            <div class="pwa-accordion-header">
                <div class="pwa-accordion-header-info" [class.has-comment]="configuration.has_comments" *ngIf="block">
                    <div>{{ block.name }}</div>
                    <div *ngIf="economyGroup">, {{ block.economy_group | translate }}</div>
                    <div *ngIf="boardMeetings.length">, {{ getBoardMeetingName(block.board_meeting) }}</div>
                </div>
                <div class="pwa-accordion-header-attachments" *ngIf="configuration.has_attachments">
                    <ng-container *ngIf="block && block.attachments && block.attachments.length">
                        <pwa-attachments-display
                            [readonly]="true"
                            [attachments]="block.attachments"
                            fontSize="1rem"
                        ></pwa-attachments-display>
                    </ng-container>
                </div>
                <div
                    class="pwa-accordion-header-granted-amount"
                    [class.has-statuses]="configuration.payment_statuses?.length"
                >
                    <div *ngIf="block">
                        {{ 'correspondence_template_editor' | translate : 'granted_amount' }}
                        {{
                            block.partial_granting_percentage === 100
                                ? ''
                                : '(' + block.partial_granting_percentage + '%)'
                        }}
                        : {{ numToStr(block.amount) | commasInInput }} <fonda-currency></fonda-currency>
                    </div>
                    <div *ngIf="!block && hasGrantedAmount">
                        {{ numToStr(grantedAmount) | commasInInput }} <fonda-currency></fonda-currency>
                    </div>
                </div>
                <div class="pwa-accordion-header-attachments-mobile" *ngIf="configuration.has_attachments">
                    <ng-container *ngIf="block && block.attachments && block.attachments.length">
                        <pwa-attachments-display
                            [readonly]="true"
                            [attachments]="block.attachments"
                            fontSize="1rem"
                        ></pwa-attachments-display>
                    </ng-container>
                </div>
                <div class="pwa-accordion-header-placeholder" *ngIf="block || hasGrantedAmount"></div>
                <div class="pwa-accordion-header-icon"></div>
            </div>
        </ng-container>
        <ng-container>
            <div class="pwa-table" *ngIf="children.length">
                <div class="pwa-table-container">
                    <div class="pwa-line pwa-line-header">
                        <div class="pwa-column">{{ 'general' | translate : 'name' }}</div>
                        <div class="pwa-column">{{ 'general' | translate : 'attachments' }}</div>
                        <div class="pwa-column pwa-column-right">{{ 'general' | translate : 'amount' }}</div>
                    </div>
                    <div class="pwa-line" *ngFor="let child of children">
                        <div class="pwa-column">{{ child.name }}</div>
                        <div class="pwa-column">
                            <pwa-attachments-display
                                [readonly]="true"
                                [attachments]="child.attachments"
                                fontSize="1rem"
                            ></pwa-attachments-display>
                        </div>
                        <div class="pwa-column pwa-column-right">
                            {{ child.original_full_amount | commasInInput }} <fonda-currency></fonda-currency>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pwa-granted-lines-children" *ngIf="children.length">
                <div class="pwa-granted-lines-children-item" *ngFor="let child of children">
                    <div class="pwa-granted-lines-children-item--header">
                        {{ child.name }}
                    </div>
                    <div class="pwa-granted-lines-children-item--content">
                        <pwa-attachments-display
                            [readonly]="true"
                            [attachments]="child.attachments"
                            fontSize="1rem"
                        ></pwa-attachments-display>
                    </div>
                </div>
            </div>
            <div class="pwa-table">
                <div class="pwa-table-container">
                    <div class="pwa-line pwa-line-header">
                        <div class="pwa-column">{{ 'general' | translate : 'name' }}</div>
                        <div class="pwa-column">{{ 'general' | translate : 'date' }}</div>
                        <div class="pwa-column">{{ 'payment_profile' | translate : 'payment_profile' }}</div>
                        <div class="pwa-column" *ngIf="configuration.has_comments">
                            {{ 'general' | translate : 'comment' }}
                        </div>
                        <div class="pwa-column" *ngIf="configuration.has_attachments">
                            {{ 'general' | translate : 'attachments' }}
                        </div>
                        <div class="pwa-column">{{ 'general' | translate : 'behaviours' }}</div>
                        <div class="pwa-column" *ngIf="configuration.payment_statuses?.length">
                            {{ 'application_field' | translate : 'status' }}
                        </div>
                        <div class="pwa-column pwa-column-right">{{ 'general' | translate : 'amount' }}</div>
                        <div class="pwa-column pwa-column-right" *ngIf="block || hasGrantedAmount">
                            {{ 'general' | translate : 'balance' }}
                        </div>
                        <div class="pwa-column pwa-column-button"></div>
                    </div>
                    <div class="pwa-line" *ngFor="let payment of payments; let i = index">
                        <div class="pwa-column">
                            {{ payment.name }}
                        </div>
                        <div class="pwa-column">
                            {{ payment.date | utcDate }}
                        </div>
                        <div class="pwa-column">
                            {{ getPaymentProfileName(payment.payment_profile) }}
                        </div>
                        <div class="pwa-column" *ngIf="configuration.has_comments">
                            {{ payment.comment }}
                        </div>
                        <div class="pwa-column" *ngIf="configuration.has_attachments">
                            <pwa-attachments-display
                                [readonly]="true"
                                [attachments]="payment.attachments"
                                fontSize="0.875rem"
                            ></pwa-attachments-display>
                        </div>
                        <div class="pwa-column">
                            {{ uuid | translate : payment.type }}
                        </div>
                        <div class="pwa-column" *ngIf="configuration.payment_statuses?.length">
                            {{ 'general' | translate : payment.payment_status }}
                        </div>
                        <div class="pwa-column pwa-column-right">
                            {{ payment.type === 'refunded' ? '+ ' : '- ' }}
                            {{ numToStr(payment.amount) | commasInInput }} <fonda-currency></fonda-currency>
                        </div>
                        <div class="pwa-column pwa-column-right" *ngIf="block || hasGrantedAmount">
                            <ng-container *ngIf="affectsBalance(payment.payment_status)">
                                {{ numToStr(payment.balance) | commasInInput }} <fonda-currency></fonda-currency>
                            </ng-container>
                        </div>
                        <div class="pwa-column pwa-column-button pwa-edit-button">
                            <button
                                *ngIf="payment.payment_status && payment.payment_status !== 'paid'"
                                (click)="toRequestPayment(payment['grant_identifier'], payment['payment_identifier'])"
                                pwaButton
                                color="secondary"
                            >
                                <span class="icon-pencil"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <pwa-accordion-list class="pwa-subaccordion" *ngFor="let payment of payments; let i = index">
                <ng-container pwaAccordionTitle>
                    <div class="pwa-subaccordion-title">
                        <div>
                            {{ payment.name }}
                        </div>
                        <div class="pwa-subaccordion-title-amount">
                            {{ payment.type === 'refunded' ? '+ ' : '- ' }}
                            {{ numToStr(payment.amount) | commasInInput }} <fonda-currency></fonda-currency>
                        </div>
                        <button
                            *ngIf="payment.payment_status && payment.payment_status !== 'paid'"
                            (click)="toRequestPayment(payment['grant_identifier'], payment['payment_identifier'])"
                            pwaButton
                            color="secondary"
                            class="pwa-subaccordion-title-button"
                        >
                            <span class="icon-pencil"></span>
                        </button>
                    </div>
                </ng-container>
                <pwa-list-element>
                    <pwa-labelled-text>
                        <ng-container pwaLabelledTextLabel>
                            {{ 'general' | translate : 'date' }}
                        </ng-container>
                        <ng-container pwaLabelledTextContent>
                            {{ payment.date | utcDate }}
                        </ng-container>
                    </pwa-labelled-text>
                </pwa-list-element>
                <pwa-list-element>
                    <pwa-labelled-text>
                        <ng-container pwaLabelledTextLabel>
                            {{ 'payment_profile' | translate : 'payment_profile' }}
                        </ng-container>
                        <ng-container pwaLabelledTextContent>
                            {{ getPaymentProfileName(payment.payment_profile) }}
                        </ng-container>
                    </pwa-labelled-text>
                </pwa-list-element>
                <pwa-list-element *ngIf="configuration.has_comments">
                    <pwa-labelled-text>
                        <ng-container pwaLabelledTextLabel>
                            {{ 'general' | translate : 'comment' }}
                        </ng-container>
                        <ng-container pwaLabelledTextContent>
                            {{ payment.comment }}
                        </ng-container>
                    </pwa-labelled-text>
                </pwa-list-element>
                <pwa-list-element *ngIf="configuration.has_attachments">
                    <pwa-labelled-text>
                        <ng-container pwaLabelledTextLabel>
                            {{ 'general' | translate : 'attachments' }}
                        </ng-container>
                        <ng-container pwaLabelledTextContent>
                            <pwa-attachments-display
                                [readonly]="true"
                                [attachments]="payment.attachments"
                            ></pwa-attachments-display>
                        </ng-container>
                    </pwa-labelled-text>
                </pwa-list-element>
                <pwa-list-element>
                    <pwa-labelled-text>
                        <ng-container pwaLabelledTextLabel>
                            {{ 'general' | translate : 'behaviours' }}
                        </ng-container>
                        <ng-container pwaLabelledTextContent>
                            {{ uuid | translate : payment.type }}
                        </ng-container>
                    </pwa-labelled-text>
                </pwa-list-element>
                <pwa-list-element *ngIf="configuration.payment_statuses?.length">
                    <pwa-labelled-text>
                        <ng-container pwaLabelledTextLabel>
                            {{ 'application_field' | translate : 'status' }}
                        </ng-container>
                        <ng-container pwaLabelledTextContent>
                            {{ 'general' | translate : payment.payment_status }}
                        </ng-container>
                    </pwa-labelled-text>
                </pwa-list-element>
                <pwa-list-element *ngIf="block || hasGrantedAmount">
                    <pwa-labelled-text>
                        <ng-container pwaLabelledTextLabel>
                            {{ 'general' | translate : 'balance' }}
                        </ng-container>
                        <ng-container pwaLabelledTextContent>
                            <ng-container *ngIf="affectsBalance(payment.payment_status)">
                                {{ numToStr(payment.balance) | commasInInput }} <fonda-currency></fonda-currency>
                            </ng-container>
                        </ng-container>
                    </pwa-labelled-text>
                </pwa-list-element>
            </pwa-accordion-list>
            <div class="pwa-add-button">
                <button (click)="toRequestPayment(block.grant_identifier)" pwaButton color="primary">
                    {{ 'general' | translate : 'add_new' }}
                </button>
            </div>
        </ng-container>
    </pwa-accordion-list>
</ng-container>
