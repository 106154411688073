import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BasicFormControlsModule } from '../../../shared/basic-form-controls/basic-form-controls.module';
import { TranslationModule } from '../../../shared/translation/translation.module';

import { PwaButtonModule } from '../pwa-button/pwa-button.module';
import { PwaInputModule } from '../pwa-input/pwa-input.module';

import { PwaAttachmentsDisplayComponent } from './pwa-attachments-display/pwa-attachments-display.component';
import { PwaAttachmentsUploaderComponent } from './pwa-attachments-uploader/pwa-attachments-uploder.component';

const COMPONENTS = [PwaAttachmentsDisplayComponent, PwaAttachmentsUploaderComponent];

@NgModule({
    declarations: [COMPONENTS],
    imports: [CommonModule, TranslationModule, BasicFormControlsModule, PwaInputModule, PwaButtonModule],
    exports: [COMPONENTS],
})
export class PwaAttachmentsModule {}
