import { DOMService } from '../../services/dom-service';
import { AccountCorrespondencePopupComponent } from './account-correspondence-popup.component';
import { ComponentRef, EventEmitter, Injectable } from '@angular/core';
import { UploadedFondaFile } from '../../models/uploaded-fonda-file';

@Injectable()
export class AccountCaseworkerCorrespondencePopupService {
    public messageSend = new EventEmitter<{
        threadTitle: string;
        messageBody: string;
        statusName: string;
        filesUploaded: Array<UploadedFondaFile>;
        correspondenceTemplateUuid?: string;
    }>();
    statusChangeWithoutMessage = new EventEmitter<{ statusName: string }>();

    constructor(private domService: DOMService) {}

    public async openCorrespondencePopup(applicantUuid: string, accountEmail?: string) {
        const component = this.initPopup();
        const instance = component.instance;
        instance.accountEmail = accountEmail;
        instance.closed.subscribe(() => this.removeComponent(component));
        instance.applicantUuid = applicantUuid;
        instance.messageSend.subscribe(m => {
            this.messageSend.emit(m);
        });
        instance.initManualMessagePopupComponent();
    }

    private initPopup(): ComponentRef<AccountCorrespondencePopupComponent> {
        return this.domService.appendComponentToBody(AccountCorrespondencePopupComponent);
    }

    private removeComponent(component) {
        this.domService.removeComponentFromBody(component);
    }
}
