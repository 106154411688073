<div class="communication">
    <div class="communication-inbox" style="position: relative">
        <div
            class="communication-message-item"
            *ngFor="let thread of threads"
            [class.communication-message-item-seen]="thread.read"
            [class.communication-message-item-not-seen]="!thread.read"
            (click)="onItemClick()"
        >
            <span>{{ thread.creator }}</span>
            <span>
                <span>{{ thread.title }}</span>
                <ng-container *ngIf="thread.messages && thread.messages.length > 1">
                    &nbsp;({{ thread.messages.length }})
                </ng-container>
                <span class="message-text" *ngIf="thread.messages && thread.messages.length">
                    &nbsp;-&nbsp;{{ getLastMessageFirstWords(thread.messages) }}
                </span>
            </span>
            <span>
                <message-date-display [createdAt]="thread.createdAt"></message-date-display>
            </span>
        </div>
    </div>
</div>
