<td>
    {{ line.name }}
</td>
<td *ngIf="paymentView || configuration.has_attachments">
    <attachments-display
        [attachments]="line.attachments ? line.attachments : []"
        [readonly]="true"
        style="display: block; margin-bottom: 6px"
        [fontSize]="parent ? 1 : 0.9"
    ></attachments-display>
</td>
<td *ngIf="paymentView || !configuration.has_partial_granting">
    {{ numToStr(line.amount) | commasInInput }} <fonda-currency></fonda-currency>
</td>
<td *ngIf="paymentView || configuration.has_partial_granting">
    {{ numToStr(line.original_full_amount) | commasInInput }}
    <fonda-currency></fonda-currency>
</td>
<td *ngIf="paymentView || economyGroups.length">
    {{ line.economy_group ? (line.economy_group | translate) : '' }}
</td>
<td>
    {{ getBoardMeetingNameByUuid(line.board_meeting) || '' }}
</td>
<td *ngIf="paymentView || configuration.has_partial_granting">{{ line.partial_granting_percentage }} %</td>
<td *ngIf="paymentView || configuration.has_partial_granting">
    {{ numToStr(line.amount) | commasInInput }} <fonda-currency></fonda-currency>
</td>
<td *ngIf="!paymentView">
    <button *ngIf="foldable" (click)="toggle.emit(line.uuid)" class="btn-expand" [class.unfolded]="unfolded"></button>
</td>
