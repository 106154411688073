import {
    Directive,
    ElementRef,
    HostListener,
    Inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    SimpleChanges,
} from '@angular/core';
import { createPopper, Instance as PopperInstance, Placement } from '@popperjs/core';
import { DOCUMENT } from '@angular/common';

@Directive({
    selector: '[fondaTooltip]',
})
export class TooltipDirective implements OnChanges, OnDestroy {
    @Input('fondaTooltip') text;
    div: HTMLDivElement;

    @Input() placement: Placement = 'top';
    popperInstance: PopperInstance | null = null;

    constructor(private _eref: ElementRef, private ngZone: NgZone, @Inject(DOCUMENT) private document: Document) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.text && this.div) {
            this.div.textContent = this.text;
        }
    }

    @HostListener('mouseenter') public onMouseOver() {
        this.div = document.createElement('div');
        this.div.classList.add('tooltip');
        this.div.textContent = this.text;
        this.document.body.appendChild(this.div);
        this.ngZone.runOutsideAngular(() => {
            this.destroy();
            this.popperInstance = createPopper(this._eref.nativeElement, this.div, {
                placement: this.placement,
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 5],
                        },
                    },
                ],
            });
        });
    }

    @HostListener('mouseleave') public onMouseLeave() {
        if (this.div && this.div.parentNode) this.div.parentNode.removeChild(this.div);
        this.destroy();
    }

    destroy() {
        if (this.popperInstance) this.popperInstance.destroy();
        this.popperInstance = null;
    }

    ngOnDestroy() {
        this.onMouseLeave();
    }
}
