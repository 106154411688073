<ng-container *isNotOnPWA>
    <div *ngIf="!isReadonly && !isOnSearch">
        <form [formGroup]="form">
            <ng-container *ngIf="configuration && configuration.expenses_display_mode !== 'none'">
                <h3>
                    {{ uuid | translate : 'expenses_title' }}
                    <span *ngIf="metadata.isRequired" class="mandatory">*</span>
                </h3>
                <economy-expense-multiline-edit
                    *ngIf="
                        configuration.expenses_display_mode === 'multi_lines_only' ||
                        configuration.expenses_display_mode === 'multi_lines_and_total'
                    "
                    [recordUuid]="uuid"
                    [economyGroups]="economyGroups"
                    [hasEconomyGroup]="configuration.expenses_have_economy_group"
                    [hasAttachments]="configuration.expenses_have_attachments"
                    [hasAttachmentsMandatory]="configuration.expenses_attachments_are_mandatory"
                    formControlName="expenses"
                    [total]="configuration.expenses_display_mode === 'multi_lines_and_total'"
                ></economy-expense-multiline-edit>
                <economy-record-total-edit
                    *ngIf="configuration.expenses_display_mode === 'total_only'"
                    [recordUuid]="uuid"
                    formControlName="expenses"
                    label="expenses_overall"
                ></economy-record-total-edit>
            </ng-container>
            <ng-container *ngIf="configuration && configuration.incomes_display_mode !== 'none'">
                <h3>
                    {{ uuid | translate : 'incomes_title' }}
                    <span *ngIf="metadata.isRequired" class="mandatory">*</span>
                </h3>
                <economy-income-multiline-edit
                    *ngIf="
                        configuration.incomes_display_mode === 'multi_lines_only' ||
                        configuration.incomes_display_mode === 'multi_lines_and_total'
                    "
                    [recordUuid]="uuid"
                    [hasAttachments]="configuration.incomes_have_attachments"
                    [hasAttachmentsMandatory]="configuration.incomes_attachments_are_mandatory"
                    formControlName="incomes"
                    [total]="configuration.incomes_display_mode === 'multi_lines_and_total'"
                ></economy-income-multiline-edit>
                <economy-record-total-edit
                    *ngIf="configuration.incomes_display_mode === 'total_only'"
                    [recordUuid]="uuid"
                    formControlName="incomes"
                    label="incomes_overall"
                ></economy-record-total-edit>
            </ng-container>
            <section class="application-economy">
                <div class="form-item readonly budget valuta">
                    <input
                        id="application-economy-wanted-amount-financial"
                        type="text"
                        [fondaMoneyInput]="financialNeeds"
                        placeholder="0"
                        [disabled]="true"
                    />
                    <label for="application-economy-wanted-amount-financial">{{
                        uuid | translate : 'financial_needs'
                    }}</label>
                    <div class="form-focus"></div>
                    <div class="form-error"></div>
                </div>
                <economy-record-will-apply-for-edit
                    *ngIf="configuration && configuration.show_apply_for"
                    [recordUuid]="uuid"
                    formControlName="willApplyFor"
                ></economy-record-will-apply-for-edit>
            </section>
        </form>
    </div>

    <a *ngIf="isReadonly" class="full">
        <ng-container *ngIf="configuration">
            <form [formGroup]="form">
                <ul>
                    <li class="full economy">
                        <h3>{{ uuid | translate : 'expenses_title' }}</h3>

                        <economy-record-total-view
                            *ngIf="configuration.expenses_display_mode === 'total_only'"
                            [recordUuid]="uuid"
                            [value]="form.value.expenses"
                            label="expenses_overall"
                        >
                        </economy-record-total-view>
                        <economy-expense-multiline-view
                            *ngIf="
                                configuration.expenses_display_mode === 'multi_lines_only' ||
                                configuration.expenses_display_mode === 'multi_lines_and_total'
                            "
                            [recordUuid]="uuid"
                            [economyGroups]="economyGroups"
                            [hasAttachments]="configuration.expenses_have_attachments"
                            [hasEconomyGroup]="configuration.expenses_have_economy_group"
                            [total]="configuration.expenses_display_mode === 'multi_lines_and_total'"
                            [value]="form.value.expenses"
                        ></economy-expense-multiline-view>
                    </li>
                </ul>

                <ul>
                    <li class="full economy">
                        <h3>{{ uuid | translate : 'income_title' }}</h3>

                        <economy-record-total-view
                            *ngIf="configuration.incomes_display_mode === 'total_only'"
                            [recordUuid]="uuid"
                            [value]="form.value.incomes"
                            label="incomes_overall"
                        >
                        </economy-record-total-view>
                        <economy-income-multiline-view
                            *ngIf="
                                configuration.incomes_display_mode === 'multi_lines_only' ||
                                configuration.incomes_display_mode === 'multi_lines_and_total'
                            "
                            [recordUuid]="uuid"
                            [hasAttachments]="configuration.incomes_have_attachments"
                            [total]="configuration.incomes_display_mode === 'multi_lines_and_total'"
                            [value]="form.value.incomes"
                        ></economy-income-multiline-view>
                    </li>
                </ul>

                <section class="application-economy">
                    <ul>
                        <h3>{{ uuid | translate : 'financial_needs' }}</h3>
                        <li class="full" [style.padding-top]="'10px'">
                            <div class="economy-readonly-item">
                                <p>{{ uuid | translate : 'financial_needs' }}</p>
                                <div>
                                    <span class="valuta">{{ numToStr(financialNeeds) | commasInInput }}</span>
                                </div>
                            </div>
                        </li>
                        <li class="full" [style.padding-top]="'10px'">
                            <div *ngIf="configuration.show_apply_for" class="economy-readonly-item">
                                <p>{{ uuid | translate : 'financial_will_apply_for' }}</p>
                                <div>
                                    <span class="valuta">{{ numToStr(willApplyFor) | commasInInput }}</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </section>
            </form>
        </ng-container>
    </a>

    <ng-container *ngIf="!isReadonly && isOnSearch">
        <div style="display: flex; position: relative">
            <div class="form-item between even first" [ngClass]="{ half: metadata.size === 1 }">
                <input
                    id="{{ metadata.uuid }}-economy-search-from"
                    type="text"
                    [fondaMoneyInput]="searchValue[0]"
                    (fondaMoneyInputChange)="update(0, $event)"
                />
                <label for="{{ metadata.uuid }}-economy-search-from">
                    {{ metadata.title }} {{ 'general' | translate : 'from' }}
                </label>
                <div class="form-focus"></div>
            </div>
            <div class="form-item between even second" [ngClass]="{ half: metadata.size === 1 }">
                <input
                    id="{{ metadata.uuid }}-economy-search-to"
                    type="text"
                    [fondaMoneyInput]="searchValue[1]"
                    (fondaMoneyInputChange)="update(1, $event)"
                />
                <label for="{{ metadata.uuid }}-economy-search-to">
                    {{ metadata.title }} {{ 'general' | translate : 'to' }}
                </label>
                <div class="form-focus"></div>
            </div>
        </div>
    </ng-container>
</ng-container>

<ng-container *isOnPWA>
    <ng-container *ngIf="!isReadonly && !isOnSearch && configuration">
        <form [formGroup]="form">
            <pwa-box *ngIf="configuration.expenses_display_mode !== 'none'" style="margin-bottom: 1rem">
                <div
                    pwaBoxTitle
                    style="margin-bottom: 1rem; display: flex; justify-content: space-between; align-items: center"
                >
                    <div>
                        {{ uuid | translate : 'expenses_title' }}
                        <span *ngIf="metadata.isRequired" class="mandatory">*</span>
                    </div>
                    <pwa-error-message *ngIf="errorMessage" [errorMessage]="errorMessage"></pwa-error-message>
                </div>
                <economy-expense-multiline-edit
                    *ngIf="
                        configuration.expenses_display_mode === 'multi_lines_only' ||
                        configuration.expenses_display_mode === 'multi_lines_and_total'
                    "
                    [recordUuid]="uuid"
                    [economyGroups]="economyGroups"
                    [hasEconomyGroup]="configuration.expenses_have_economy_group"
                    [hasAttachments]="configuration.expenses_have_attachments"
                    [hasAttachmentsMandatory]="configuration.expenses_attachments_are_mandatory"
                    formControlName="expenses"
                    [total]="configuration.expenses_display_mode === 'multi_lines_and_total'"
                ></economy-expense-multiline-edit>
                <economy-record-total-edit
                    *ngIf="configuration.expenses_display_mode === 'total_only'"
                    [recordUuid]="uuid"
                    formControlName="expenses"
                    label="expenses_overall"
                ></economy-record-total-edit>
            </pwa-box>
            <pwa-box>
                <ng-container pwaBoxTitle *ngIf="configuration.incomes_display_mode !== 'none'">
                    <div style="margin-bottom: 1rem">
                        {{ uuid | translate : 'incomes_title' }}
                        <span *ngIf="metadata.isRequired" class="mandatory">*</span>
                    </div>
                </ng-container>
                <ng-container *ngIf="configuration.incomes_display_mode !== 'none'">
                    <economy-income-multiline-edit
                        *ngIf="
                            configuration.incomes_display_mode === 'multi_lines_only' ||
                            configuration.incomes_display_mode === 'multi_lines_and_total'
                        "
                        [recordUuid]="uuid"
                        [hasAttachments]="configuration.incomes_have_attachments"
                        [hasAttachmentsMandatory]="configuration.expenses_attachments_are_mandatory"
                        formControlName="incomes"
                        [total]="configuration.incomes_display_mode === 'multi_lines_and_total'"
                    ></economy-income-multiline-edit>
                    <economy-record-total-edit
                        *ngIf="configuration.incomes_display_mode === 'total_only'"
                        [recordUuid]="uuid"
                        formControlName="incomes"
                        label="incomes_overall"
                    ></economy-record-total-edit>
                </ng-container>
                <pwa-alert type="success" class="is-flex-between" style="margin-top: 0.5rem">
                    <span class="dark"> {{ uuid | translate : 'financial_needs' }}: </span>
                    <span>
                        <strong>
                            {{ numToStr(financialNeeds) | commasInInput }}
                            <fonda-currency></fonda-currency>
                        </strong>
                    </span>
                </pwa-alert>
                <economy-record-will-apply-for-edit
                    *ngIf="configuration && configuration.show_apply_for"
                    [recordUuid]="uuid"
                    formControlName="willApplyFor"
                ></economy-record-will-apply-for-edit>
            </pwa-box>
        </form>
    </ng-container>
    <ng-container *ngIf="isReadonly && configuration">
        <form [formGroup]="form">
            <pwa-box style="margin-bottom: 1rem">
                <div pwaBoxTitle style="margin-bottom: 1rem">
                    {{ uuid | translate : 'expenses_title' }}
                </div>
                <economy-record-total-view
                    *ngIf="configuration.expenses_display_mode === 'total_only'"
                    [recordUuid]="uuid"
                    [value]="form.value.expenses"
                    label="expenses_overall"
                >
                </economy-record-total-view>
                <economy-expense-multiline-view
                    *ngIf="
                        configuration.expenses_display_mode === 'multi_lines_only' ||
                        configuration.expenses_display_mode === 'multi_lines_and_total'
                    "
                    [recordUuid]="uuid"
                    [economyGroups]="economyGroups"
                    [hasAttachments]="configuration.expenses_have_attachments"
                    [hasEconomyGroup]="configuration.expenses_have_economy_group"
                    [total]="configuration.expenses_display_mode === 'multi_lines_and_total'"
                    [value]="form.value.expenses"
                ></economy-expense-multiline-view>
            </pwa-box>
            <pwa-box>
                <div pwaBoxTitle style="margin-bottom: 1rem">
                    {{ uuid | translate : 'incomes_title' }}
                </div>
                <economy-record-total-view
                    *ngIf="configuration.incomes_display_mode === 'total_only'"
                    [recordUuid]="uuid"
                    [value]="form.value.incomes"
                    label="incomes_overall"
                >
                </economy-record-total-view>
                <economy-income-multiline-view
                    *ngIf="
                        configuration.incomes_display_mode === 'multi_lines_only' ||
                        configuration.incomes_display_mode === 'multi_lines_and_total'
                    "
                    [recordUuid]="uuid"
                    [hasAttachments]="configuration.incomes_have_attachments"
                    [total]="configuration.incomes_display_mode === 'multi_lines_and_total'"
                    [value]="form.value.incomes"
                ></economy-income-multiline-view>
                <pwa-alert type="success" class="is-flex-between" style="margin-top: 0.5rem">
                    <span class="dark"> {{ uuid | translate : 'financial_needs' }}: </span>
                    <span>
                        <strong>
                            {{ numToStr(financialNeeds) | commasInInput }}
                            <fonda-currency></fonda-currency>
                        </strong>
                    </span>
                </pwa-alert>
                <pwa-alert
                    type="success"
                    *ngIf="configuration.show_apply_for"
                    class="is-flex-between"
                    style="margin-top: 0.5rem"
                >
                    <span class="dark"> {{ uuid | translate : 'financial_will_apply_for' }}: </span>
                    <span>
                        <strong>
                            {{ numToStr(willApplyFor) | commasInInput }}
                            <fonda-currency></fonda-currency>
                        </strong>
                    </span>
                </pwa-alert>
            </pwa-box>
        </form>
    </ng-container>
</ng-container>
