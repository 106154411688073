<div
    class="select-component"
    (click)="clickInside()"
    id="{{ placeholder }}"
    [ngClass]="{ checkbox: checkbox, expanded: show }"
    *ngIf="showDropdown()"
    escapeClick
    (escPressed)="show = false"
>
    <div
        class="advanced-select-box-label"
        id="select-component-label{{ label }}"
        [ngClass]="{ visible: choosen[0] && label }"
    >
        <i>{{ label }}</i>
    </div>
    <ng-container *ngIf="!shouldShowValuesOnCheckbox() && !translate">
        {{ checkbox || !choosen[0] || actionSelect ? placeholder : choosen[0] }}
    </ng-container>

    <ng-container *ngIf="!shouldShowValuesOnCheckbox() && translate">
        <abbr
            [innerHTML]="
                checkbox || !choosen[0] || actionSelect ? placeholder : (choosen[0] | translate : translationArgument)
            "
        ></abbr>
    </ng-container>

    <ng-container *ngIf="shouldShowValuesOnCheckbox() && !translate">
        {{ !choosen[0] || actionSelect ? (placeholder | removeCamelcase) : (choosen.join(', ') | removeCamelcase) }}
    </ng-container>
    <ng-container *ngIf="shouldShowValuesOnCheckbox() && translate">
        {{ !choosen[0] || actionSelect ? placeholder : getTranslatedLabel(choosen) }}
    </ng-container>

    <div
        class="option-component-container"
        [ngClass]="{ 'right-placed': containerPlace == 'right' }"
        *ngIf="options && options.length > 0 && show"
    >
        <div class="option-free-search" *ngIf="options?.length > 10" (click)="$event.stopPropagation()">
            <div>
                <input
                    #textInput
                    [placeholder]="'general' | translate : 'filter'"
                    [(ngModel)]="textSearch"
                    type="text"
                />
            </div>
        </div>

        <div
            class="option-component"
            [ngClass]="{ choosen: choosen.indexOf(option) !== -1 && !actionSelect }"
            *ngFor="
                let option of options
                    | orderByPipe : sort
                    | filterWithTranslation : textSearch : translate : translationArgument
            "
            (click)="choose(option)"
        >
            <div class="checkbox-icon" *ngIf="checkbox"></div>
            <ng-container *ngIf="optionTemplate">
                <ng-container
                    *ngTemplateOutlet="optionTemplate.template; context: { $implicit: option }"
                ></ng-container>
            </ng-container>
            <ng-container *ngIf="!optionTemplate">
                <ng-container *ngIf="!translate && uppercase">{{ option | removeCamelcase }}</ng-container>
                <ng-container *ngIf="!translate && !uppercase">{{ option }}</ng-container>
                <ng-container *ngIf="translate">
                    <abbr [innerHTML]="option | translate : translationArgument"></abbr>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
