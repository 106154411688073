<div class="form-item" [ngClass]="{ half: metadata.size === 1 }" *ngIf="!isReadonly" style="margin-bottom: 40px">
    <fonda-select
        [value]="responsibleCaseworker"
        (valueChange)="chooseCaseworker($event)"
        [options]="options"
        [label]="metadata.uuid | translate : 'title'"
        [placeholder]="metadata.uuid | translate : 'title'"
        [canUnselect]="true"
        [multiple]="false"
        [boxDisplay]="false"
        [mandatory]="metadata.isRequired"
    >
        <ng-container *fondaSelectOption="let uuid">
            {{ getUsername(uuid) }}
        </ng-container>
    </fonda-select>
</div>

<a class="form-item" *ngIf="isReadonly" [ngClass]="{ full: metadata.size === 2 }">
    <p>{{ metadata.uuid | translate : 'title' }}</p>
    <span>{{ getUsername(metadata.initialValue.uuid) || '-' }}</span>
    <div class="form-focus"></div>
    <div class="form-error"></div>
</a>
