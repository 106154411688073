import { CaseworkerDto } from '../dto/caseworker-dto';

export class CaseworkerMapper {
    public static GetCaseworkers(jsonDataObject: any): Array<CaseworkerDto> {
        const caseworkers: Array<CaseworkerDto> = [];

        if (jsonDataObject) {
            jsonDataObject.forEach(caseworker => {
                caseworkers.push(
                    new CaseworkerDto(
                        caseworker.uuid,
                        caseworker.confirmed,
                        caseworker.create_date,
                        caseworker.email,
                        caseworker.type,
                        caseworker.roles,
                        caseworker.username,
                        caseworker.initials,
                        caseworker.active,
                        caseworker.can_authenticate_with_credentials,
                        caseworker.subcategories
                    )
                );
            });
        }

        return caseworkers;
    }
}
