import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface TextSubrecordValue {
    text: string;
}

export interface TextSubrecordConfiguration {
    min_length: number;
    max_length: number;
}

@Component({
    selector: 'fonda-text-subrecord',
    templateUrl: './text-subrecord.component.html',
    styleUrls: ['./text-subrecord.component.scss'],
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TextSubrecordComponent), multi: true }],
})
export class TextSubrecordComponent implements ControlValueAccessor {
    @Input() configuration: TextSubrecordConfiguration;

    value: TextSubrecordValue = { text: '' };
    isDisabled = false;
    onChange: (value: TextSubrecordValue) => void = () => {};
    onTouched: () => void = () => {};

    constructor(private readonly cdRef: ChangeDetectorRef) {}

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        this.cdRef.markForCheck();
    }

    writeValue(obj: any): void {
        if (obj && obj.text && typeof obj.text === 'string') {
            this.value = { text: obj.text };
        } else {
            this.value = { text: '' };
        }

        this.cdRef.markForCheck();
    }

    viewToModelUpdate(value: string) {
        this.value = { text: value };
        this.onChange(this.value);
    }
}
