import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PwaLabelledTextComponent } from './pwa-labelled-text.component';

import { PwaErrorMessageModule } from '../pwa-error-message/pwa-error-message.module';

const COMPONENTS = [PwaLabelledTextComponent];

@NgModule({
    declarations: [COMPONENTS],
    imports: [CommonModule, PwaErrorMessageModule],
    exports: [COMPONENTS],
})
export class PwaLabelledTextModule {}
