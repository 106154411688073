import { Component } from '@angular/core';
import { BaseRecordComponent, RecordMetadata } from '../../base-record.component';
import { FormControl, Validators } from '@angular/forms';

import { ApplicantValidator } from '../../../utils/validators/applicant-validator';
import { TextValue } from '../../values.types';

export interface UrlRecordConfiguration {
    min_length: number;
    max_length: number | null;
}

@Component({
    selector: 'url-record',
    templateUrl: 'url-record.component.html',
})
export class UrlRecordComponent extends BaseRecordComponent<TextValue, UrlRecordConfiguration> {
    text = new FormControl('', [ApplicantValidator.UrlRequired]);

    public emitValue() {
        this.onChange.emit(this.getValue());
    }

    initRecord(metadata: RecordMetadata<TextValue, UrlRecordConfiguration>) {
        if (metadata.isRequired) {
            this.text.setValidators([Validators.required, ApplicantValidator.UrlRequired]);
        }
    }

    isValid(): boolean {
        return this.text.valid;
    }

    isEmpty(): boolean {
        return !this.text.value;
    }

    setValue(value: TextValue) {
        this.text.setValue(value.text);
    }

    getValue(): TextValue {
        return { text: this.text.value };
    }

    correctValue() {
        if (this.text.valid && this.text.value) {
            if (
                this.text.value.toLowerCase().indexOf('http://') < 0 &&
                this.text.value.toLowerCase().indexOf('https://') < 0
            ) {
                this.text.setValue('http://' + this.text.value);
            }
        }

        this.emitValue();
    }
}
