import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FondaCurrencyComponent } from './currency/currency.component';

const EXPORTED_DECLARATIONS = [FondaCurrencyComponent];

@NgModule({
    imports: [CommonModule],
    declarations: EXPORTED_DECLARATIONS,
    exports: EXPORTED_DECLARATIONS,
})
export class CurrencyModule {}
