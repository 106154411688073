<ng-container *isNotOnPWA>
    <div class="form-item" *ngIf="!isDisabled">
        <fonda-select
            [value]="currentUser"
            (valueChange)="setValue($event)"
            [label]="'general' | translate : 'username'"
            [placeholder]="'general' | translate : 'choose'"
            [options]="options"
            [canUnselect]="true"
            [multiple]="false"
            [boxDisplay]="false"
        >
            <ng-container *fondaSelectOption="let uuid">
                {{ getUsername(uuid) }}
            </ng-container>
        </fonda-select>
    </div>

    <a class="form-item" *ngIf="isDisabled">
        <p>{{ 'general' | translate : 'username' }}</p>
        <span>{{ getUsername(currentUser) || '-' }}</span>
        <div class="form-focus"></div>
        <div class="form-error"></div>
    </a>
</ng-container>

<ng-container *isOnPWA>
    <ng-container *ngIf="!isDisabled">
        <pwa-select
            [value]="currentUser"
            (valueChange)="setValue($event)"
            [placeholder]="'general' | translate : 'choose'"
            [options]="options"
            [canUnselect]="true"
            [multiple]="false"
            [noBorder]="true"
        >
            <ng-container *pwaSelectOption="let uuid">
                {{ getUsername(uuid) }}
            </ng-container>
        </pwa-select>
    </ng-container>
    <ng-container *ngIf="isDisabled">
        {{ 'general' | translate : 'username' }}: {{ getUsername(currentUser) || '-' }}
    </ng-container>
</ng-container>
