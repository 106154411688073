<form>
    <div class="pdf-creator">
        <div class="form-item readonly" style="float: none">
            <input type="text" id="fileName" name="fileName" disabled [(ngModel)]="fileName" />
            <label for="fileName">{{ 'general' | translate : 'file_name' }}</label>
            <div class="form-focus"></div>
        </div>
        <div class="form-item">
            <!--<textarea-->
            <!--matTextareaAutosize-->
            <!--id="bigTextEditorPDF"-->
            <!--name="bigTextEditorPDF"-->
            <!--[(ngModel)]="bodyText"-->
            <!--placeholder=" "-->
            <!--type="text"-->
            <!--rows="5"-->
            <!--&gt;</textarea>-->
            <!--<label for="bigTextEditorPDF">-->
            <!--{{'messages' | translate: 'pdf_body_text'}}-->
            <!--</label>-->
            <!---->
            <text-editor [(text)]="bodyShare"></text-editor>
            <div class="form-focus"></div>
        </div>
        <div class="actions">
            <button class="button white-blue" (click)="previewPdf()">
                {{ 'messages' | translate : 'pdf_preview' }}
            </button>
            <button class="button color-blue" (click)="generatePdf()">
                {{ 'messages' | translate : 'pdf_create' }}
            </button>
        </div>
    </div>
</form>
