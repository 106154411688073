<label *ngIf="label" class="select-box-label">
    <ng-container *ngIf="translateLabel">{{ label | translate : 'title' }}</ng-container>
    <ng-container *ngIf="!translateLabel">{{ label }}</ng-container>
    <span *ngIf="mandatory" class="mandatory">*</span>
</label>

<div
    class="select-box"
    [ngClass]="{
        open: show,
        'color-mode': colorMode,
        green: isActualValue(paymentColor.Green),
        orange: isActualValue(paymentColor.Orange)
    }"
    (click)="clickInside()"
    escapeClick
    (escPressed)="show = false"
>
    <ng-container *ngIf="translate">
        <abbr class="on-field">{{
            selectedValue ? (selectedValue | translate : argumentTranslation) : placeholder
        }}</abbr>
    </ng-container>
    <ng-container *ngIf="!translate">
        <abbr class="on-field">{{ (selectedValue ? selectedValue : placeholder) | removeCamelcase }}</abbr>
    </ng-container>

    <div class="dropdown-container" *ngIf="show && values?.length > 0 && !colorMode">
        <div class="dropdown-element text-search" (click)="$event.stopPropagation()" *ngIf="values?.length > 10">
            <input
                #textInput
                [placeholder]="'general' | translate : 'filter'"
                type="text"
                [(ngModel)]="massSearchText"
            />
        </div>
        <div class="dropdown-element" *ngIf="includeNoOneOption" (click)="choose(globalVariables.NO_ONE_VARIABLE)">
            <abbr>{{ 'general' | translate : 'none' }}</abbr>
        </div>
        <div
            class="dropdown-element"
            *ngFor="let value of values | filterWithTranslation : massSearchText : translate : argumentTranslation"
            (click)="choose(value)"
        >
            <ng-container *ngIf="!translate">
                <abbr>{{ value | removeCamelcase }}</abbr>
            </ng-container>
            <ng-container *ngIf="translate">
                <abbr>{{ value | translate : argumentTranslation }}</abbr>
            </ng-container>
        </div>
    </div>

    <div class="dropdown-container" *ngIf="show && valuesWithColor?.length > 0 && colorMode">
        <div class="dropdown-element" *ngFor="let value of valuesWithColor" (click)="chooseValueWithColor(value)">
            <ng-container *ngIf="!translate">
                <abbr
                    [ngClass]="{
                        green: value.color === paymentColor.Green,
                        orange: value.color === paymentColor.Orange
                    }"
                    >{{ value.value | removeCamelcase }}</abbr
                >
            </ng-container>
            <ng-container *ngIf="translate">
                <abbr
                    [ngClass]="{
                        green: value.color === paymentColor.Green,
                        orange: value.color === paymentColor.Orange
                    }"
                    >{{ value.value | translate : argumentTranslation }}</abbr
                >
            </ng-container>
        </div>
    </div>
</div>

<select [ngModel]="selectedValue" (ngModelChange)="choose($event)">
    <option value="" disabled *ngIf="placeholder">
        {{ translate ? (placeholder | translate) : (placeholder | removeCamelcase) }}
    </option>
    <ng-container *ngFor="let value of values">
        <option *ngIf="value" value="{{ value }}">
            <ng-container *ngIf="translate">{{ value | translate : argumentTranslation }}</ng-container>
            <ng-container *ngIf="!translate">{{ value | removeCamelcase }}</ng-container>
        </option>
    </ng-container>
</select>
