import { Component } from '@angular/core';
import { BaseRecordComponent, RecordMetadata } from '../../base-record.component';

type MoneyAmountRecordValue = [number | null, number | null];

export interface MoneyAmountRecordOutput {
    text: number | [number, number];
}

@Component({
    selector: 'money-amount-record',
    templateUrl: 'money-amount-record.component.html',
})
export class MoneyAmountRecordComponent extends BaseRecordComponent<MoneyAmountRecordOutput, null> {
    value: MoneyAmountRecordValue = [null, null];

    initRecord(metadata: RecordMetadata<MoneyAmountRecordOutput, null>) {}

    emitValue() {
        this.onChange.emit(this.getValue());
    }

    isValid(): boolean {
        return this.metadata.isRequired && typeof this.value[0] === 'number';
    }

    isEmpty(): boolean {
        return this.value.every(value => value === null);
    }

    getValue(): MoneyAmountRecordOutput {
        if (this.isOnSearch) {
            return { text: [this.value[0], this.value[1]] };
        }
        return { text: this.value[0] };
    }

    setValue(value: MoneyAmountRecordOutput) {
        if (this.isOnSearch) {
            if (Array.isArray(value.text) && value.text.length === 2 && value.text.every(v => typeof v === 'number')) {
                this.value = [value.text[0], value.text[1]];
            }
        } else {
            if (typeof value.text === 'number') {
                this.value = [value.text, null];
            }
        }
    }

    update(index: 0 | 1, value: number) {
        this.value = this.value.slice() as MoneyAmountRecordValue;
        this.value.splice(index, 1, value);
        this.emitValue();
    }
}
