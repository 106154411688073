import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UtilsModule } from '../../../shared/utils/utils.module';
import { PwaButtonModule } from '../pwa-button/pwa-button.module';
import { TranslationModule } from '../../../shared/translation/translation.module';

import { PwaPopupComponent } from './pwa-popup.component';
import { PwaDeleteConfirmationPopupComponent } from './pwa-delete-confirmation-popup/pwa-delete-confirmation-popup.component';

const COMPONENTS = [PwaPopupComponent, PwaDeleteConfirmationPopupComponent];

@NgModule({
    declarations: [COMPONENTS],
    imports: [CommonModule, UtilsModule, TranslationModule, PwaButtonModule],
    exports: [COMPONENTS],
})
export class PwaPopupModule {}
