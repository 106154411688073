import { Injectable } from '@angular/core';
import {
    AccountCorrespondenceThreadData,
    AccountCorrespondenceThreadMessageData,
} from './account-correspondence-threads/account-correspondence-threads-data';
import { FondaApiService } from '../api/fonda-api.service';

@Injectable()
export class CorrespondenceService {
    public constructor(private apiService: FondaApiService) {}

    public fetchApplicantThreads(applicantUuid: string | null): Promise<Array<AccountCorrespondenceThreadData>> {
        if (!applicantUuid) {
            return new Promise<Array<AccountCorrespondenceThreadData>>((resolve, reject) => []);
        }

        return this.apiService
            .getApplicantThreads(applicantUuid)
            .then(threads => {
                const resultThreads = [];
                threads.forEach(thread => {
                    const messages: Array<AccountCorrespondenceThreadMessageData> = [];
                    thread.messages.forEach(message => {
                        messages.push(
                            new AccountCorrespondenceThreadMessageData(
                                message.uuid,
                                message.sentAt,
                                message.body && message.body.replace(/&nbsp;/g, ' '),
                                message.sender,
                                message.attachements
                            )
                        );
                    });
                    resultThreads.push(
                        new AccountCorrespondenceThreadData(
                            thread.uuid,
                            thread.creator.username,
                            this.getLastDate(messages.map(message => message.sentAt)),
                            thread.locked,
                            thread.title,
                            messages,
                            thread.read,
                            thread.readByApplicant
                        )
                    );
                });
                return resultThreads;
            })
            .catch(() => {
                return [];
            });
    }

    private getLastDate(dates: Array<Date>): Date {
        let lastDate = dates[0];

        dates.forEach(date => {
            if (lastDate < date) {
                lastDate = date;
            }
        });

        return lastDate;
    }
}
