import {
    Component,
    EventEmitter,
    Input,
    Output,
    ChangeDetectionStrategy,
    OnChanges,
    SimpleChanges,
    OnInit,
    OnDestroy,
    ChangeDetectorRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { PwaRedirectionService } from '../../../../../pwa/core/pwa-redirection.service';
import { IPaymentRequesterRecordConfigurationData } from '../../../../../shared/record-configuration/configurations/payment-requester-record-configuration/payment-requester-record-configuration.component';
import { convertToString } from '../../economy-record/economy-converter';
import { PaymentsBalanceService } from '../../payment-record/payment-balance.service';
import {
    PaymentGrantedBlocksDto,
    PaymentRequesterDto,
    PaymentStatusDto,
    PaymentTypeDto,
} from '../../payment-record/payment-types';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';

@Component({
    selector: 'payment-block',
    templateUrl: './payment-block.component.html',
    styleUrls: ['payment-block.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentBlockComponent implements OnChanges, OnInit, OnDestroy {
    @Input() payments: PaymentRequesterDto[] = [];
    @Input() block: PaymentGrantedBlocksDto = null;
    @Input() uuid: string;
    @Input() configuration: IPaymentRequesterRecordConfigurationData;
    @Input() readonly: boolean = false;
    @Input() economyGroup: boolean = false;
    @Input() paymentProfiles = [];
    @Input() boardMeetings = [];
    @Input() grantedAmount: number;
    @Input() nextStatusBtn: boolean = false;
    @Input() children: Array<PaymentGrantedBlocksDto> = [];
    @Output() addPayment = new EventEmitter();
    @Output() editPayment = new EventEmitter();
    @Output() deletePayment = new EventEmitter();
    @Output() changePaymentStatus = new EventEmitter<{
        payment: PaymentRequesterDto;
        status: string;
    }>();

    expanded: string;
    routeSub$ = Subscription.EMPTY;
    breakSub$ = Subscription.EMPTY;
    isDesktop: boolean = true;

    showChildren: boolean = false;

    constructor(
        private paymentsBalanceService: PaymentsBalanceService,
        private pwaRedirectionService: PwaRedirectionService,
        private activatedRoute: ActivatedRoute,
        private route: ActivatedRoute,
        private cdRef: ChangeDetectorRef,
        private breakpointObserver: BreakpointObserver
    ) {}

    get statuses(): PaymentStatusDto[] {
        if (!this.configuration.payment_statuses) return [];
        return this.configuration.payment_statuses;
    }

    get hasGrantedAmount() {
        return typeof this.grantedAmount === 'number';
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.payments) {
            this.orderPaymentsByDateAsc();
            this.calcPaymentsBalance();
        }
    }

    ngOnInit() {
        this.routeSub$ = this.route.queryParams.subscribe(params => {
            this.expanded = params['grant_identifier'];
            this.cdRef.markForCheck();
        });

        this.breakSub$ = this.breakpointObserver
            .observe('(min-width: 1024px)')
            .pipe(map(state => state.matches))
            .subscribe(isDesktop => {
                this.isDesktop = isDesktop;
                this.cdRef.markForCheck();
            });
    }

    onAdd() {
        this.addPayment.emit(null);
    }

    onAddBlock(block) {
        this.addPayment.emit(block);
    }

    onEdit(payment) {
        this.editPayment.emit(payment);
    }

    onDelete(payment) {
        this.deletePayment.emit(payment);
    }

    onStatus(payment: PaymentRequesterDto) {
        this.changePaymentStatus.emit({
            payment: payment,
            status: this.getExpectedNextStatus(payment.payment_status),
        });
    }

    getPaymentProfileByUuid(uuid) {
        return this.paymentProfiles.find(p => p.uuid === uuid);
    }

    getPaymentProfileName(uuid) {
        const profile = this.getPaymentProfileByUuid(uuid);
        return profile ? profile.name : '';
    }

    getBoardMeetingByUuid(uuid: string) {
        return this.boardMeetings.find(bm => bm.uuid === uuid);
    }

    getBoardMeetingName(uuid: string) {
        const boardMeeting = this.getBoardMeetingByUuid(uuid);
        return boardMeeting ? boardMeeting.name : '';
    }

    getExpectedNextStatus(statusName: string) {
        const status = this.statuses.find(s => s.name === statusName);
        return status ? status.expected_next_status : null;
    }

    getExpectedNextStatusPointer(statusName: string) {
        const nextStatus = this.getExpectedNextStatus(statusName);
        return nextStatus ? `forward_to_${nextStatus}` : null;
    }

    orderPaymentsByDateAsc() {
        this.payments = this.payments.slice().sort((a, b) => {
            return new Date(a.date).getTime() - new Date(b.date).getTime();
        });
    }

    isNextStatusShown(payment_status: string) {
        const nextStatus = this.getExpectedNextStatus(payment_status);
        if (nextStatus === 'exported' || nextStatus === 'paid') return false;
        return true;
    }

    calcPaymentsBalance() {
        let granted = 0;
        let requested = 0;

        if (this.block) granted = this.block.amount;
        else granted = this.grantedAmount;

        this.payments.forEach(p => {
            if (!this.affectsBalance(p.payment_status) || !p.amount) {
                p.balance = granted - requested;
            } else {
                if (p.type === PaymentTypeDto.PAID || p.type === PaymentTypeDto.CANCELED) {
                    p.balance = parseFloat(granted.toFixed(2)) - parseFloat(requested.toFixed(2)) - parseFloat(p.amount.toFixed(2));
                    requested += p.amount;
                } else if (p.type === PaymentTypeDto.REFUNDED) {
                    p.balance = parseFloat(granted.toFixed(2)) - parseFloat(requested.toFixed(2)) + parseFloat(p.amount.toFixed(2));
                    requested -= p.amount;
                }
            }
        });
    }

    affectsBalance(status): boolean {
        return this.paymentsBalanceService.affectsBalance(status, this.statuses);
    }

    numToStr(amount) {
        return convertToString(amount);
    }

    toRequestPayment(grantIdentifier: string, paymentIdentifier: string | null) {
        const params = { uuid: this.activatedRoute.snapshot.queryParams.uuid };

        if (paymentIdentifier) params['payment_identifier'] = paymentIdentifier;
        if (grantIdentifier) params['grant_identifier'] = grantIdentifier;

        this.pwaRedirectionService.toRequestPayment(params);
    }

    ngOnDestroy() {
        this.routeSub$.unsubscribe();
        this.breakSub$.unsubscribe();
    }
}
