import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    QueryList,
    Renderer2,
    SimpleChanges,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { LanguageStoreService } from '../../../services/language-store.service';
import { BulletPointWithCoordsDto, CircleCoordDto } from '../../../api/dto/login-page-dto/bullet-point-dto';
import { LoginPageSettingsDto } from '../../../api/dto/login-page-dto/login-page-dto';
import { Subscription } from 'rxjs';

@Component({
    selector: 'fonda-circle-diagram',
    templateUrl: './circle-diagram.component.html',
    styleUrls: ['./circle-diagram.component.scss'],
})
export class CircleDiagramComponent implements OnChanges, AfterViewInit, OnInit, OnDestroy {
    @ViewChildren('mobileCircle') mobileCircles: QueryList<ElementRef>;
    @ViewChild('line') line: ElementRef;

    @Input() loginPageSettings: LoginPageSettingsDto;
    langSub$ = Subscription.EMPTY;

    circlesCoords: CircleCoordDto[] = [
        { top: 10, left: 30 },
        { top: 25, left: 52.5 },
        { top: 45, left: 37.5 },
        { top: 60, left: 70 },
        { top: 72.5, left: 51.5 },
        { top: 92.5, left: 57.5 },
    ];

    bulletPoints: BulletPointWithCoordsDto[] = [];

    lineHeight: number;

    get numberOfActivated(): number {
        return this.loginPageSettings.diagram_section_bullet_points.reduce(
            (acc, p) => (p.is_activated ? (acc += 1) : (acc += 0)),
            0
        );
    }

    get lastActivatedIndex(): number {
        return this.numberOfActivated - 1;
    }

    constructor(
        private renderer: Renderer2,
        private languageStoreService: LanguageStoreService,
        private cdRef: ChangeDetectorRef
    ) {}

    @HostListener('window:resize')
    onResize() {
        this.calcMobileLineHeight();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.loginPageSettings) {
            this.setCirclesCoordsSettings();
        }
    }

    ngOnInit() {
        this.langSub$ = this.languageStoreService.languageChange.subscribe(() => {
            setTimeout(() => {
                this.calcMobileLineHeight();
                this.cdRef.markForCheck();
            });
        });
    }

    ngAfterViewInit() {
        this.calcMobileLineHeight();
    }

    setCirclesCoordsSettings() {
        this.bulletPoints = [];
        if (this.loginPageSettings.diagram_section_bullet_points) {
            this.loginPageSettings.diagram_section_bullet_points.forEach(bullet => {
                this.bulletPoints.push({
                    sequence: bullet.sequence,
                    is_activated: bullet.is_activated,
                    has_rejected_outcome: bullet.has_rejected_outcome,
                    top: this.calcTop(this.circlesCoords[bullet.sequence].top),
                    left: this.circlesCoords[bullet.sequence].left,
                });
            });
        }
    }

    hasActiveBullets() {
        let hasActiveBullets = false;

        this.bulletPoints.forEach(point => {
            if (point.is_activated) {
                hasActiveBullets = true;
            }
        });

        return hasActiveBullets;
    }

    calcTop(top: number) {
        if (this.numberOfActivated === 6) return top;
        else return top * (6 / (this.numberOfActivated + 0.5));
    }

    calcMobileLineHeight() {
        if (this.mobileCircles.length > 1 && this.line) {
            const height =
                this.mobileCircles.last.nativeElement.offsetTop - this.mobileCircles.first.nativeElement.offsetTop;
            this.renderer.setStyle(this.line.nativeElement, 'height', height + 'px');
        }
    }

    getRejectedLeft(point: CircleCoordDto, even: boolean, additional: number = 0) {
        return even ? point.left - 7.5 + additional + '%' : point.left + 7.5 + additional + '%';
    }

    getRejectedTop(point: CircleCoordDto, even: boolean, additional: number = 0) {
        return even ? point.top + 17.5 + additional + '%' : point.top - 17.5 + additional + '%';
    }

    ngOnDestroy() {
        this.langSub$.unsubscribe();
    }
}
