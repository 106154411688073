import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Maybe } from 'true-myth';
import { FondaApiService } from '../api/fonda-api.service';
import { ISiteSetting } from '../api/models/site-settings.interface';
import { FondaLoggerService } from './fonda-logger.service';
import { ILanguage, LANGUAGES, LanguageStoreService } from './language-store.service';
import _camelCase from 'lodash/camelCase';

export interface ColorSiteSettings {
    successColor: string;
    successColorHover: string;
    successBackgroundColor: string;
    successBackgroundColorHover: string;
    primaryColor: string;
    primaryColorHover: string;
    primaryBackgroundColor: string;
    primaryBackgroundColorHover: string;
    heroColor: string;
    heroColorHover: string;
    heroBackgroundColor: string;
    heroBackgroundColorHover: string;
    linkColor: string;
    linkColorHover: string;
    pwaPrimaryButtonColor: string;
    pwaPrimaryButtonColorHover: string;
}

const FALLBACK_COLORS: ColorSiteSettings = {
    successColor: '#ffffff',
    successColorHover: '#ffffff',
    successBackgroundColor: '#2980b9',
    successBackgroundColorHover: '#236fa1',
    primaryColor: '#ffffff',
    primaryColorHover: '#ffffff',
    primaryBackgroundColor: '#2980b9',
    primaryBackgroundColorHover: '#236fa1',
    heroColor: '#ffffff',
    heroColorHover: '#ffffff',
    heroBackgroundColor: '#2980b9',
    heroBackgroundColorHover: '#236fa1',
    linkColor: 'inherit',
    linkColorHover: '#2980b9',
    pwaPrimaryButtonColor: '#ed7d30',
    pwaPrimaryButtonColorHover: '#e45000',
};

const FALLBACK_FONT_FAMILY = 'libre_franklin';

@Injectable()
export class SiteSettingsService {
    private readonly renderer2: Renderer2;
    private settings$ = new ReplaySubject<readonly ISiteSetting[]>(1);

    private currentSetFontFamily: string | null = null;

    constructor(
        private api: FondaApiService,
        private rendererFactory2: RendererFactory2,
        @Inject(DOCUMENT) private document: Document,
        private logger: FondaLoggerService,
        private languageStoreService: LanguageStoreService
    ) {
        this.renderer2 = this.rendererFactory2.createRenderer(document.head, null);
        this.settings$.subscribe(settings => this.updateOnSettingsLoad(settings));
        this.loadSettings();
    }

    getSetting<T = any>(setting: string): Observable<Maybe<T[]>> {
        return this.settings$.pipe(map(settings => this.getValues(settings, setting)));
    }

    getSettingSingle<T = any>(setting: string): Observable<Maybe<T>> {
        return this.settings$.pipe(map(settings => this.getValue(settings, setting)));
    }

    reload() {
        this.loadSettings();
    }

    private loadSettings(): void {
        this.api.getSiteSettings().subscribe(settings => {
            this.settings$.next(settings);
        });
    }

    private updateOnSettingsLoad(settings: readonly ISiteSetting[]) {
        this.updateFont(settings);
        this.updateColors(settings);
        this.setLanguages(settings);
    }

    private setLanguages(settings: readonly ISiteSetting[]) {
        const mapToLanguages = (languages: string[]): readonly Maybe<ILanguage>[] =>
            languages.map(language => Maybe.of(LANGUAGES.find(l => l.name === language)));
        const filterPresent = (lngs: readonly Maybe<ILanguage>[]): readonly ILanguage[] =>
            lngs.filter(lng => lng.isJust).map(lng => lng.unsafelyUnwrap());

        const siteLanguages: readonly ILanguage[] = this.getValues<string>(settings, 'languages')
            .map(mapToLanguages)
            .map(filterPresent)
            .unwrapOrElse(() => {
                this.logger.error(`Missing 'languages' site setting. Falling back to default languages!`);
                return LANGUAGES;
            });
        this.languageStoreService.setLanguageList(siteLanguages);
    }

    private updateFont(settings: readonly ISiteSetting[]) {
        const fontStylesEl = this.getHeadEl('font-family-style', 'link');
        const fontFamily = this.getValue<string>(settings, 'font_family').unwrapOrElse(() => {
            this.logger.error(`Missing font_family site setting! Loaded fallback ${FALLBACK_FONT_FAMILY}`);
            return FALLBACK_FONT_FAMILY;
        });

        if (this.currentSetFontFamily === fontFamily) return;
        this.currentSetFontFamily = fontFamily;

        this.renderer2.setAttribute(fontStylesEl, 'rel', 'stylesheet');
        this.renderer2.setAttribute(fontStylesEl, 'type', 'text/css');
        this.renderer2.setAttribute(fontStylesEl, 'href', `/${fontFamily}.css`);
    }

    private updateColors(settings: readonly ISiteSetting[]) {
        const styleEl = this.getHeadEl('site-setting-styles', 'style');
        const css = this.generateCss(settings);
        this.renderer2.setProperty(styleEl, 'innerHTML', css);
    }

    private generateCss(settings: readonly ISiteSetting[]) {
        const getColor = color => this.getValue(settings, color).unwrapOr(FALLBACK_COLORS[_camelCase(color)]);
        const colors: ColorSiteSettings = {
            successColor: getColor('success_color'),
            successColorHover: getColor('success_color_hover'),
            successBackgroundColor: getColor('success_background_color'),
            successBackgroundColorHover: getColor('success_background_color_hover'),
            primaryColor: getColor('primary_color'),
            primaryColorHover: getColor('primary_color_hover'),
            primaryBackgroundColor: getColor('primary_background_color'),
            primaryBackgroundColorHover: getColor('primary_background_color_hover'),
            heroColor: getColor('hero_color'),
            heroColorHover: getColor('hero_color_hover'),
            heroBackgroundColor: getColor('hero_background_color'),
            heroBackgroundColorHover: getColor('hero_background_color_hover'),
            linkColor: getColor('link_color'),
            linkColorHover: getColor('link_color_hover'),
            pwaPrimaryButtonColor: getColor('pwa_primary_button_color'),
            pwaPrimaryButtonColorHover: getColor('pwa_primary_button_color_hover'),
        };

        return this.rootColors(colors);
    }

    private rootColors(colors: ColorSiteSettings): string {
        return `
            :root {
                --success-color: ${colors.successColor};
                --success-color-hover: ${colors.successColorHover};
                --success-background-color: ${colors.successBackgroundColor};
                --success-background-color-hover: ${colors.successBackgroundColorHover};
                --primary-color: ${colors.primaryColor};
                --primary-color-hover: ${colors.primaryColorHover};
                --primary-background-color: ${colors.primaryBackgroundColor};
                --primary-background-color-hover: ${colors.primaryBackgroundColorHover};
                --hero-color: ${colors.heroColor};
                --hero-color-hover: ${colors.heroColorHover};
                --hero-background-color: ${colors.heroBackgroundColor};
                --hero-background-color-hover: ${colors.heroBackgroundColorHover};
                --link-color: ${colors.linkColor};
                --link-color-hover: ${colors.linkColorHover};
                --pwa-primary-button-color: ${colors.pwaPrimaryButtonColor};
                --pwa-primary-button-color-hover: ${colors.pwaPrimaryButtonColorHover};
            }
        `;
    }

    private getValues<T = any>(settings: readonly ISiteSetting[], option: string): Maybe<T[]> {
        return Maybe.of(settings.find(opt => opt.name === option))
            .map(v => v.value)
            .map(v => (Array.isArray(v) ? v : [v]));
    }

    private getValue<T = any>(settings: readonly ISiteSetting[], option: string): Maybe<T> {
        return Maybe.of(settings.find(opt => opt.name === option))
            .map(v => v.value)
            .map(v => (Array.isArray(v) ? v[0] : v));
    }

    private getHeadEl(id: string, elementType: string): HTMLElement {
        const el = this.document.getElementById(id);
        if (el) return el;

        const newEl = this.renderer2.createElement(elementType);
        this.renderer2.setAttribute(newEl, 'id', id);
        this.renderer2.appendChild(document.head, newEl);
        return newEl;
    }
}
