import { Component, Input } from '@angular/core';
import { UUID } from '../../../../../models/uuid';
import { convertToString } from '../economy-converter';

@Component({
    selector: 'economy-expense-multiline-view',
    templateUrl: './economy-expense-multiline-view.component.html',
    styleUrls: ['../economy-record.scss'],
})
export class EconomyExpenseMultilineViewComponent {
    @Input() value: Array<{
        title: string;
        amount: number;
        economy_group?: string;
        attachments: Array<{
            uuid: string;
            file_name: string;
            mime_type: string;
            size: string;
        }>;
    }> = [];
    @Input() hasAttachments: boolean = false;
    @Input() hasEconomyGroup: boolean = false;
    @Input() economyGroups: Array<string>;
    @Input() recordUuid: UUID;
    @Input() total: boolean = false;
    @Input() label: string;

    numToStr(amount) {
        return convertToString(amount);
    }

    get totalValue() {
        let amount = 0;
        this.value.forEach(expense => {
            amount += expense.amount;
        });
        return amount;
    }
}
