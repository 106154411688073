<div #leftElement [style.min-width]="minWidth">
    {{ 'general' | translate : 'show' }}
    <select-component
        [choosen]="[perPage.toString()]"
        (getChoosen)="updatePerPage($event[0])"
        [options]="options"
    ></select-component>
    {{ 'general' | translate : 'out_of' }} {{ total }} {{ 'general' | translate : 'results' }}
</div>

<ul class="pagination">
    <li *ngIf="currentPage > 0">
        <a (click)="updateCurrentPage(currentPage - 1)" class="icon-arrow-left"> </a>
    </li>
    <li *ngFor="let page of pages">
        <a (click)="updateCurrentPage(page - 1)" [class.active]="currentPage === page - 1">
            {{ page }}
        </a>
    </li>
    <li *ngIf="currentPage < pagesCount - 1">
        <a (click)="updateCurrentPage(currentPage + 1)" class="icon-arrow-right"> </a>
    </li>
</ul>

<div class="actions-right" #rightElement [style.min-width]="minWidth">
    <ng-content></ng-content>
</div>
