<pwa-popup (close)="cancel()" size="small">
    <ng-container pwa-popup-header></ng-container>
    <div>{{ 'general' | translate : 'are_you_sure_you_want_to_delete' }} ?</div>
    <div class="pwa-delete-confirmation-popup-actions" pwa-popup-actions>
        <button pwaButton color="gray" (click)="cancel()">
            {{ 'general' | translate : 'cancel' }}
        </button>
        <button pwaButton color="pink" (click)="confirm()">
            {{ 'general' | translate : 'delete' }}
        </button>
    </div>
</pwa-popup>
