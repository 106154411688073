import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import {
    ResponsibleSelectComponent,
    ResponsibleSelectOptionDirective,
} from './responsible-select/responsible-select.component';

const EXPORTED_DECLARATIONS = [ResponsibleSelectComponent, ResponsibleSelectOptionDirective];

@NgModule({
    declarations: EXPORTED_DECLARATIONS,
    exports: EXPORTED_DECLARATIONS,
    imports: [CommonModule, SharedModule],
})
export class CorrespondenceSharedModule {}
