<div class="communication-head" *ngIf="isActiveNow" (window:resize)="onResize($event)" style="display: block">
    <div style="justify-content: space-between">
        <h2>{{ 'messages' | translate : 'title' }}</h2>
        <ul>
            <li>
                <ng-content></ng-content>
            </li>
            <li>
                <a (click)="openCorrespondencePopup()" class="button">{{
                    'messages' | translate : 'topbar_new_message'
                }}</a>
            </li>
            <li>
                <a class="icon-refresh" (click)="initialiseThreadsCoponent()"><a class="refresh"></a></a>
            </li>
            <li>
                <ng-content select="[main-button]"></ng-content>
            </li>
        </ul>
    </div>
</div>
<div class="communication-inbox" *ngIf="isActiveNow" (window:resize)="onResize($event)" style="position: relative">
    <div
        class="communication-message-item"
        *ngFor="let thread of threads"
        [class.communication-message-item-seen]="thread.read"
        [class.communication-message-item-not-seen]="!thread.read"
        (click)="clickOnThread(thread)"
    >
        <span>{{ thread.creator }}</span>
        <span>
            <span>{{ thread.title }}</span>
            <ng-container *ngIf="thread.messages && thread.messages.length > 1">
                &nbsp;({{ thread.messages.length }})
            </ng-container>
            <span class="message-text" *ngIf="thread.messages && thread.messages.length">
                &nbsp;-&nbsp;{{ getLastMessageFirstWords(thread.messages) }}
            </span>
            <span class="applicant-seen message-text" *ngIf="thread.readByApplicant && !isApplicant">
                &nbsp;({{ 'messages' | translate : 'seen_by_applicant' }})
            </span>
        </span>
        <span>
            <message-date-display [createdAt]="thread.createdAt"></message-date-display>
        </span>
    </div>
    <div *ngIf="!threads || threads.length < 1" class="no-messages" style="text-align: center">
        <div class="icon-paperplane"></div>
        <abbr>{{ 'messages' | translate : 'no_messages' }}</abbr>
        <a class="button color-blue" (click)="openCorrespondencePopup()">{{
            'messages' | translate : 'no_messages_send_message'
        }}</a>
    </div>

    <div class="overlay" style="position: absolute" *ngIf="isLoading">
        <div style="position: relative; height: 100%; width: 100%">
            <div class="loader-container" style="position: absolute">
                <div class="loader"></div>
            </div>
        </div>
    </div>
</div>
<section
    class="communication-message-container"
    *ngIf="isActiveNow"
    [ngClass]="{ active: showMessages && isActiveNow }"
>
    <form style="margin-bottom: 0">
        <div class="communication-message-header">
            <div class="communication-message-header-actions">
                <a class="icon-arrow" (click)="showMessages = false"></a>
                <h2>{{ threadPicked && threadPicked.title }}</h2>
            </div>
            <ng-container *ngIf="showMessages">
                <ng-content select="[in-message-button]"></ng-content>
            </ng-container>
        </div>
        <div class="dialogue" setHeight offset="381" id="message-window">
            <div *ngFor="let message of messagesToView" [ngClass]="{ 'align-right': message.sender.uuid === userUuid }">
                <div
                    class="message"
                    [ngClass]="{ other: message.sender.uuid !== userUuid, user: message.sender.uuid === userUuid }"
                >
                    <h3>
                        {{ message.sender.username }}
                        <ng-container *ngIf="message.sender.type == 'applicant'"> (ansøger) </ng-container>
                    </h3>
                    <p
                        class="hyphenate text"
                        [ngStyle]="{ 'max-width': textWidth + 'px' }"
                        [innerHTML]="message.body"
                    ></p>
                    <div class="correspondence-file">
                        <!--<div class="file-icon"><div class="file-download"></div></div>-->
                        <div class="file" *ngFor="let attachment of message.attachments">
                            <a
                                [ngClass]="{
                                    other: message.sender.uuid !== userUuid,
                                    user: message.sender.uuid === userUuid
                                }"
                                (click)="downloadFile(attachment.uuid)"
                                >{{ attachment.fileName }}</a
                            >
                        </div>
                    </div>
                    <span>
                        {{ message.sentAt | date : 'HH:mm dd-MM-yyyy' }}
                        <!--<ng-container *ngIf="threadPicked?.readByApplicant">-->
                        <!--{{'messages' | translate : 'seen_by_applicant'}}-->
                        <!--</ng-container>-->
                    </span>
                </div>
            </div>
        </div>

        <div class="texting" *ngIf="threadPicked && !threadPicked.locked">
            <div>
                <text-editor
                    #textCkeEditor
                    [text]="textEdit"
                    (textChange)="handleTextChange($event)"
                    (onFileAdding)="addFile($event)"
                ></text-editor>
            </div>

            <form *ngIf="filesUploaded.length > 0">
                <div class="form-item" style="float: none">
                    <div class="upload" style="margin: 15px 0">
                        <div class="upload-head thread" *ngFor="let file of filesUploaded">
                            <div class="upload-filename">{{ file.fileName }}</div>
                            <div class="upload-size">{{ file.size | fileSize }}</div>
                            <div class="upload-delete" (click)="deleteFile(file.uuid)">
                                <a class="delete-file"><div class="icon-delete-file"></div></a>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="actions" *ngIf="showMessages">
                <button class="button color-blue" (click)="clickSend(threadPicked && threadPicked.uuid)">
                    <span *ngIf="isApplicant">
                        {{ 'general' | translate : 'send_message' }}
                    </span>
                    <span *ngIf="!isApplicant">
                        {{ 'general' | translate : 'send_message_to' }} {{ accountEmail }}
                    </span>
                </button>
                <b style="color: red">{{ errorMessage }}</b>
            </div>
        </div>
    </form>
</section>
<!---->
