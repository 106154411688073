import { CorrespondenceMessageAttachmentDto } from '../../api/dto/correspondence-dto/correspondence-message-attachment-dto';
import { BackendUserData } from '../../api/dto/backend-user-data';

export class AccountCorrespondenceThreadData {
    constructor(
        readonly uuid: string,
        readonly creator: string,
        readonly createdAt: Date,
        readonly locked: boolean,
        readonly title: string,
        readonly messages: Array<AccountCorrespondenceThreadMessageData>,
        public read: boolean,
        readonly readByApplicant: boolean
    ) {}
}

export class AccountCorrespondenceThreadMessageData {
    constructor(
        readonly uuid: string,
        readonly sentAt: Date,
        readonly body: string,
        readonly sender: BackendUserData,
        readonly attachments: Array<CorrespondenceMessageAttachmentDto>
    ) {}
}
