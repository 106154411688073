import * as pdfMake from 'pdfmake/build/pdfmake';
import { FileService } from '../services/file.service';
import { Inject, Injectable } from '@angular/core';
import { BoardMeetingAgendaDto } from '../api/dto/board-meeting/board-meeting-agenda-dto';

declare const PDFMAKEVFS: any;

export class SectionData {
    sectionName: string;
    isCategory: boolean;
    manualText: string;
    applications: Array<{
        applicationName: string;
        files: Array<string>;
    }>;
}

export interface SectionListInterface {
    sections: SectionData[];
    boardMeetingData: BoardMeetingAgendaDto;
}

@Injectable()
export class PdfListService {
    constructor(@Inject(FileService) private fileService: FileService) {
        pdfMake.vfs = PDFMAKEVFS;
    }

    public getBlobPdf(entry: SectionListInterface): Promise<string> {
        const content: Array<object> = [];

        entry.sections.forEach(section => {
            const olAr = [];

            olAr.push({
                text: section.sectionName,
                style: 'subHeader',
            });
            section.applications.forEach(application => {
                const ulAr = [];
                ulAr.push({ text: application.applicationName, style: 'subSubHeader' });
                olAr.push({
                    ul: ulAr,
                });
            });
            if (section.applications.length == 0) {
                const ulAr = [];
                ulAr.push({ text: 'Ingen ansøgninger indstillet til bestyrelsesmødet.', style: 'subSubHeader' });
                olAr.push({
                    ul: ulAr,
                });
            }

            content.push({
                type: 'none',
                ul: olAr,
            });
        });

        const pdf = {
            pageSize: 'LEGAL',
            content,
            styles: {
                body: {
                    marginLeft: 140,
                    marginRight: 140,
                    fontSize: 10,
                },
                subSubHeader: {
                    fontSize: 14,
                    marginTop: 5,
                },
                subHeader: {
                    fontSize: 16,
                    marginTop: 10,
                },
                header: {
                    fontSize: 19,
                    marginTop: 15,
                },
            },
            pageMargins: [20, 20, 20, 60],
        };

        return new Promise(resolve => {
            pdfMake.createPdf(pdf).getDataUrl((dataURL: string) => {
                resolve(dataURL);
            });
        });
    }

    public getHtmlList(entry: SectionListInterface): string {
        const result = [];

        const months = [
            'januar',
            'februar',
            'marts',
            'april',
            'maj',
            'juni',
            'juli',
            'august',
            'september',
            'oktober',
            'november',
            'december',
        ];

        const date = new Date(entry.boardMeetingData.date);

        // tslint:disable:max-line-length
        result.push(`
            <div>
                Bestyrelsen afholder sit ${entry.boardMeetingData.name}. møde ${date.getDate()}. ${
            months[date.getMonth()]
        } ${date.getFullYear()}
            <br/> <br/> <br/> <br/> <span style='font-size: 13pt;'>DAGSORDEN</span>
            </div>
        `);
        // tslint:enable:max-line-length

        entry.sections.forEach(section => {
            result.push(this.getDivHtml(section.sectionName, 30));

            if (section.isCategory) {
                section.applications.forEach(application => {
                    result.push(this.getDivHtml(application.applicationName));
                });
            }

            if (section.manualText) {
                result.push(this.getDivHtml(section.manualText));
            }

            if (section.applications.length === 0) {
                result.push(this.getDivHtml('Ingen ansøgninger indstillet til bestyrelsesmødet.'));
            }
        });

        return `<div style="width:500px;">
                    ${result.join('')}
                </div>`;
    }

    private getDivHtml(content: string, padding: number = 10): string {
        return `<div style='width: 100%;padding: ${padding}px 0px 0px 0px;'>
                    <table style='width:100%;'>
                            <tr style='width:100%;'>
                                <td style='width:100%; text-align: left;'>${this.correctContent(content)}</td>
                            </tr>
                    </table>
                </div>`;
    }

    /**
     * PhpWord library works only with <br/>
     * We cannot have stray & in the text, needs to be encoded as &amp;
     *
     * @param content {string} Content to encode
     * @private
     */
    private correctContent(content: string) {
        return content.replace(/(<br \/>)|(<br >)|(<br>)/g, '<br/>').replace(/&(?!amp;)/g, '&amp;');
    }
}
