export class GrantedAmountObject {
    board_meeting: string;
    amount: string;
    economy_group: string;
    name: string;
    attachments?: Array<{
        file_size: number;
        file_name: string;
        uuid: string;
    }>;
    partial_granting_percentage?: number;
    original_full_amount?: number;
    is_originally_from_applicant: boolean; // original line -> cannot be removed
    new_line?: boolean; // not saved new line -> editable original amount
    uuid?: string;
    parentUuid?: string;
}
