import { Inject, Injectable } from '@angular/core';
import { RecordValueOutputDto } from './dto/record-value-output-dto';
import { ApplicationDto } from './dto/application-dto';
import { AuthService } from '../auth/auth.service';
import 'rxjs/add/operator/toPromise';
import { ApplicantDto } from './dto/applicant-dto';
import { ApplicationResponseMapper } from './mappers/application-response-mapper';
import { FileDTO } from './dto/file-dto';
import { PaginationDto } from './dto/search-dto/pagination-dto';
import { FiltersDto } from './dto/search-dto/filters-dto';
import { SearchResponseMapper } from './mappers/search-response-mapper';
import { SearchResponseDto } from './dto/search-dto/search-response-dto';
import { ApplicationTemplateDto } from './dto/search-dto/application-template-dto';
import { CaseworkerApplicationDto } from './dto/caseworker-application-dto';
import { SavedSearchDto } from './dto/search-dto/saved-search-dto';
import { SavedSearchResponseDto } from './dto/search-dto/saved-search-response-dto';
import {
    HttpClient,
    HttpEvent,
    HttpEventType,
    HttpHeaders,
    HttpParams,
    HttpRequest,
    HttpResponse,
    HttpUrlEncodingCodec,
} from '@angular/common/http';
import { BoardMeetingDto } from './dto/board-meeting/board-meeting-dto';
import { ApplicationLogDto } from './dto/application-log-dto';
import { ApplicationLockStatusDto } from './dto/application-lock-status-dto';
import { CorrespondenceTemplateManualDto } from './dto/correspondence-dto/correspondence-template-manual-dto';
import { CorrespondenceTemplatePopulateDto } from './dto/correspondence-dto/correspondence-template-populate-dto';
import { CorrespondenceThreadDto } from './dto/correspondence-dto/correspondence-thread-dto';
import { CorrespondenceMapper } from './mappers/correspondence-mapper';
import { CaseworkerDto } from './dto/caseworker-dto';
import { CaseworkerMapper } from './mappers/caseworker-mapper';
import { BackendUserData } from './dto/backend-user-data';
import { UploadedFondaFile } from '../models/uploaded-fonda-file';
import { CorrespondenceTokenValueDto } from './dto/correspondence-dto/correspondence-token-value-dto';
import { UserPostDto } from './dto/user-post-dto';
import { CorrespondenceMessageDto } from './dto/correspondence-dto/correspondence-message-dto';
import { FiscalYearDto } from './dto/fiscal-year-dto';
import { NotificationMapper } from './mappers/notification-mapper';
import { FondaApiGlobalVariables } from './fonda-api-global-variables';
import { CaseworkerFileDto } from './dto/caseworker-file-dto';
import { PaymentMapper } from './mappers/payment-mapper';
import { BudgetDto } from './dto/post-grant-dto/budget-dto';
import { FiscalYearPaymentDto } from './dto/post-grant-dto/fiscal-year-payment-dto';
import { BackendApplicationPaymentDto } from './dto/backend-application-payment-dto';
import { CountryDto } from './dto/country-dto';
import { ApiResponseErrorAdapter } from './api-response-error-adapter';
import { ToasterService } from 'angular2-toaster';
import { ApiResponseToasterHelper } from '../services/api-response-toaster-service';
import { TranslationDto } from './dto/translation-dto/translation-dto';
import { TranslationResponseMapper } from './mappers/translation-response-mapper';
import { CategoryPaymentDto } from './dto/post-grant-dto/category-payment-dto';
import { ReversedPaymentDto } from './dto/post-grant-dto/reversed-payment-dto';
import { CategoryReversedPaymentDto } from './dto/post-grant-dto/category-reversed-payment-dto';
import { TranslationLogDto } from './dto/translation-dto/translation-log-dto';
import { SectionUpdateAllDto } from './dto/translation-dto/section-update-all-dto';
import { CallSearchMapper } from './mappers/call-search-mapper';
import { GroupFiltersDto } from './dto/search-dto/group-filters-dto';
import { GroupSearchResponseDto } from './dto/search-dto/group-search-response-dto';
import { NotificationDto } from './dto/notification-dto';
import { UPDATE_APPLICATION_TRANSLATION, UPDATE_STATUS_TRANSLATION } from '../models/error-interface';
import { WorksDatabaseDto } from './dto/works-database-dto/works-database-dto';
import { WorksDatabaseMapper } from './mappers/works-database-mapper';
import { BoardMeetingMapper } from './mappers/board-meeting-mapper';
import { BoardMeetingAgendaDto } from './dto/board-meeting/board-meeting-agenda-dto';
import { AgendaSectionsCategory } from './dto/board-meeting/agenda-sections-category';
import { AgendaSectionsManual } from './dto/board-meeting/agenda-sections-manual';
import { GetBoardMeetingNoteDto } from './dto/board-meeting/get-board-meeting-note-dto';
import { PaymentProfileDto } from './dto/payment-profile-dto/payment-profile-dto';
import { PatchBoardMeetingAgendaDto } from './dto/board-meeting/patch-board-meeting-agenda-dto';
import { Observable, throwError } from 'rxjs';
import { catchError, map, pluck, tap } from 'rxjs/operators';
import { TokenDto } from './dto/token.dto';
import { IApplicationSchema } from './models/application-schema.interface';
import { ISiteSetting } from './models/site-settings.interface';
import { TranslateService } from '../shared/translation/translate.service';
import { API_BASE_URL } from './api-base-url';
import { SearchColumn } from './models/search-columns';
import { OrderBy } from './models/order-by';
import { Pagination } from './models/pagination';
import { GetApplicantSearchResponse } from './models/get/get-applicant-search';
import { UUID } from '../models/uuid';
import { FondaAccount } from '../models/fonda-account';
import { GetApplicationsResponse } from './models/get/get-applications-response';
import { GetBackendApplicantApplications } from './models/get/get-backend-applicant-applications';
import { Email } from '../models/email';
import { SectionTypes } from './models/section-types';
import { AppSchemaBehaviour } from './models/app-schema-behaviour';
import { CategoryDto } from './dto/category.dto';
import { ApplicationSchema } from './dto/application-schema';
import {
    CreateCustomListDto,
    CustomListDto,
    CustomListMapper,
    GetCustomListApiResponse,
    GetCustomListCollectionApiResponse,
    UpdateCustomListDto,
} from './dto/custom-list';
import {
    DocumentTypeDto,
    FileDocumentDto,
} from '../caseworker/documents/documents-section/documents-section.component';
import { LoginPageSettingsWithTextsDto } from './dto/login-page-dto/login-page-dto';
import { ManualSectionTitle } from './dto/board-meeting/manual-section-title';
import {
    ExportedPaymentBatchDto,
    PaymentProcessorExportDto,
    PaymentProcessorExportResponseDto,
    PaymentProcessorDto,
} from './dto/payment-processor-dto/payment-processor-dto';
import { RegionsReportRecapDto } from './dto/economy-report-dto';

@Injectable()
export class FondaApiService {
    public _getApplication: Array<object> = [];
    public _getBackendApplication: Array<object> = [];
    private _getApplicationPaymentProfiles: { [uuid: string]: Promise<Array<PaymentProfileDto>> };
    private _getApplicationTemplate: Promise<ApplicationTemplateDto>;
    private _getBoardMeetings: Promise<Array<BoardMeetingDto>>;
    private _getBackendUsersCaseworker: Promise<Array<CaseworkerDto>>;
    private _getCaegorySubCategories: { [key: string]: Promise<Array<{ uuid: string; categoryUuid: string }>> };
    private _getBackendUsers: Promise<Array<CaseworkerDto>>;
    private _getCountries: Promise<Array<CountryDto>>;
    private _allSubCategoriesCache: Promise<Array<{ uuid: string; categoryUuid: string }>>;

    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private globalVar: FondaApiGlobalVariables,
        private translateService: TranslateService,
        private toasterService: ToasterService,
        @Inject(API_BASE_URL) private apiBaseUrl: string
    ) {}

    public resetCache() {
        this._getBackendUsersCaseworker = null;
        this._getBoardMeetings = null;
        this._getApplicationTemplate = null;
        this._getBackendUsers = null;
        this._getCaegorySubCategories = null;
    }

    public getApplication(applicationUuid: string): Promise<ApplicationDto> {
        return this.http
            .get<any>(this.apiBaseUrl + '/application/' + applicationUuid, { headers: this.defaultHeaders() })
            .toPromise()
            .then(res => ApplicationResponseMapper.getApplication(res))
            .catch(error => {
                this.handleErrorResponse(error, 'get-application');
                throw error;
            });
    }

    public getApplicationObservable(applicationUuid: string): Observable<ApplicationDto> {
        return this.http
            .get<any>(this.apiBaseUrl + '/application/' + applicationUuid, { headers: this.defaultHeaders() })
            .pipe(map(res => ApplicationResponseMapper.getApplication(res)));
    }

    public getTranslations(parameters: {
        language?: string;
        type?: string;
        identifier?: string;
        subIdentifier?: string;
    }): Observable<Array<TranslationDto>> {
        let params = new HttpParams();
        if (parameters && parameters.language) {
            params = params.append('language', parameters.language);
        }
        if (parameters && parameters.type) {
            params = params.append('type', parameters.type);
        }
        if (parameters && parameters.identifier) {
            params = params.append('identifier', parameters.identifier);
        }
        if (parameters && parameters.subIdentifier) {
            params = params.append('subIdentifier', parameters.subIdentifier);
        }

        return this.http
            .get(this.apiBaseUrl + '/translations', { headers: this.defaultHeaders(), params: params })
            .pipe(
                map(res => {
                    return TranslationResponseMapper.GetTranslation(res);
                }),
                this.logError()
            );
    }

    public resetPaymentProfiles() {
        this._getApplicationPaymentProfiles = null;
    }

    public getApplicationPaymentProfiles(applicationUuid: string): Promise<Array<PaymentProfileDto>> {
        if (!this._getApplicationPaymentProfiles) {
            this._getApplicationPaymentProfiles = {};
        }
        if (!this._getApplicationPaymentProfiles[applicationUuid]) {
            this._getApplicationPaymentProfiles[applicationUuid] = this.http
                .get(this.apiBaseUrl + '/application/' + applicationUuid + '/payment-profiles', {
                    headers: this.defaultHeaders(),
                })
                .toPromise()
                .then(res => {
                    return PaymentMapper.getPaymentProfiles(res);
                })
                .catch(error => {
                    this.handleErrorResponse(error);
                    throw error;
                });
        }
        return this._getApplicationPaymentProfiles[applicationUuid];
    }

    public getTranslationLogs(uuid: string): Promise<Array<TranslationLogDto>> {
        return this.http
            .get(this.apiBaseUrl + '/translation/' + uuid + '/logs', { headers: this.defaultHeaders() })
            .toPromise()
            .then(res => {
                return TranslationResponseMapper.GetTranslationLogs(res);
            })
            .catch(error => {
                throw error;
            });
    }

    postFileCreateWordFromHtml(htmlContent: string): Observable<HttpEvent<string>> {
        const base64HtmlContent = btoa(encodeURIComponent(htmlContent));

        return this.http
            .post<{ docx_base64_contents: string }>(
                this.apiBaseUrl + '/create-word-from-html',
                { base64_html: base64HtmlContent },
                { headers: this.defaultHeaders(), observe: 'events', reportProgress: true, responseType: 'json' }
            )
            .pipe(
                this.logError(),
                map(event => {
                    if (event.type === HttpEventType.Response) {
                        return event.clone({ body: event.body.docx_base64_contents }) as HttpEvent<string>;
                    }

                    return event as HttpEvent<string>;
                })
            );
    }

    public postFileCreatePdfFromHtml(_htmlContent: string): Promise<any> {
        const htmlContent = btoa(encodeURIComponent(_htmlContent));

        return this.http
            .post(
                this.apiBaseUrl + '/create-pdf-from-html',
                { base64_html: htmlContent },
                { headers: this.defaultHeaders() }
            )
            .toPromise()
            .then(res => {
                const json: any = res;
                return json['pdf_base64_contents'];
            })
            .catch(error => {
                this.handleErrorResponse(error);
                throw error;
            });
    }

    public postFileConvertToPdf(uuid: string): Promise<string> {
        return this.http
            .post(this.apiBaseUrl + '/file/convert-to-pdf', { file_uuid: uuid }, { headers: this.defaultHeaders() })
            .toPromise()
            .then(res => {
                const json: any = res;
                return json['file_uuid'];
            })
            .catch(error => {
                this.handleErrorResponse(error);
                throw error;
            });
    }

    public deletePaymentProfile(profileUuid: string): Promise<void> {
        return this.http
            .delete(this.apiBaseUrl + '/payment-profile/' + profileUuid, { headers: this.defaultHeaders() })
            .toPromise()
            .then(res => {
                return;
            })
            .catch(error => {
                this.handleErrorResponse(error, 'delete-payment-profile');
                throw error;
            });
    }

    public getPayments(query: {
        page: number;
        perPage: number;
        category?: string;
        applicationSchema?: string;
        boardMeeting?: string;
        from?: string;
        to?: string;
        paymentType?: string;
        paymentStatus?: string;
    }): Observable<any[]> {
        let params = new HttpParams();

        params = params.append('page', query.page.toString());
        params = params.append('per_page', query.perPage.toString());

        if (query.category) params = params.append('category', query.category);
        if (query.applicationSchema) params = params.append('application_schema', query.applicationSchema);
        if (query.boardMeeting) params = params.append('board_meeting', query.boardMeeting);
        if (query.from) params = params.append('from', query.from);
        if (query.to) params = params.append('to', query.to);
        if (query.paymentType) params = params.append('payment_type', query.paymentType);
        if (query.paymentStatus) params = params.append('payment_status', query.paymentStatus);

        return this.http.get<any[]>(this.apiBaseUrl + '/payments', { params: params, headers: this.defaultHeaders() });
    }

    public getPaymentBatches(query: {
        page: number;
        perPage: number;
        from?: string;
        to?: string;
        economy_group?: string;
    }): Observable<{
        payment_batches: ExportedPaymentBatchDto[];
        total: number;
    }> {
        let params = new HttpParams();

        params = params.append('page', query.page.toString());
        params = params.append('per_page', query.perPage.toString());

        if (query.economy_group) params = params.append('economy_group', query.economy_group);
        if (query.from) params = params.append('from', query.from);
        if (query.to) params = params.append('to', query.to);

        return this.http.get<{
            payment_batches: ExportedPaymentBatchDto[];
            total: number;
        }>(this.apiBaseUrl + '/payment-batches', {
            params: params,
            headers: this.defaultHeaders(),
        });
    }

    public reexportPayments(data: PaymentProcessorDto) {
        return this.http
            .post(this.apiBaseUrl + '/reexport-payments-novemberfirst', data, {
                responseType: 'blob',
                observe: 'response',
                headers: this.defaultHeaders(),
            })
            .pipe(map(res => this.createBlobFile(res)));
    }

    public reexportPayments_V2(data: PaymentProcessorDto) {
        return this.http
            .post(this.apiBaseUrl + '/export-payments', data, {
                responseType: 'blob',
                observe: 'response',
                headers: this.defaultHeaders(),
            })
            .pipe(map(res => this.createBlobFile(res)));
    }

    public exportPayments(data: PaymentProcessorExportDto) {
        return this.http
            .post(this.apiBaseUrl + '/export-payments', data, {
                responseType: 'blob',
                observe: 'response',
                headers: this.defaultHeaders(),
            })
            .pipe(map(res => this.createBlobFile(res)));
    }

    public exportPaymentsNovemberFirst(data: PaymentProcessorExportDto): Observable<PaymentProcessorExportResponseDto> {
        return this.http.post<PaymentProcessorExportResponseDto>(
            this.apiBaseUrl + '/export-payments-novemberfirst',
            data
        );
    }

    public getApplicationPayments(applicationUuid: string): Promise<Array<BackendApplicationPaymentDto>> {
        return this.http
            .get(this.apiBaseUrl + '/application/' + applicationUuid + '/payments', { headers: this.defaultHeaders() })
            .toPromise()
            .then(res => {
                const payments: Array<BackendApplicationPaymentDto> = [];

                const json: any = res;

                json.forEach(payment => {
                    payments.push(
                        new BackendApplicationPaymentDto(
                            payment.application_uuid,
                            payment.date,
                            payment.name,
                            payment.amount,
                            payment.uuid
                        )
                    );
                });

                return payments;
            })
            .catch(error => {
                this.handleErrorResponse(error);
                throw error;
            });
    }

    public postPayment(dto: BackendApplicationPaymentDto): Promise<void> {
        const json = {
            application_uuid: dto.applicationUuid,
            date: dto.date,
            name: dto.name,
            amount: dto.amount,
        };

        return this.http
            .post(this.apiBaseUrl + '/payment', json, { headers: this.defaultHeaders() })
            .toPromise()
            .then(() => {
                return;
            })
            .catch(error => {
                this.handleErrorResponse(error);
                throw error;
            });
    }

    patchApplicationStatusToDraft({ applicationUuid }: { applicationUuid: string }): Observable<void> {
        const json = {
            application_uuid: applicationUuid,
            new_status: 'draft',
        };
        return this.http
            .patch<void>(this.apiBaseUrl + `/application/` + applicationUuid + `/status`, json, {
                headers: this.defaultHeaders(),
            })
            .pipe(
                this.logError(),
                tap(() => this.handleSuccessResponse('update-record'))
            );
    }

    public patchApplicationSchemaUpdateRecordSection(
        uuid: string,
        data: { record_uuid: string; new_section: number }
    ): Observable<void> {
        return this.http
            .patch<void>(`${this.apiBaseUrl}/application-schema/${uuid}/update-record-section`, data, {
                headers: this.defaultHeaders(),
            })
            .pipe(this.logError());
    }

    public patchApplicationSchemaUpdateSectionRecordSequences(
        uuid: string,
        section: { record_type: string; record_section: number },
        records: Array<{ record_uuid: string; sequence: number }>
    ): Observable<void> {
        const payload: {
            record_type: string;
            record_section: number;
            record_sequences: Array<{ record_uuid: string; sequence: number }>;
        } = {
            ...section,
            record_sequences: records,
        };

        return this.http
            .patch<void>(`${this.apiBaseUrl}/application-schema/${uuid}/update-section-record-sequences`, payload, {
                headers: this.defaultHeaders(),
            })
            .pipe(this.logError());
    }

    public putApplicationSchemaRecord(
        applicationSchemaUuid: string,
        recordUuid: string,
        data: {
            field_size: 1 | 2;
            is_required: boolean | null;
            configuration: {
                [key: string]: any;
            };
        }
    ): Observable<void> {
        return this.http
            .put<void>(`${this.apiBaseUrl}/application-schema/${applicationSchemaUuid}/record/${recordUuid}`, data, {
                headers: this.defaultHeaders(),
            })
            .pipe(
                this.logError('update-record'),
                tap(() => this.handleSuccessResponse('update-record'))
            );
    }

    public patchTranslationSectionUpdateAll(dto: SectionUpdateAllDto): Promise<void> {
        const json = {
            ref_uuid: dto.refUuid,
            type: dto.type,
            section: dto.section,
            texts: dto.texts,
        };
        return this.http
            .patch(this.apiBaseUrl + '/translation/section/update-all', json, { headers: this.defaultHeaders() })
            .toPromise()
            .then(() => {
                return;
            })
            .catch(error => {
                this.handleErrorResponse(error);
                throw error;
            });
    }

    public deletePayment(uuid: string): Promise<void> {
        return this.http
            .delete(this.apiBaseUrl + '/payment/' + uuid, { headers: this.defaultHeaders() })
            .toPromise()
            .then(() => {
                return;
            })
            .catch(error => {
                this.handleErrorResponse(error);
                throw error;
            });
    }

    public patchTranslation(text: string, uuid: string): Promise<void> {
        const json = {
            text: text,
        };

        return this.http
            .patch(this.apiBaseUrl + '/translation/' + uuid, json, { headers: this.defaultHeaders() })
            .toPromise()
            .then(() => {
                this.handleSuccessResponse('update-translation');
            })
            .catch(error => {
                this.handleErrorResponse(error, 'update-translation');
                throw error;
            });
    }

    public postBoardMeetingAgenda(uuid: string): Promise<void> {
        const json = {
            board_meeting_uuid: uuid,
        };

        return this.http
            .post(this.apiBaseUrl + '/board-meeting-agenda', json, { headers: this.defaultHeaders() })
            .toPromise()
            .then(() => {
                return;
            })
            .catch(error => {
                this.handleErrorResponse(error);
                throw error;
            });
    }

    public putBoardMeetingAgenda(
        uuid: string,
        sections: Array<AgendaSectionsCategory | AgendaSectionsManual>,
        manualSectionTitles: Array<ManualSectionTitle>,
        isAgendaPublished: boolean
    ): Promise<void> {
        const json = {
            is_published: isAgendaPublished,
            new_agenda_sections: BoardMeetingMapper.PutBoardMeetingAgenda(sections),
            manual_section_titles: manualSectionTitles,
        };
        return this.http
            .put(this.apiBaseUrl + '/board-meeting-agenda/' + uuid, json, { headers: this.defaultHeaders() })
            .toPromise()
            .then(() => {
                return;
            })
            .catch(error => {
                this.handleErrorResponse(error);
                throw error;
            });
    }

    public putBoardMeeting(boardMeeting: PatchBoardMeetingAgendaDto): Promise<void> {
        const json = {
            uuid: boardMeeting.uuid,
            name: boardMeeting.name,
            fiscal_year: boardMeeting.fiscalYear,
            date: boardMeeting.date,
            accepting_application_last_date: boardMeeting.acceptingApplicationLastDate,
            summary_file: boardMeeting.summary
                ? {
                      file_name: boardMeeting.summary.fileName,
                      file_size: boardMeeting.summary.size,
                      uuid: boardMeeting.summary.uuid,
                  }
                : null,
        };
        return this.http
            .put(this.apiBaseUrl + '/board-meeting/' + boardMeeting.uuid, json, { headers: this.defaultHeaders() })
            .toPromise()
            .then(() => {
                return;
            })
            .catch(error => {
                this.handleErrorResponse(error);
                throw error;
            });
    }

    public putAccountDefaultLanguage(uuid: string, language: string): Promise<void> {
        const json = {
            language: language,
        };

        return this.http
            .put(this.apiBaseUrl + '/account/' + uuid + '/default-language', json, { headers: this.defaultHeaders() })
            .toPromise()
            .then(() => {
                return;
            })
            .catch(error => {
                this.handleErrorResponse(error);
                throw error;
            });
    }

    public getApplicationFiles(uuid: string): Promise<Array<CaseworkerFileDto>> {
        return this.http
            .get(this.apiBaseUrl + '/application/' + uuid + '/files', { headers: this.defaultHeaders() })
            .toPromise()
            .then(res => {
                const json: any = res;
                const files: Array<CaseworkerFileDto> = [];

                json.forEach(file => {
                    files.push(
                        new CaseworkerFileDto(
                            file.application_uuid,
                            file.resource_type,
                            file.resource,
                            new BackendUserData(
                                file.uploader ? file.uploader.initials : '',
                                file.uploader ? file.uploader.type : '',
                                file.uploader ? file.uploader.username : '',
                                file.uploader ? file.uploader.uuid : ''
                            ),
                            file.deletable,
                            file.file_uuid,
                            file.file_name,
                            file.file_size,
                            file.sub_resource_type
                        )
                    );
                });

                return files;
            })
            .catch(error => {
                this.handleErrorResponse(error);
                throw error;
            });
    }

    public postFileCombinePdfZip(zipBase64: string, boardMeetingName: string): Promise<string> {
        const json = {
            zip_base64: zipBase64,
            board_meeting_name: boardMeetingName,
        };

        return this.http
            .post(this.apiBaseUrl + '/file/combine-pdf-zip', json, { headers: this.defaultHeaders() })
            .toPromise()
            .then(res => {
                const json: any = res;
                return json.file_uuid.uuid;
            })
            .catch(err => {
                this.handleErrorResponse(err, 'undefined');
                throw err;
            });
    }

    public getApplications(): Promise<Array<ApplicationDto>> {
        return this.http
            .get<GetApplicationsResponse>(this.apiBaseUrl + '/applications', { headers: this.defaultHeaders() })
            .pipe(
                map(res => res.map(ApplicationResponseMapper.getApplication)),
                this.logError()
            )
            .toPromise();
    }

    public getApplicationsObservable(): Observable<Array<ApplicationDto>> {
        return this.http
            .get<GetApplicationsResponse>(this.apiBaseUrl + '/applications', { headers: this.defaultHeaders() })
            .pipe(
                map(res => res.map(ApplicationResponseMapper.getApplication)),
                this.logError()
            );
    }

    public deleteApplication(uuid: string): Promise<void> {
        return this.http
            .delete(this.apiBaseUrl + '/application/' + uuid, { headers: this.defaultHeaders() })
            .toPromise()
            .then(() => {
                this.handleSuccessResponse('delete-application');
                return;
            })
            .catch(error => {
                this.handleErrorResponse(error, 'delete-application');
                throw error;
            });
    }

    public patchApplication(
        applicationUuid: string,
        values: Array<RecordValueOutputDto>,
        showSuccessMessage: boolean,
        status: string
    ): Promise<void> {
        const json = {
            record_values: values,
        };

        return this.http
            .patch(this.apiBaseUrl + '/application/' + applicationUuid, json, { headers: this.defaultHeaders() })
            .toPromise()
            .then(() => {
                if (showSuccessMessage) {
                    this.handleSuccessResponse(UPDATE_APPLICATION_TRANSLATION + '-' + status);
                }
                return;
            })
            .catch(error => {
                this.handleErrorResponse(error, UPDATE_APPLICATION_TRANSLATION + '-' + status);
                throw error;
            });
    }

    public patchPaymentProfile(name: string, values: Array<RecordValueOutputDto>, uuid: string): Promise<void> {
        const json = {
            name: name,
            record_values: values.length > 0 ? values : null,
        };

        return this.http
            .patch(this.apiBaseUrl + '/payment-profile/' + uuid, json, { headers: this.defaultHeaders() })
            .toPromise()
            .then(() => {
                this.handleSuccessResponse(UPDATE_APPLICATION_TRANSLATION);
                return;
            })
            .catch(error => {
                throw new ApiResponseErrorAdapter(error && error.error);
            });
    }

    public postPaymentProfile(applicationUuid: string, name: string, prefiller: boolean): Promise<void> {
        const json = {
            use_prefiller: prefiller,
            application_uuid: applicationUuid,
            name: name,
        };

        return this.http
            .post(this.apiBaseUrl + '/payment-profile', json, { headers: this.defaultHeaders() })
            .toPromise()
            .then(() => {
                return;
            })
            .catch(err => {
                this.handleErrorResponse(err);
                throw err;
            });
    }

    public patchBackendApplication(
        applicationUuid: string,
        values: Array<RecordValueOutputDto>,
        status: string
    ): Promise<void> {
        const json = {
            record_values: values,
        };

        return this.http
            .patch(this.apiBaseUrl + '/backend/application/' + applicationUuid, json, {
                headers: this.defaultHeaders(),
            })
            .toPromise()
            .then(() => {
                this.handleSuccessResponse('update-application' + '-' + status);
                return;
            })
            .catch(error => {
                throw new ApiResponseErrorAdapter(error && error.error);
            });
    }

    patchBackendApplicationVisibleByApplicant(uuid: string, isVisibleByApplicant: boolean): Observable<void> {
        return this.http
            .patch<void>(
                `${
                    this.apiBaseUrl
                }/backend/application/${uuid}/visible-by-applicant/${isVisibleByApplicant.toString()}`,
                ''
            )
            .pipe(
                this.logError(),
                tap(() => this.handleSuccessResponse(isVisibleByApplicant ? 'show-application' : 'hide-application'))
            );
    }

    public getBackendRoles(): Promise<Array<string>> {
        return this.http
            .get(this.apiBaseUrl + '/backend-roles', { headers: this.defaultHeaders() })
            .toPromise()
            .then(res => {
                const roles: any = res;
                const resRoles: Array<string> = [];
                roles.forEach(role => {
                    resRoles.push(role);
                });

                return resRoles;
            })
            .catch(error => {
                this.handleErrorResponse(error);
                throw error;
            });
    }

    public patchApplicationStatus(applicationUuid: string, status: string, oldStatus: string): Promise<void> {
        const json = {
            new_status: status,
        };

        return new Promise((resolve, reject) =>
            this.http
                .patch(this.apiBaseUrl + '/application/' + applicationUuid + '/status', json, {
                    headers: this.defaultHeaders(),
                })
                .toPromise()
                .then(() => {
                    this.handleSuccessResponse(UPDATE_STATUS_TRANSLATION + '-' + oldStatus + '-to-' + status);
                    resolve();
                })
                .catch(error => {
                    this.handleErrorResponse(error, UPDATE_STATUS_TRANSLATION + '-' + oldStatus + '-to-' + status);
                    reject(
                        new ApiResponseErrorAdapter(
                            error && error.error,
                            UPDATE_STATUS_TRANSLATION + '-to-' + oldStatus + '-' + status
                        )
                    );
                })
        );
    }

    public patchBackendApplicationStatus(
        applicationUuid: string,
        status: string,
        oldStatus: string,
        filesUploaded: Array<UploadedFondaFile> = [],
        sendToUniconta: boolean = false
    ): Promise<void> {
        const pdfAttachments: Array<{
            uuid: string;
            file_name: string;
            file_size: number;
        }> = [];

        if (Array.isArray(filesUploaded) && filesUploaded?.length) {
            filesUploaded.forEach(attachment => {
                pdfAttachments.push({
                    uuid: attachment.uuid,
                    file_name: attachment.fileName,
                    file_size: attachment.size,
                });
            });
        }

        const json = {
            new_status: status,
            pdf: pdfAttachments,
            send_to_uniconta: sendToUniconta
        };

        return new Promise((resolve, reject) =>
            this.http
                .patch(this.apiBaseUrl + '/backend/application/' + applicationUuid + '/status', json, {
                    headers: this.defaultHeaders(),
                })
                .toPromise()
                .then(() => {
                    this.handleSuccessResponse(UPDATE_STATUS_TRANSLATION + '-' + oldStatus + '-to-' + status);
                    resolve();
                })
                .catch(error => {
                    reject(
                        new ApiResponseErrorAdapter(
                            error && error.error,
                            UPDATE_STATUS_TRANSLATION + '-to-' + oldStatus + '-' + status
                        )
                    );
                })
        );
    }

    public patchBackendApplicationPostGrantStatus(applicationUuid: string, status: string, sendToUniconta:boolean = false): Promise<void> {
        const json = {
            new_status: status,
            send_to_uniconta: sendToUniconta
        };

        return new Promise((resolve, reject) =>
            this.http
                .patch(this.apiBaseUrl + '/backend/application/' + applicationUuid + '/post-grant-status', json, {
                    headers: this.defaultHeaders(),
                })
                .toPromise()
                .then(() => {
                    this.handleSuccessResponse('update-status');
                    resolve();
                })
                .catch(error => {
                    // this.handleErrorResponse(error, 'update-status');
                    reject(new ApiResponseErrorAdapter(error && error.error, 'update-status'));
                })
        );
    }

    public getBackendApplicationLogs(applicationUuid: string, lastLogId: number): Promise<Array<ApplicationLogDto>> {
        let params = new HttpParams();
        params = params.append('batchSize', '10');
        params = params.append('fromId', lastLogId.toString());

        return this.http
            .get(this.apiBaseUrl + '/backend/application/' + applicationUuid + '/logs', {
                headers: this.defaultHeaders(),
                params: params,
            })
            .toPromise()
            .then(res => {
                const logs: any = res;
                const applicationLogs: Array<ApplicationLogDto> = [];
                logs.forEach(log => {
                    const backendProfile = new BackendUserData(
                        log.changer_profile.initials,
                        log.changer_profile.type,
                        log.changer_profile.username,
                        log.changer_profile.uuid
                    );
                    const tmpLog = new ApplicationLogDto(
                        log.id,
                        backendProfile,
                        log.application_uuid,
                        log.occurred_date,
                        log.type,
                        log.data,
                        log.data.record_configuration
                    );

                    tmpLog.recordBehaviour = log.data.record_behaviour;
                    tmpLog.recordUuid = log.data.record_uuid;
                    applicationLogs.push(tmpLog);
                });

                return applicationLogs;
            })
            .catch(error => {
                this.handleErrorResponse(error);
                return error;
            });
    }

    public postAccountToken(email: string, password: string): Observable<TokenDto> {
        const body = `email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`;
        const headers = new HttpHeaders().append('Content-Type', 'application/x-www-form-urlencoded');

        return this.http.post<{ jwt: string }>(this.apiBaseUrl + '/account-token', body, { headers }).pipe(
            map(response => new TokenDto(response.jwt)),
            catchError(error => {
                return throwError(new ApiResponseErrorAdapter(error && error.error, 'login'));
            })
        );
    }

    public getAccountTokenAsImpersonator(email: string) {
        return this.http
            .get<{ jwt: string }>(`${this.apiBaseUrl}/account-token-as-impersonator/${encodeURIComponent(email)}`, {
                headers: this.defaultHeaders(),
            })
            .pipe(
                map(response => new TokenDto(response.jwt)),
                catchError(error => {
                    return throwError(new ApiResponseErrorAdapter(error && error.error, 'login'));
                })
            );
    }

    public getNotifications(_params?: {
        userUuid?: string;
        pagination?: PaginationDto;
        type?: string;
    }): Promise<Array<NotificationDto>> {
        let params = new HttpParams();
        if (_params && _params.userUuid) {
            params = params.append('user', _params.userUuid);
        }
        if (_params && _params.pagination) {
            params = params.append(
                'pagination',
                _params.pagination.pageNumber + ':' + _params.pagination.applicationsPerPage
            );
        }
        if (_params && _params.type) {
            params = params.append('type', _params.type);
        }
        return this.http
            .get(this.apiBaseUrl + '/notifications', { headers: this.defaultHeaders(), params: params })
            .toPromise()
            .then(res => {
                return NotificationMapper.getNotification(res);
            });
    }

    public putNotificationsSetSeen(): Promise<void> {
        return this.http
            .put(this.apiBaseUrl + '/notifications/set-seen', {}, { headers: this.defaultHeaders() })
            .toPromise()
            .then(() => {
                return;
            });
    }

    public putApplicationDiscardNotifications(applicationUuid: string): Promise<void> {
        return this.http
            .put(
                this.apiBaseUrl + '/application/' + applicationUuid + '/discard-notifications',
                {},
                { headers: this.defaultHeaders() }
            )
            .toPromise()
            .then(() => {
                return;
            });
    }

    public putApplicantDiscardNotifications(applicantUuid: string): Promise<void> {
        return this.http
            .put(
                this.apiBaseUrl + '/applicant/' + applicantUuid + '/discard-notifications',
                {},
                { headers: this.defaultHeaders() }
            )
            .toPromise()
            .then(() => {
                return;
            });
    }

    public putAccountConfirmed({ uuid, token }: { uuid: string; token: string }): Observable<void> {
        const json = {
            confirmation_token: token,
        };

        return this.http
            .put<void>(this.apiBaseUrl + '/account/' + uuid + '/confirmed', json)
            .pipe(this.logError('confirm-account'));
    }

    public getApplicationSchemas(): Observable<ApplicationSchema[]> {
        return this.http
            .get<Array<{ uuid: string; can_be_created_by_applicant: boolean }>>(
                this.apiBaseUrl + '/application-schemas',
                {
                    headers: this.defaultHeaders(),
                }
            )
            .pipe(
                map(schemas => schemas.map(ApplicationSchema.fromApiResponse)),
                this.logError()
            );
    }

    public getApplicationSchemaUuidsObservable(): Observable<Array<string>> {
        return this.getApplicationSchemas().pipe(map(schemas => schemas.map(a => a.uuid)));
    }

    public getApplicationSchemaUuids(): Promise<Array<string>> {
        return this.getApplicationSchemaUuidsObservable().toPromise();
    }

    public postApplication(appSchemaUuid: string): Promise<string> {
        const json = {
            application_schema_uuid: appSchemaUuid,
        };
        return new Promise((resolve, reject) => {
            this.http
                .post(this.apiBaseUrl + '/application', json, { headers: this.defaultHeaders() })
                .toPromise()
                .then(res => {
                    resolve(res['application_uuid']);
                })
                .catch(error => {
                    this.handleErrorResponse(error, 'create-application');
                    reject(error);
                });
        });
    }

    public putAccountPassword(previousPassword: string, newPassword: string): Promise<void> {
        const json = {
            previous_password: previousPassword,
            new_password: newPassword,
        };

        return new Promise((resolve, reject) => {
            this.http
                .put(this.apiBaseUrl + '/account/' + this.auth.getUuid() + '/password', json, {
                    headers: this.defaultHeaders(),
                })
                .toPromise()
                .then(() => {
                    this.handleSuccessResponse('reset-password');
                    resolve();
                })
                .catch(error => {
                    this.handleErrorResponse(error, 'reset-password');
                    reject(error);
                });
        });
    }

    public putAccountPasswordAsAdmin(newPassword: string, accountId: string): Promise<void> {
        const json = {
            new_password: newPassword,
        };

        return new Promise((resolve, reject) => {
            this.http
                .put(this.apiBaseUrl + '/account/' + accountId + '/password', json, {
                    headers: this.defaultHeaders(),
                })
                .toPromise()
                .then(() => {
                    this.handleSuccessResponse('reset-password-by-admin');
                    resolve();
                })
                .catch(error => {
                    this.handleErrorResponse(error, 'reset-password-by-admin');
                    reject(error);
                });
        });
    }

    public postAccount(email: string, password: string, language: string): Observable<void> {
        const data = {
            email: email.toLowerCase(),
            password: password,
            language: language,
        };

        return this.http
            .post<void>(this.apiBaseUrl + '/account', data)
            .pipe(catchError(error => throwError(new ApiResponseErrorAdapter(error && error.error, 'create-account'))));
    }

    public patchApplicant(values: Array<RecordValueOutputDto>): Promise<void> {
        const json = JSON.stringify({
            record_values: values,
        });

        return new Promise((resolve, reject) =>
            this.http
                .patch(this.apiBaseUrl + '/applicant/' + this.auth.getUuid(), json, { headers: this.defaultHeaders() })
                .toPromise()
                .then(() => {
                    this.handleSuccessResponse('update-applicant');
                    resolve();
                })
                .catch(error => {
                    this.handleErrorResponse(error, 'update-applicant');
                    reject(new ApiResponseErrorAdapter(error && error.error));
                })
        );
    }

    public getApplicant(): Promise<ApplicantDto> {
        return this.http
            .get<any>(this.apiBaseUrl + '/applicant/' + this.auth.getUuid(), { headers: this.defaultHeaders() })
            .toPromise()
            .then(res => {
                return new ApplicantDto(
                    ApplicationResponseMapper.getRecordValuesFromSection(res),
                    res.is_complete_profile,
                    res.uuid,
                    res.responsible_caseworkers_uuids
                );
            })
            .catch(error => {
                this.handleErrorResponse(error);
                throw error;
            });
    }

    public getApplicantByUuid(uuid: UUID): Observable<ApplicantDto> {
        return this.http
            .get<any>(this.apiBaseUrl + '/applicant/' + uuid, {
                headers: this.defaultHeaders(),
            })
            .pipe(
                map(res => {
                    return new ApplicantDto(
                        ApplicationResponseMapper.getRecordValuesFromSection(res),
                        res.is_complete_profile,
                        res.uuid,
                        res.responsible_caseworkers_uuids
                    );
                })
            );
    }

    public patchApplicantByUuid(uuid: UUID, values: Array<RecordValueOutputDto>): Promise<void> {
        const json = {
            record_values: values,
        };

        return this.http
            .patch(this.apiBaseUrl + '/backend/applicant/' + uuid, json, { headers: this.defaultHeaders() })
            .toPromise()
            .then(() => {
                this.handleSuccessResponse('update-applicant');
            })
            .catch(error => {
                this.handleErrorResponse(error, 'update-applicant');
                throw new ApiResponseErrorAdapter(error && error.error);
            });
    }

    public getReportEconomy(year: number, boardMeetingUuid?: string): Promise<BudgetDto> {
        let params = new HttpParams();
        if (boardMeetingUuid) {
            params = params.append('board_meeting', boardMeetingUuid);
        }

        return this.http
            .get(this.apiBaseUrl + '/report/economy/' + year, { headers: this.defaultHeaders(), params: params })
            .toPromise()
            .then(res => {
                return PaymentMapper.GetBudget(res);
            })
            .catch(error => {
                this.handleErrorResponse(error);
                throw error;
            });
    }

    public getReportEconomyCategory(
        year: number,
        categoryUuid: string,
        boardMeetingUuid?: string
    ): Promise<Array<{ uuid: string; categoryPaymentDto: CategoryPaymentDto }>> {
        let params = new HttpParams();
        if (boardMeetingUuid) {
            params = params.append('board_meeting', boardMeetingUuid);
        }

        return this.http
            .get(this.apiBaseUrl + '/report/economy/' + year + '/category/' + categoryUuid, {
                headers: this.defaultHeaders(),
                params: params,
            })
            .toPromise()
            .then(res => {
                return PaymentMapper.GetCategoryBudget(res);
            })
            .catch(error => {
                this.handleErrorResponse(error);
                throw error;
            });
    }

    public getRegionReportEconomy(
        startDate: string,
        endDate: string,
        economyGroup: string | null,
        postCodes: number[] = []
    ): Observable<RegionsReportRecapDto> {
        let params = new HttpParams();
        if (economyGroup) params = params.append('economy_group', economyGroup);
        if (postCodes.length)
            postCodes.forEach(code => {
                params = params.append('zipcode[]', code);
            });
        return this.http.get<RegionsReportRecapDto>(
            this.apiBaseUrl + `/report/json/economygroup/from/${startDate}/to/${endDate}`,
            {
                params,
            }
        );
    }

    public getRegionReportEconomyCsv(
        startDate: string,
        endDate: string,
        economyGroup: string | null,
        postCodes: number[] = [],
        granted: boolean = false
    ): Observable<UploadedFondaFile> {
        let params = new HttpParams();
        if (economyGroup) params = params.append('economy_group', economyGroup);
        if (postCodes.length)
            postCodes.forEach(code => {
                params = params.append('zipcode[]', code);
            });
        return this.http
            .get(
                this.apiBaseUrl + `/report/csv/${granted ? 'granted' : 'economygroup'}/from/${startDate}/to/${endDate}`,
                {
                    params: params,
                    responseType: 'blob',
                    observe: 'response',
                    headers: this.defaultHeaders(),
                }
            )
            .pipe(map(res => this.createBlobFile(res)));
    }

    public getFile(fileUuid: string): Promise<FileDTO> {
        return this.http
            .get(this.apiBaseUrl + '/file/' + fileUuid, {
                responseType: 'blob',
                observe: 'response',
                headers: this.binaryHeaders(),
            })
            .toPromise()
            .then(res => {
                const file: any = res;
                const newFile = new FileDTO(fileUuid, 0, this.getFileNameForBlob(res), file.mime_type);
                newFile.blob = file.body;
                return newFile;
            });
    }

    public getFileMetaData(fileUuid: string): Promise<FileDTO> {
        if (fileUuid) {
            return new Promise((resolve, reject) => {
                this.http
                    .get(this.apiBaseUrl + '/file/' + fileUuid + '/metadata', { headers: this.defaultHeaders() })
                    .toPromise()
                    .then(res => {
                        const file: any = res;
                        const newFile = new FileDTO(file.uuid, file.size, file.name, file.mime_type);
                        resolve(newFile);
                    })
                    .catch(error => {
                        this.handleErrorResponse(error);
                        reject(error);
                    });
            });
        }
    }

    public getApplicationSchemaObservable(uuid: string): Observable<IApplicationSchema> {
        return this.http
            .get<IApplicationSchema>(`${this.apiBaseUrl}/application-schema/${uuid}`, {
                headers: this.defaultHeaders(),
            })
            .pipe(this.logError());
    }

    public getApplicationSchema(applicationTemplateUuid: string): Promise<ApplicationTemplateDto> {
        return this.http
            .get(this.apiBaseUrl + '/application-schema/' + applicationTemplateUuid, { headers: this.defaultHeaders() })
            .toPromise()
            .then(res => {
                return ApplicationResponseMapper.GetApplicationTemplateInformation(res);
            })
            .catch(error => {
                this.handleErrorResponse(error);
                return error;
            });
    }

    public getApplicationTemplate(applicationTemplateUuid: string): Promise<ApplicationTemplateDto> {
        if (!this._getApplicationTemplate) {
            this._getApplicationTemplate = this.http
                .get(this.apiBaseUrl + '/application-template/' + applicationTemplateUuid, {
                    headers: this.defaultHeaders(),
                })
                .toPromise()
                .then(res => {
                    return ApplicationResponseMapper.GetApplicationTemplateInformation(res);
                })
                .catch(error => {
                    this.handleErrorResponse(error);
                    return error;
                });
        }
        return this._getApplicationTemplate;
    }

    public postFile(fileName: string, fileContent: string): Promise<string> {
        const json = {
            name: fileName,
            content: fileContent,
        };

        const req = new HttpRequest('POST', this.apiBaseUrl + '/file', json, {
            reportProgress: true,
            headers: this.defaultHeaders(),
        });
        return new Promise((resolve, reject) => {
            this.http.request(req).subscribe(
                event => {
                    if (event.type === HttpEventType.UploadProgress) {
                        this.globalVar.uploadingFileProgress.next(Math.round((100 * event.loaded) / event.total));
                    } else if (event instanceof HttpResponse) {
                        resolve(event.body['uuid']);
                    }
                },
                error => {
                    this.handleErrorResponse(error);
                    reject(error);
                }
            );
        });
    }

    public postPasswordResetRequest(email: string): Observable<string> {
        return this.http
            .post<void>(this.apiBaseUrl + '/account/password-reset/request', {
                email: email.toLowerCase(),
            })
            .pipe(
                map(() => 'request-password-reset'),
                catchError(error =>
                    throwError(new ApiResponseErrorAdapter(error && error.error, 'request-password-reset'))
                )
            );
    }

    public putPasswordReset(uuid: string, token: string, password: string): Observable<void> {
        return this.http
            .put<void>(this.apiBaseUrl + '/account/' + uuid + '/password-reset', {
                token: token,
                new_password: password,
            })
            .pipe(
                tap(() => {
                    this.handleSuccessResponse('reset-password');
                }),
                catchError(error => throwError(new ApiResponseErrorAdapter(error && error.error, 'reset-password')))
            );
    }

    public getApplicationTemplateStatusesObservable(uuid: string): Observable<Array<string>> {
        return this.http
            .get<Array<string>>(this.apiBaseUrl + '/application-template/' + uuid + '/statuses')
            .pipe(this.logError());
    }

    public getApplicationTemplateStatuses(uuid: string) {
        return this.getApplicationTemplateStatusesObservable(uuid).toPromise();
    }

    public getApplicationTemplatePostGrantStatusesObservable(uuid: string): Observable<Array<string>> {
        return this.http
            .get<Array<string>>(this.apiBaseUrl + '/application-template/' + uuid + '/post-grant-statuses')
            .pipe(this.logError());
    }

    public getApplicationTemplatePostGrantStatuses(uuid: string) {
        return this.getApplicationTemplatePostGrantStatusesObservable(uuid).toPromise();
    }

    public getApplicationsSearch(pagination: PaginationDto | null, filters: FiltersDto): Promise<SearchResponseDto> {
        // Log left for easier debugging of the search on live installation in
        // the future. One might think that is is easy to just add this log
        // while this is true for local development it won't be possible on live
        // installation, thus would need to be performed by a frontend developer.
        // One might also think that you can just look at the Network tab in
        // devtools and then find that filters are url encoded and then sent
        // as base64 string making them really painful to debug quickly.
        // Please be so kind to leave this log here so you or someone else could
        // use it in the future.
        // console.log('Sending search filters:', filters);

        const jsonFilters = CallSearchMapper.getFilterJsonFromFilterObject(filters);

        const base64Filters = btoa(encodeURIComponent(JSON.stringify(jsonFilters)));
        let params = new HttpParams();
        params = params.append('filter', base64Filters);

        if (pagination) {
            const jsonPagination = {
                page_number: pagination.pageNumber,
                entities_per_page: pagination.applicationsPerPage,
            };
            const base64Pagination = btoa(JSON.stringify(jsonPagination));
            params = params.append('pagination', base64Pagination);
        }
        return this.http
            .get(this.apiBaseUrl + '/applications/search', { headers: this.defaultHeaders(), params })
            .toPromise()
            .then(res => {
                return SearchResponseMapper.GetSearchResponse(res);
            })
            .catch(err => {
                this.handleErrorResponse(err);
                throw err;
            });
    }

    public getApplicationsExport(
        pagination: PaginationDto | null,
        filters: FiltersDto,
        exportType: string
    ): Promise<UploadedFondaFile> {
        const jsonFilters = CallSearchMapper.getFilterJsonFromFilterObject(filters);
        const base64Filters = btoa(encodeURIComponent(JSON.stringify(jsonFilters)));
        let params = new HttpParams();
        params = params.append('filter', base64Filters);
        params = params.append('export_type', exportType);

        if (pagination) {
            const jsonPagination = {
                page_number: pagination.pageNumber,
                entities_per_page: pagination.applicationsPerPage,
            };
            const base64Pagination = btoa(JSON.stringify(jsonPagination));
            params = params.append('pagination', base64Pagination);
        }

        return this.http
            .get(this.apiBaseUrl + '/applications/export', {
                responseType: 'blob',
                observe: 'response',
                headers: this.defaultHeaders(),
                params,
            })
            .toPromise()
            .then(res => {
                return this.createBlobFile(res);
            })
            .catch(err => {
                this.handleErrorResponse(err);
                throw err;
            });
    }

    public getApplicationGroupSearch(filters: GroupFiltersDto): Promise<GroupSearchResponseDto> {
        const jsonFilters = CallSearchMapper.GetGroupSearchDto(filters);
        const base64Filters = btoa(encodeURIComponent(JSON.stringify(jsonFilters)));
        let params = new HttpParams();
        params = params.append('filter', base64Filters);

        return this.http
            .get(this.apiBaseUrl + '/applications/chart-data', { headers: this.defaultHeaders(), params: params })
            .toPromise()
            .then(res => {
                return SearchResponseMapper.GetGroupBySearchResponse(res);
            })
            .catch(err => {
                this.handleErrorResponse(err);
                throw err;
            });
    }

    public postSearch(filters: FiltersDto, saveName: string, isGlobal: boolean): Promise<void> {
        const jsonFilters = CallSearchMapper.getFilterJsonFromFilterObject(filters);

        const base64Filters = btoa(JSON.stringify(jsonFilters));

        const dataObject = {
            name: saveName,
            search: base64Filters,
            is_global: isGlobal,
        };

        return this.http
            .post(this.apiBaseUrl + '/search', dataObject, { headers: this.defaultHeaders() })
            .toPromise()
            .then(res => {
                return;
            })
            .catch(error => {
                this.handleErrorResponse(error);
                throw error;
            });
    }

    public getBackendApplication(applicationUuid: string): Promise<CaseworkerApplicationDto> {
        return new Promise((resolve, reject) => {
            this.http
                .get(this.apiBaseUrl + '/backend/application/' + applicationUuid, { headers: this.defaultHeaders() })
                .toPromise()
                .then(res => {
                    resolve(ApplicationResponseMapper.getCaseworkerApplication(res));
                })
                .catch(error => {
                    this.handleErrorResponse(error);
                    reject(error);
                });
        });
    }

    public patchBackendApplicationClose(isClosed: boolean, applicationUuid: string): Promise<void> {
        return this.http
            .patch(
                this.apiBaseUrl + '/backend/application/' + applicationUuid + '/close/' + isClosed,
                {},
                { headers: this.defaultHeaders() }
            )
            .toPromise()
            .then(() => {
                return;
            })
            .catch(error => {
                this.handleErrorResponse(error);
                throw error;
            });
    }

    public getSearches(): Promise<SavedSearchResponseDto> {
        return this.http
            .get(this.apiBaseUrl + '/searches', { headers: this.defaultHeaders() })
            .toPromise()
            .then(res => {
                const searches: any = res;
                const personalSavedSearchSettings: Array<SavedSearchDto> = [];
                searches.personal.forEach(search => {
                    personalSavedSearchSettings.push(
                        new SavedSearchDto(
                            search.created_at,
                            search.name,
                            search.requester,
                            SearchResponseMapper.GetSearchFilters(JSON.parse(atob(search.search)))
                        )
                    );
                });

                const globalSavedSearchSettings: Array<SavedSearchDto> = [];
                searches.global.forEach(search => {
                    globalSavedSearchSettings.push(
                        new SavedSearchDto(
                            search.created_at,
                            search.name,
                            search.requester,
                            SearchResponseMapper.GetSearchFilters(JSON.parse(atob(search.search)))
                        )
                    );
                });

                return new SavedSearchResponseDto(personalSavedSearchSettings, globalSavedSearchSettings);
            });
    }

    public getBoardMeeting(uuid: string): Promise<BoardMeetingAgendaDto> {
        return this.http
            .get(this.apiBaseUrl + '/board-meeting/' + uuid, { headers: this.defaultHeaders() })
            .toPromise()
            .then(res => {
                return BoardMeetingMapper.GetBoardMeetingAgenda(res);
            })
            .catch(err => {
                this.handleErrorResponse(err);
                throw err;
            });
    }

    public getBoardMeetings(): Promise<Array<BoardMeetingDto>> {
        if (!this._getBoardMeetings) {
            this._getBoardMeetings = this.http
                .get(this.apiBaseUrl + '/board-meetings', { headers: this.defaultHeaders() })
                .toPromise()
                .then(res => {
                    const jsonResponse: any = res;
                    const boardMeetings: Array<BoardMeetingDto> = [];
                    jsonResponse.forEach(boardMeeting => {
                        const summary = boardMeeting.summary_file
                            ? new UploadedFondaFile(
                                  boardMeeting.summary_file.uuid,
                                  boardMeeting.summary_file.file_name,
                                  '',
                                  boardMeeting.summary_file.file_size
                              )
                            : null;
                        const tmpBoardMeeting = new BoardMeetingDto(
                            boardMeeting.name,
                            boardMeeting.fiscal_year,
                            boardMeeting.date,
                            boardMeeting.accepting_application_last_date,
                            summary,
                            boardMeeting.has_agenda
                        );
                        tmpBoardMeeting.isPublished = boardMeeting.has_agenda && boardMeeting.is_agenda_published;
                        tmpBoardMeeting.hasApplication = boardMeeting.has_applications;
                        tmpBoardMeeting.uuid = boardMeeting.uuid;
                        tmpBoardMeeting.isSelectable = boardMeeting.is_selectable;
                        boardMeetings.push(tmpBoardMeeting);
                    });
                    return boardMeetings;
                });
        }
        return this._getBoardMeetings;
    }

    public postBoardMeetingNote(
        noteBody: string,
        applicationUuid: string,
        isPublic: boolean,
        uuid: string
    ): Promise<void> {
        const json = {
            application_uuid: applicationUuid,
            is_public: isPublic,
            note: noteBody,
        };

        return this.http
            .post(this.apiBaseUrl + '/board-meeting-agenda/' + uuid + '/note', json, { headers: this.defaultHeaders() })
            .toPromise()
            .then(() => {
                return;
            })
            .catch(error => {
                throw new ApiResponseErrorAdapter(error && error.error);
            });
    }

    public getBoardMeetingApplicationNotes(
        bmUuid: string,
        applicationUuid: string
    ): Promise<Array<GetBoardMeetingNoteDto>> {
        return this.http
            .get(this.apiBaseUrl + '/board-meeting/' + bmUuid + '/application-notes/' + applicationUuid, {
                headers: this.defaultHeaders(),
            })
            .toPromise()
            .then(res => {
                const notesResult: Array<GetBoardMeetingNoteDto> = [];
                if (res) {
                    const notesJson: any = res;
                    notesJson.forEach(note => {
                        notesResult.push(
                            new GetBoardMeetingNoteDto(
                                note.created_at,
                                new BackendUserData(
                                    note.created_by && note.created_by.initials,
                                    note.created_by && note.created_by.type,
                                    note.created_by && note.created_by.username,
                                    note.created_by && note.created_by.uuid
                                ),
                                note.is_public,
                                note.text,
                                note.uuid
                            )
                        );
                    });
                }
                return notesResult;
            })
            .catch(error => {
                throw new ApiResponseErrorAdapter(error && error.error);
            });
    }

    public deleteBoardMEetingAgendaNote(uuid: string): Promise<void> {
        return this.http
            .delete(this.apiBaseUrl + '/board-meeting-agenda-note/' + uuid, { headers: this.defaultHeaders() })
            .toPromise()
            .then(() => {
                return;
            })
            .catch(error => {
                throw new ApiResponseErrorAdapter(error && error.error);
            });
    }

    public deleteBoardMeeting(uuid: string): Promise<void> {
        return this.http
            .delete(this.apiBaseUrl + '/board-meeting/' + uuid, { headers: this.defaultHeaders() })
            .toPromise()
            .then(() => {
                return;
            })
            .catch(error => {
                throw new ApiResponseErrorAdapter(error && error.error);
            });
    }

    public postBoardMeeting(boardMeeting: BoardMeetingDto): Promise<void> {
        const json = {
            name: boardMeeting.name,
            fiscal_year: boardMeeting.fiscalYear,
            date: boardMeeting.getDate(),
            accepting_application_last_date: boardMeeting.getAcceptingApplicationLastDate(),
        };

        return this.http
            .post(this.apiBaseUrl + '/board-meeting', json, { headers: this.defaultHeaders() })
            .toPromise()
            .then(() => {
                return;
            })
            .catch(error => {
                this.handleErrorResponse(error);
                throw error;
            });
    }

    public getLockStatus(applicationUuid: string, type: string): Promise<ApplicationLockStatusDto> {
        return this.http
            .get(this.apiBaseUrl + '/backend/lock/' + type + '/' + applicationUuid, { headers: this.defaultHeaders() })
            .toPromise()
            .then(res => {
                const jsonResponse: any = res;
                return new ApplicationLockStatusDto(
                    jsonResponse.resource_id,
                    jsonResponse.resource_type,
                    jsonResponse.is_locked_by_account,
                    jsonResponse.is_locked_by_other_account,
                    jsonResponse.locked_by_account,
                    jsonResponse.expire_on
                );
            });
    }

    public postLock(applicationUuid: string, type: string): Promise<void> {
        return this.http
            .post(this.apiBaseUrl + '/backend/lock/' + type + '/' + applicationUuid, null, {
                headers: this.defaultHeaders(),
            })
            .toPromise()
            .then(res => {
                return;
            });
    }

    public putLockRefresh(applicationUuid: string, type: string): Promise<void> {
        return this.http
            .put(this.apiBaseUrl + '/backend/lock/' + type + '/' + applicationUuid + '/refresh', null, {
                headers: this.defaultHeaders(),
            })
            .toPromise()
            .then(res => {
                return;
            })
            .catch(error => {});
    }

    public deleteLock(applicationUuid: string, type: string, token?: string): Promise<void> {
        let headers = this.defaultHeaders();
        if (token) {
            headers = headers.set('X-AUTH-JWT', token);
        }
        return this.http
            .delete(this.apiBaseUrl + '/backend/lock/' + type + '/' + applicationUuid, { headers })
            .toPromise()
            .then(res => {
                return;
            })
            .catch(error => {});
    }

    public getApplicationSchemaCorrespondenceTemplates(
        schemaUuid: string
    ): Promise<Array<CorrespondenceTemplateManualDto>> {
        return this.http
            .get(this.apiBaseUrl + '/application-schema/' + schemaUuid + '/correspondence-templates', {
                headers: this.defaultHeaders(),
            })
            .toPromise()
            .then(res => {
                const resTemplates: any = res;
                const templates: Array<CorrespondenceTemplateManualDto> = [];
                resTemplates.forEach(template => {
                    const staticTokens: Array<{ key: string; value: string }> = [];
                    if (template.static_tokens) {
                        for (const key of Object.keys(template.static_tokens)) {
                            staticTokens.push({ value: template.static_tokens[key], key: key });
                        }
                    }
                    templates.push(
                        new CorrespondenceTemplateManualDto(
                            template.uuid,
                            template.ref_uuid,
                            template.status_transition,
                            template.parent_template_uuid,
                            template.attached_files
                                ? template.attached_files.map(att => ({
                                      uuid: att.file_uuid,
                                      language: att.language,
                                  }))
                                : [],
                            template.pdf_generator_record_uuid,
                            staticTokens
                        )
                    );
                });
                return templates;
            })
            .catch(error => {
                this.handleErrorResponse(error);
                return error;
            });
    }

    public getApplicationTemplateCorrespondenceTemplate(
        templateUuid: string
    ): Promise<Array<CorrespondenceTemplateManualDto>> {
        return this.http
            .get(this.apiBaseUrl + '/application-template/' + templateUuid + '/correspondence-templates', {
                headers: this.defaultHeaders(),
            })
            .toPromise()
            .then(res => {
                const resTemplates: any = res;
                const templates: Array<CorrespondenceTemplateManualDto> = [];
                resTemplates.forEach(template => {
                    templates.push(
                        new CorrespondenceTemplateManualDto(
                            template.uuid,
                            template.ref_uuid,
                            template.status_transition,
                            template.parent_template_uuid,
                            template.attached_files
                                ? template.attached_files.map(att => ({
                                      uuid: att.file_uuid,
                                      language: att.language,
                                  }))
                                : [],
                            template.pdf_generator_record_uuid,
                            [],
                            template.is_logged
                        )
                    );
                });
                return templates;
            })
            .catch(error => {
                this.handleErrorResponse(error);
                return error;
            });
    }

    public getApplicationArtworks(applicationUuid: string): Promise<WorksDatabaseDto> {
        return this.http
            .get(this.apiBaseUrl + '/application/' + applicationUuid + '/artworks', { headers: this.defaultHeaders() })
            .toPromise()
            .then(res => {
                return WorksDatabaseMapper.GetWorksDto(res);
            })
            .catch(error => {
                this.handleErrorResponse(error);
                return error;
            });
    }

    public getApplicationCorrespondenceTemplate(
        applicationUuid: string,
        templateUuid: string
    ): Promise<CorrespondenceTemplatePopulateDto> {
        return this.http
            .get(this.apiBaseUrl + '/application/' + applicationUuid + '/correspondence-template/' + templateUuid, {
                headers: this.defaultHeaders(),
            })
            .toPromise()
            .then(res => {
                return new CorrespondenceTemplatePopulateDto(
                    res['subject'],
                    res['body'],
                    res['warnings'],
                    res['pdf_body']
                );
            })
            .catch(error => {
                this.handleErrorResponse(error);
                return error;
            });
    }

    public getSiteSettings(): Observable<ReadonlyArray<ISiteSetting>> {
        return this.http
            .get<ReadonlyArray<ISiteSetting>>(this.apiBaseUrl + '/site-settings', { headers: this.defaultHeaders() })
            .pipe(this.logError());
    }

    public postApplicationThread(
        threadTitle: string,
        messageBody: string,
        attachments: Array<UploadedFondaFile>,
        uuid: string,
        correspondenceTemplateUuid?: string
    ): Promise<void> {
        const jsonAttachments: Array<{
            uuid: string;
            file_name: string;
            file_size: number;
        }> = [];
        attachments.forEach(attachment => {
            jsonAttachments.push({
                uuid: attachment.uuid,
                file_name: attachment.fileName,
                file_size: attachment.size,
            });
        });

        const json = {
            thread_title: threadTitle,
            first_message_body: messageBody,
            attachments: jsonAttachments,
            correspondence_template_uuid: correspondenceTemplateUuid,
        };

        return this.http
            .post(this.apiBaseUrl + '/application/' + uuid + '/thread', json, { headers: this.defaultHeaders() })
            .toPromise()
            .then(() => {
                this.handleSuccessResponse('create-thread');
                return;
            })
            .catch(error => {
                this.handleErrorResponse(error, 'create-thread');
                throw error;
            });
    }

    public postApplicantThread(
        threadTitle: string,
        messageBody: string,
        attachments: Array<UploadedFondaFile>,
        uuid: string
    ): Promise<void> {
        const jsonAttachments: Array<{
            uuid: string;
            file_name: string;
            file_size: number;
        }> = [];
        attachments.forEach(attachment => {
            jsonAttachments.push({
                uuid: attachment.uuid,
                file_name: attachment.fileName,
                file_size: attachment.size,
            });
        });

        const json = {
            thread_title: threadTitle,
            first_message_body: messageBody,
            attachments: jsonAttachments,
        };

        return this.http
            .post(this.apiBaseUrl + '/applicant/' + uuid + '/thread', json, { headers: this.defaultHeaders() })
            .toPromise()
            .then(() => {
                this.handleSuccessResponse('create-thread');
                return;
            })
            .catch(error => {
                this.handleErrorResponse(error, 'create-thread');
                throw error;
            });
    }

    public getApplicationThreads(applicationUuid: string): Promise<Array<CorrespondenceThreadDto>> {
        return this.http
            .get(this.apiBaseUrl + '/application/' + applicationUuid + '/threads', { headers: this.defaultHeaders() })
            .toPromise()
            .then(res => {
                return CorrespondenceMapper.GetCorrespondenceThreads(res);
            })
            .catch(error => {
                this.handleErrorResponse(error);
                return error;
            });
    }

    public getApplicantThreads(
        applicantUuid: string,
        includeAppThreads: boolean = false
    ): Promise<Array<CorrespondenceThreadDto>> {
        let params = new HttpParams();
        if (includeAppThreads) {
            params = params.append('include-application-threads', includeAppThreads.toString());
        }
        return this.http
            .get(this.apiBaseUrl + '/applicant/' + applicantUuid + '/threads', {
                headers: this.defaultHeaders(),
                params: params,
            })
            .toPromise()
            .then(res => {
                return CorrespondenceMapper.GetCorrespondenceThreads(res);
            })
            .catch(error => {
                this.handleErrorResponse(error);
                return error;
            });
    }

    public getApplicantThreadsObservable(
        applicantUuid: string,
        includeAppThreads: boolean = false
    ): Observable<Array<CorrespondenceThreadDto>> {
        let params = new HttpParams();
        if (includeAppThreads) {
            params = params.append('include-application-threads', includeAppThreads.toString());
        }
        return this.http
            .get(this.apiBaseUrl + '/applicant/' + applicantUuid + '/threads', {
                headers: this.defaultHeaders(),
                params: params,
            })
            .pipe(
                map(res => {
                    return CorrespondenceMapper.GetCorrespondenceThreads(res);
                })
            );
    }

    public getApplicationThreadsObservable(applicationUuid: string): Observable<Array<CorrespondenceThreadDto>> {
        return this.http
            .get(this.apiBaseUrl + '/application/' + applicationUuid + '/threads', { headers: this.defaultHeaders() })
            .pipe(
                map(res => {
                    return CorrespondenceMapper.GetCorrespondenceThreads(res);
                })
            );
    }

    public postApplicationThreadMessage(
        applicationUuid: string,
        threadUuid: string,
        message: string,
        attachments: Array<UploadedFondaFile>
    ): Promise<void> {
        const jsonAttachments: Array<{
            uuid: string;
            file_name: string;
            file_size: number;
        }> = [];
        attachments.forEach(attachment => {
            jsonAttachments.push({
                uuid: attachment.uuid,
                file_name: attachment.fileName,
                file_size: attachment.size,
            });
        });

        const json = {
            message: message,
            attachments: jsonAttachments,
        };

        return this.http
            .post(this.apiBaseUrl + '/application/' + applicationUuid + '/thread/' + threadUuid + '/message', json, {
                headers: this.defaultHeaders(),
            })
            .toPromise()
            .then(() => {
                this.handleSuccessResponse('send-message');
                return;
            })
            .catch(error => {
                this.handleErrorResponse(error, 'send-message');
                throw error;
            });
    }

    public postApplicantThreadMessage(
        applicantUuid: string,
        threadUuid: string,
        message: string,
        attachments: Array<UploadedFondaFile>
    ): Promise<void> {
        const jsonAttachments: Array<{
            uuid: string;
            file_name: string;
            file_size: number;
        }> = [];
        attachments.forEach(attachment => {
            jsonAttachments.push({
                uuid: attachment.uuid,
                file_name: attachment.fileName,
                file_size: attachment.size,
            });
        });

        const json = {
            message: message,
            attachments: jsonAttachments,
        };

        return this.http
            .post(this.apiBaseUrl + '/applicant/' + applicantUuid + '/thread/' + threadUuid + '/message', json, {
                headers: this.defaultHeaders(),
            })
            .toPromise()
            .then(() => {
                this.handleSuccessResponse('send-message');
                return;
            })
            .catch(error => {
                this.handleErrorResponse(error, 'send-message');
                throw error;
            });
    }

    public getBackendUsersCaseworker(): Promise<Array<CaseworkerDto>> {
        if (!this._getBackendUsersCaseworker) {
            this._getBackendUsersCaseworker = this.http
                .get(this.apiBaseUrl + '/backend-users/caseworker', { headers: this.defaultHeaders() })
                .toPromise()
                .then(caseworkers => {
                    return CaseworkerMapper.GetCaseworkers(caseworkers);
                })
                .catch(error => {
                    this.handleErrorResponse(error);
                    throw error;
                });
        }
        return this._getBackendUsersCaseworker;
    }

    public getCategorySubCategories(uuid: string): Promise<Array<{ uuid: string; categoryUuid: string }>> {
        if (!this._getCaegorySubCategories) {
            this._getCaegorySubCategories = {};
        }
        if (!this._getCaegorySubCategories[uuid]) {
            this._getCaegorySubCategories[uuid] = this.http
                .get(this.apiBaseUrl + '/category/' + uuid + '/sub-categories', { headers: this.defaultHeaders() })
                .toPromise()
                .then(res => {
                    const subCategories: any = res;
                    const subCategoriesRes: Array<{
                        uuid: string;
                        categoryUuid: string;
                    }> = [];
                    subCategories.forEach(category => {
                        subCategoriesRes.push({
                            uuid: category.uuid,
                            categoryUuid: category.category_uuid,
                        });
                    });
                    return subCategoriesRes;
                })
                .catch(error => {
                    this.handleErrorResponse(error);
                    throw error;
                });
        }
        return this._getCaegorySubCategories[uuid];
    }

    public getBackendUsers(force: boolean = false, role: string = null ): Promise<Array<CaseworkerDto>> {
        if (!this._getBackendUsers || force) {
            let params = new HttpParams();

            if(role) {
                params = params.append("role", role);
            }

            this._getBackendUsers = this.http
                .get(this.apiBaseUrl + '/backend-users', { headers: this.defaultHeaders(), params: params })
                .toPromise()
                .then(caseworkers => {
                    return CaseworkerMapper.GetCaseworkers(caseworkers);
                })
                .catch(error => {
                    this.handleErrorResponse(error);
                    throw error;
                });
        }
        return this._getBackendUsers;
    }

    public getBackendApplicationStatusVerify(uuid: string, status: string, oldStatus: string): Promise<void> {
        return this.http
            .get(this.apiBaseUrl + '/backend/application/' + uuid + '/status/' + status + '/verify', {
                headers: this.defaultHeaders(),
            })
            .toPromise()
            .then(() => {
                return;
            })
            .catch(error => {
                throw new ApiResponseErrorAdapter(
                    error && error.error,
                    UPDATE_STATUS_TRANSLATION + '-' + oldStatus + '-' + status
                );
            });
    }

    public getBackendApplicationPostGrantStatusVerify(uuid: string, status: string, oldStatus: string): Promise<void> {
        return this.http
            .get(this.apiBaseUrl + '/backend/application/' + uuid + '/post-granted-status/' + status + '/verify', {
                headers: this.defaultHeaders(),
            })
            .toPromise()
            .then(() => {
                return;
            })
            .catch(error => {
                throw new ApiResponseErrorAdapter(
                    error && error.error,
                    UPDATE_STATUS_TRANSLATION + '-' + oldStatus + '-' + status
                );
            });
    }

    public postBackendAccount(userPostDto: UserPostDto): Promise<void> {
        const json = {
            email: userPostDto.email.toLowerCase(),
            username: userPostDto.username,
            initials: userPostDto.initials,
            password: userPostDto.password,
            roles: userPostDto.roles,
            can_authenticate_with_credentials: userPostDto.canAuthenticateWithCredentials,
            subcategories: userPostDto.subcategories,
        };
        this.resetCache();

        return this.http
            .post(this.apiBaseUrl + '/backend/account', json, { headers: this.defaultHeaders() })
            .toPromise()
            .then(() => {
                return;
            })
            .catch(error => {
                throw error;
            });
    }

    public putBackendAccount(
        username: string,
        initials: string,
        roles: Array<string>,
        uuid: string,
        canAuthenticateWithCredentials: boolean,
        subcategories: Array<string> = [],
        email: string,
        active: boolean
    ): Promise<void> {
        const json = {
            username: username,
            initials: initials,
            roles: roles,
            can_authenticate_with_credentials: canAuthenticateWithCredentials,
            subcategories: subcategories,
            email: email,
            active: active,
        };
        this.resetCache();

        return this.http
            .put(this.apiBaseUrl + '/backend/account/' + uuid, json, { headers: this.defaultHeaders() })
            .toPromise()
            .then(() => {
                this.handleSuccessResponse('update_user');
                return;
            })
            .catch(error => {
                this.handleErrorResponse(error, 'update_user');
                throw error;
            });
    }

    public applicationCorrespondenceTokenValues(uuid: string): Promise<Array<CorrespondenceTokenValueDto>> {
        return this.http
            .get(this.apiBaseUrl + '/application/' + uuid + '/correspondence-token-values', {
                headers: this.defaultHeaders(),
            })
            .toPromise()
            .then(res => {
                const tokens: any = res;
                const resultToken: Array<CorrespondenceTokenValueDto> = [];
                tokens.forEach(token => {
                    resultToken.push(
                        new CorrespondenceTokenValueDto(token.reference, token.reference_type, token.stringified_value)
                    );
                });

                return resultToken;
            })
            .catch(error => {
                throw error;
            });
    }

    public patchApplicationThreadSetRead(applicationUuid: string, threadUuid: string): Promise<void> {
        return this.http
            .patch(
                this.apiBaseUrl + '/application/' + applicationUuid + '/thread/' + threadUuid + '/set-read',
                {},
                { headers: this.defaultHeaders() }
            )
            .toPromise()
            .then(() => {
                return;
            })
            .catch(error => {
                throw error;
            });
    }

    public patchApplicantThreadSetRead(applicantUuid: string, threadUuid: string): Promise<void> {
        return this.http
            .patch(
                this.apiBaseUrl + '/applicant/' + applicantUuid + '/thread/' + threadUuid + '/set-read',
                {},
                { headers: this.defaultHeaders() }
            )
            .toPromise()
            .then(() => {
                return;
            })
            .catch(error => {
                throw error;
            });
    }

    public getApplicationInternalMessages(applicationUuid: string): Promise<Array<CorrespondenceMessageDto>> {
        return this.http
            .get(this.apiBaseUrl + '/application/' + applicationUuid + '/internal-messages', {
                headers: this.defaultHeaders(),
            })
            .toPromise()
            .then(res => {
                return CorrespondenceMapper.GetInternalMessages(res);
            })
            .catch(error => {
                throw error;
            });
    }

    public postApplicationInternalMessage(
        applicationUuid: string,
        message: string,
        attachments: Array<UploadedFondaFile>
    ): Promise<void> {
        const jsonAttachments: Array<{
            uuid: string;
            file_name: string;
            file_size: number;
        }> = [];
        attachments.forEach(attachment => {
            jsonAttachments.push({
                uuid: attachment.uuid,
                file_name: attachment.fileName,
                file_size: attachment.size,
            });
        });

        const json = {
            message: message,
            attachments: jsonAttachments,
        };

        return this.http
            .post(this.apiBaseUrl + '/application/' + applicationUuid + '/internal-message', json, {
                headers: this.defaultHeaders(),
            })
            .toPromise()
            .then(() => {
                return;
            })
            .catch(error => {
                this.handleErrorResponse(error);
                return error;
            });
    }

    public patchApplicationInternalMessage(
        message: string,
        attachments: Array<UploadedFondaFile>,
        messageUuid: string,
        applicationUuid: string
    ): Promise<void> {
        const jsonAttachments: Array<{
            uuid: string;
            file_name: string;
            file_size: number;
        }> = [];
        attachments.forEach(attachment => {
            jsonAttachments.push({
                uuid: attachment.uuid,
                file_name: attachment.fileName,
                file_size: attachment.size,
            });
        });

        const json = {
            message: message,
            attachments: jsonAttachments,
        };

        return this.http
            .patch(this.apiBaseUrl + '/application/' + applicationUuid + '/internal-message/' + messageUuid, json, {
                headers: this.defaultHeaders(),
            })
            .toPromise()
            .then(() => {
                return;
            })
            .catch(error => {
                this.handleErrorResponse(error);
                throw error;
            });
    }

    public deleteApplicationInternalMessage(messageUuid: string, applicationUuid: string): Promise<void> {
        return this.http
            .delete(this.apiBaseUrl + '/application/' + applicationUuid + '/internal-message/' + messageUuid, {
                headers: this.defaultHeaders(),
            })
            .toPromise()
            .then(() => {
                return;
            })
            .catch(error => {
                this.handleErrorResponse(error);
                throw error;
            });
    }

    public postFiscalYear(fiscalYear: FiscalYearDto): Promise<void> {
        const json = {
            year: fiscalYear.year,
            start_date: fiscalYear.startDate,
            end_date: fiscalYear.endDate,
        };

        return this.http
            .post(this.apiBaseUrl + '/fiscal-year', json, { headers: this.defaultHeaders() })
            .toPromise()
            .then(() => {
                return;
            })
            .catch(error => {
                this.handleErrorResponse(error);
                throw error;
            });
    }

    public putFiscalYear(yearBefore: number, year: number, startDate: Date, endDate: Date): Promise<void> {
        const json = {
            year: year,
            start_date: startDate,
            end_date: endDate,
        };

        return this.http
            .put(this.apiBaseUrl + '/fiscal-year/' + yearBefore, json, { headers: this.defaultHeaders() })
            .toPromise()
            .then(() => {
                return;
            })
            .catch(error => {
                this.handleErrorResponse(error);
                throw error;
            });
    }

    public postCategoryYearlyBudget(categoryUuid: string, fiscalYear: number, budget: number): Promise<void> {
        const json = {
            category_uuid: categoryUuid,
            fiscal_year: fiscalYear,
            budget: budget,
        };

        return this.http
            .post(this.apiBaseUrl + '/category-yearly-budget', json, { headers: this.defaultHeaders() })
            .toPromise()
            .then(() => {
                return;
            })
            .catch(error => {
                this.handleErrorResponse(error);
                throw error;
            });
    }

    public getBudget(year: number): Promise<FiscalYearPaymentDto> {
        return this.http
            .get(this.apiBaseUrl + '/budgets/' + year, { headers: this.defaultHeaders() })
            .toPromise()
            .then(res => {
                return PaymentMapper.FiscalYearPayment(res);
            });
    }

    public putCategoryYearlyBudget(fiscalYear: number, categoryUuid: string, budget: number): Promise<void> {
        const json = {
            budget: budget,
        };

        let params = new HttpParams();
        params = params.append('fiscal-year', fiscalYear.toString());
        params = params.append('category-uuid', categoryUuid);

        return this.http
            .put(this.apiBaseUrl + '/category-yearly-budget', json, { headers: this.defaultHeaders(), params: params })
            .toPromise()
            .then(() => {
                return;
            })
            .catch(error => {
                this.handleErrorResponse(error);
                throw error;
            });
    }

    public deleteCategoryYearlyBudget(fiscalYear: number, categoryUuid: string): Promise<void> {
        let params = new HttpParams();
        params = params.append('fiscal-year', fiscalYear.toString());
        params = params.append('category-uuid', categoryUuid);

        return this.http
            .delete(this.apiBaseUrl + '/category-yearly-budget', { headers: this.defaultHeaders(), params: params })
            .toPromise()
            .then(() => {
                return;
            })
            .catch(error => {
                this.handleErrorResponse(error);
                throw error;
            });
    }

    public getCategories(): Observable<CategoryDto[]> {
        return this.http
            .get<Array<{ uuid: string; is_deletable: boolean }>>(this.apiBaseUrl + '/categories', {
                headers: this.defaultHeaders(),
            })
            .pipe(map(res => res.map(CategoryDto.fromApiResponse)));
    }

    public getFiscalYearsUnfinished(): Promise<Array<FiscalYearDto>> {
        return this.http
            .get(this.apiBaseUrl + '/fiscal-years/unfinished', { headers: this.defaultHeaders() })
            .toPromise()
            .then(res => {
                const years: any = res;
                const fiscalYears: Array<FiscalYearDto> = [];
                years.forEach(year => {
                    fiscalYears.push(new FiscalYearDto(year.year, year.start_date, year.end_date));
                });
                return fiscalYears;
            })
            .catch(error => {
                this.handleErrorResponse(error);
                throw error;
            });
    }

    public getReportReversedPayments(year: number, boardMeetingUuid?: string): Promise<Array<ReversedPaymentDto>> {
        let params = new HttpParams();
        if (boardMeetingUuid) {
            params = params.append('board_meeting', boardMeetingUuid);
        }

        return this.http
            .get(this.apiBaseUrl + '/report/reversed-payments/' + year, {
                headers: this.defaultHeaders(),
                params: params,
            })
            .toPromise()
            .then(res => {
                const resArr: Array<ReversedPaymentDto> = [];
                const payments: any = res;
                payments.forEach(payment => {
                    resArr.push(
                        new ReversedPaymentDto(
                            payment.category_uuid,
                            PaymentMapper.changeCommasToDots(payment.reversed_amount)
                        )
                    );
                });
                return resArr;
            })
            .catch(error => {
                this.handleErrorResponse(error);
                throw error;
            });
    }

    public getReportReversedPaymentsCategory(
        year: number,
        categoryUuid: string,
        boardMeetingUuid?: string
    ): Promise<Array<CategoryReversedPaymentDto>> {
        let params = new HttpParams();
        if (boardMeetingUuid) {
            params = params.append('board_meeting', boardMeetingUuid);
        }

        return this.http
            .get(this.apiBaseUrl + '/report/reversed-payments/' + year + '/category/' + categoryUuid, {
                headers: this.defaultHeaders(),
                params: params,
            })
            .toPromise()
            .then(res => {
                const resArr: Array<CategoryReversedPaymentDto> = [];
                const payments: any = res;
                payments.forEach(payment => {
                    resArr.push(
                        new CategoryReversedPaymentDto(
                            payment.sub_category,
                            PaymentMapper.changeCommasToDots(payment.amount)
                        )
                    );
                });
                return resArr;
            })
            .catch(error => {
                this.handleErrorResponse(error);
                throw error;
            });
    }

    public getFiscalYears(): Promise<Array<FiscalYearDto>> {
        return this.http
            .get(this.apiBaseUrl + '/fiscal-years', { headers: this.defaultHeaders() })
            .toPromise()
            .then(res => {
                const years: any = res;
                const fiscalYears: Array<FiscalYearDto> = [];
                years.forEach(year => {
                    fiscalYears.push(new FiscalYearDto(year.year, year.start_date, year.end_date));
                });
                return fiscalYears;
            })
            .catch(error => {
                this.handleErrorResponse(error);
                throw error;
            });
    }

    public getFiscalYearsObservable(): Observable<Array<FiscalYearDto>> {
        return this.http.get<Array<FiscalYearDto>>(this.apiBaseUrl + '/fiscal-years');
    }

    public deleteSearch(name: string, isGlobal: boolean): Promise<void> {
        return this.http
            .delete(this.apiBaseUrl + '/search/' + name + '/' + isGlobal, { headers: this.defaultHeaders() })
            .toPromise()
            .then(res => {
                return;
            })
            .catch(error => {
                this.handleErrorResponse(error);
                throw error;
            });
    }

    public getCountries(): Promise<Array<CountryDto>> {
        if (!this._getCountries) {
            this._getCountries = this.http
                .get(this.apiBaseUrl + '/countries', { headers: this.defaultHeaders() })
                .toPromise()
                .then(res => {
                    const countriesJSON: any = res;
                    const countries: Array<CountryDto> = [];

                    countriesJSON.forEach(country => {
                        countries.push(new CountryDto(country.uuid, country.name));
                    });

                    return countries;
                })
                .catch(error => {
                    this.handleErrorResponse(error);
                    throw error;
                });
        }
        return this._getCountries;
    }

    public getBehaviours(): Observable<Array<{ name: string; required_translations: Array<string> }>> {
        return this.http
            .get<Array<{ name: string; required_translations: Array<string> }>>(`${this.apiBaseUrl}/behaviours`, {
                headers: this.defaultHeaders(),
            })
            .pipe(this.logError());
    }

    public postApplicationSchemaAddRecord(
        applicationSchemaUuid: string,
        data: {
            parent: null;
            is_required: boolean;
            configuration: any;
            field_size: 1 | 2;
            behaviour: string;
            section: number;
            type: string;
            primary_language_title: string;
        }
    ) {
        return this.http
            .post<{ record_uuid: string }>(
                `${this.apiBaseUrl}/application-schema/${applicationSchemaUuid}/add-record`,
                data,
                { headers: this.defaultHeaders() }
            )
            .pipe(
                this.logError('create-record'),
                tap(() => this.handleSuccessResponse('create-record'))
            );
    }

    public deleteApplicationSchemaRecord(applicationSchemaUuid: string, recordUuid: string): Observable<void> {
        return this.http
            .delete<void>(`${this.apiBaseUrl}/application-schema/${applicationSchemaUuid}/record/${recordUuid}`, {
                headers: this.defaultHeaders(),
            })
            .pipe(this.logError());
    }

    public putRecordTranslations(
        data: Array<{ record_uuid: string; texts: Array<{ language: string; offset: string; text: string }> }>
    ): Observable<void> {
        return this.http
            .put<void>(`${this.apiBaseUrl}/translations/records`, { records: data }, { headers: this.defaultHeaders() })
            .pipe(this.logError());
    }

    public postApplicationSchema(newSchemaData: {
        application_template_uuid: string;
        category_uuid: string;
        can_be_created_by_applicant: boolean;
        are_applications_visible_by_applicant_by_default: boolean;
        texts: Array<{ language: string; text: string; sub_identifier: string }>;
    }): Observable<{ application_schema_uuid: string }> {
        return this.http
            .post<{ application_schema_uuid }>(`${this.apiBaseUrl}/application-schema`, newSchemaData, {
                headers: this.defaultHeaders(),
            })
            .pipe(this.logError());
    }

    public patchApplicationSchema(
        uuid: UUID,
        updateSchema: {
            can_be_created_by_applicant: boolean;
            are_applications_visible_by_applicant_by_default: boolean;
        }
    ): Observable<void> {
        return this.http
            .patch<void>(`${this.apiBaseUrl}/application-schema/${uuid}`, updateSchema, {
                headers: this.defaultHeaders(),
            })
            .pipe(
                this.logError(),
                tap(() => {
                    this.handleSuccessResponse('update-application-schema-metadata');
                })
            );
    }

    public postApplicationSchemaDuplicate(
        applicationSchemaUuid: string,
        newSchemaData: {
            category_uuid: string;
            texts: Array<{ language: string; text: string; sub_identifier: string }>;
        }
    ): Observable<{ application_schema_uuid: string }> {
        return this.http
            .post<{ application_schema_uuid: string }>(
                `${this.apiBaseUrl}/application-schema/${applicationSchemaUuid}/duplicate`,
                newSchemaData,
                { headers: this.defaultHeaders() }
            )
            .pipe(this.logError());
    }

    public deleteApplicationSchema(uuid: string) {
        return this.http
            .delete(`${this.apiBaseUrl}/application-schema/${uuid}`)
            .pipe(this.logError('delete-application-schema'));
    }

    getApplicantsSearch(
        text: string,
        columns: SearchColumn[],
        orderBy: OrderBy[],
        pagination: Pagination
    ): Observable<GetApplicantSearchResponse> {
        const filter = {
            mass_text_search_filter: text,
            columns: columns,
            order_by: orderBy,
        };
        const filterString = btoa(encodeURIComponent(JSON.stringify(filter)));
        const paginationString = btoa(JSON.stringify(pagination));

        const params = new HttpParams().append('filter', filterString).append('pagination', paginationString);

        const responseMapper = (response): GetApplicantSearchResponse => {
            return {
                total_amount: response.total_amount,
                type: response.type,
                rows: response.rows.map(row => {
                    return {
                        applicant_uuid: row.applicant_uuid,
                        account_email: row.account_email,
                        application_count: row.application_count,
                        locked_by_other_caseworker: row.locked_by_other_caseworker,
                        values: Object.keys(row.values).map(key => {
                            const [uuid, projection] = key.split('{%}');
                            return { uuid, projection, value: row.values[key] };
                        }),
                    };
                }),
            };
        };

        return this.http
            .get<any>(this.apiBaseUrl + '/applicants/search', { params })
            .pipe(map(responseMapper), this.logError());
    }

    getBackendAccount(uuid: UUID): Observable<FondaAccount> {
        return this.http.get<FondaAccount>(this.apiBaseUrl + '/backend/account/' + uuid).pipe(this.logError());
    }

    getBackendApplicantApplications(applicantUuid: UUID): Observable<GetBackendApplicantApplications> {
        return this.http
            .get<GetBackendApplicantApplications>(`${this.apiBaseUrl}/backend/applicant/${applicantUuid}/applications`)
            .pipe(this.logError());
    }

    putAccountEmail(
        uuid: UUID,
        data: { propagate_to_applicant: boolean; propagate_to_applications: boolean; email: Email }
    ): Observable<void> {
        return this.http
            .put<void>(`${this.apiBaseUrl}/account/${uuid}/email`, data)
            .pipe(this.logError('update-applicant-account-email'), this.logSuccess('update-applicant-account-email'));
    }

    postAccountApplicant(data: { password: string; email: Email }): Observable<{ account_uuid: UUID }> {
        return this.http
            .post<{ account_uuid: UUID }>(`${this.apiBaseUrl}/backend/applicant-account`, data)
            .pipe(this.logError());
    }

    getAppSchemaBehaviours(uuid: string, record_type: SectionTypes): Observable<AppSchemaBehaviour[]> {
        const params = new HttpParams().append('record_type', record_type);
        return this.http
            .get<AppSchemaBehaviour[]>(`${this.apiBaseUrl}/application-schema/${uuid}/behaviours`, { params })
            .pipe(this.logError());
    }

    patchBackendApplicationSchema(application_uuid: string, application_schema_uuid: string): Observable<void> {
        return this.http
            .patch<void>(`${this.apiBaseUrl}/backend/application/${application_uuid}/application-schema`, {
                application_schema_uuid,
            })
            .pipe(this.logError());
    }

    postCategory(texts: { text: string; language: string }[]): Observable<{ uuid: string }> {
        return this.http
            .post<{ uuid: string }>(`${this.apiBaseUrl}/category`, { texts, account_system_account_number: null })
            .pipe(this.logError('add-category'));
    }

    patchCategory(uuid: string, texts: { text: string; language: string }[]): Observable<void> {
        return this.http
            .patch<void>(`${this.apiBaseUrl}/category/${uuid}`, { texts })
            .pipe(this.logError('update-category'));
    }

    deleteCategory(uuid: string): Observable<void> {
        return this.http.delete<void>(`${this.apiBaseUrl}/category/${uuid}`).pipe(this.logError());
    }

    deleteBackendUser(uuid: string): Observable<void> {
        return this.http.delete<void>(`${this.apiBaseUrl}/account/${uuid}`, { headers: this.defaultHeaders() });
    }

    getAllSubcategories(): Promise<Array<{ uuid: string; categoryUuid: string }>> {
        if (!this._allSubCategoriesCache) {
            const amap =
                <T, Y>(fn: (v: T) => Y) =>
                (arr: T[]): Y[] =>
                    arr.map(fn);
            const mapSubCategories = (subCategory: {
                uuid: string;
                category_uuid: string;
            }): { uuid: string; categoryUuid: string } => ({
                uuid: subCategory.uuid,
                categoryUuid: subCategory.category_uuid,
            });
            this._allSubCategoriesCache = this.http
                .get<Array<{ uuid: string; category_uuid: string }>>(`${this.apiBaseUrl}/sub-categories`, {
                    headers: this.defaultHeaders(),
                })
                .pipe(map(amap(mapSubCategories)))
                .toPromise();
        }

        return this._allSubCategoriesCache;
    }

    postAzureLoginCheck(code: string): Observable<{ jwt: string }> {
        const formData = new FormData();
        formData.append('code', code);
        return this.http
            .post<{ jwt: string }>(`${this.apiBaseUrl}/auth/azure-login-check`, formData)
            .pipe(this.logError('login-via-azure'));
    }

    deleteSiteSetting(siteSetting: string): Observable<void> {
        return this.http.delete<void>(`${this.apiBaseUrl}/site-settings/${siteSetting}`).pipe(this.logError());
    }

    putSiteSettingAzureConnection(data: { client_id: string; client_secret: string }): Observable<void> {
        return this.http
            .put<void>(`${this.apiBaseUrl}/site-settings/azure-connection`, data, { headers: this.defaultHeaders() })
            .pipe(this.logError());
    }

    putSiteSettingAccountCorrespondenceResponsibleCaseworkers(data: string[]): Observable<void> {
        return this.http
            .put<void>(`${this.apiBaseUrl}/site-settings/account-correspondence-responsible-caseworkers`, data, {
                headers: this.defaultHeaders(),
            })
            .pipe(this.logError());
    }

    putSiteSettingLoginPage(data: LoginPageSettingsWithTextsDto) {
        return this.http
            .put<void>(`${this.apiBaseUrl}/site-settings/login-page`, data, {
                headers: this.defaultHeaders(),
            })
            .pipe(this.logError());
    }

    putBackendApplicantResponsibleCaseworkers(applicantUuid: string, caseworkers: string[]): Observable<void> {
        return this.http
            .put<void>(
                `${this.apiBaseUrl}/backend/applicant/${applicantUuid}/responsible-caseworkers`,
                { responsible_caseworkers_uuids: caseworkers },
                { headers: this.defaultHeaders() }
            )
            .pipe(this.logError());
    }

    getTerritories(): Observable<string[]> {
        return this.http.get<string[]>(`${this.apiBaseUrl}/territories`).pipe(this.logError());
    }

    postBackendApplication(data: { applicant_uuid: string; application_schema_uuid: string }): Observable<UUID> {
        return this.http
            .post<{ application_uuid: UUID }>(`${this.apiBaseUrl}/backend/application`, data, {
                headers: this.defaultHeaders(),
            })
            .pipe(
                map(res => res.application_uuid),
                this.logError()
            );
    }

    getCustomLists(): Observable<CustomListDto[]> {
        return this.http
            .get<GetCustomListCollectionApiResponse>(`${this.apiBaseUrl}/custom-lists`)
            .pipe(map(CustomListMapper.fromCollectionResponse), this.logError());
    }

    postCustomList(data: CreateCustomListDto): Observable<string> {
        return this.http
            .post<{ custom_list_uuid: string }>(`${this.apiBaseUrl}/custom-list`, data)
            .pipe(pluck('custom_list_uuid'), this.logError('create-custom-list'));
    }

    patchCustomList(dto: UpdateCustomListDto): Observable<CustomListDto> {
        return this.http
            .patch<GetCustomListApiResponse>(`${this.apiBaseUrl}/custom-list`, dto)
            .pipe(this.logError('update-custom-list'), map(CustomListMapper.fromResponse));
    }

    getRecordDynamicPrefillings(uuid: string, value: any): Observable<{ uuid: string; value: any }[]> {
        return this.http.post<{ uuid: string; value: any }[]>(`${this.apiBaseUrl}/record-dynamic-prefillings`, {
            uuid: uuid,
            value: value,
        });
    }

    getEconomyGroups(): Observable<string[]> {
        return this.http.get<string[]>(`${this.apiBaseUrl}/economy-groups`);
    }

    postApplicationOwnership({
        applicationUuid,
        applicantEmail,
    }: {
        applicationUuid: string;
        applicantEmail: string;
    }): Observable<void> {
        const json = {
            application_uuid: applicationUuid,
            applicant_email: applicantEmail,
        };
        return this.http
            .post<void>(this.apiBaseUrl + `/application-ownership`, json, { headers: this.defaultHeaders() })
            .pipe(this.logError('share-application'), this.notifyOnSuccess('share-application'));
    }

    getBackendDocuments(): Observable<FileDocumentDto[]> {
        return this.http.get<FileDocumentDto[]>(`${this.apiBaseUrl}/backend/documents`);
    }

    getDocuments(): Observable<FileDocumentDto[]> {
        return this.http.get<FileDocumentDto[]>(`${this.apiBaseUrl}/documents`);
    }

    getCaseDocuments(caseUuid: UUID): Observable<FileDocumentDto[]> {
        return this.http.get<FileDocumentDto[]>(`${this.apiBaseUrl}/application/${caseUuid}/files/applicant`);
    }

    deleteDocument(uuid: UUID) {
        return this.http.delete(`${this.apiBaseUrl}/document/${uuid}`);
    }

    addDocument(fileUuid: UUID, type: DocumentTypeDto) {
        return this.http.post(`${this.apiBaseUrl}/document`, {
            file_uuid: fileUuid,
            document_type: type,
        });
    }

    changeDocumentVisibility(uuid: UUID, visible: boolean) {
        return this.http.put(`${this.apiBaseUrl}/document/${uuid}/visible`, {
            visible: visible,
        });
    }

    private logError<T>(identifier?: string) {
        return tap<T>({
            error: error => this.handleErrorResponse(error, identifier),
        });
    }

    private notifyOnSuccess<T>(identifier: string) {
        return tap<T>({
            next: () => this.handleSuccessResponse(identifier),
        });
    }

    private defaultHeaders(): HttpHeaders {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        return headers;
    }

    private binaryHeaders(): HttpHeaders {
        const headers = new HttpHeaders({
            'Content-Type': 'text/plain',
        });
        return headers;
    }

    private handleErrorResponse(error: any, identifier?: string) {
        // do not log error on 503
        if (error.status === 503) return;

        ApiResponseToasterHelper.handleError(
            new ApiResponseErrorAdapter(error && error.error, identifier),
            this.translateService,
            this.toasterService
        );
    }

    private handleSuccessResponse(identifier: string) {
        ApiResponseToasterHelper.handleSuccess(identifier, this.translateService, this.toasterService);
    }

    private createBlobFile(response: HttpResponse<Blob>): UploadedFondaFile {
        const file = new UploadedFondaFile(
            '',
            this.getFileNameForBlob(response),
            response.body.type,
            response.body.size
        );
        file.blob = response.body;
        return file;
    }

    private getFileNameForBlob(response: HttpResponse<Blob>): string {
        const contentDisposition = response.headers.get('content-disposition');
        if (!contentDisposition) return 'unknown-file';

        const match = /filename="(.*)"/.exec(contentDisposition);
        return match[1];
    }

    private logSuccess<T>(identifier) {
        return tap<T>(() => {
            this.handleSuccessResponse(identifier);
        });
    }
}

class GhQueryEncoder extends HttpUrlEncodingCodec {
    encodeKey(k: string): string {
        k = super.encodeKey(k);
        return k.replace(/\+/gi, '%2B');
    }

    encodeValue(v: string): string {
        v = super.encodeKey(v);
        return v.replace(/\+/gi, '%2B');
    }
}
