import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { PdfFileCreator } from '../../../services/pdf-file-creator';
import { LanguageStoreService } from '../../../services/language-store.service';
import { LocalStorageService } from '../../../services/local-storage-service';
import { FileService } from '../../../services/file.service';
import { CorrespondencePdfService, PdfToken } from '../../../services/correspondence-pdf-service';

export interface IPdfGenerateData {
    baseUri: string;
    fileName: string;
    bodyText: string;
}

class PdfGenerateData implements IPdfGenerateData {
    constructor(public bodyText: string, public fileName: string, public baseUri: string) {}
}

@Component({
    selector: 'correspondence-pdf',
    templateUrl: 'correspondence-pdf.component.html',
})
export class CorrespondencePdfComponent {
    public tokens: Array<PdfToken>;
    public bodyShare = '';
    public fileName = '';

    @Output('pdfGenerate') pdfGenerate = new EventEmitter<PdfGenerateData>();

    constructor(private correspondencePdfService: CorrespondencePdfService, private pdfFileCreator: PdfFileCreator) {}

    public initPdfComponent(bodyText: string, fileName: string) {
        this.correspondencePdfService.encodeTokens(bodyText).then(({ value, tokens }) => {
            this.tokens = tokens;
            this.bodyShare = value;
        });
        this.fileName = this.correspondencePdfService.getFileName(fileName) + '.pdf';
    }

    public async generatePdf() {
        this.pdfFileCreator
            .createPdf(this.correspondencePdfService.decodeTokensWithContentValue(this.bodyShare, this.tokens))
            .then(uri => {
                const text = this.correspondencePdfService.decodeTokenToOriginValues(this.bodyShare, this.tokens);
                this.pdfGenerate.emit(new PdfGenerateData(text, this.fileName, uri));
            });
    }

    public previewPdf() {
        this.pdfFileCreator.downloadPdf(
            this.correspondencePdfService.decodeTokensWithContentValue(this.bodyShare, this.tokens)
        );
    }
}
