<div class="responsible">
    <div class="caseworker-selector">
        <fonda-select
            [value]=""
            [label]="'Select caseworker'"
            [options]="uniqueOptions()"
            [canUnselect]="false"
            [multiple]="false"
            [boxDisplay]="false"
            (valueChange)="addSelected($event)"
        >
            <ng-container *fondaSelectOption="let caseworker">
                <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: caseworker }"></ng-container>
            </ng-container>
        </fonda-select>
    </div>
    <div class="selected">
        <div class="selected-item" *ngFor="let selectedCaseworker of selected">
            <fonda-caseworker-badge (remove)="removeSelected(selectedCaseworker)">
                <ng-container
                    *ngTemplateOutlet="optionTemplate; context: { $implicit: selectedCaseworker }"
                ></ng-container>
            </fonda-caseworker-badge>
        </div>
    </div>
</div>
