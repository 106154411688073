<ng-container *isNotOnPWA>
    <div
        *ngIf="!isReadonly && !isOnSearch"
        class="form-item"
        [ngClass]="{ half: metadata.size === 1, 'force-full': metadata.size === 2 }"
    >
        <textarea
            autosize
            [minHeight]="70"
            id="{{ metadata.uuid }}"
            [formControl]="text"
            placeholder=" "
            rows="3"
        ></textarea>
        <label for="{{ metadata.uuid }}">
            {{ metadata.uuid | translate : 'title' }}
            <span *ngIf="metadata.isRequired" class="mandatory">*</span>
        </label>
        <div class="form-focus"></div>
        <div class="form-error"></div>
        <div class="form-error configuration active" *ngIf="metadata.configuration.max_length">
            {{ text.value.length }} / {{ metadata.configuration.max_length }}
        </div>
    </div>

    <div *ngIf="!isReadonly && isOnSearch" class="form-item" [ngClass]="{ half: metadata.size === 1 }">
        <input id="{{ metadata.uuid }}-search" [formControl]="text" placeholder=" " type="text" />
        <label for="{{ metadata.uuid }}-search">{{ metadata.title }}</label>
        <div class="form-focus"></div>
    </div>

    <a class="form-item" *ngIf="isReadonly" [ngClass]="{ full: metadata.size === 2 }">
        <p>{{ metadata.uuid | translate : 'title' }}</p>
        <span [innerHTML]="readonlyValue || '-'"></span>
        <div class="form-focus"></div>
        <div class="form-error"></div>
    </a>
</ng-container>

<ng-container *isOnPWA>
    <ng-container *ngIf="!isReadonly">
        <pwa-list-element>
            <pwa-labelled-text [mandatory]="metadata.isRequired" [errorMessage]="errorMessage">
                <ng-container pwaLabelledTextLabel>
                    {{ metadata.uuid | translate : 'title' }}
                </ng-container>
                <ng-container pwaLabelledTextContent>
                    <textarea
                        autosize
                        [minHeight]="65"
                        id="{{ metadata.uuid }}"
                        [formControl]="text"
                        placeholder=" "
                        rows="3"
                    ></textarea>
                </ng-container>
            </pwa-labelled-text>
        </pwa-list-element>
    </ng-container>
    <ng-container *ngIf="isReadonly">
        <pwa-list-element>
            <pwa-labelled-text>
                <ng-container pwaLabelledTextLabel>
                    {{ metadata.uuid | translate : 'title' }}
                </ng-container>
                <ng-container pwaLabelledTextContent>
                    <span [innerHTML]="readonlyValue || '-'"></span>
                </ng-container>
            </pwa-labelled-text>
        </pwa-list-element>
    </ng-container>
</ng-container>
