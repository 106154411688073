<ng-container *isNotOnPWA>
    <ng-container *ngIf="!isReadonly && !isOnSearch">
        <div class="form-item-container">
            <div class="form-item half" *ngIf="showCheckbox">
                <div class="checkbox">
                    <input
                        type="checkbox"
                        placeholder=" "
                        style="width: 24px; height: 24px"
                        [formControl]="isCompany"
                        id="application-awaiting-setting-{{ metadata.uuid }}"
                    />
                    <label for="application-awaiting-setting-{{ metadata.uuid }}" style="font-size: 1em">
                        {{ metadata.uuid | translate : 'is_company' }}
                        <span *ngIf="metadata.isRequired" class="mandatory">*</span>
                    </label>
                </div>
            </div>
            <div class="form-item no-margin" [ngClass]="{ readonly: !isCompany.value, 'half last': showCheckbox }">
                <input
                    id="{{ metadata.uuid }}-on-edit"
                    type="text"
                    name="value2"
                    [formControl]="companyName"
                    placeholder=" "
                />
                <label for="{{ metadata.uuid }}-on-edit">
                    {{ metadata.uuid | translate : 'company_name' }}
                    <span *ngIf="!showCheckbox && metadata.isRequired" class="mandatory">*</span>
                </label>
                <div class="form-focus"></div>
                <div class="form-error" [ngClass]="{ active: isCompany.value && companyName.errors?.minlength }">
                    Indsæt mindst {{ metadata.configuration && metadata.configuration.min_length }} tegn
                </div>
                <div
                    class="form-error configuration active"
                    *ngIf="companyName.value && metadata.configuration.max_length"
                >
                    {{ companyName.value.length }} / {{ metadata.configuration.max_length }}
                </div>
            </div>
        </div>
    </ng-container>

    <div class="form-item" [ngClass]="{ half: metadata.size === 1 }" *ngIf="!isReadonly && isOnSearch">
        <div class="checkbox search">
            <input
                type="checkbox"
                placeholder=" "
                style="width: 24px; height: 24px"
                [formControl]="isCompany"
                id="{{ metadata.uuid }}-on-search"
                name=""
            />
            <label for="{{ metadata.uuid }}-on-search" style="font-size: 1em">
                {{ metadata.uuid | translate : 'title' }}
            </label>
        </div>
    </div>

    <a *ngIf="isReadonly && !isOnSearch && showCheckbox" class="full readonly">
        <ul class="readonly-checkbox">
            <li class="form-item readonly-checkbox" *ngIf="showCheckbox">
                <input
                    type="checkbox"
                    placeholder=" "
                    [value]="isCompany.value"
                    disabled
                    id="readonly-iscompany-{{ metadata.uuid }}"
                    name=""
                />
                <label for="readonly-iscompany-{{ metadata.uuid }}" style="font-size: 1em">
                    {{ metadata.uuid | translate : 'is_company' }}
                </label>
            </li>
            <li class="form-item" [style.margin-top]="showCheckbox ? '3rem' : ''" [ngClass]="{ full: !showCheckbox }">
                <p>{{ metadata.uuid | translate : 'company_name' }}</p>
                <span>{{ companyName.value || '-' }}</span>
                <div class="form-focus"></div>
                <div class="form-error"></div>
            </li>
        </ul>
    </a>
</ng-container>

<ng-container *isOnPWA>
    <div class="pwa-company-record" *ngIf="!isReadonly && !isOnSearch">
        <pwa-list-element>
            <pwa-checkbox
                [mandatory]="metadata.isRequired"
                *ngIf="showCheckbox"
                label="{{ metadata.uuid | translate : 'is_company' }}"
            >
                <input type="checkbox" pwaCheckbox [formControl]="isCompany" />
            </pwa-checkbox>
            <pwa-labelled-text [mandatory]="!showCheckbox && metadata.isRequired" [errorMessage]="errorMessage">
                <ng-container pwaLabelledTextLabel>
                    {{ metadata.uuid | translate : 'company_name' }}
                </ng-container>
                <ng-container pwaLabelledTextContent>
                    <input
                        #companyInput
                        [readonly]="!isCompany.value"
                        [id]="metadata.uuid"
                        type="text"
                        [formControl]="companyName"
                        placeholder=" "
                    />
                </ng-container>
            </pwa-labelled-text>
        </pwa-list-element>
    </div>
    <div *ngIf="isReadonly && !isOnSearch">
        <pwa-list-element>
            <pwa-labelled-text>
                <ng-container pwaLabelledTextLabel>
                    {{ metadata.uuid | translate : 'company_name' }}
                </ng-container>
                <ng-container pwaLabelledTextContent>
                    {{ companyName.value || '-' }}
                </ng-container>
            </pwa-labelled-text>
        </pwa-list-element>
    </div>
</ng-container>
