<td [attr.colspan]="colspan" class="payment-block-children">
    <table>
        <tr>
            <th>
                {{ GRANTED_AMOUNT_RECORD_UUID | translate : 'name' }}
            </th>
            <th>
                {{ 'general' | translate : 'attachments' }}
            </th>
            <th>
                {{ GRANTED_AMOUNT_RECORD_UUID | translate : 'amount' }}
            </th>
            <th>
                {{ GRANTED_AMOUNT_RECORD_UUID | translate : 'original_full_amount' }}
            </th>
            <th>
                {{ 'general' | translate : 'economy_group' }}
            </th>
            <th>
                {{ GRANTED_AMOUNT_RECORD_UUID | translate : 'board_meeting' }}
            </th>
            <th>
                <!-- % -->
            </th>
            <th>
                {{ GRANTED_AMOUNT_RECORD_UUID | translate : 'amount' }}
            </th>
        </tr>
        <tr
            granted-amount-readonly-line
            *ngFor="let line of children"
            [line]="line"
            [boardMeetings]="boardMeetings"
            [paymentView]="true"
        ></tr>
    </table>
</td>
