import {
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { PwaInputDirective } from './pwa-input.directive';

@Component({
    selector: 'pwa-input',
    templateUrl: './pwa-input.component.html',
    styleUrls: ['./pwa-input.component.scss'],
    host: {
        class: 'pwa-input',
        '[class.currency]': 'currency',
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class PwaInputComponent implements AfterContentInit, OnDestroy {
    @ContentChild(PwaInputDirective) input: PwaInputDirective;

    @Input() validation: boolean = false;
    @Input() reset: boolean = false;
    @Input() currency: boolean = false;
    @Output() onReset = new EventEmitter<void>();

    inputSub$: Subscription;

    constructor(private cdRef: ChangeDetectorRef) {}

    get valid() {
        if (this.input) return this.input.valid;
        return true;
    }

    get empty() {
        if (this.input) return this.input.empty;
        return false;
    }

    ngAfterContentInit() {
        if (this.input) {
            this.inputSub$ = this.input.stateChanges.subscribe(() => {
                this.cdRef.markForCheck();
            });
        }
    }

    getClass() {
        if (!this.validation) return '';
        if (this.valid) return 'valid';
        return 'invalid';
    }

    resetInput() {
        if (this.input) this.input.resetValue();
        this.onReset.emit();
    }

    ngOnDestroy() {
        if (this.inputSub$) this.inputSub$.unsubscribe();
    }
}
