<div id="downloadableThing">
    <div class="admin-application-review applicant downloading">
        <div class="container" *ngIf="_sections && _sections.length > 0">
            <ng-container *ngFor="let section of sectionsWithRecords; let ind = index; let first = first">
                <div *ngIf="!first" data-pdf-next-page></div>
                <div class="application-flow">
                    <h2 [class.download-first-header]="first" data-pdf-part>
                        {{ section.sectionType | sectionTranslate : section.id : 'title' : section.schemaUuid }}
                    </h2>
                    <record-values-edit
                        #editRecords
                        (afterInit)="onAfterInit()"
                        [recordValuesEditInput]="{ recordValues: section.recordValues, isOnCaseworkerMode: true }"
                        [readonlyGroup]="true"
                    ></record-values-edit>
                </div>
            </ng-container>
        </div>
    </div>
</div>
