import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'fonda-caseworker-badge',
    templateUrl: './caseworker-badge.html',
    styleUrls: ['./caseworker-badge.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaseworkerBadge {
    @Input() imageUrl: string;
    @Output() remove = new EventEmitter<void>();

    onRemove(): void {
        this.remove.emit();
    }
}
