import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PrimitiveBaseRecordComponent } from '../primitive-base-record.component';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'primitive-text-record',
    templateUrl: 'primitive-text-record.component.html',
})
export class PrimitiveTextRecordComponent extends PrimitiveBaseRecordComponent {
    @Input('uuid') public uuid: string;
    @Input('value') public value: object;
    @Input('isRequired') public isRequired: boolean;
    @Input('size') public size: number;
    private initValue: string;
    public text = new FormControl();
    @Input('title') title: string;
    @Input('readonly') public readonly = false;
    @Output('onChange') onChange = new EventEmitter<object>();
    @Input('configuration') configuration: object;

    public emitValue() {
        this.onChange.emit(this.getValue());
    }

    public ngOnInit() {
        this.initValue = this.value['text'];
        this.text.setValue(this.value['text']);
        this.text.valueChanges.subscribe(() => {
            this.emitValue();
        });
        this.emitValue();
    }

    public isValid(): boolean {
        return this.text.valid;
    }

    public isChanged(): boolean {
        return (this.initValue || this.text.value) && this.initValue != this.text.value;
    }
    public isEmpty(): boolean {
        return !this.value;
    }
    public getValue(): object {
        this.value['text'] = this.text.value;
        return this.value;
    }
}
