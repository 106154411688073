<!--<div class="application-header">-->
<!--<h2>Dokumenter</h2>-->
<!--<div class="actions">-->
<!--<ul>-->
<!--<li>-->
<!--<a class="icon-refresh" (click)="fetchFiles()"><a class="refresh"></a></a>-->
<!--</li>-->
<!--<ng-content></ng-content>-->
<!--</ul>-->
<!--</div>-->
<!--</div>-->

<div class="overlay" style="position: absolute" *ngIf="isLoading">
    <div style="position: relative; height: 100%; width: 100%">
        <div class="loader-container" style="position: absolute">
            <div class="loader"></div>
        </div>
    </div>
</div>

<div style="margin-bottom: 20px">
    <table class="files-table">
        <tr>
            <th>{{ uuid | translate : 'name' }}</th>
            <th>{{ uuid | translate : 'uploaded_by' }}</th>
            <th>{{ uuid | translate : 'size' }}</th>
            <th>{{ uuid | translate : 'type' }}</th>
            <th></th>
            <!--<th></th>-->
        </tr>
        <ng-container *ngIf="files?.length > 0">
            <tr *ngFor="let file of files">
                <td>
                    <abbr class="file-name">
                        {{ file.fileName }}
                    </abbr>
                </td>
                <td>
                    {{
                        file.uploader &&
                            (file.uploader.type == 'applicant'
                                ? ('general' | translate : 'applicant')
                                : file.uploader.username)
                    }}
                </td>
                <td style="min-width: 85px">{{ file.fileSize | fileSize }}</td>
                <td>
                    <abbr>
                        {{ 'file_type' | translate : file.resourceType }}
                    </abbr>
                </td>
                <td (click)="downloadFile(file)">
                    <div class="download-icon"></div>
                </td>
                <!--<td *ngIf="file.deletable">-->
                <!--<div class="delete-icon">-->
                <!--</div>-->
                <!--</td>-->
            </tr>
        </ng-container>
        <ng-container *ngIf="files?.length === 0">
            <tr>
                <td>
                    <i>{{ uuid | translate : 'no_files_found' }}</i>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </ng-container>
    </table>
</div>
